import { StateCreator } from 'zustand';
import { AccessTokenSlice } from './accessTokenSlice';
import { ResearchProjectSlice } from './researchProjectSlice';
import { PatientSlice } from './patientSlice';
import { ResearchProjectPatientsSlice } from './researchProjectPatientsSlice';
import { PatientDataSlice } from './patientDataSlice';
import { PatientReview, REVIEW_STATUSES } from '../../../../data/PatientReviewData';
import { PatientApprovalPatient } from '../../../../data/PatientApprovalData';
import { MeasurementDefinition } from '../../../../data/MeasurementDefinitionData';
import { MeasurementDefinitionsSlice } from './measurementDefinitionsSlice';
import { MeasurementSlice } from './measurementSlice';

export interface AppActionsSlice {
  storeAccessToken: (accessToken: string) => void;
  selectResearchProject: (researchProjectId: string) => void;
  selectPatient: (patient: PatientApprovalPatient) => void;
  selectMeasurement: (measurement: MeasurementDefinition) => void;
  applyClientSideReview: (review: PatientReview) => void;
}

export const createAppActionsSlice: StateCreator<
  AccessTokenSlice &
    ResearchProjectSlice &
    PatientSlice &
    ResearchProjectPatientsSlice &
    PatientDataSlice &
    MeasurementDefinitionsSlice &
    MeasurementSlice,
  [],
  [],
  AppActionsSlice
> = (set, get) => ({
  storeAccessToken: accessToken => {
    get().setAccessToken(accessToken);

    // Measurement definition types are global so load them as soon as we get an access token.
    let measurementDefinitionTypes = get().measurementDefinitionTypes;
    if (
      measurementDefinitionTypes.data === undefined &&
      !measurementDefinitionTypes.loading &&
      measurementDefinitionTypes.error === undefined
    ) {
      get().loadMeasurementDefinitionTypesFromServer();
    }
  },
  // Update the research project and clear out all patient-related data, loading the new research project's patients
  // from the server.
  selectResearchProject: researchProjectId => {
    get().setResearchProjectId(researchProjectId);
    get().setPatient(undefined);
    get().loadResearchProjectPatientsFromServer(researchProjectId);
    get().setPatientData(undefined);
    get().setMeasurementDefinitions(undefined);
    get().setMeasurement(undefined);
    get().loadMeasurementDefinitionsFromServer(researchProjectId);
  },

  // Update the patient and load their data from the server.
  selectPatient: patient => {
    const researchProjectId = get().researchProjectId;
    if (!researchProjectId) {
      return;
    }

    get().setPatient(patient);
    get().loadPatientDataFromServer(researchProjectId, patient.deidentity);
  },

  // Update the selected measurement.
  selectMeasurement: measurement => {
    get().setMeasurement(measurement);
  },

  // Update the existing research project's patients with the new decision, client-side only.
  applyClientSideReview: (review: PatientReview) => {
    const status = REVIEW_STATUSES.find(s => s.reviewStatusId === review.reviewStatusId);
    if (status === undefined) {
      return;
    }

    const researchProjectPatients = get().researchProjectPatients?.data;

    if (researchProjectPatients) {
      const newPatients = researchProjectPatients.map(p => {
        if (p.deidentity === review.deidentity) {
          const newPatient = { ...p };

          newPatient.reviewStatusId = status.reviewStatusId;
          newPatient.reviewScore = review.score;
          newPatient.reviewNotes = review.notes;

          return newPatient;
        }

        return p;
      });

      get().setResearchProjectPatients(newPatients);
    }
  },
});
