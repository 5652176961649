import React, { ComponentProps } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { AuthorizedSection } from './AuthorizedSection';
import { UnauthorizedPage } from '../pages/UnauthorizedPage';

type Props = Omit<ComponentProps<typeof AuthorizedSection>, 'unauthorizedComponent'>;

export const AuthorizedPage = (props: Props) => {
  const { isAuthenticated } = useAuth0();

  const unauthorizedPage = <UnauthorizedPage />;

  if (isAuthenticated) {
    return <AuthorizedSection {...props} unauthorizedComponent={unauthorizedPage} />;
  } else {
    return unauthorizedPage;
  }
};
