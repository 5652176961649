import { Box, Dialog, DialogActions, DialogContent, DialogTitle, TextField, Typography } from '@mui/material';
import { Dispatch, MutableRefObject, SetStateAction, useEffect, useState } from 'react';
import { DialogCloseButton } from '../../components/DialogCloseButton';
import { DialogOpenButton } from '../../components/DialogOpenButton';
import InputIcon from '@mui/icons-material/Input';
import useMemoTranslation from '../../hooks/UseMemoTranslation';
import { CancelButton } from '../../components/CancelButton';
import { PrimaryButton } from '../../components/PrimaryButton';
import { GridTextParsingState } from 'components/GridTextParsing';
import { CompactGridWrapper } from 'components/grid/CompactGridWrapper';
import { GridApiPro, GridColDef, GridRowSelectionModel, GridValidRowModel, useGridApiRef } from '@mui/x-data-grid-pro';
import { invalidSampleIdentifier, invalidSampleIdsFileName } from 'util/Constants';
import { GridExportButton } from 'components/GridExportButton';
import { FlexTableBox } from 'components/FlexTableBox';
import { FlexBox } from 'components/FlexBox';
import { Many, pick, uniq, values } from 'lodash';

interface SampleTrackingRowSelectionFeedback {
  numRowsFound: number;
  numRowsNotFound: number;
  idsNotFound: ReadonlyArray<string>;
}

export interface SampleTrackingRowSelectionModalProps {
  gridApiRef: MutableRefObject<GridApiPro>;
  sampleIdProperties: Many<keyof GridValidRowModel>;
  setSelectionModel: Dispatch<React.SetStateAction<GridRowSelectionModel | undefined>>;
}

export const SampleTrackingRowSelectionModal = ({
  gridApiRef,
  sampleIdProperties,
  setSelectionModel,
}: SampleTrackingRowSelectionModalProps) => {
  const { t } = useMemoTranslation();
  const defaultRowSelectionFeedback: SampleTrackingRowSelectionFeedback = {
    numRowsFound: 0,
    numRowsNotFound: 0,
    idsNotFound: [],
  };

  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [state, setState] = useState<GridTextParsingState>('TextGrid');
  const [stringData, setStringData] = useState<string>('');
  const [parsedData, setParsedData] = useState<ReadonlyArray<string>>([]);
  const [rowSelectionFeedback, setRowSelectionFeedback] =
    useState<SampleTrackingRowSelectionFeedback>(defaultRowSelectionFeedback);

  useEffect(() => {
    const data: string[] = [];

    stringData.split('\n').forEach(rowString => {
      if (!rowString || rowString.length === 0) {
        return;
      }

      data.push(rowString.trim());
    });

    setParsedData(data);
  }, [stringData]);

  function handleClose() {
    setModalOpen(false);
    setStringData('');
    setParsedData([]);
    setRowSelectionFeedback(defaultRowSelectionFeedback);
    setState('TextGrid');
  }

  function handleParseData() {
    const feedback = validateSelectedIds();

    if (feedback.numRowsNotFound === 0) {
      handleSubmit();
    } else {
      setRowSelectionFeedback(feedback);
      setState('Validation');
    }
  }

  function handleSubmit() {
    selectRows();
    handleClose();
  }

  function validateSelectedIds(): SampleTrackingRowSelectionFeedback {
    const normalizedIds = uniq(parsedData.map(id => id?.toLowerCase().trim()));
    let foundIds: string[] = [];

    gridApiRef.current.getSortedRows().forEach(row => {
      const propertiesToCheck = pick(row, sampleIdProperties);

      normalizedIds.forEach(id => {
        values(propertiesToCheck).forEach(val => {
          if (val && (val as string).toLowerCase().trim() === id) {
            foundIds.push(id);
          }
        });
      });
    });

    foundIds = uniq(foundIds);

    return {
      numRowsFound: foundIds.length,
      numRowsNotFound: normalizedIds.length - foundIds.length,
      idsNotFound: normalizedIds.filter(id => !foundIds.includes(id)),
    };
  }

  function selectRows() {
    const normalizedIds = parsedData.map(id => id?.toLowerCase().trim());
    const selectedRows: number[] = [];

    gridApiRef.current.getSortedRows().forEach(row => {
      const propertiesToCheck = pick(row, sampleIdProperties);

      normalizedIds.forEach(id => {
        values(propertiesToCheck).forEach(val => {
          if (val && (val as string).toLowerCase().trim() === id) {
            selectedRows.push(row.id);
          }
        });
      });
    });

    setSelectionModel(uniq(selectedRows));
  }

  return (
    <>
      <DialogOpenButton title={t('selectSamples')} onClick={() => setModalOpen(true)}>
        <InputIcon />
      </DialogOpenButton>
      <Dialog open={modalOpen} fullWidth={true} maxWidth={'sm'}>
        <DialogTitle>
          <DialogCloseButton onClick={handleClose} />
          {t('selectSamples')}
          {state === 'TextGrid' && <Typography variant='subtitle1'>{t('selectRowsInfo')}</Typography>}
          {state === 'Validation' && (
            <Box display={'flex'} flexDirection={'row'} justifyItems={'center'}>
              <Typography variant='subtitle1' display={'flex'} flexDirection={'row'} justifyItems={'center'}>
                {`Of the ${
                  rowSelectionFeedback.numRowsFound + rowSelectionFeedback.numRowsNotFound
                } Sample Identifier(s) entered, 
                  ${rowSelectionFeedback.numRowsNotFound} ${
                  rowSelectionFeedback.numRowsNotFound === 1 ? 'is' : 'are'
                } invalid and will not be selected.`}
              </Typography>
            </Box>
          )}
        </DialogTitle>
        {state === 'TextGrid' && (
          <TextInputModalContents
            parsedData={parsedData}
            setStringData={setStringData}
            handleClose={handleClose}
            handleParseData={handleParseData}
          />
        )}
        {state === 'Validation' && (
          <InvalidIdsModalContents
            rowSelectionFeedback={rowSelectionFeedback}
            handleClose={handleClose}
            handleSubmit={handleSubmit}
          />
        )}
      </Dialog>
    </>
  );
};

const TextInputModalContents = ({
  parsedData,
  setStringData,
  handleClose,
  handleParseData,
}: {
  parsedData: ReadonlyArray<string>;
  setStringData: Dispatch<SetStateAction<any>>;
  handleClose: () => void;
  handleParseData: () => void;
}) => {
  const { t } = useMemoTranslation();

  return (
    <>
      <DialogContent>
        <Box component='form' sx={{ marginTop: 2 }}>
          <TextField
            multiline
            fullWidth
            label={t('ids')}
            type='text'
            onChange={event => setStringData(event.target.value)}
            placeholder={t('sampleBbidOrSampleForeignHashOrLabAssignedId')}
            rows={10}
            autoFocus
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <CancelButton onClick={handleClose} />
        <PrimaryButton onClick={handleParseData} disabled={parsedData.length === 0}>
          {t('submit')}
        </PrimaryButton>
      </DialogActions>
    </>
  );
};

const InvalidIdsModalContents = ({
  rowSelectionFeedback,
  handleClose,
  handleSubmit,
}: {
  rowSelectionFeedback: SampleTrackingRowSelectionFeedback;
  handleClose: () => void;
  handleSubmit: () => void;
}) => {
  const { t } = useMemoTranslation();
  const apiRef = useGridApiRef();

  var columns: GridColDef[] = [
    {
      field: invalidSampleIdentifier,
      headerName: t(invalidSampleIdentifier),
      flex: 2,
      resizable: false,
    },
  ];

  const rows: any[] = [];
  let id = 1;
  rowSelectionFeedback.idsNotFound.forEach(notFoundId => {
    rows.push({
      id: id++,
      invalidSampleIdentifier: notFoundId,
    });
  });

  return (
    <>
      <DialogContent>
        <FlexTableBox sx={{ height: '275px' }}>
          <FlexBox width={'100%'} flexDirection='row' justifyContent='right'>
            <GridExportButton apiRef={apiRef} fileName={t(invalidSampleIdsFileName)} />
          </FlexBox>
          <CompactGridWrapper
            apiRef={apiRef}
            rows={rows}
            columns={columns}
            disableColumnFilter={true}
            disableColumnMenu={true}
            disableRowSelectionOnClick
            hideFooterRowCount
          />
        </FlexTableBox>
      </DialogContent>
      <DialogActions>
        <CancelButton onClick={handleClose} />
        <PrimaryButton onClick={handleSubmit}>{t('submit')}</PrimaryButton>
      </DialogActions>
    </>
  );
};
