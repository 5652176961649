import React, { useEffect, useState } from 'react';

import { Box, Grow, IconButton, Paper, Popper, Skeleton, Stack, Tab, Tabs, Typography, useTheme } from '@mui/material';

import ReviewGroup from '../reviewGroup/ReviewGroup';

import { usePatient, usePatientData } from '../../stores/dataStore';
import { ContentCopyOutlined, ErrorTwoTone, InfoTwoTone } from '@mui/icons-material';
import PatientJourneyGroup from '../patientJourneyGroup/PatientJourneyGroup';
import CuratedDataGroup from '../curatedDataGroup/CuratedDataGroup';
import { t } from 'i18next';

type DataView = 'patientJourney' | 'curatedData';

const PatientFrame: React.FC<{
  onDoneReviewing: () => void;
}> = ({ onDoneReviewing }) => {
  const theme = useTheme();

  // Load this data here so that we can show a loading indicator if it's not ready.
  const patient = usePatient();
  const { loading, data: patientData, error: patientDataError } = usePatientData();

  const renderSkeleton = loading;
  const renderError = !loading && patientDataError !== undefined;
  const renderNoData = !loading && patientDataError === undefined && patientData === undefined;
  const renderData = !loading && patientData !== undefined;

  const [copyButtonEl, setCopyButtonEl] = useState<HTMLButtonElement | null>(null);
  const [copiedAlertOpen, setCopiedAlertOpen] = useState(false);
  useEffect(() => {
    if (!copiedAlertOpen) {
      return;
    }
    const timeout = setTimeout(() => {
      setCopiedAlertOpen(false);
    }, 2000);

    return () => clearTimeout(timeout);
  }, [copiedAlertOpen, setCopiedAlertOpen]);

  const [dataView, setDataView] = useState<DataView>('patientJourney');

  return (
    <Stack flex={1} direction='column' sx={{ marginTop: theme.spacing(-3) }}>
      <Box
        component='fieldset'
        flex={1}
        padding={2}
        paddingTop={0}
        maxWidth={'100%'}
        sx={{
          color: theme.palette.primary.main,
          borderColor: theme.palette.primary.main,
          border: '1px solid',
          borderRadius: 1,
        }}
      >
        <Typography
          component='legend'
          sx={{
            width: 'auto',
            maxWidth: '100%',
            paddingLeft: theme.spacing(1),
            marginBottom: 0,
            color: theme.palette.primary.main,
          }}
        >
          <Stack direction='row' spacing={1} alignItems='center'>
            <Box
              sx={{
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              }}
            >
              Patient: {patient && `${patient.deidentity}`}
            </Box>

            {patient && (
              <>
                <IconButton
                  size='small'
                  onClick={e => {
                    setCopyButtonEl(e.currentTarget);
                    navigator.clipboard.writeText(`${patient.deidentity}`).then(() => {
                      setCopiedAlertOpen(true);
                    });
                  }}
                >
                  <ContentCopyOutlined />
                </IconButton>

                <Popper open={copiedAlertOpen} anchorEl={copyButtonEl} placement='top' transition>
                  {({ TransitionProps }) => (
                    <Grow
                      {...TransitionProps}
                      timeout={
                        copiedAlertOpen
                          ? theme.transitions.duration.enteringScreen
                          : theme.transitions.duration.leavingScreen
                      }
                    >
                      <Paper
                        sx={{
                          backgroundColor: theme.palette.success.main,
                          color: theme.palette.success.contrastText,
                          paddingX: theme.spacing(2),
                          paddingY: theme.spacing(1),
                          marginBottom: theme.spacing(1),
                        }}
                      >
                        Copied!
                      </Paper>
                    </Grow>
                  )}
                </Popper>
              </>
            )}
          </Stack>
        </Typography>

        <Skeleton animation={loading ? 'wave' : false} variant='rounded' sx={{ height: renderSkeleton ? '100%' : 0 }} />

        <Stack
          direction='column'
          alignItems='center'
          justifyContent='center'
          sx={{ height: renderError ? '100%' : 0, padding: renderError ? theme.spacing(2) : 0, overflow: 'hidden' }}
        >
          <ErrorTwoTone color='error' fontSize='large' />
          <Typography color={theme.palette.error.main} textAlign='center'>
            {patientDataError}
          </Typography>
        </Stack>

        <Stack
          direction='column'
          alignItems='center'
          justifyContent='center'
          sx={{ height: renderNoData ? '100%' : 0, padding: renderNoData ? theme.spacing(2) : 0, overflow: 'hidden' }}
        >
          <InfoTwoTone color='info' fontSize='large' />
          <Typography color={theme.palette.info.main} textAlign='center'>
            No data available for this patient.
          </Typography>
        </Stack>

        <Stack
          direction='column'
          spacing={1}
          height={renderData ? '100%' : 0}
          overflow={renderData ? 'visible' : 'hidden'}
          justifyContent='center'
          alignItems='stretch'
          sx={{ color: theme.palette.text.primary, paddingTop: theme.spacing(0) }}
        >
          <Tabs
            value={dataView}
            onChange={(_e, dv) => setDataView(dv)}
            sx={{ minHeight: 0, lineHeight: 1, paddingBottom: 0 }}
          >
            <Tab
              value={'patientJourney' as DataView}
              label={t('patientJourney')}
              sx={{ minHeight: 0, lineHeight: 1, paddingTop: theme.spacing(1), paddingBottom: theme.spacing(1) }}
            />
            <Tab
              value={'curatedData' as DataView}
              label={t('curatedData')}
              sx={{ minHeight: 0, lineHeight: 1, paddingTop: theme.spacing(1), paddingBottom: theme.spacing(1) }}
            />
          </Tabs>

          {dataView === 'patientJourney' && <PatientJourneyGroup patientData={patientData} />}
          {dataView === 'curatedData' && <CuratedDataGroup />}

          <ReviewGroup onDoneReviewing={onDoneReviewing} />
        </Stack>
      </Box>
    </Stack>
  );
};

export default PatientFrame;
