import React, { useEffect, useState } from 'react';
import { AuthorizedPage } from '../auth/AuthorizedPage';
import { Page } from '../components/Page';
import {
  CreateDataProduct,
  DataProduct,
  DataProductField,
  DataProductTable,
  DeleteDataProduct,
  GetDataProducts,
} from '../data/DataProductData';
import useAuth from '../auth/UseAuth';
import { DataProductsTable } from '../dataProducts/DataProductsTable';
import { PageContent } from '../components/PageContent';
import { Box, Tab, Tabs, useTheme } from '@mui/material';
import { DataSchema, GetDataSchemas } from '../data/DataSchemaData';
import { DataSchemasTable } from '../dataProducts/schemas/DataSchemasTable';
import { t } from 'i18next';
import { LoadingState, LoadState } from '../components/LoadingStateUtil';
import { AuthorizedSection } from '../auth/AuthorizedSection';
import { DialogOpenButton } from '../components/DialogOpenButton';
import { DataProductCreationDialog } from '../dataProducts/DataProductCreationDialog';
import { v4 as uuid } from 'uuid';
import { ConfirmDeleteProductDialog } from '../dataProducts/ConfirmDeleteProductDialog';

const PAGE_TABS = ['products', 'schemas'] as const;
type PageTab = (typeof PAGE_TABS)[number];

export const DataProductsPage = () => {
  const { accessToken } = useAuth();

  const theme = useTheme();

  const [selectedTab, setSelectedTab] = useState<PageTab>('products');

  const [dataProducts, setDataProducts] = useState<DataProduct[]>([]);
  const [dataProductsLoadingState, setDataProductsLoadingState] = useState<LoadingState>({ status: 'NotStarted' });
  useEffect(() => {
    if (accessToken === undefined) {
      return;
    }

    return LoadState(setDataProductsLoadingState, async () => {
      const res = await GetDataProducts(false, accessToken);
      setDataProducts(res);
    });
  }, [accessToken]);

  const [dataSchemas, setDataSchemas] = useState<DataSchema[]>([]);
  const [dataSchemasLoadingState, setDataSchemasLoadingState] = useState<LoadingState>({ status: 'NotStarted' });
  useEffect(() => {
    if (accessToken === undefined) {
      return;
    }

    return LoadState(setDataSchemasLoadingState, async () => {
      const res = await GetDataSchemas(true, accessToken);
      setDataSchemas(res);
    });
  }, [accessToken]);

  const onCreateDataProduct = async (name: string, dataSchema: DataSchema): Promise<void> => {
    if (accessToken === undefined) {
      return;
    }

    await LoadState(setDataProductsLoadingState, async () => {
      const dataProductId = uuid();

      const newDataProduct: DataProduct = {
        dataProductId,
        name,
        dataSchemaId: dataSchema.dataSchemaId,
        dataSchemaName: dataSchema.name,
        tables: [],
        datasets: [],
      };

      for (const schemaTable of dataSchema.tables) {
        const productTable: DataProductTable = {
          dataProductId,
          dataSchemaTableId: schemaTable.dataSchemaTableId,
          name: schemaTable.name,
          redacted: false,
          fields: [],
        };

        for (const schemaField of schemaTable.fields) {
          const productField: DataProductField = {
            dataProductId,
            dataSchemaFieldId: schemaField.dataSchemaFieldId,
            name: schemaField.name,
            redacted: false,
            pointsToTableId: null,
          };

          productTable.fields.push(productField);
        }

        newDataProduct.tables.push(productTable);
      }

      await CreateDataProduct(newDataProduct, accessToken);

      const res = await GetDataProducts(false, accessToken);
      setDataProducts(res);
    });
  };

  const onDeleteDataProduct = async (dataProductId: string) => {
    if (accessToken === undefined) {
      return;
    }

    await LoadState(setDataProductsLoadingState, async () => {
      await DeleteDataProduct(dataProductId, accessToken);

      const res = await GetDataProducts(false, accessToken);
      setDataProducts(res);
    });
  };

  const [creationDialogOpen, setCreationDialogOpen] = useState(false);
  const [deletionDialogOpen, setDeletionDialogOpen] = useState(false);
  const [toDelete, setToDelete] = useState<DataProduct | null>(null);

  return (
    <AuthorizedPage hasGreenSideViewAccess>
      <Page title={t('dataProducts')}>
        <PageContent>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
            mb={2}
          >
            <Box>
              <Tabs value={selectedTab} onChange={(_, newTab) => setSelectedTab(newTab)}>
                {PAGE_TABS.map(tab => (
                  <Tab key={tab} value={tab} label={t(tab)} sx={{ color: theme.colors.alpha.black[100] }} />
                ))}
              </Tabs>
            </Box>
            {selectedTab === 'products' && (
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                }}
              >
                <AuthorizedSection hasGreenSideWriteAccess>
                  <DialogOpenButton title='Create Data Product' onClick={() => setCreationDialogOpen(true)} />
                </AuthorizedSection>
              </Box>
            )}
          </Box>

          {selectedTab === 'products' && (
            <DataProductsTable
              loadingState={dataProductsLoadingState}
              dataProducts={dataProducts}
              onDeleteDataProduct={(dataProductId: string) => {
                const dataProduct = dataProducts.find(dp => dp.dataProductId === dataProductId);
                if (dataProduct !== undefined) {
                  setToDelete(dataProduct);
                  setDeletionDialogOpen(true);
                }
              }}
            />
          )}

          {selectedTab === 'schemas' && (
            <DataSchemasTable loadingState={dataSchemasLoadingState} dataSchemas={dataSchemas} />
          )}
        </PageContent>

        {selectedTab === 'products' && (
          <>
            <DataProductCreationDialog
              open={creationDialogOpen}
              onAdd={(name, dataSchema) => {
                onCreateDataProduct(name, dataSchema).then(() => setCreationDialogOpen(false));
              }}
              onCancel={() => setCreationDialogOpen(false)}
            />

            {toDelete !== null && (
              <ConfirmDeleteProductDialog
                open={deletionDialogOpen}
                name={toDelete.name}
                onConfirm={() => {
                  onDeleteDataProduct(toDelete?.dataProductId).then(() => {
                    setToDelete(null);
                    setDeletionDialogOpen(false);
                  });
                }}
                onCancel={() => {
                  setToDelete(null);
                  setDeletionDialogOpen(false);
                }}
              />
            )}
          </>
        )}
      </Page>
    </AuthorizedPage>
  );
};
