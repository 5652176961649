import useAllUserPermissions from './UseAllUserPermissions';
import { useEffect, useState } from 'react';

export type UserPermissions = {
  hasAdminAccess: boolean;
  hasDecryptForeignHashesAccess: boolean;
  hasGreenSideViewAccess: boolean;
  hasGreenSideWriteAccess: boolean;
  hasSampleTrackingViewAccess: boolean;
  hasSampleTrackingWriteAccess: boolean;
  hasSuperDuperFiestaAccess: boolean;
  hasSuperDuperFiestaAdminAccess: boolean;
  hasSampleTrackingAdminAccess: boolean;
  isAuthenticated: boolean;
};

export const useUserPermissions = () => {
  const permissions = useAllUserPermissions();
  const [userPermissions, setUserPermissions] = useState<UserPermissions>({
    hasAdminAccess: false,
    hasDecryptForeignHashesAccess: false,
    hasGreenSideViewAccess: false,
    hasGreenSideWriteAccess: false,
    hasSampleTrackingViewAccess: false,
    hasSampleTrackingWriteAccess: false,
    hasSuperDuperFiestaAccess: false,
    hasSuperDuperFiestaAdminAccess: false,
    hasSampleTrackingAdminAccess: false,
    isAuthenticated: false,
  });

  useEffect(() => {
    if (!permissions.includes('livinglab:user')) {
      setUserPermissions({
        hasAdminAccess: false,
        hasDecryptForeignHashesAccess: false,
        hasGreenSideViewAccess: false,
        hasGreenSideWriteAccess: false,
        hasSampleTrackingViewAccess: false,
        hasSampleTrackingWriteAccess: false,
        hasSuperDuperFiestaAccess: false,
        hasSuperDuperFiestaAdminAccess: false,
        hasSampleTrackingAdminAccess: false,
        isAuthenticated: false,
      });
    }

    setUserPermissions({
      hasAdminAccess: permissions.includes('livinglab:admin'),
      hasDecryptForeignHashesAccess: permissions.includes('livinglab:read:decrypted-foreign-hashes'),
      hasGreenSideViewAccess: permissions.includes('livinglab:read:enrichment-data'),
      hasGreenSideWriteAccess: permissions.includes('livinglab:write:enrichment-data'),
      hasSampleTrackingViewAccess: permissions.includes('livinglab:read:sample-tracking'),
      hasSampleTrackingWriteAccess: permissions.includes('livinglab:write:sample-tracking'),
      hasSuperDuperFiestaAccess: permissions.includes('superduperfiesta:user'),
      hasSuperDuperFiestaAdminAccess: permissions.includes('superduperfiesta:admin'),
      hasSampleTrackingAdminAccess: permissions.includes('sample-tracking:admin'),
      isAuthenticated: permissions.includes('livinglab:user'),
    });
  }, [permissions]);

  return userPermissions;
};
