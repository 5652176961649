import React, { ChangeEvent, useState } from 'react';
import { appSettings } from 'AppSettings';
import {
  Box,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  LinearProgress,
  TextField,
} from '@mui/material';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import useAuth from 'auth/UseAuth';
import { DiseaseArea } from 'data/DiseaseAreaData';
import { LoadingStatus } from 'components/LoadingStatus';
import { DialogCloseButton } from '../../components/DialogCloseButton';
import { DialogOpenButton } from '../../components/DialogOpenButton';
import { CancelButton } from 'components/CancelButton';
import { PrimaryButton } from 'components/PrimaryButton';
import DiseaseAreaMeasurementsInput from './DiseaseAreaMeasurementsInput';
import { LinkedMeasurementDefinition, LinkedMeasurementDefinitions } from '../../data/MeasurementDefinitionData';
import DiseaseAreaCodesInputGroup, { WorkingDiseaseAreaCodes } from './DiseaseAreaCodesInputGroup';
import { ConvertAllCodesToUserFormat } from '../../data/conversions/Icd10CodeConversions';
import DiseaseAreaMedicationsInputGroup from './DiseaseAreaMedicationsInputGroup';
import CollapsibleSection from './CollapsibleSection';
import { t } from 'i18next';

export interface Props {
  onDiseaseAreaCreate: Function;
}

export const CreateDiseaseAreaModal = ({ onDiseaseAreaCreate }: Props) => {
  const { accessToken } = useAuth();

  const defaultName = '';
  const defaultCodes = [{ diagnosis: undefined, icd10Codes: '' }];
  const defaultMedications: string[] = [];
  const defaultMeasurementDefinitions: LinkedMeasurementDefinition[] = [];
  const defaultAvailableOnDashboard = false;
  const defaultShowOnDashboardByDefault = false;

  const [open, setOpen] = useState<boolean>(false);
  const [status, setStatus] = useState<LoadingStatus>('NotStarted');
  const [errorMessage, setErrorMessage] = useState<string>();
  const [selectedName, setSelectedName] = useState<string>(defaultName);
  const [selectedCodes, setSelectedCodes] = useState<WorkingDiseaseAreaCodes[]>(defaultCodes);
  const [selectedMedications, setSelectedMedications] = useState<string[]>(defaultMedications);
  const [selectedMeasurementDefinitions, setSelectedMeasurementDefinitions] =
    useState<LinkedMeasurementDefinition[]>(defaultMeasurementDefinitions);
  const [selectedAvailableOnDashboard, setSelectedAvailableOnDashboard] =
    useState<boolean>(defaultAvailableOnDashboard);
  const [selectedDefaultOnDashboard, setSelectedDefaultOnDashboard] = useState<boolean>(
    defaultShowOnDashboardByDefault
  );

  const handleButtonClick = async () => {
    setOpen(true);
  };

  const handleSave = async () => {
    setStatus('Loading');

    let emptyId = '00000000-0000-0000-0000-000000000000';

    let newDiseaseArea: DiseaseArea = {
      diseaseAreaId: emptyId,
      name: selectedName,
      codes: selectedCodes.map(c => ({
        diseaseAreaId: emptyId,
        diagnosis: c.diagnosis,
        icd10Codes: ConvertAllCodesToUserFormat(c.icd10Codes.split(',')),
      })),
      medications: selectedMedications.map(m => ({
        diseaseAreaId: emptyId,
        medicationName: m,
      })),
      availableToShowOnDashboard: selectedAvailableOnDashboard,
      showOnDashboardByDefault: selectedDefaultOnDashboard,
    };

    try {
      if (accessToken) {
        const saveDiseaseAreaResponse = await fetch(`${appSettings.api.endpoint}/api/v2/DiseaseAreas`, {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${accessToken}`,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(newDiseaseArea),
        });

        if (!saveDiseaseAreaResponse.ok) {
          let message = await saveDiseaseAreaResponse.text();
          setStatus('Error');

          if (message.length > 0) {
            setErrorMessage(message);
          } else if (saveDiseaseAreaResponse.status === 401) {
            setErrorMessage('Error: unauthorized user.');
          } else {
            setErrorMessage('Unknown error.');
          }
        }

        const savedDiseaseArea = (await saveDiseaseAreaResponse.json()) as DiseaseArea;

        const updatedMeasurementDefinitions: LinkedMeasurementDefinitions = {
          targetStatisticId:
            selectedMeasurementDefinitions.length === 0
              ? undefined
              : selectedMeasurementDefinitions.find(m => m.isTargetStatistic)?.measurementDefinition
                  .measurementDefinitionId,
          measurementDefinitionIds: selectedMeasurementDefinitions.map(
            m => m.measurementDefinition.measurementDefinitionId
          ),
        };

        const saveMeasurementDefinitionsResponse = await fetch(
          `${appSettings.api.endpoint}/api/v2/MeasurementDefinitions/DiseaseAreas/${savedDiseaseArea.diseaseAreaId}`,
          {
            method: 'POST',
            headers: {
              Authorization: `Bearer ${accessToken}`,
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(updatedMeasurementDefinitions),
          }
        );

        if (!saveMeasurementDefinitionsResponse.ok) {
          let message = await saveMeasurementDefinitionsResponse.text();
          setStatus('Error');

          if (message.length > 0) {
            setErrorMessage(message);
          } else if (saveMeasurementDefinitionsResponse.status === 401) {
            setErrorMessage('Error: unauthorized user.');
          } else {
            setErrorMessage('Unknown error.');
          }
          return;
        }

        setStatus('Complete');
        handleClose();
        onDiseaseAreaCreate();
      }
    } catch (err) {
      setStatus('Error');
      setErrorMessage('Unknown error.');
    }
  };

  const handleClose = () => {
    clearSelectedState();
    setOpen(false);
    setErrorMessage(undefined);
  };

  const clearSelectedState = () => {
    setSelectedName(defaultName);
    setSelectedCodes(defaultCodes);
    setSelectedMedications(defaultMedications);
    setSelectedMeasurementDefinitions(defaultMeasurementDefinitions);
    setSelectedAvailableOnDashboard(defaultAvailableOnDashboard);
    setSelectedDefaultOnDashboard(defaultShowOnDashboardByDefault);
    setStatus('NotStarted');
  };

  const handleNameChange = (ev: ChangeEvent<HTMLInputElement>) => {
    setSelectedName(ev.target.value);
  };

  return (
    <>
      <DialogOpenButton title={'New Disease Area'} onClick={handleButtonClick} />
      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogTitle>
          <DialogCloseButton onClick={handleClose} />
          Create New Disease Area
        </DialogTitle>
        <DialogContent>
          <Box component='form'>
            <TextField
              id='disease-area-name-input'
              fullWidth
              label='Name'
              margin='normal'
              onChange={handleNameChange}
              type='text'
              value={selectedName}
              variant='outlined'
            />

            <CollapsibleSection title={t('codes')} initiallyExpanded={true}>
              <DiseaseAreaCodesInputGroup
                diseaseAreaCodes={selectedCodes}
                onDiseaseAreaCodesChange={setSelectedCodes}
              />
            </CollapsibleSection>

            <CollapsibleSection title={t('medications')} initiallyExpanded={true}>
              <DiseaseAreaMedicationsInputGroup
                diseaseAreaMedications={selectedMedications}
                onDiseaseAreaMedicationsChange={setSelectedMedications}
              />
            </CollapsibleSection>

            <DiseaseAreaMeasurementsInput
              loading={false}
              selectedMeasurementDefinitions={selectedMeasurementDefinitions}
              onMeasurementsChange={setSelectedMeasurementDefinitions}
            />

            <FormControlLabel
              label='Available to show on dashboard'
              control={
                <Checkbox
                  checked={selectedAvailableOnDashboard}
                  onChange={() => {
                    setSelectedAvailableOnDashboard(!selectedAvailableOnDashboard);
                  }}
                />
              }
            />
            <FormControlLabel
              label='Default on dashboard'
              control={
                <Checkbox
                  checked={selectedAvailableOnDashboard && selectedDefaultOnDashboard}
                  onChange={() => {
                    setSelectedDefaultOnDashboard(!selectedDefaultOnDashboard);
                  }}
                  disabled={!selectedAvailableOnDashboard}
                />
              }
            />
          </Box>
          {status === 'Error' && (
            <DialogContentText display='flex' alignItems='center' mt={1} color='error'>
              <ErrorOutlineIcon sx={{ mr: 1 }} />
              {errorMessage}
            </DialogContentText>
          )}
        </DialogContent>
        {status === 'Loading' && <LinearProgress />}
        <DialogActions>
          <CancelButton onClick={handleClose} disabled={status === 'Loading'}>
            Cancel
          </CancelButton>
          <PrimaryButton onClick={handleSave} disabled={status === 'Loading'}>
            Save
          </PrimaryButton>
        </DialogActions>
      </Dialog>
    </>
  );
};
