import React, { useEffect } from 'react';
import { Checkbox, FormControl, FormControlLabel } from '@mui/material';
import { Controller } from 'react-hook-form';
import { FormInputProps } from './FormInputProps';

export const FormCheckboxField: React.FC<FormInputProps> = ({
  name,
  control,
  label,
  trigger,
  required,
  sx = [],
  labelPlacement = 'start',
  additionalProps,
}) => {
  // triggers rules on render
  useEffect(() => {
    trigger(name);
  }, [trigger, name]);

  return (
    <FormControl size={'small'} variant={'outlined'}>
      <FormControlLabel
        label={label}
        labelPlacement={labelPlacement}
        sx={{ ...sx }}
        {...additionalProps}
        control={
          <Controller
            name={name}
            control={control}
            rules={{
              required: required,
            }}
            render={({ field: { onChange, value }, fieldState: { error }, formState }) => (
              <Checkbox checked={value} onChange={onChange} />
            )}
          />
        }
      />
    </FormControl>
  );
};
