import * as React from 'react';
import { MutableRefObject, useState } from 'react';
import { Dialog, DialogActions, DialogContent, Typography } from '@mui/material';
import { LoadingIndicator } from './LoadingIndicator';
import { ErrorManagement, LoadingState } from './LoadingStateUtil';
import { ErrorIndicator } from './ErrorIndicator';
import { CancelButton } from './CancelButton';
import { PrimaryButton } from './PrimaryButton';
import { ActionButton, DialogOpenButton } from './DialogOpenButton';
import { useTranslation } from 'react-i18next';
import {
  ExportTransitionManifest,
  SelectableCandidate,
  SelectableEntity,
} from '../data/SampleTrackingFieldSelectionData';
import useAuth from '../auth/UseAuth';
import { EntityFieldSelector } from './EntityFieldSelector';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { GridApiPro } from '@mui/x-data-grid-pro/models/gridApiPro';
import { removeSpecialCharsAndReplaceWhiteSpaceWithDash } from '../util/grid/FileUtils';
import { formatDateTime } from '../util/grid/DateUtil';
import { EntityFieldDisplay } from './EntityFieldDisplay';
import { FlexBox } from './FlexBox';
import { DialogHeader } from './DialogHeader';

export const ExportSelectorDialog = ({
  candidates,
  apiRef,
  fileName,
  researchProjectId,
  currentEntitiesInView,
}: {
  candidates: ReadonlyArray<SelectableCandidate>;
  apiRef?: MutableRefObject<GridApiPro>;
  fileName?: string;
  researchProjectId?: string;
  currentEntitiesInView: ReadonlyArray<SelectableEntity>;
}) => {
  const { t } = useTranslation();
  const { accessToken } = useAuth();
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [loadingState, setLoadingState] = useState<LoadingState>({ status: 'Complete' });

  const [entities, setEntities] = useState<ReadonlyArray<SelectableEntity>>([]);
  const [selectedCurrentView, setSelectedCurrentView] = useState<boolean>(true);

  function handleClose() {
    setModalOpen(false);
    setLoadingState({ status: 'Complete' });
    setSelectedCurrentView(true);
  }

  async function handleExport() {
    ErrorManagement('Loading', setLoadingState, async () => {
      if (selectedCurrentView) {
        if (!apiRef?.current) {
          return;
        }

        apiRef?.current.exportDataAsCsv({
          fields: apiRef.current
            .getVisibleColumns()
            .filter(o => !o.disableExport)
            .map(o => o.field),
          fileName: `${removeSpecialCharsAndReplaceWhiteSpaceWithDash(
            fileName ?? t('sampleTrackingFileNameShort')
          )}_${formatDateTime()}`,
        });
      } else {
        await ExportTransitionManifest({ entities, candidates, researchProjectId }, accessToken);
      }

      setLoadingState({ status: 'Complete' });
      handleClose();
    });
  }

  return (
    <>
      <DialogOpenButton title={t('export')} onClick={() => setModalOpen(true)}>
        <FileDownloadIcon />
      </DialogOpenButton>
      <Dialog open={modalOpen} onClose={handleClose} maxWidth={'xs'} fullWidth>
        <DialogHeader title={t('export')} handleClose={handleClose}>
          {!selectedCurrentView && <Typography variant='subtitle1'>{t('exportLineageInfo')}</Typography>}
          {selectedCurrentView && <Typography variant='subtitle1'>{t('exportLineageCurrentViewInfo')}</Typography>}
        </DialogHeader>
        <DialogContent>
          {!selectedCurrentView && (
            <EntityFieldSelector
              onChange={entries => {
                setEntities(entries);
              }}
              customActions={
                <ActionButton
                  sx={{ p: 0 }}
                  title={t('selectView')}
                  onClick={() => {
                    setSelectedCurrentView(true);
                  }}
                  disableMarginLeft={true}
                >
                  <></>
                </ActionButton>
              }
              hideSelectDefault
              disableCaching
            />
          )}
          {selectedCurrentView && (
            <FlexBox sx={{ gap: 1, flexDirection: 'column' }}>
              <FlexBox>
                <ActionButton
                  sx={{ p: 0 }}
                  title={t('selectSpecific')}
                  onClick={() => {
                    setSelectedCurrentView(false);
                  }}
                  disableMarginLeft={true}
                >
                  <></>
                </ActionButton>
              </FlexBox>
              <EntityFieldDisplay entities={currentEntitiesInView} />
            </FlexBox>
          )}
        </DialogContent>
        <LoadingIndicator loadingState={loadingState} margin={'LR'} />
        <ErrorIndicator loadingState={loadingState} />
        <DialogActions>
          <CancelButton onClick={handleClose} />
          <PrimaryButton disabled={loadingState.status === 'Loading'} onClick={handleExport}>
            {t('export')}
          </PrimaryButton>
        </DialogActions>
      </Dialog>
    </>
  );
};
