import React, { useEffect } from 'react';
import { TextField } from '@mui/material';
import { AutocompleteWrapper } from '../../components/AutocompleteWrapper';
import { ActionButton } from '../../components/DialogOpenButton';
import { FlexBox } from '../../components/FlexBox';
import { LoadState, LoadingProps } from '../../components/LoadingStateUtil';
import useMemoTranslation from '../../hooks/UseMemoTranslation';
import { usePipelineRulesContext } from '../../contexts/PipelineRulesContext';

export const PipelineRules = ({ loadingProps }: { loadingProps: LoadingProps }) => {
  const { t } = useMemoTranslation();
  const {
    getFilterData,
    filterState,
    selectedEntity,
    setSelectedEntity,
    selectedPipeline,
    setSelectedPipeline,
    selectedStage,
    setSelectedStage,
  } = usePipelineRulesContext();

  const filteredStages = ['promoteToAugmentation', 'promoteToSelection'];

  const [, setLoadingState] = loadingProps;

  useEffect(() => {
    return LoadState(setLoadingState, async () => {
      await getFilterData();
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (selectedStage === null) {
      const transformationStage = filterState.stages.find(s => s.name === 'transformation');
      if (transformationStage) setSelectedStage(transformationStage);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterState.stages, setSelectedStage]);

  useEffect(() => {
    if (selectedEntity === null) {
      const labEntity = filterState.entities.find(e => e.name === 'lab');
      if (labEntity) setSelectedEntity(labEntity);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterState.entities, setSelectedEntity]);

  function handleClear() {
    setSelectedPipeline(null);
    setSelectedStage(null);
    setSelectedEntity(null);
    setLoadingState({ status: 'NotStarted' });
  }

  return (
    <FlexBox sx={{ flexDirection: 'column', gap: 1, width: '100%' }}>
      <AutocompleteWrapper
        fullWidth
        value={selectedPipeline}
        options={filterState.pipelines}
        getOptionLabel={option => (typeof option === 'string' ? option : option.name)}
        isOptionEqualToValue={(option: any, value: any) =>
          typeof option === 'string' ? option === value : option.name === value.name
        }
        renderInput={params => <TextField {...params} label={t('pipeline')} margin='dense' required />}
        onChange={(_, value) => setSelectedPipeline(value)}
      />
      <AutocompleteWrapper
        fullWidth
        value={selectedStage}
        options={filterState.stages.filter(s => !filteredStages.includes(s.name))}
        getOptionLabel={option => (typeof option === 'string' ? option : option.displayName)}
        isOptionEqualToValue={(option: any, value: any) =>
          typeof option === 'string' ? option === value : option.displayName === value.displayName
        }
        renderInput={params => <TextField {...params} label={t('stage')} margin='dense' required />}
        onChange={(_, value) => setSelectedStage(value)}
      />
      <AutocompleteWrapper
        fullWidth
        value={selectedEntity}
        options={filterState.entities}
        getOptionLabel={option => (typeof option === 'string' ? option : option.displayName)}
        isOptionEqualToValue={(option: any, value: any) =>
          typeof option === 'string' ? option === value : option.displayName === value.displayName
        }
        renderInput={params => <TextField {...params} label={t('entity')} margin='dense' required />}
        onChange={(_, value) => setSelectedEntity(value)}
      />
      <ActionButton
        sx={{ p: 0 }}
        title={'Clear Filters'}
        onClick={() => {
          handleClear();
        }}
        disableMarginLeft={true}
      >
        <></>
      </ActionButton>
    </FlexBox>
  );
};
