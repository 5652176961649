import { LoadingStatus } from './LoadingStatus';
import { UseState } from '../util/TypeUtil';

export type LoadingProps = UseState<LoadingState>;

export interface LoadingState {
  status: LoadingStatus;
  errorMessage?: string;
  warningMessage?: string;
  actions?: string[];
  disableLoadingIndicator?: boolean;
}

/**
 * Utility method to allow calling component to automatically set loading states while running setup functionality.
 * Caller should return LoadState in the UseEffect to allow React to update `isCancelled` property once the
 * component is unmounted. This allows states updates to not run if the component is no longer mounted.
 *
 * LoadingIndicator.tsx is best coupled with this functionality to allow for easy loading indicators and error indicators
 * @param setLoadingState
 * @param func
 * @constructor
 */
export function LoadState(
  setLoadingState: (value: ((prevState: LoadingState) => LoadingState) | LoadingState) => void = () => {},
  func: () => Promise<void>
) {
  let isCancelled = false;
  const actionKey = generateKey();
  setLoadingState(current => {
    return { ...current, status: 'Loading', actions: [...(current.actions ?? []), actionKey] };
  });

  func()
    .then(() => {
      if (isCancelled || !setLoadingState) {
        return;
      }

      setLoadingState(current => {
        const index = (current.actions ?? []).indexOf(actionKey, 0);
        if (index > -1) {
          current.actions?.splice(index, 1);
        }

        return {
          ...current,
          status: (current.actions?.length ?? 0) > 0 ? 'Loading' : 'Complete',
          actions: current.actions,
        };
      });
    })
    .catch((e: Error) => {
      if (isCancelled || !setLoadingState) {
        return;
      }

      setLoadingState(current => ({ ...current, status: 'Error', errorMessage: getErrorMessage(e) }));
    });

  return () => {
    isCancelled = true;
    setLoadingState(current => {
      const index = (current.actions ?? []).indexOf(actionKey, 0);
      if (index > -1) {
        current.actions?.splice(index, 1);
      }

      return { ...current, actions: current.actions };
    });
  };
}

export function ErrorManagement(
  initialState: LoadingStatus,
  setLoadingState: (value: ((prevState: LoadingState) => LoadingState) | LoadingState) => void,
  func: () => Promise<void>
) {
  if (initialState) {
    setLoadingState({ status: initialState });
  }

  func().catch((e: any) => {
    if (!setLoadingState) {
      return;
    }

    setLoadingState({ status: 'Error', errorMessage: getErrorMessage(e) });
  });
}

export async function ErrorManagementAsync<T>(
  initialState: LoadingStatus,
  setLoadingState: (value: ((prevState: LoadingState) => LoadingState) | LoadingState) => void,
  func: () => Promise<T>
): Promise<T | undefined> {
  if (initialState) {
    setLoadingState({ status: initialState });
  }

  try {
    return await func();
  } catch (e) {
    if (!setLoadingState) {
      return undefined;
    }

    setLoadingState({ status: 'Error', errorMessage: getErrorMessage(e) });
  }

  return undefined;
}

function getErrorMessage(e: any): string {
  let message;
  if (e instanceof Error) {
    message = e.message;

    try {
      const parsed = JSON.parse(message);
      const subMessage = parsed?.message;
      message = subMessage ?? message;
    } catch (jsonParseError) {
      message = e.message;
    }
  } else {
    message = String(e);
  }

  return message ?? 'Unknown Error';
}

function generateKey() {
  return (Math.random() + 1).toString(36).substring(7);
}
