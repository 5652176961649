import { FlexTableBox } from '../FlexTableBox';
import {
  CompactGridWrapper,
  CompactGridWrapperProps,
  SAMPLE_AND_PATIENT_IDENTIFYING_COLUMNS,
} from './CompactGridWrapper';
import { GridColDef } from '@mui/x-data-grid-pro';
import React, { MutableRefObject, useEffect, useState } from 'react';
import { AuthorizedSection } from '../../auth/AuthorizedSection';
import { ExportSelectorDialog } from '../ExportSelectorDialog';
import { TableLineageColumnSelectorDialog } from '../TableLineageColumnSelectorDialog';
import { FlexBox } from '../FlexBox';
import {
  SelectableCandidate,
  SelectableEntity,
  SelectedData,
  SelectFields,
} from '../../data/SampleTrackingFieldSelectionData';
import { LoadingProps, LoadState } from '../LoadingStateUtil';
import useAuth from '../../auth/UseAuth';
import { difference, filter, find, flatMap, keyBy, map, uniqBy } from 'lodash';
import { useParams } from 'react-router-dom';
import { formatDate, jsonIsEqual } from '../../util/grid/TableUtils';
import useLocalStorage from '../../hooks/UseLocalStorage';
import { UseStateSetter } from '../../util/TypeUtil';
import { GridColumnVisibilityModel } from '@mui/x-data-grid/hooks/features/columns/gridColumnsInterfaces';
import { GridApiPro } from '@mui/x-data-grid-pro/models/gridApiPro';
import { useResearchProject } from '../hooks/UseResearchProjects';

export interface SampleTrackingGridProps extends CompactGridWrapperProps {
  rightActions?: React.ReactNode;
  leftActions?: React.ReactNode;
  loadingProps: LoadingProps;
  cacheKey: string;
  defaultColumnOrdering?: ReadonlyArray<string>;
  columnDefinitionOverrides?: ReadonlyArray<GridColDef>;
  apiRef: MutableRefObject<GridApiPro>;
  refreshTrigger?: boolean;
  columnsToAutoSizeOverride?: ReadonlyArray<string>;
}

const CURRENT_VIEW_ENTITY = 'current_view';

export const SampleTrackingGridWrapper = (props: SampleTrackingGridProps) => {
  const [loadingState, setLoadingState] = props.loadingProps;

  const { accessToken } = useAuth();
  let { researchProjectId } = useParams() as { researchProjectId: string };
  const researchProject = useResearchProject(researchProjectId, setLoadingState);

  const [refreshTrigger, setRefreshTrigger] = useState<boolean>(true);

  const [columns, setColumns] = useState<GridColDef[]>([]);
  const [orderedColumns, setOrderedColumns] = useState<GridColDef[]>([]);
  const [rows, setRows] = useState<any[]>([]);
  const [columnVisibilityModel, setColumnVisibilityModel] = useState<GridColumnVisibilityModel>({});

  const [selectableCandidates, setSelectableCandidates] = useState<ReadonlyArray<SelectableCandidate>>([]);
  const [currentSelectedColumnEntities, setCurrentSelectedColumnEntities] = useState<ReadonlyArray<SelectableEntity>>(
    []
  );
  const [allColumnEntities, setAllColumnEntities] = useState<ReadonlyArray<SelectableEntity>>([]);
  const [additionalColumnData, setAdditionalColumnData] = useState<SelectedData>({
    data: {},
    columns: [],
    entries: [],
  });
  const [entitiesFromProvidedColumns, setEntitiesFromProvidedColumns] = useState<SelectableEntity[]>([]);

  const [columnOrdering, setColumnOrdering] = useLocalStorage<ReadonlyArray<String>>(
    `${props.cacheKey}-column-ordering`,
    props.defaultColumnOrdering ?? []
  );

  useEffect(() => {
    return LoadState(setLoadingState, async () => {
      if (!accessToken || currentSelectedColumnEntities.length === 0 || selectableCandidates.length === 0) {
        return;
      }

      const data = await SelectFields(
        {
          entities: currentSelectedColumnEntities,
          candidates: selectableCandidates,
          researchProjectId: researchProjectId,
        },
        accessToken
      );

      setAdditionalColumnData(data);
    });
  }, [
    accessToken,
    currentSelectedColumnEntities,
    researchProjectId,
    selectableCandidates,
    setLoadingState,
    refreshTrigger,
  ]);

  useEffect(() => {
    // If no columns are selected then clear table data
    if (!(currentSelectedColumnEntities.length === 0 && additionalColumnData.entries.length > 0)) {
      return;
    }

    setAdditionalColumnData({
      data: {},
      columns: [],
      entries: [],
    });
  }, [currentSelectedColumnEntities, additionalColumnData]);

  useEffect(() => {
    if (props.rows.length === 0) {
      return;
    }

    setSelectableCandidates(prevState => {
      const newState = uniqBy(
        filter(
          map(props.rows, (i): SelectableCandidate => {
            return {
              sampleId: i.sampleId ?? '',
              sampleJourneyId: i.sampleJourneyId ?? '',
            };
          }),
          i => !!i.sampleId && !!i.sampleJourneyId
        ),
        i => i.sampleId + i.sampleJourneyId
      );

      if (jsonIsEqual(prevState, newState)) {
        return prevState;
      }

      return newState;
    });
  }, [props.rows]);

  useEffect(() => {
    const allFields = flatMap(allColumnEntities, i => i.fields);

    const currentViewEntity =
      currentSelectedColumnEntities.find(i => i.name === CURRENT_VIEW_ENTITY) ||
      entitiesFromProvidedColumns.find(i => i.name === CURRENT_VIEW_ENTITY);
    const currentViewFieldNames = currentViewEntity?.fields.map(i => i.key) ?? [];

    const visibility: GridColumnVisibilityModel = {};

    allFields.forEach(col => {
      visibility[col.key] = false;
    });

    additionalColumnData.columns.forEach(col => {
      visibility[col.key] = true;
    });

    props.columns.forEach(col => {
      visibility[col.field] = currentSelectedColumnEntities.length > 0 && currentViewFieldNames.includes(col.field);
    });

    setColumnVisibilityModel(prevState => {
      if (jsonIsEqual(prevState, visibility)) {
        return prevState;
      }

      return visibility;
    });
  }, [
    props.columns,
    allColumnEntities,
    currentSelectedColumnEntities,
    additionalColumnData,
    entitiesFromProvidedColumns,
  ]);

  useEffect(() => {
    const allFields = flatMap(allColumnEntities, i => i.fields);

    const propColumnFields = map(props.columns, i => i.field);

    setColumns(prevState => {
      const newColumns = [
        ...props.columns,
        ...(map(
          filter(allFields, i => !propColumnFields.includes(i.key)),
          column => {
            const override = find(props.columnDefinitionOverrides, i => i.field === column.key);

            return override !== undefined
              ? {
                  ...override,
                  headerName: column.fullDisplayName,
                  valueGetter: ({ row }) => (row?.additionalData && row?.additionalData[column.key]) ?? '',
                }
              : {
                  field: column.key,
                  headerName: column.fullDisplayName,
                  headerAlign: 'left',
                  align: 'left',
                  sortable: true,
                  minWidth: 130,
                  valueGetter: ({ row }) => (row?.additionalData && row?.additionalData[column.key]) ?? '',
                  valueFormatter: ({ value }) => (column.type === 'Date' && value ? formatDate(value) : value),
                };
          }
        ) as GridColDef[]),
      ];

      if (jsonIsEqual(prevState, newColumns)) {
        return prevState;
      }

      return newColumns;
    });
  }, [props.columns, props.columnDefinitionOverrides, allColumnEntities]);

  useEffect(() => {
    const entriesBySampleId = keyBy(additionalColumnData.entries, i => i.sampleId);

    setRows(
      props.rows.map(row => {
        const entry = entriesBySampleId[row.sampleId ?? ''];
        const additionalData = additionalColumnData.data[entry?.entryId ?? ''] ?? {};

        return { additionalData, ...row };
      })
    );
  }, [props.rows, additionalColumnData]);

  useEffect(() => {
    props.apiRef?.current?.subscribeEvent('columnOrderChange', () => {
      if (!props.apiRef?.current) {
        return;
      }

      const order = props.apiRef.current.getVisibleColumns().map(i => i.field);
      setColumnOrdering(order);
    });
  }, [props.apiRef, setColumnOrdering]);

  useEffect(() => {
    const orderedColumns = filter(
      map(columnOrdering, column => {
        return find(columns, col => col.field === column);
      }),
      col => col !== undefined && col !== null
    ) as GridColDef[];

    const orderedColumnsFields = orderedColumns.map(col => col?.field);
    const remainingColumns = columns.filter(col => !orderedColumnsFields.includes(col.field));
    const newColumns = [...orderedColumns, ...remainingColumns];

    setOrderedColumns(prevState => {
      if (jsonIsEqual(prevState, newColumns)) {
        return prevState;
      }

      return newColumns;
    });
  }, [columnOrdering, columns]);

  useEffect(() => {
    setEntitiesFromProvidedColumns([
      {
        name: CURRENT_VIEW_ENTITY,
        displayName: 'Current View',
        fields: props.columns
          .filter(col => col.headerName !== undefined)
          .map(col => ({
            columnDisplayName: col.headerName ?? col.field,
            columnName: col.field,
            fullDisplayName: col.headerName ?? col.field,
            key: col.field,
            type: 'Text',
          })),
      },
    ]);
  }, [props.columns]);

  useEffect(() => {
    setRefreshTrigger(current => !current);
  }, [props.refreshTrigger]);

  useEffect(() => {
    if (!props.apiRef?.current || !props.initialState) {
      return;
    }

    // Restore initial state once all columns are loaded otherwise grid disposes of initial state
    // whenever new columns are added. Columns should be only trigger for this
    props.apiRef?.current?.restoreState(props.initialState);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [columns]);

  return (
    <FlexTableBox>
      <FlexBox>
        <FlexBox width={'100%'} flexDirection='row' alignItems='center' justifyContent='space-between' mb={1}>
          <>{props.leftActions}</>
          <FlexBox flexDirection='row' alignItems='center' justifyContent='space-between'>
            <AuthorizedSection hasSampleTrackingViewAccess>
              <ExportSelectorDialog
                candidates={selectableCandidates}
                apiRef={props.apiRef}
                fileName={researchProject ? researchProject.name : undefined}
                researchProjectId={researchProjectId}
                currentEntitiesInView={currentSelectedColumnEntities}
              />
              <MemoizedEditResearchProjectGridCell
                setAdditionalColumnEntities={setCurrentSelectedColumnEntities}
                setAllColumnEntities={setAllColumnEntities}
                defaultColumns={props.defaultColumnOrdering ?? []}
                entitiesFromProvidedColumns={entitiesFromProvidedColumns}
                cacheKey={props.cacheKey}
              />
            </AuthorizedSection>
            <>{props.rightActions}</>
          </FlexBox>
        </FlexBox>
      </FlexBox>
      <CompactGridWrapper
        {...props}
        rows={rows}
        columns={orderedColumns}
        loadingState={loadingState}
        columnsToAutoSize={
          props.columnsToAutoSize || additionalColumnData.columns.length > 0
            ? difference(
                orderedColumns.map(c => c.field).concat(props.columnsToAutoSize ?? []),
                SAMPLE_AND_PATIENT_IDENTIFYING_COLUMNS
              )
            : undefined
        }
        disableColumnSelector
        columnVisibilityModel={columnVisibilityModel}
      />
    </FlexTableBox>
  );
};

function MemorizedTableLineageColumnSelectorDialog({
  defaultColumns,
  setAdditionalColumnEntities,
  setAllColumnEntities,
  entitiesFromProvidedColumns,
  cacheKey,
}: {
  defaultColumns: ReadonlyArray<string>;
  setAdditionalColumnEntities: UseStateSetter<ReadonlyArray<SelectableEntity>>;
  setAllColumnEntities: UseStateSetter<ReadonlyArray<SelectableEntity>>;
  entitiesFromProvidedColumns: SelectableEntity[];
  cacheKey: string;
}) {
  return (
    <TableLineageColumnSelectorDialog
      onChange={entries => {
        setAdditionalColumnEntities(entries);
      }}
      onPopulate={setAllColumnEntities}
      defaultColumns={defaultColumns}
      additionalEntities={entitiesFromProvidedColumns}
      cacheKey={cacheKey}
    />
  );
}

const MemoizedEditResearchProjectGridCell = React.memo(
  MemorizedTableLineageColumnSelectorDialog,
  (prevProps, nextProps) => {
    return (
      jsonIsEqual(prevProps.defaultColumns, nextProps.defaultColumns) &&
      prevProps.setAdditionalColumnEntities === nextProps.setAdditionalColumnEntities &&
      prevProps.setAllColumnEntities === nextProps.setAllColumnEntities &&
      prevProps.cacheKey === nextProps.cacheKey &&
      jsonIsEqual(prevProps.entitiesFromProvidedColumns, nextProps.entitiesFromProvidedColumns)
    );
  }
);
