import { AuthorizedPage } from 'auth/AuthorizedPage';
import { Box, Divider } from '@mui/material';
import React, { useState } from 'react';
import { Page } from 'components/Page';
import useMemoTranslation from '../hooks/UseMemoTranslation';
import { PageContent } from '../components/PageContent';
import { PipelineReview } from './components/PipelineReview';
import { PipelineReviewGrid } from './components/PipelineReviewGrid';
import { FlexBox } from '../components/FlexBox';
import { FlexTableBox } from '../components/FlexTableBox';
import { LoadingIndicator } from '../components/LoadingIndicator';
import { ErrorIndicator } from '../components/ErrorIndicator';
import { LoadingState } from '../components/LoadingStateUtil';
import { PipelineReviewContextProvider } from '../contexts/PipelineReviewContext';

export const PipelineReviewPage = () => {
  const { t } = useMemoTranslation();

  const [loadingState, setLoadingState] = useState<LoadingState>({ status: 'NotStarted' });

  return (
    <AuthorizedPage hasSuperDuperFiestaAccess>
      <Page title={t('pipelineReview')}>
        <PipelineReviewContextProvider>
          <PageContent>
            <FlexBox sx={{ flexDirection: 'column', height: '100%' }}>
              <FlexBox sx={{ flexDirection: 'row', gap: 3, flexGrow: 2, height: '100%' }}>
                <Box sx={{ width: '35%' }}>
                  <PipelineReview loadingProps={[loadingState, setLoadingState]} />
                </Box>
                <Divider orientation={'vertical'} />
                <FlexTableBox sx={{ width: '100%' }}>
                  <PipelineReviewGrid loadingProps={[loadingState, setLoadingState]} />
                </FlexTableBox>
              </FlexBox>
              <Box>
                <LoadingIndicator loadingState={loadingState} margin={'T'} />
                <ErrorIndicator loadingState={loadingState} />
              </Box>
            </FlexBox>
          </PageContent>
        </PipelineReviewContextProvider>
      </Page>
    </AuthorizedPage>
  );
};
