import React, { useEffect, useState } from 'react';
import { ConstructDataProductSql, DataProduct, DataProductSql, DataProductTableSql } from '../../data/DataProductData';
import { Box, Button, FormControlLabel, FormGroup, Stack, Switch, Typography, useTheme } from '@mui/material';
import { TablePickerTable } from '../common/TablePickerTable';
import { SqlTextField } from './SqlTextField';
import useAuth from '../../auth/UseAuth';
import { t } from 'i18next';
import { ContentCopyOutlined } from '@mui/icons-material';
import { WithPopper } from '../common/WithPopper';
import { LoadingState, LoadState } from '../../components/LoadingStateUtil';

interface SqlViewerProps {
  loadingState: LoadingState;
  dataProduct: DataProduct | null;
}

export const SqlViewer: React.FC<SqlViewerProps> = ({ loadingState, dataProduct }) => {
  const theme = useTheme();

  const { accessToken } = useAuth();

  const [allSql, setAllSql] = useState<DataProductSql | null>(null);
  const [sqlLoadingState, setSqlLoadingState] = useState<LoadingState>({ status: 'NotStarted' });

  useEffect(() => {
    if (dataProduct === null || accessToken === undefined) {
      return;
    }

    return LoadState(setSqlLoadingState, async () => {
      const res = await ConstructDataProductSql(dataProduct, accessToken);
      setAllSql(res);
    });
  }, [dataProduct, accessToken]);

  const [selectedTableId, setSelectedTableId] = useState<string | null>(null);

  const [currentTableSql, setCurrentTableSql] = useState<DataProductTableSql | null>();
  useEffect(() => {
    if (allSql === null || selectedTableId === null) {
      return;
    }

    const tableSql = allSql.tableSql.find(t => t.dataSchemaTableId === selectedTableId);
    setCurrentTableSql(tableSql ?? null);
  }, [allSql, selectedTableId]);

  const [withInsert, setWithInsert] = useState<boolean>(false);

  return (
    <Stack flex={1} direction='row' spacing={2}>
      <Stack flex={1} direction='column' spacing={2} sx={{ maxWidth: theme.spacing(30) }}>
        <Typography flex={0} variant='h4'>
          {t('tables')}
        </Typography>
        <Box flex={1} sx={{ position: 'relative' }}>
          <Box sx={{ position: 'absolute', top: 0, left: 0, bottom: 0, right: 0 }}>
            <TablePickerTable
              loadingState={loadingState}
              tables={dataProduct?.tables.filter(t => !t.redacted) ?? []}
              onTableSelected={newSelectedTableId => setSelectedTableId(newSelectedTableId)}
            />
          </Box>
        </Box>

        <WithPopper
          text={t('copied')}
          element={
            <Button
              variant='contained'
              disabled={sqlLoadingState.status !== 'Complete' || allSql === null}
              onClick={() =>
                navigator.clipboard.writeText(JSON.stringify(allSql, null, 2) || '').catch(e => console.error(e))
              }
            >
              <Stack direction='row' spacing={1} alignItems='center'>
                <Box>{t('copyAll')}</Box>
                <ContentCopyOutlined fontSize='small' />
              </Stack>
            </Button>
          }
        />
      </Stack>
      <Stack flex={1} direction='column' spacing={4}>
        <Stack flex={1} direction={'column'} spacing={2}>
          <Typography flex={0} variant='h4'>
            {t('tableCreationSql')}
          </Typography>
          <SqlTextField loadingState={sqlLoadingState} sql={currentTableSql?.creationSql || null} />
        </Stack>

        <Stack flex={1} direction={'column'} spacing={2}>
          <Stack flex={0} direction={'row'} spacing={2} alignItems='center'>
            <Typography flex={1} variant='h4'>
              {t('dataSql')}
            </Typography>
            <FormGroup>
              <FormControlLabel
                control={<Switch checked={withInsert} onChange={e => setWithInsert(e.target.checked)} />}
                label={t('withInsert')}
              />
            </FormGroup>
          </Stack>
          <SqlTextField
            loadingState={sqlLoadingState}
            sql={(withInsert ? currentTableSql?.insertDataSql : currentTableSql?.selectDataSql) || null}
          />
        </Stack>
      </Stack>
    </Stack>
  );
};
