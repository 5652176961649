import { GridRenderCellParams } from '@mui/x-data-grid-pro';
import React, { useState } from 'react';
import { SampleTrackingTransitionSampleCheckByGroupScopedModal } from '../../../researchProjectManagement/sampleTracking/SampleTrackingTransitionSampleModal';
import { DialogOpenButton } from '../../DialogOpenButton';
import { GuidGridCellTooltip } from './GuidGridCellTooltip';

interface SampleTrackingTransitionSampleCheckByGroupScopedModalCellProps extends GridRenderCellParams {
  onClose?: () => void;
}

export function renderSampleTrackingTransitionSampleCheckByGroupScopedModalCell(
  params: SampleTrackingTransitionSampleCheckByGroupScopedModalCellProps
) {
  return <MemoizedSampleTrackingTransitionSampleCheckByGroupScopedModalCell {...params} />;
}

const SampleTrackingTransitionSampleCheckByGroupScopedModalCell = ({
  value,
  row,
  onClose = () => {},
}: SampleTrackingTransitionSampleCheckByGroupScopedModalCellProps) => {
  const [modalOpen, setModalOpen] = useState<boolean>(false);

  function handleClose() {
    onClose();
    setModalOpen(false);
  }

  return (
    <span>
      {value && (
        <>
          <DialogOpenButton fontWeight={'inherit'} onClick={() => setModalOpen(true)}>
            <GuidGridCellTooltip guid={row.transitionCheckByGroupId} />
          </DialogOpenButton>
          {modalOpen && (
            <SampleTrackingTransitionSampleCheckByGroupScopedModal
              researchProjectId={row.researchProjectId}
              checkByGroupId={row.transitionCheckByGroupId}
              transitionType={row.sampleTrackingTransition}
              onClose={handleClose}
              title={value}
            />
          )}
        </>
      )}
    </span>
  );
};

const MemoizedSampleTrackingTransitionSampleCheckByGroupScopedModalCell = React.memo(
  SampleTrackingTransitionSampleCheckByGroupScopedModalCell,
  (prevProps, nextProps) => {
    return prevProps.value === nextProps.value;
  }
);
