import { Button } from '@mui/material';
import { ResearchProjectDetails } from 'data/ResearchProjectData';
import { Link } from 'react-router-dom';
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import React from 'react';
import GridCellWithIconButton from '../../components/grid/cell/GridCellWithIconButton';

export interface ResearchProjectGridCellProps {
  researchProject: ResearchProjectDetails;
  url: string;
  onEditButtonClick: () => unknown;
}

export const ResearchProjectGridCell = ({ researchProject, url, onEditButtonClick }: ResearchProjectGridCellProps) => {
  return (
    <GridCellWithIconButton
      icon={<ModeEditOutlineOutlinedIcon color='primary' />}
      buttonPermissions={['hasGreenSideWriteAccess']}
      onButtonClick={onEditButtonClick}
    >
      <Button
        component={Link}
        to={url}
        sx={[
          {
            display: 'block',
            fontSize: 'inherit',
            fontWeight: 'inherit',
            padding: 0,
            '&:hover': {
              backgroundColor: 'transparent',
            },
          },
        ]}
      >
        {researchProject.name}
      </Button>
    </GridCellWithIconButton>
  );
};
