import { LoadingState } from './LoadingStateUtil';
import { Autocomplete, CircularProgress, Tooltip } from '@mui/material';
import React from 'react';
import { ErrorTwoTone } from '@mui/icons-material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { AutocompleteProps } from '@mui/material/Autocomplete/Autocomplete';
import { ChipTypeMap } from '@mui/material/Chip';

type LoadableAutocompleteProps<
  Value,
  Multiple extends boolean | undefined = false,
  DisableClearable extends boolean | undefined = false,
  FreeSolo extends boolean | undefined = false,
  ChipComponent extends React.ElementType = ChipTypeMap['defaultComponent']
> = {
  loadingState: LoadingState;
} & AutocompleteProps<Value, Multiple, DisableClearable, FreeSolo, ChipComponent>;

/*
 * A wrapper around the MUI Autocomplete component that adds handling for loading or error states.
 */
function LoadableAutocomplete<
  Value,
  Multiple extends boolean | undefined = false,
  DisableClearable extends boolean | undefined = false,
  FreeSolo extends boolean | undefined = false,
  ChipComponent extends React.ElementType = ChipTypeMap['defaultComponent']
>({ loadingState, ...props }: LoadableAutocompleteProps<Value, Multiple, DisableClearable, FreeSolo, ChipComponent>) {
  return (
    <Autocomplete
      {...props}
      loading={loadingState.status === 'Loading'}
      disabled={loadingState.status !== 'Complete' || props.disabled}
      popupIcon={
        loadingState.status === 'Loading' ? (
          <CircularProgress size={20} />
        ) : loadingState.status === 'Error' ? (
          <Tooltip title={loadingState.errorMessage} placement='top'>
            <ErrorTwoTone color='error' sx={{ cursor: 'pointer', pointerEvents: 'auto' }} />
          </Tooltip>
        ) : (
          props.popupIcon ?? <ArrowDropDownIcon />
        )
      }
    />
  );
}

export default LoadableAutocomplete;
