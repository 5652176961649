import {
  AppBar,
  Box,
  Button,
  CircularProgress,
  Dialog,
  IconButton,
  Stack,
  ToggleButton,
  ToggleButtonGroup,
  Toolbar,
  Typography,
  useTheme,
} from '@mui/material';
import Summarize from '@mui/icons-material/Summarize';
import TableView from '@mui/icons-material/TableView';
import SsidChart from '@mui/icons-material/SsidChart';
import { GridRenderCellParams } from '@mui/x-data-grid-pro';
import React, { useEffect, useState } from 'react';
import {
  DepthExplorer,
  GapTable,
  Organism,
  organisms,
  StatsSummaryTable,
  useSequenceRunReferences,
  useSequenceRunsDepths,
  useSequenceRunStats,
} from '@physion/depth-store-ui';
import CloseIcon from '@mui/icons-material/Close';
import { BoxProps } from '@mui/material/Box/Box';
import { useDepthStoreService } from 'hooks/UseDepthStoreService';

export function renderGridCellInformaticsSequenceDepths(params: GridRenderCellParams) {
  return <GridGridCellInformaticsSequenceDepths {...params} />;
}

const GridGridCellInformaticsSequenceDepths = ({ row }: GridRenderCellParams) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const { data: referencesWithDepths, isLoading: referencesAreLoading } = useSequenceRunReferences(row.sequenceRunId);

  const [organism, setOrganism] = useState<Organism | undefined>();

  const setOrganismFromName = (referenceName: string) => {
    const matchingOrganism = Object.values(organisms).find(organism => organism.name === referenceName);
    setOrganism(matchingOrganism);
  };

  useEffect(() => {
    if (row?.reference?.relativePath) {
      setOrganismFromName(row.reference.relativePath);
    } else {
      setOrganism(undefined);
    }
  }, [row]);

  if (referencesWithDepths === undefined || referencesWithDepths.indexOf(row?.reference?.relativePath) === -1) {
    return null;
  }

  return (
    <>
      {referencesAreLoading && <CircularProgress size='1rem' />}

      {!referencesAreLoading && organism && (
        <>
          <Button
            sx={[
              {
                '&:hover': {
                  backgroundColor: 'transparent',
                },
              },
            ]}
            onClick={() => setIsOpen(true)}
          >
            <SsidChart />
          </Button>

          <DepthsDialog
            isOpen={isOpen}
            onClose={() => setIsOpen(false)}
            sampleIdentifier={row.sampleIdentifier}
            sequenceRunId={row.sequenceRunId}
            referenceName={row.reference.name}
            organism={organism}
          />
        </>
      )}
    </>
  );
};

const LabelledSection: React.FC<
  {
    label: string;
    labelBackgroundColor?: string;
  } & BoxProps
> = ({ label, labelBackgroundColor, children, ...boxProps }) => {
  const theme = useTheme();

  return (
    <Box {...boxProps} sx={{ ...boxProps?.sx, position: 'relative' }}>
      {children}
      <Typography
        variant='h6'
        sx={{
          position: 'absolute',
          top: '-0.5em',
          left: theme.spacing(2),
          paddingLeft: '0.5em',
          paddingRight: '0.5em',
          lineHeight: 1,
          backgroundColor: labelBackgroundColor ?? 'inherit',
          pointerEvents: 'none',
        }}
      >
        {label}
      </Typography>
    </Box>
  );
};

const DepthsDialog: React.FC<{
  isOpen: boolean;
  onClose: () => void;
  sampleIdentifier: string;
  sequenceRunId: string;
  referenceName: string;
  organism: Organism;
}> = ({ isOpen, onClose, sampleIdentifier, sequenceRunId, referenceName, organism }) => {
  const theme = useTheme();

  const depthStoreService = useDepthStoreService();

  const {
    isLoading: depthsAreLoading,
    error: depthsError,
    data: depthsData,
  } = useSequenceRunsDepths(
    depthStoreService,
    organism,
    organism.ncbiId,
    isOpen ? [{ sequenceName: sampleIdentifier, sequenceRunId }] : []
  );

  const { isLoading: statsAreLoading, data: statsData } = useSequenceRunStats(
    depthStoreService,
    isOpen ? sequenceRunId : undefined,
    organism.name,
    organism.ncbiId
  );

  type ComponentName = 'depthChart' | 'statsSummary' | 'gapTable';
  const [componentsToShow, setComponentsToShow] = useState<ComponentName[]>(['depthChart', 'statsSummary', 'gapTable']);

  return (
    <Dialog fullScreen={true} open={isOpen}>
      <AppBar sx={{ position: 'relative', backgroundColor: 'black' }}>
        <Toolbar>
          <IconButton edge='start' color='inherit' onClick={onClose} aria-label='close'>
            <CloseIcon />
          </IconButton>
          <Typography sx={{ marginLeft: 0, ml: 1, flex: 1 }} variant='h4'>
            {`${sampleIdentifier} - ${referenceName} Depths`}
          </Typography>

          <Box sx={{ display: 'flex', flexDirection: 'row', flexGrow: 0, alignItems: 'center' }}>
            <ToggleButtonGroup
              value={componentsToShow}
              onChange={(_, newComponentsToShow) => {
                if (newComponentsToShow.length > 0) {
                  setComponentsToShow(newComponentsToShow);
                }
              }}
              sx={{ flex: 0 }}
            >
              <ToggleButton value='depthChart' title='Show Depth Chart'>
                <SsidChart />
              </ToggleButton>

              <ToggleButton value='statsSummary' title='Show Depth Summary'>
                <Summarize />
              </ToggleButton>

              <ToggleButton value='gapTable' title='Show Gap Table'>
                <TableView />
              </ToggleButton>
            </ToggleButtonGroup>
          </Box>
        </Toolbar>
      </AppBar>

      <Stack spacing={theme.spacing(4)} sx={{ height: '100%', margin: theme.spacing(2) }}>
        {
          /* Unmount the DepthExplorer chart as soon as the dialog closes to prevent an error related to Highcharts
             pointer event handling. */
          isOpen && (
            <LabelledSection
              hidden={!componentsToShow.includes('depthChart')}
              label='Depth Chart'
              sx={{ position: 'relative', flex: 1 }}
            >
              <Box sx={{ position: 'absolute', top: 0, left: 0, bottom: 0, right: 0, marginTop: '1rem' }}>
                <DepthExplorer
                  organism={organism}
                  sequenceDepths={depthsData ?? []}
                  isLoading={depthsAreLoading}
                  error={depthsError}
                />
              </Box>
            </LabelledSection>
          )
        }

        {statsData && (
          <LabelledSection
            label='Depth Summary'
            labelBackgroundColor={theme.palette.background.paper}
            hidden={!componentsToShow.includes('statsSummary')}
            sx={{ flexGrow: 0 }}
          >
            <StatsSummaryTable
              includeSequenceNameColumn={false}
              statsRows={[
                {
                  sequenceRunId: sequenceRunId,
                  sequenceName: sampleIdentifier,
                  ...statsData,
                },
              ]}
              loading={statsAreLoading}
            />
          </LabelledSection>
        )}

        <LabelledSection
          label='Gaps'
          labelBackgroundColor={theme.palette.background.paper}
          hidden={!componentsToShow.includes('gapTable')}
          sx={{ position: 'relative', flex: 1 }}
        >
          <Box sx={{ position: 'absolute', top: 0, left: 0, bottom: 0, right: 0 }}>
            <GapTable
              organism={organism}
              sequenceDepths={depthsData ?? []}
              includeSequenceNameColumn={false}
              loading={depthsAreLoading}
            />
          </Box>
        </LabelledSection>
      </Stack>
    </Dialog>
  );
};
