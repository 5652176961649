import { create } from 'zustand';
import { AccessTokenSlice, createAccessTokenSlice } from './slices/accessTokenSlice';
import { ResearchProjectSlice, createResearchProjectSlice } from './slices/researchProjectSlice';
import { createPatientSlice, PatientSlice } from './slices/patientSlice';
import {
  ResearchProjectPatientsSlice,
  createResearchProjectPatientsSlice,
} from './slices/researchProjectPatientsSlice';
import { createPatientDataSlice, PatientDataSlice } from './slices/patientDataSlice';
import { AppActionsSlice, createAppActionsSlice } from './slices/appActionsSlice';
import { createMeasurementDefinitionsSlice, MeasurementDefinitionsSlice } from './slices/measurementDefinitionsSlice';
import { createMeasurementSlice, MeasurementSlice } from './slices/measurementSlice';
import { createShowDataWithIssuesSlice, ShowDataWithIssuesSlice } from './slices/showDataWithIssuesSlice';

// Store that holds data loaded from the server, including data about research projects and patients.
const useDataStore = create<
  AccessTokenSlice &
    ResearchProjectSlice &
    ResearchProjectPatientsSlice &
    PatientSlice &
    PatientDataSlice &
    MeasurementDefinitionsSlice &
    MeasurementSlice &
    ShowDataWithIssuesSlice &
    AppActionsSlice
>()((...a) => ({
  ...createAccessTokenSlice(...a),
  ...createResearchProjectSlice(...a),
  ...createResearchProjectPatientsSlice(...a),
  ...createPatientSlice(...a),
  ...createPatientDataSlice(...a),
  ...createMeasurementDefinitionsSlice(...a),
  ...createMeasurementSlice(...a),
  ...createShowDataWithIssuesSlice(...a),
  ...createAppActionsSlice(...a),
}));

// Getters
export const useResearchProjectId = () => useDataStore(state => state.researchProjectId);
export const usePatient = () => useDataStore(state => state.patient);
export const useResearchProjectPatients = () => useDataStore(state => state.researchProjectPatients);
export const usePatientData = () => useDataStore(state => state.patientData);
export const useMeasurementDefinitions = () => useDataStore(state => state.measurementDefinitions);
export const useMeasurementDefinitionTypes = () => useDataStore(state => state.measurementDefinitionTypes);
export const useMeasurement = () => useDataStore(state => state.measurement);
export const useShowDataWithIssues = () => useDataStore(state => state.showDataWithIssues);

// Actions
export const useStoreAccessToken = () => useDataStore(state => state.storeAccessToken);
export const useSelectResearchProject = () => useDataStore(state => state.selectResearchProject);
export const useSelectPatient = () => useDataStore(state => state.selectPatient);
export const useSelectMeasurement = () => useDataStore(state => state.setMeasurement);
export const useApplyClientSideReview = () => useDataStore(state => state.applyClientSideReview);
export const useSetShowDataWithIssues = () => useDataStore(state => state.setShowDataWithIssues);
