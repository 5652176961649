import { DepthStoreService } from '@physion/depth-store-ui';
import useAuth from 'auth/UseAuth';
import { useEffect, useRef } from 'react';

export const useDepthStoreService = (): DepthStoreService => {
  const { accessToken } = useAuth();

  const serviceRef = useRef(new DepthStoreService(process.env.REACT_APP_DEPTHSTORE_API_URL || ''));
  useEffect(() => {
    if (accessToken !== undefined) {
      serviceRef.current.setToken(accessToken);
    }
  }, [accessToken]);

  return serviceRef.current;
};
