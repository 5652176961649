import useAuth from '../../auth/UseAuth';
import { useEffect, useState } from 'react';
import { orderBy } from 'lodash';
import { GetStages, SuperDuperFiestaStage } from '../../data/SuperDuperFiestaData';

const useSuperDuperFiestaStages = (): ReadonlyArray<SuperDuperFiestaStage> => {
  const { accessToken } = useAuth();
  const [SuperDuperFiestaStages, setSuperDuperFiestaStages] = useState<ReadonlyArray<SuperDuperFiestaStage>>([]);

  useEffect(() => {
    let isCancelled = false;

    GetStages(accessToken).then(data => {
      if (isCancelled) {
        return;
      }

      setSuperDuperFiestaStages(orderBy(data, i => i.name));
    });

    return () => {
      isCancelled = true;
    };
  }, [accessToken]);

  return SuperDuperFiestaStages;
};

export default useSuperDuperFiestaStages;
