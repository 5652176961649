import useAuth from '../../auth/UseAuth';
import { useEffect, useState } from 'react';
import { LoadingState, LoadState } from '../LoadingStateUtil';
import { UseStateSetter } from '../../util/TypeUtil';
import { GetAllResearchProjects, GetResearchProject, ResearchProject } from '../../data/ResearchProjectData';

export const useResearchProjects = (
  setLoadingState: UseStateSetter<LoadingState>
): [ReadonlyArray<ResearchProject>, () => void] => {
  const { accessToken } = useAuth();
  const [researchProjects, setResearchProjects] = useState<ReadonlyArray<ResearchProject>>([]);
  const [refresh, refreshTrigger] = useState<boolean>(true);

  useEffect(() => {
    return LoadState(setLoadingState, async () => {
      if (!accessToken) {
        return;
      }

      const data = await GetAllResearchProjects(accessToken);
      setResearchProjects(data);
    });
  }, [refresh, accessToken, setLoadingState]);

  function refreshCallBack() {
    refreshTrigger(!refresh);
  }

  return [researchProjects, refreshCallBack];
};

export const useResearchProject = (
  researchProjectId: string,
  setLoadingState: UseStateSetter<LoadingState>
): ResearchProject | undefined => {
  const { accessToken } = useAuth();
  const [researchProject, setResearchProject] = useState<ResearchProject>();

  useEffect(() => {
    return LoadState(setLoadingState, async () => {
      if (!accessToken) {
        return;
      }

      const data = await GetResearchProject(researchProjectId, accessToken);
      setResearchProject(data);
    });
  }, [researchProjectId, accessToken, setLoadingState]);

  return researchProject;
};
