import React, { PropsWithChildren, useState } from 'react';
import { Accordion, AccordionDetails, AccordionSummary, Box, Typography, useTheme } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { BoxProps } from '@mui/material/Box/Box';

interface OwnProps {
  title: string;
  initiallyExpanded?: boolean;
}

type CollapsibleSectionProps = PropsWithChildren<OwnProps & BoxProps>;

const CollapsibleSection: React.FC<CollapsibleSectionProps> = ({ title, initiallyExpanded, children, ...boxProps }) => {
  const theme = useTheme();

  const [expanded, setExpanded] = useState<boolean>(initiallyExpanded ?? false);

  const handleExpandChange = () => {
    setExpanded(value => !value);
  };

  return (
    <Box {...boxProps} sx={{ marginTop: '16px', marginBottom: '8px', ...boxProps?.sx }}>
      <Accordion
        expanded={expanded}
        onChange={handleExpandChange}
        sx={{
          borderStyle: 'solid',
          borderWidth: '1px',
          borderColor: 'rgba(0, 0, 0, 0.23)',
          '&:hover': {
            borderColor: theme.colors.alpha.black[50],
          },
        }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          sx={{
            minHeight: 0,
            '&.Mui-expanded': {
              minHeight: 0,
              '& .MuiAccordionSummary-content': {
                marginTop: '12px',
                marginBottom: 0,
              },
              '& .MuiAccordionSummary-expandIconWrapper': {
                marginTop: '12px',
                marginBottom: 0,
              },
            },
          }}
        >
          <Typography variant='subtitle1'>{title}</Typography>
        </AccordionSummary>
        <AccordionDetails sx={{ paddingTop: 0 }}>{children}</AccordionDetails>
      </Accordion>
    </Box>
  );
};

export default CollapsibleSection;
