import { StateCreator } from 'zustand';
import { MeasurementDefinition } from '../../../../data/MeasurementDefinitionData';

export interface MeasurementSlice {
  measurement: MeasurementDefinition | undefined;
  setMeasurement: (measurement: MeasurementDefinition | undefined) => void;
}

export const createMeasurementSlice: StateCreator<MeasurementSlice> = set => ({
  measurement: undefined,
  setMeasurement: measurement => set({ measurement }),
});
