import { createCaseInsensitiveMap, Dictionary } from '../TypeUtil';
import { GridValueFormatterParams } from '@mui/x-data-grid/models/params/gridCellParams';
import { SampleAvailabilityType } from '../../data/ReferenceData';
import { SampleType } from '../../data/SampleTypeData';
import { TFunction } from 'i18next';
import { FlexBox } from '../../components/FlexBox';
import React from 'react';
import { SampleTrackingCountRow } from '../../data/SampleTrackingData';
import { SampleTrackingCountByType } from '../../pages/ResearchProjectManagementPage';
import {
  stGridPatientId,
  stGridPatientSourceKey,
  stGridRootSampleBbid,
  stGridRootSampleForeignHash,
  stGridRootSampleSampleId,
  stGridSampleBbid,
  stGridSampleForeignHash,
  stGridSampleId,
  stGridSamplePatientId,
} from '../Constants';
import { renderSampleHierarchyGridCell } from '../../components/grid/cell/SampleHierarchyGridCell';
import { renderGuidCellTooltip } from '../../components/grid/cell/GuidGridCellTooltip';
import { GridColDef } from '@mui/x-data-grid-pro';

export function generateWidthFromProperty(
  data: ReadonlyArray<any>,
  minWidth: number,
  getProperty: (row: any) => string | undefined,
  buffer = 9
) {
  let width = minWidth;

  data.forEach(row => (width = Math.max(width, (getProperty(row)?.length ?? 0) * buffer)));

  return width;
}

export function formatPatientSampleCountAsFlex(
  row: {
    sampleCounts: Dictionary<SampleTrackingCountRow>;
    patientCounts: Dictionary<SampleTrackingCountRow>;
  },
  field: string,
  sampleTrackingCountByType: SampleTrackingCountByType,
  includePatientCount: boolean,
  defaultValue: string = ''
): React.ReactNode {
  const patientCount =
    getForCountType(sampleTrackingCountByType, createCaseInsensitiveMap(row?.patientCounts ?? {})?.get(field)) ?? 0;
  const sampleCount =
    getForCountType(sampleTrackingCountByType, createCaseInsensitiveMap(row?.sampleCounts ?? {})?.get(field)) ?? 0;

  if (patientCount === 0 && sampleCount === 0) {
    return <div>{defaultValue}</div>;
  }

  return (
    <FlexBox flexDirection={'row'} flexWrap={'wrap'}>
      <div>{sampleCount.toLocaleString()}</div>
      {includePatientCount && <div style={{ marginLeft: 2 }}>{` (${patientCount.toLocaleString()}p)`}</div>}
    </FlexBox>
  );
}

export function formatPatientSampleCountWithDisplayName(
  row: {
    sampleCounts: Dictionary<SampleTrackingCountRow>;
    patientCounts: Dictionary<SampleTrackingCountRow>;
  },
  field: string,
  sampleTrackingCountByType: SampleTrackingCountByType,
  defaultValue: string = ''
) {
  if (field === undefined) {
    return '';
  }

  const patientCount =
    getForCountType(sampleTrackingCountByType, createCaseInsensitiveMap(row?.patientCounts ?? {})?.get(field)) ?? 0;
  const sampleCount =
    getForCountType(sampleTrackingCountByType, createCaseInsensitiveMap(row?.sampleCounts ?? {})?.get(field)) ?? 0;

  if (patientCount === 0 && sampleCount === 0) {
    return `${field} | ${defaultValue}`;
  }

  return `${field} | ${sampleCount.toLocaleString()} (${patientCount.toLocaleString()}p)`;
}

export function formatSampleCountWithDisplayName(
  row: {
    sampleCounts: Dictionary<SampleTrackingCountRow>;
  },
  field: string,
  sampleTrackingCountByType: SampleTrackingCountByType,
  defaultValue: string = ''
) {
  if (field === undefined) {
    return '';
  }

  const sampleCount =
    getForCountType(sampleTrackingCountByType, createCaseInsensitiveMap(row?.sampleCounts ?? {})?.get(field)) ?? 0;

  if (sampleCount === 0) {
    return `${field} | ${defaultValue}`;
  }

  return `${field} | ${sampleCount.toLocaleString()}`;
}

function getForCountType(sampleTrackingCountByType: SampleTrackingCountByType, count?: SampleTrackingCountRow) {
  if (count === undefined) {
    return 0;
  }

  switch (sampleTrackingCountByType) {
    case 'countByPassed':
      return count.passedCount;
    case 'countByFailed':
      return count.failedCount;
    case 'countByRemaining':
      return count.remainingCount;
    case 'countByWorkLeft':
      return count.workLeftCount;
  }
}

export function ExtractDisplayValueFromPossibleArray(value: any, t: TFunction) {
  if (!value) {
    return t(value);
  }

  if (Array.isArray(value) && value.length > 1) {
    return t('multiple');
  }

  if (Array.isArray(value)) {
    return t(value[0]);
  }

  return t(value);
}

export const formatDate = (value: string) =>
  value &&
  new Date(value).toLocaleString('en-US', {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
  });

export const dateFormatter = (params: GridValueFormatterParams) => params && params.value && formatDate(params.value);
export const dateGetter = (params: GridValueFormatterParams) => params && params.value && new Date(params.value);
export const formatSampleAvailabilityTypeByName = (values: ReadonlyArray<SampleAvailabilityType>, name: string) => {
  const type = values.find(i => i.name === name);

  return type?.isExhaustedState ? type.name : '';
};

export const formatSampleType = (values: ReadonlyArray<SampleType>, id: string) => {
  return values.find(i => i.sampleTypeId === id)?.displayName ?? '';
};

export function formatMsToHHMMSS(milliseconds: number): string {
  const totalSeconds = Math.floor(milliseconds / 1000);
  const hours = Math.floor(totalSeconds / 3600);
  const minutes = Math.floor((totalSeconds % 3600) / 60);
  const seconds = totalSeconds % 60;
  const millisecondsRemaining = milliseconds % 1000;

  const formattedHours = hours.toString().padStart(2, '0');
  const formattedMinutes = minutes.toString().padStart(2, '0');
  const formattedSeconds = seconds.toString().padStart(2, '0');
  const formattedMilliseconds = millisecondsRemaining.toString().padStart(3, '0');

  return `${formattedHours}h ${formattedMinutes}m ${formattedSeconds}s ${formattedMilliseconds}ms`;
}

export const sampleTrackingGridDefaultColumnOverrides: GridColDef[] = [
  ...([stGridSampleBbid, stGridRootSampleBbid].map(i => ({
    field: i,
    align: 'left',
    headerAlign: 'left',
    minWidth: 300,
    renderCell: params =>
      renderSampleHierarchyGridCell({
        ...params,
        sampleId:
          (params.row?.additionalData && params.row?.additionalData[stGridRootSampleSampleId]) ?? params.row.sampleId,
        displayValue: params.value,
      }),
  })) as GridColDef[]),
  ...([stGridSampleForeignHash, stGridRootSampleForeignHash].map(i => ({
    field: i,
    align: 'left',
    headerAlign: 'left',
    minWidth: 300,
    renderCell: params =>
      renderSampleHierarchyGridCell({
        ...params,
        sampleId:
          (params.row?.additionalData && params.row?.additionalData[stGridRootSampleSampleId]) ?? params.row.sampleId,
        displayValue: params.value,
      }),
  })) as GridColDef[]),
  ...[stGridSampleId, stGridSamplePatientId, stGridPatientId].map(i => ({
    field: i,
    sortable: false,
    width: 130,
    align: 'left',
    headerAlign: 'left',
    renderCell: renderGuidCellTooltip,
  })),
  {
    field: stGridPatientSourceKey,
    align: 'left',
    headerAlign: 'left',
    minWidth: 300,
  },
] as GridColDef[];

export const jsonIsEqual = (a: any, b: any) => JSON.stringify(a) === JSON.stringify(b);
