import useAuth from '../../auth/UseAuth';
import { useEffect, useState } from 'react';
import { orderBy } from 'lodash';
import { GetIssueTypes, SdfIssueType } from '../../data/SuperDuperFiestaData';

const useSdfIssueTypes = (): ReadonlyArray<SdfIssueType> => {
  const { accessToken } = useAuth();
  const [sdfIssueTypes, setSdfIssueTypes] = useState<ReadonlyArray<SdfIssueType>>([]);

  useEffect(() => {
    let isCancelled = false;

    GetIssueTypes(accessToken).then(data => {
      if (isCancelled) {
        return;
      }

      setSdfIssueTypes(orderBy(data, i => i.name));
    });

    return () => {
      isCancelled = true;
    };
  }, [accessToken]);

  return sdfIssueTypes;
};

export default useSdfIssueTypes;
