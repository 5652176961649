import React from 'react';
import { GridFilterInputValueProps } from '@mui/x-data-grid-pro';
import { useGridRootProps } from '@mui/x-data-grid';
import Autocomplete, { AutocompleteProps } from '@mui/material/Autocomplete';
import { unstable_useId as useId } from '@mui/utils';
import { useCallback, useState } from 'react';

export type GridFilterInputMultipleValueProps = {
  type?: 'text' | 'number';
} & GridFilterInputValueProps &
  Omit<AutocompleteProps<string, true, false, true>, 'options' | 'renderInput'>;

export function CustomGridFilterInputMultipleValue(props: GridFilterInputMultipleValueProps) {
  const { item, applyValue, type, apiRef, focusElementRef, color, error, helperText, size, variant, ...other } = props;
  const TextFieldProps = {
    color,
    error,
    helperText,
    size,
    variant,
  };

  const [filterValueState, setFilterValueState] = useState(item.value || []);
  const id = useId();

  const rootProps = useGridRootProps();

  const handleChange = useCallback(
    (event, value) => {
      const expandedValue = value
        .flatMap((val: string) => val.split(/[,;\n]/))
        .map((val: string) => val.trim())
        .filter(Boolean);

      setFilterValueState(expandedValue);
      applyValue({ ...item, value: [...expandedValue] });
    },
    [applyValue, item]
  );

  return (
    <Autocomplete<string, true, false, true>
      multiple
      freeSolo
      options={[]}
      filterOptions={(options, params) => {
        const { inputValue } = params;
        return inputValue == null || inputValue === '' ? [] : [inputValue];
      }}
      id={id}
      value={filterValueState}
      onChange={handleChange}
      renderTags={(value, getTagProps) =>
        value.map((option, index) => (
          <rootProps.slots.baseChip variant='outlined' size='small' label={option} {...getTagProps({ index })} />
        ))
      }
      renderInput={params => (
        <rootProps.slots.baseTextField
          {...params}
          label={apiRef.current.getLocaleText('filterPanelInputLabel')}
          placeholder={apiRef.current.getLocaleText('filterPanelInputPlaceholder')}
          InputLabelProps={{
            ...params.InputLabelProps,
            shrink: true,
          }}
          inputRef={focusElementRef}
          type={type || 'text'}
          {...TextFieldProps}
          {...rootProps.slotProps?.baseTextField}
          multiline
          rows={1}
        />
      )}
      {...other}
    />
  );
}
