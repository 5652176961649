import useAuth from '../../auth/UseAuth';
import { useEffect, useState } from 'react';
import { LoadingState, LoadState } from '../LoadingStateUtil';
import { UseStateSetter } from '../../util/TypeUtil';
import { GetAllMeasurementDefinitions, MeasurementDefinition } from '../../data/MeasurementDefinitionData';

export const useAllMeasurementDefinitions = (
  setLoadingState?: UseStateSetter<LoadingState>
): [ReadonlyArray<MeasurementDefinition>, () => void] => {
  const { accessToken } = useAuth();
  const [measurementDefinitions, setMeasurementDefinitions] = useState<ReadonlyArray<MeasurementDefinition>>([]);
  const [refresh, refreshTrigger] = useState<boolean>(true);

  useEffect(() => {
    return LoadState(setLoadingState, async () => {
      if (!accessToken) {
        return;
      }

      const data = await GetAllMeasurementDefinitions(accessToken);
      setMeasurementDefinitions(data);
    });
  }, [refresh, accessToken, setLoadingState]);

  function refreshCallBack() {
    refreshTrigger(!refresh);
  }

  return [measurementDefinitions, refreshCallBack];
};
