import { useEffect, useState } from 'react';

function useLocalStorage<T>(key: string | undefined, initialValue: T) {
  // Get the stored value from local storage on initial render
  let wasPresent = false;
  let parsedValue = null;
  if (key) {
    try {
      const storedValue = localStorage.getItem(key);
      parsedValue = storedValue ? JSON.parse(storedValue) : null;
      wasPresent = storedValue !== null;
    } catch (e) {
      parsedValue = null;
    }
  }

  const initial = parsedValue ?? initialValue;

  // Set up state to track the current value
  const [value, setValue] = useState<T>(initial);

  // Update local storage when the value changes
  useEffect(() => {
    if (!key) {
      return;
    }

    localStorage.setItem(key, JSON.stringify(value));
  }, [key, value]);

  return [value, setValue, wasPresent] as const;
}

export default useLocalStorage;
