import { appSettings } from '../AppSettings';
import { BaseDeleteVoid, BaseGet, BasePost, BasePut } from './BaseData';

export interface DataProduct {
  dataProductId: string;
  name: string;
  dataSchemaId: string;
  dataSchemaName: string;
  tables: DataProductTable[];
  datasets: DataProductDataset[];
}

export interface DataProductTable {
  dataProductId: string;
  dataSchemaTableId: string;
  name: string;
  redacted: boolean;
  fields: DataProductField[];
}

export interface DataProductField {
  dataProductId: string;
  dataSchemaFieldId: string;
  name: string;
  redacted: boolean;
  pointsToTableId: string | null;
}

export interface DataProductDataset {
  dataProductId: string;
  name: string | null;
  researchProjectId: string;
  researchProjectName: string;
}

export interface DataProductSql {
  tableSql: DataProductTableSql[];
}

export interface DataProductTableSql {
  dataSchemaTableId: string;
  tableName: string;
  creationSql: string;
  selectDataSql: string;
  insertDataSql: string;
}

export async function GetDataProducts(includeLinkedEntities: boolean, accessToken: string): Promise<DataProduct[]> {
  return await BaseGet(
    `${appSettings.api.endpoint}/api/v2/DataProduct?includeLinkedEntities=${includeLinkedEntities}`,
    accessToken
  );
}

export async function GetDataProduct(dataProductId: string, accessToken: string): Promise<DataProduct | null> {
  return await BaseGet(`${appSettings.api.endpoint}/api/v2/DataProduct/${dataProductId}`, accessToken);
}

export async function CreateDataProduct(dataProduct: DataProduct, accessToken: string): Promise<DataProduct> {
  return await BasePost(`${appSettings.api.endpoint}/api/v2/DataProduct`, dataProduct, accessToken);
}

export async function UpdateDataProduct(dataProduct: DataProduct, accessToken: string): Promise<DataProduct> {
  return await BasePut(
    `${appSettings.api.endpoint}/api/v2/DataProduct/${dataProduct.dataProductId}`,
    dataProduct,
    accessToken
  );
}

export async function DeleteDataProduct(dataProductId: string, accessToken: string): Promise<void> {
  await BaseDeleteVoid(`${appSettings.api.endpoint}/api/v2/DataProduct/${dataProductId}`, accessToken);
}

export async function ConstructDataProductSql(dataProduct: DataProduct, accessToken: string): Promise<DataProductSql> {
  return await BasePost(`${appSettings.api.endpoint}/api/v2/DataProduct/AdHocSql`, dataProduct, accessToken);
}
