import { DependencyList, useEffect, useRef } from 'react';
import { useMediaQuery } from '@mui/material';

// Hook that fires the given effect whenever the media query switches between active and inactive. The effect will not
// be fired unless the query's active state changes, even if the user-provided dependencies change.
const useMediaQueryEffect = (
  query: Parameters<typeof useMediaQuery>[0],
  effect: (queryActive: boolean) => void,
  deps: DependencyList
) => {
  const queryActive = useMediaQuery(query);
  const prevQueryActive = useRef(queryActive);

  useEffect(() => {
    if (prevQueryActive.current === queryActive) {
      return;
    }

    prevQueryActive.current = queryActive;

    effect(queryActive);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query, queryActive, effect, ...deps]);
};

export default useMediaQueryEffect;
