import { GRID_CHECKBOX_SELECTION_FIELD, GridColDef, GridRowSelectionModel, useGridApiRef } from '@mui/x-data-grid-pro';
import useAuth from 'auth/UseAuth';
import { useEffect, useMemo, useState } from 'react';
import { Box } from '@mui/material';
import { GetSampleTrackingSelectedSamples, SampleTrackingSelectedTableData } from '../../data/SampleTrackingData';
import { getSampleIdentifier, Sample } from '../../data/SampleData';
import { CreateSampleTrackingTransitionModal } from './CreateSampleTrackingTransitionModal';
import { AuthorizedSection } from '../../auth/AuthorizedSection';
import { FlexTableBox } from '../../components/FlexTableBox';
import {
  copyBtn,
  foreignHash,
  sampleBbid,
  sampleId,
  sampleIdentifier,
  stGridResearchProjectSampleSelectionStatus,
  stGridSampleAvailability,
  stGridSampleBioBankId,
  stGridSampleCollectionDate,
  stGridSampleLabId,
  stGridTransitionSampleTransitionId,
} from '../../util/Constants';
import { map, uniq } from 'lodash';
import { SampleTrackingRowSelectionModal } from './SampleTrackingRowSelectionModal';
import { LoadingState, LoadState } from '../../components/LoadingStateUtil';
import useMemoTranslation from '../../hooks/UseMemoTranslation';
import { renderSampleHierarchyGridCell } from '../../components/grid/cell/SampleHierarchyGridCell';
import { SampleTrackingExhaustModal } from './SampleTrackingExhaustModal';
import { DecryptForeignHashesSwitch } from '../../components/DecryptForeignHashesSwitch';
import { SampleTrackingGridWrapper } from '../../components/grid/SampleTrackingGridWrapper';
import { sampleTrackingGridDefaultColumnOverrides } from '../../util/grid/TableUtils';
import { renderCopyTextGridCell } from '../../components/grid/cell/CopyTextGridCell';

type Row = {
  id: number;
  sampleIdentifier: string;
  currentStage?: string;
  originLab?: string;
  originBiobank?: string;
  sampleJourneyId: string;
} & Sample;

export interface SampleTrackingSelectedSampleGridProps {
  researchProjectId: string;
  sampleGroup?: string;
}

export const SampleTrackingSelectedSampleGrid = ({
  researchProjectId,
  sampleGroup,
}: SampleTrackingSelectedSampleGridProps) => {
  const { accessToken } = useAuth();
  const apiRef = useGridApiRef();

  const [loadingState, setLoadingState] = useState<LoadingState>({ status: 'Loading' });
  const [refreshTrigger, setRefreshTrigger] = useState<boolean>(true);

  const [rawData, setRawData] = useState<ReadonlyArray<SampleTrackingSelectedTableData>>([]);
  const [selectedSampleIds, setSelectedSampleIds] = useState<string[]>([]);
  const [selectionModel, setSelectionModel] = useState<GridRowSelectionModel>();
  const [useDecryptedHashes, setUseDecryptedHashes] = useState<boolean>(false);

  const columns = useColumns();
  const rows = useRows(rawData);

  useEffect(() => {
    return LoadState(setLoadingState, async () => {
      if (!accessToken || !researchProjectId) {
        return;
      }

      const data = await GetSampleTrackingSelectedSamples(
        researchProjectId,
        accessToken,
        useDecryptedHashes,
        sampleGroup
      );

      setRawData(data);
    });
  }, [accessToken, researchProjectId, useDecryptedHashes, sampleGroup, refreshTrigger]);

  useEffect(() => {
    if (!selectionModel) {
      return;
    }

    const sampleIds: string[] = [];
    selectionModel.forEach(selected => {
      const id = apiRef.current?.getRow(selected)?.sampleId;
      if (id) {
        sampleIds.push(id);
      }
    });

    setSelectedSampleIds(uniq(sampleIds));
  }, [selectionModel, apiRef]);

  function refreshData(hasChanges: boolean) {
    if (!hasChanges) {
      return;
    }

    setRefreshTrigger(currentState => !currentState);
    setSelectionModel([]);
  }

  return (
    <>
      <FlexTableBox>
        <SampleTrackingGridWrapper
          apiRef={apiRef}
          leftActions={
            <Box display='flex' flexDirection='row' alignItems='center'>
              <DecryptForeignHashesSwitch decryptedHashesProps={[useDecryptedHashes, setUseDecryptedHashes]} />
            </Box>
          }
          rightActions={
            <Box display='flex' flexDirection='row-reverse' alignItems='center'>
              <AuthorizedSection hasSampleTrackingWriteAccess>
                <CreateSampleTrackingTransitionModal
                  researchProjectId={researchProjectId}
                  selectedSamples={map(selectedSampleIds, i => {
                    const item = rows.find(j => j.sampleId === i);

                    return { ...item, sampleIdentifier: item?.sampleIdentifier ?? '' } as any;
                  })}
                  onClose={refreshData}
                />
                <SampleTrackingExhaustModal selectedSampleIds={selectedSampleIds} onClose={refreshData} />
                <SampleTrackingRowSelectionModal
                  gridApiRef={apiRef}
                  sampleIdProperties={[foreignHash, sampleIdentifier, sampleBbid, sampleId]}
                  setSelectionModel={setSelectionModel}
                />
              </AuthorizedSection>
            </Box>
          }
          loadingState={loadingState}
          rows={rows}
          columns={columns}
          initialState={{
            pinnedColumns: { left: [GRID_CHECKBOX_SELECTION_FIELD, copyBtn] },
            sorting: {
              sortModel: [{ field: sampleIdentifier, sort: 'asc' }],
            },
          }}
          checkboxSelection
          disableRowSelectionOnClick
          onRowSelectionModelChange={newSelectionModel => setSelectionModel(newSelectionModel)}
          rowSelectionModel={selectionModel}
          loadingProps={[loadingState, setLoadingState]}
          defaultColumnOrdering={[
            copyBtn,
            sampleIdentifier,
            stGridTransitionSampleTransitionId,
            stGridSampleLabId,
            stGridSampleBioBankId,
            stGridResearchProjectSampleSelectionStatus,
            stGridSampleCollectionDate,
            stGridSampleAvailability,
          ]}
          cacheKey={'sample-tracking-selected-sample-grid'}
          columnDefinitionOverrides={sampleTrackingGridDefaultColumnOverrides}
          refreshTrigger={refreshTrigger}
        />
      </FlexTableBox>
    </>
  );
};

const useColumns = (): GridColDef[] => {
  const { t } = useMemoTranslation();

  return useMemo(
    () => [
      {
        field: copyBtn,
        headerName: t(copyBtn),
        headerAlign: 'center',
        align: 'center',
        maxWidth: 30,
        renderCell: renderCopyTextGridCell,
        valueGetter: params => params.row.sampleIdentifier,
      },
      {
        field: sampleIdentifier,
        headerName: t(sampleIdentifier),
        headerAlign: 'left',
        align: 'left',
        width: 350,
        maxWidth: 600,
        renderCell: params =>
          renderSampleHierarchyGridCell({
            ...params,
            sampleId: params.row.sampleId,
            displayValue: params.value,
          }),
        valueGetter: params => params.value,
      },
    ],
    [t]
  );
};

const useRows = (samples: ReadonlyArray<SampleTrackingSelectedTableData>): ReadonlyArray<Row> => {
  return useMemo(() => {
    return samples.map((r, index) => {
      return {
        id: index++,
        ...r.sample,
        sampleIdentifier: getSampleIdentifier(r.sample, r.labAssignedSampleLabel),
        sampleJourneyId: r.sampleJourneyId,
      };
    });
  }, [samples]);
};
