import React, { useState } from 'react';

import DateRangeTwoToneIcon from '@mui/icons-material/DateRangeTwoTone';

import { Button, ButtonGroup, FormControl, InputLabel, MenuItem, Select, Stack } from '@mui/material';
import { useCurrentDate, useEarliestStartYear, useSetStartYear, useStartYear } from '../../../../stores/dateRangeStore';

const StartYearSelector: React.FC<{ expanded: boolean }> = ({ expanded }) => {
  const startYear = useStartYear();
  const setStartYear = useSetStartYear();

  const currentYear = useCurrentDate().getUTCFullYear();
  const earliestStartYear = useEarliestStartYear();

  const shortcutEvery = 5; // Add a shortcut button for every five-year increment.
  const numShortcuts = Math.floor((currentYear - earliestStartYear) / shortcutEvery);
  const yearsBackShortcuts = Array.from({ length: numShortcuts }, (_, i) => (i + 1) * shortcutEvery);

  const yearsBackToYear = (yearsBack: number) => currentYear - yearsBack;

  const [years] = useState<number[]>(() => {
    const ys: number[] = [];

    for (let year = currentYear; year >= yearsBackToYear(yearsBackShortcuts.at(-1)!); year--) {
      ys.push(year);
    }

    return ys;
  });

  return (
    <Stack direction='row' spacing={2} alignItems='end'>
      {expanded && (
        <FormControl fullWidth sx={{ flex: 1 }}>
          <InputLabel id='start-year-select-label' sx={{ overflow: 'visible' }}>
            Start year for patient data
          </InputLabel>
          <Select
            displayEmpty
            labelId='start-year-select-label'
            id='start-year-select'
            value={startYear}
            variant='standard'
            onChange={event => setStartYear(parseInt((event.target.value as unknown as string) || '2000', 10))}
            sx={{ flex: 1 }}
          >
            {years?.map(year => (
              <MenuItem key={year} value={year}>
                <Stack direction='row' alignItems='center'>
                  <DateRangeTwoToneIcon style={{ marginRight: '0.5em' }} />
                  <span style={{ paddingRight: '12px' }}>{year}</span>
                </Stack>
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}

      <ButtonGroup fullWidth orientation={expanded ? 'horizontal' : 'vertical'} sx={{ flex: 0 }}>
        {yearsBackShortcuts.map(yearsBack => (
          <Button
            key={yearsBack}
            size='small'
            variant={yearsBackToYear(yearsBack) === startYear ? 'contained' : 'outlined'}
            value={yearsBackToYear(yearsBack)}
            onClick={() => setStartYear(yearsBackToYear(yearsBack))}
            sx={{
              paddingLeft: '.5em',
              paddingRight: '.5em',
            }}
          >
            -{yearsBack}Y
          </Button>
        ))}
      </ButtonGroup>
    </Stack>
  );
};

export default StartYearSelector;
