import useAuth from '../../auth/UseAuth';
import { useEffect, useState } from 'react';
import { orderBy } from 'lodash';
import { GetSteps, SuperDuperFiestaStep } from '../../data/SuperDuperFiestaData';

const useSuperDuperFiestaSteps = (): ReadonlyArray<SuperDuperFiestaStep> => {
  const { accessToken } = useAuth();
  const [SuperDuperFiestaSteps, setSuperDuperFiestaSteps] = useState<ReadonlyArray<SuperDuperFiestaStep>>([]);

  useEffect(() => {
    let isCancelled = false;

    GetSteps(accessToken).then(data => {
      if (isCancelled) {
        return;
      }

      setSuperDuperFiestaSteps(orderBy(data, i => i.name));
    });

    return () => {
      isCancelled = true;
    };
  }, [accessToken]);

  return SuperDuperFiestaSteps;
};

export default useSuperDuperFiestaSteps;
