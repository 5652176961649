import { DiseaseAreaOption } from 'researchProjectManagement/ResearchProjectAutocompleteOptions';
import useAuth from '../../auth/UseAuth';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { GetDiseaseAreas } from '../../data/DiseaseAreaData';

export const useDiseaseAreaOptions = (): [DiseaseAreaOption[], boolean, Dispatch<SetStateAction<boolean>>] => {
  const { accessToken } = useAuth();
  const [diseaseAreaOptions, setDiseaseAreaOptions] = useState<DiseaseAreaOption[]>([]);
  const [refresh, refreshOptions] = useState<boolean>(true);

  useEffect(() => {
    async function getData() {
      const diseaseAreas = await GetDiseaseAreas(accessToken);
      setDiseaseAreaOptions(
        diseaseAreas.map(da => {
          return { label: da.name, diseaseArea: da };
        })
      );
    }

    getData();
  }, [refresh, accessToken]);

  return [diseaseAreaOptions, refresh, refreshOptions];
};
