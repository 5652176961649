import React, { useState } from 'react';
import {
  Box,
  Chip,
  CircularProgress,
  FormControl,
  IconButton,
  InputLabel,
  OutlinedInput,
  Select,
  useTheme,
} from '@mui/material';
import { t } from 'i18next';
import { EditTwoTone } from '@mui/icons-material';
import { LinkedMeasurementDefinition } from '../../data/MeasurementDefinitionData';
import { SelectMeasurementsModal } from './SelectMeasurementsModal';

interface DiseaseAreaMeasurementsInputProps {
  loading: boolean;
  selectedMeasurementDefinitions: LinkedMeasurementDefinition[];
  onMeasurementsChange: (newMeasurementDefinitions: LinkedMeasurementDefinition[]) => unknown;
}

const DiseaseAreaMeasurementsInput: React.FC<DiseaseAreaMeasurementsInputProps> = ({
  loading,
  selectedMeasurementDefinitions,
  onMeasurementsChange,
}) => {
  const theme = useTheme();

  const [editorDialogOpen, setEditorDialogOpen] = useState(false);

  return (
    <>
      <FormControl fullWidth margin='normal'>
        <InputLabel>{t('measurements')}</InputLabel>
        <Select
          fullWidth
          multiple
          value={selectedMeasurementDefinitions}
          input={<OutlinedInput label={t('measurements')} />}
          inputProps={{
            tabIndex: -1,
          }}
          IconComponent={() => (
            <IconButton
              size='medium'
              onClick={() => setEditorDialogOpen(true)}
              disabled={loading}
              sx={{
                pointerEvents: 'auto',
                position: 'absolute',
                right: 0,
                transition: theme.transitions.create(['color']),
                '&:hover': {
                  backgroundColor: 'transparent',
                  color: theme.palette.primary.main,
                },
              }}
            >
              {loading ? <CircularProgress variant='indeterminate' size={20} /> : <EditTwoTone />}
            </IconButton>
          )}
          renderValue={selected => (
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
              {selected.map(i => (
                <Chip
                  key={i.measurementDefinition.measurementDefinitionId}
                  label={i.measurementDefinition.shortName}
                  sx={{ backgroundColor: i.isTargetStatistic ? '#faaf00' : undefined }}
                />
              ))}
            </Box>
          )}
          sx={{ pointerEvents: 'none' }}
        />
      </FormControl>

      <SelectMeasurementsModal
        open={editorDialogOpen}
        onClose={newSelectedMeasurementDefinitions => {
          if (newSelectedMeasurementDefinitions !== null) {
            onMeasurementsChange(newSelectedMeasurementDefinitions);
          }
          setEditorDialogOpen(false);
        }}
        initialLinkedMeasurementDefinitions={selectedMeasurementDefinitions}
      />
    </>
  );
};

export default DiseaseAreaMeasurementsInput;
