import { useMemo } from 'react';
import useMemoTranslation from '../hooks/UseMemoTranslation';
import { useGridApiRef } from '@mui/x-data-grid-pro';
import { FlexBox } from './FlexBox';
import { field, key, value } from '../util/Constants';
import { autoSizeNonSampleAndPatientIdentifyingColumns, CompactGridWrapper } from './grid/CompactGridWrapper';
import { FlexTableBox } from './FlexTableBox';
import { Typography } from '@mui/material';

type Row = { key: string; value: string };

export const KeyValuePairTable = ({ data }: { data: ReadonlyArray<Row> }) => {
  const apiRef = useGridApiRef();
  const { t } = useMemoTranslation();

  const columns = useColumns();
  const rows = useRows(data);

  return (
    <FlexBox sx={{ flexDirection: 'column', gap: 1 }}>
      <FlexTableBox
        sx={{
          width: '25vw',
          maxHeight: '50vh',
          '& .key-value-bold': {
            fontWeight: '600',
          },
        }}
      >
        {rows.length > 0 && (
          <CompactGridWrapper
            apiRef={apiRef}
            columns={columns}
            rows={rows}
            columnsToAutoSize={autoSizeNonSampleAndPatientIdentifyingColumns(columns)}
            disableRowSelectionOnClick={true}
          />
        )}
        {rows.length === 0 && (
          <FlexBox justifyContent={'space-around'}>
            <Typography variant={'h5'}>{t('noData')}</Typography>
          </FlexBox>
        )}
      </FlexTableBox>
    </FlexBox>
  );
};

const useColumns = () => {
  const { t } = useMemoTranslation();

  return useMemo(
    () => [
      {
        field: key,
        headerName: t(field),
        cellClassName: 'key-value-bold',
        headerClassName: 'key-value-bold',
        minWidth: 250,
        flex: 0.75,
      },
      {
        field: value,
        headerName: t(value),
        headerClassName: 'key-value-bold',
        minWidth: 250,
        flex: 1,
      },
    ],
    [t]
  );
};

const useRows = (data: ReadonlyArray<Row>) => {
  return useMemo(
    () =>
      data.map((i, index) => {
        return { ...i, id: index };
      }),
    [data]
  );
};
