import { BaseDownloadFileUsingPost, BaseGet, BasePost } from './BaseData';
import { appSettings } from '../AppSettings';
import { Dictionary } from 'lodash';

export type SelectableFieldType = 'Text' | 'Date';
export interface SelectableEntity {
  name: string;
  displayName: string;
  fields: ReadonlyArray<SelectableField>;
}

export interface SelectableField {
  columnName: string;
  columnDisplayName: string;
  type: SelectableFieldType;
  key: string;
  fullDisplayName: string;
}

export interface SelectableCandidate {
  sampleJourneyId: string;
  sampleId: string;
}

export interface GetFieldsPayload {
  entities: ReadonlyArray<SelectableEntity>;
  candidates: ReadonlyArray<SelectableCandidate>;
  researchProjectId?: string;
}

export type SelectedData = {
  data: Dictionary<Dictionary<string | undefined | null>>;
  columns: ReadonlyArray<SelectableField>;
  entries: ReadonlyArray<SelectedEntry>;
};

export type SelectedEntry = {
  entryId: string;
  sampleId: string;
};

export async function GetAllSelectableFieldsForResearchProject(
  researchProjectId: string,
  accessToken?: string
): Promise<ReadonlyArray<SelectableEntity>> {
  return await BaseGet(
    `${appSettings.api.endpoint}/api/v2/SampleTrackingFieldSelection/SelectableFields/${researchProjectId}`,
    accessToken
  );
}

export async function SelectFields(data: GetFieldsPayload, accessToken?: string): Promise<SelectedData> {
  return await BasePost(`${appSettings.api.endpoint}/api/v2/SampleTrackingFieldSelection/Select`, data, accessToken);
}

export async function ExportTransitionManifest(data: GetFieldsPayload, accessToken?: string): Promise<void> {
  await BaseDownloadFileUsingPost(
    `${appSettings.api.endpoint}/api/v2/SampleTrackingFieldSelection/Export`,
    data,
    accessToken
  );
}
