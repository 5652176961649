import React from 'react';
import { Grid } from '@mui/material';
import { DiseaseAreaBlock } from './DiseaseAreaBlock';
import { ResearchProjectBlock } from './ResearchProjectBlock';
import { PathogenGroupBlock } from './PathogenGroupBlock';
import { AuthorizedSection } from '../auth/AuthorizedSection';
import { GreenAuthorizedSection } from '../auth/GreenAuthorizedSection';
import { DataProductsBlock } from './DataProductsBlock';

export const HomePageGrid = () => {
  return (
    <Grid
      sx={{
        px: 4,
      }}
      container
      direction='row'
      justifyContent='center'
      alignItems='stretch'
      spacing={4}
    >
      <GreenAuthorizedSection>
        <Grid item md={6} xs={12}>
          <DiseaseAreaBlock />
        </Grid>
      </GreenAuthorizedSection>
      <Grid item md={6} xs={12}>
        <PathogenGroupBlock />
      </Grid>
      <AuthorizedSection hasSampleTrackingViewAccess hasGreenSideViewAccess requireAny>
        <Grid item md={6} xs={12}>
          <ResearchProjectBlock />
        </Grid>
      </AuthorizedSection>
      <AuthorizedSection hasGreenSideViewAccess>
        <Grid item md={6} xs={12}>
          <DataProductsBlock />
        </Grid>
      </AuthorizedSection>
    </Grid>
  );
};
