import { useEffect, useState } from 'react';
import { appSettings } from '../AppSettings';
import useAuth from './UseAuth';
import { AuthPermission } from './AuthPermission';

const useAllUserPermissions = (): ReadonlyArray<AuthPermission> => {
  const { user, isAuthenticated } = useAuth();
  const [permissions, setPermissions] = useState<ReadonlyArray<AuthPermission>>([]);

  useEffect(() => {
    if (!isAuthenticated || user === undefined) {
      setPermissions([]);
      return;
    }

    if (`${appSettings.auth.claims_namespace}/permissions` in user) {
      const userPermissions = user[`${appSettings.auth.claims_namespace}/permissions`];

      if (!userPermissions.includes('livinglab:user')) {
        setPermissions([]);
        return;
      }

      setPermissions(userPermissions);
    }
  }, [isAuthenticated, user]);

  return permissions as ReadonlyArray<AuthPermission>;
};

export default useAllUserPermissions;
