import React, { useEffect } from 'react';

interface Props {
  refreshTrigger: boolean;
  children: React.ReactNode;
}

const RefreshableComponent = ({ refreshTrigger, children }: Props) => {
  const [key, setKey] = React.useState(Math.random());

  useEffect(() => {
    setKey(Math.random());
  }, [refreshTrigger]);

  return <React.Fragment key={key}>{children}</React.Fragment>;
};

export const RefreshableComponentWrapper = React.memo(RefreshableComponent, (prevProps, nextProps) => {
  return prevProps.refreshTrigger === nextProps.refreshTrigger && prevProps.children === nextProps.children;
});
