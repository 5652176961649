import React, { useEffect, useState } from 'react';
import {
  Autocomplete,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  TextField,
  useTheme,
} from '@mui/material';
import useAuth from 'auth/UseAuth';
import { DataSchema, GetDataSchemas } from '../data/DataSchemaData';
import { t } from 'i18next';
import { LoadingState, LoadState } from '../components/LoadingStateUtil';
import { LoadingIndicator } from '../components/LoadingIndicator';
import { ErrorIndicator } from '../components/ErrorIndicator';

interface DataProductCreationDialogProps {
  open: boolean;
  onAdd: (name: string, dataSchema: DataSchema) => unknown;
  onCancel: () => unknown;
}

export const DataProductCreationDialog: React.FC<DataProductCreationDialogProps> = ({ open, onAdd, onCancel }) => {
  const { accessToken } = useAuth();

  const theme = useTheme();

  const [dataSchemas, setDataSchemas] = useState<DataSchema[]>([]);
  const [loadingState, setLoadingState] = useState<LoadingState>({ status: 'NotStarted' });
  useEffect(() => {
    if (accessToken === undefined) {
      return;
    }

    return LoadState(setLoadingState, async () => {
      const res = await GetDataSchemas(true, accessToken);
      setDataSchemas(res);
    });
  }, [accessToken]);

  const [name, setName] = useState<string | null>(null);
  const [selectedDataSchema, setSelectedDataSchema] = useState<DataSchema | null>(null);

  return (
    <Dialog open={open} onClose={onCancel} fullWidth maxWidth='xs' scroll='paper' sx={{ height: '100%' }}>
      <DialogTitle>{t('createDataProduct')}</DialogTitle>
      <DialogContent sx={{ paddingTop: `${theme.spacing(1)} !important` }}>
        <Stack direction='column' spacing={2}>
          <TextField
            label={t('name')}
            value={name ?? ''}
            onChange={e => {
              const newName = e.target.value.trim();
              if (newName.length > 0) {
                setName(e.target.value);
              } else {
                setName(null);
              }
            }}
          />

          <Autocomplete
            disabled={loadingState.status !== 'Complete'}
            options={dataSchemas}
            getOptionLabel={option => option.name}
            renderInput={params => <TextField {...params} label={t('schema')} />}
            value={selectedDataSchema}
            onChange={(_, value) => setSelectedDataSchema(value)}
          />
          <LoadingIndicator loadingState={loadingState} />
          <ErrorIndicator loadingState={loadingState} />
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button variant='outlined' color='error' onClick={onCancel}>
          {t('cancel')}
        </Button>
        <Button
          variant='contained'
          color='primary'
          disabled={name === null || selectedDataSchema === null}
          onClick={() => {
            if (name !== null && selectedDataSchema !== null) {
              onAdd(name, selectedDataSchema);
              setName(null);
              setSelectedDataSchema(null);
            }
          }}
        >
          {t('add')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
