import React from 'react';
import { Box, Button, ButtonProps, Stack, Tooltip } from '@mui/material';
import {
  ACCEPTED_REVIEW_STATUS,
  REJECTED_REVIEW_STATUS,
  ReviewStatus,
  UNDECIDED_REVIEW_STATUS,
} from '../../../../../data/PatientReviewData';

export interface DecisionAvailability {
  actionAvailable: boolean;
  tooltip: string | undefined;
}

const DecisionButton: React.FC<{ availability: DecisionAvailability } & ButtonProps> = ({
  availability,
  ...buttonProps
}) => {
  return (
    <Tooltip placement='top' title={availability.tooltip}>
      {/* Box required for the tooltip to show up if requested when the button is disabled */}
      <Box>
        <Button {...buttonProps} variant='contained' sx={{ height: 40 }} disabled={!availability.actionAvailable} />
      </Box>
    </Tooltip>
  );
};

const DecisionButtons: React.FC<{
  undecidedAvailability: DecisionAvailability;
  rejectAvailability: DecisionAvailability;
  acceptAvailability: DecisionAvailability;
  onDecisionSelected: (outcome: ReviewStatus['reviewStatusId']) => void;
}> = ({ undecidedAvailability, rejectAvailability, acceptAvailability, onDecisionSelected }) => {
  return (
    <Box display='flex' justifyContent='space-between' alignItems='flex-end' flex={1} margin={0}>
      <Stack direction='row' spacing={2} flex={1}>
        <DecisionButton
          availability={undecidedAvailability}
          color='secondary'
          onClick={() => onDecisionSelected(UNDECIDED_REVIEW_STATUS.reviewStatusId)}
        >
          Undecided
        </DecisionButton>

        <DecisionButton
          availability={rejectAvailability}
          color='primary'
          onClick={() => onDecisionSelected(REJECTED_REVIEW_STATUS.reviewStatusId)}
        >
          Reject
        </DecisionButton>

        <DecisionButton
          availability={acceptAvailability}
          color='primary'
          onClick={() => onDecisionSelected(ACCEPTED_REVIEW_STATUS.reviewStatusId)}
        >
          Accept
        </DecisionButton>
      </Stack>
    </Box>
  );
};

export default DecisionButtons;
