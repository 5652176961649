import { createContext, FC, useContext, useState } from 'react';

interface PageHeaderContext {
  expanded: boolean;
  setExpanded: (expanded: boolean) => void;
}

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PageHeaderContext = createContext<PageHeaderContext>({} as PageHeaderContext);

export const PageHeaderProvider: FC = ({ children }) => {
  const [expanded, setExpanded] = useState(true);

  return <PageHeaderContext.Provider value={{ expanded, setExpanded }}>{children}</PageHeaderContext.Provider>;
};

export const usePageHeaderContext = () => useContext(PageHeaderContext);
