import { ChangeEvent, useEffect, useState } from 'react';
import { AuthorizedPage } from 'auth/AuthorizedPage';
import { Page } from 'components/Page';
import { appSettings } from 'AppSettings';
import { useAuth0 } from '@auth0/auth0-react';
import { Box, Card } from '@mui/material';

type FileType = 'xlsx' | 'csv' | 'json';

export const BbidGeneratorPage = () => {
  const [batchSize, setBatchSize] = useState(100);
  const [fileType, setFileType] = useState<FileType>('xlsx');
  const [accessToken, setAccessToken] = useState<string | undefined>();

  const { getAccessTokenSilently } = useAuth0();

  useEffect(() => {
    async function getAccessToken() {
      setAccessToken(await getAccessTokenSilently());
    }
    getAccessToken();
  }, [getAccessTokenSilently]);

  const invokeGenerator = async () => {
    if (batchSize < 1) {
      alert('Invalid batch size.');
    } else {
      try {
        const response = await fetch(
          `${appSettings.api.endpoint}/api/v2/biobankids?batchsize=${batchSize}&filetype=${fileType}`,
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          }
        );

        const blob = await response.blob();

        // This code is a bit of a hack to download a fetched result.
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = url;
        a.download = response.headers.get('Content-Disposition')?.split('filename=')[1].split(';')[0] ?? '';
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        window.URL.revokeObjectURL(url);
      } catch (err) {
        console.dir(err);
      }
    }
  };

  const handleRadioButtonChange = async (ev: ChangeEvent<HTMLInputElement>) => {
    ev.persist();

    setFileType(ev.target.value as FileType);
  };

  return (
    <AuthorizedPage hasAdminAccess>
      <Page title='BBID Generation'>
        <Card>
          <Box p={3}>
            <div>
              <h3>Allocate Bio-Bank IDs</h3>
              <span>Batch Size: </span>
              <input
                type='text'
                value={batchSize}
                onChange={(ev: ChangeEvent<HTMLInputElement>): void => setBatchSize(+ev.target.value)}
              />
              <br />
              <input
                type='radio'
                id='xlsx'
                name='filetype'
                value='xlsx'
                checked={fileType === 'xlsx'}
                onChange={handleRadioButtonChange}
              />
              <label htmlFor='xlsx'>XLSX</label>
              <input
                type='radio'
                id='csv'
                name='filetype'
                value='csv'
                checked={fileType === 'csv'}
                onChange={handleRadioButtonChange}
              />
              <label htmlFor='csv'>CSV</label>
              <input
                type='radio'
                id='json'
                name='filetype'
                value='json'
                checked={fileType === 'json'}
                onChange={handleRadioButtonChange}
              />
              <label htmlFor='json'>JSON</label>
              <p />
              <button id='generate_batch_btn' className='black-button' onClick={invokeGenerator}>
                Generate Batch
              </button>
            </div>
          </Box>
        </Card>
      </Page>
    </AuthorizedPage>
  );
};
