import { Button, Typography, useTheme } from '@mui/material';
import { RefreshStatus } from 'data/RefreshStatusData';
import HourglassBottomIcon from '@mui/icons-material/HourglassBottom';
import RefreshIcon from '@mui/icons-material/Refresh';
import { MouseEventHandler } from 'react';

interface GridRefreshButtonProps {
  refreshStatus?: RefreshStatus;
  onClick: MouseEventHandler<HTMLButtonElement>;
}

export const GridRefreshButton = ({ refreshStatus, onClick }: GridRefreshButtonProps) => {
  const theme = useTheme();

  return (
    <Button
      id='grid-refresh-button'
      disabled={!!refreshStatus?.isRefreshing}
      onClick={onClick}
      sx={[
        {
          color: theme.colors.alpha.black[100],
          '&:hover': {
            backgroundColor: 'transparent',
          },
        },
      ]}
    >
      {refreshStatus?.isRefreshing ? <HourglassBottomIcon /> : <RefreshIcon />}
      <Typography variant='button' ml={1}>
        Refresh
      </Typography>
    </Button>
  );
};
