import { StateCreator } from 'zustand';

export interface AccessTokenSlice {
  accessToken: string | undefined;
  setAccessToken: (accessToken: string) => void;
}

export const createAccessTokenSlice: StateCreator<AccessTokenSlice> = set => ({
  accessToken: undefined,
  setAccessToken: accessToken => set({ accessToken }),
});
