import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import useAuth from '../auth/UseAuth';
import { AuthorizedPage } from '../auth/AuthorizedPage';
import { Page } from '../components/Page';
import { Stack } from '@mui/material';
import { PageContent } from '../components/PageContent';
import { DataSchema, GetDataSchema } from '../data/DataSchemaData';
import { SchemaViewer } from '../dataProducts/schemas/SchemaViewer';
import { t } from 'i18next';
import { LoadingState, LoadState } from '../components/LoadingStateUtil';

export const DataSchemaPage = () => {
  const { accessToken } = useAuth();

  const { dataSchemaId } = useParams() as { dataSchemaId: string };

  const [dataSchema, setDataSchema] = useState<DataSchema | null>(null);
  const [dataSchemaLoadingState, setDataSchemaLoadingState] = useState<LoadingState>({ status: 'NotStarted' });
  useEffect(() => {
    if (accessToken === undefined) {
      return;
    }

    return LoadState(setDataSchemaLoadingState, async () => {
      const res = await GetDataSchema(dataSchemaId, accessToken);
      setDataSchema(res);
    });
  }, [accessToken, dataSchemaId]);

  return (
    <AuthorizedPage hasGreenSideViewAccess>
      <Page title={t('dataSchemas')} subtitle={dataSchema?.name} goBackUrl='/data-products'>
        <PageContent>
          <Stack direction='column' spacing={2} sx={{ height: '100%' }}>
            <SchemaViewer loadingState={dataSchemaLoadingState} dataSchema={dataSchema} />
          </Stack>
        </PageContent>
      </Page>
    </AuthorizedPage>
  );
};
