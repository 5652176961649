import React, { useState, useMemo, useEffect } from 'react';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { Box, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@mui/material';
import { GridRowSelectionModel } from '@mui/x-data-grid-pro';
import { v4 as uuid } from 'uuid';
import useAuth from 'auth/UseAuth';
import { CancelButton } from 'components/CancelButton';
import { PrimaryButton } from 'components/PrimaryButton';
import { AutocompleteWrapper } from '../../components/AutocompleteWrapper';
import { DialogCloseButton } from '../../components/DialogCloseButton';
import { DialogOpenButton } from '../../components/DialogOpenButton';
import { ErrorIndicator } from '../../components/ErrorIndicator';
import { LoadingIndicator } from '../../components/LoadingIndicator';
import { WarningIndicator } from '../../components/WarningIndicator';
import { ErrorManagement, LoadingState } from '../../components/LoadingStateUtil';
import {
  ClonePipelineRules,
  CreatePipelineRules,
  PipelineRulesConfig,
  PipelineRule,
  SuperDuperFiestaPipeline,
} from '../../data/SuperDuperFiestaData';
import useMemoTranslation from '../../hooks/UseMemoTranslation';
import { usePipelineRulesContext } from '../../contexts/PipelineRulesContext';

const findIdFromName = <T extends { name?: string; displayName?: string }>(
  name: string | null,
  idKey: string,
  items: T[] | undefined
): string | number | null => {
  if (!name || !items) return null;
  const item = items.find(i => i.name === name || i.displayName === name);
  return item ? (item[idKey as keyof T] as string | number) : null;
};

const hasMatchingInactiveRules = (selectedRules: PipelineRule[], existingRules: PipelineRule[]): boolean => {
  return selectedRules.some(rule =>
    existingRules.some(
      existingRule =>
        existingRule.name === rule.name &&
        existingRule.dataSourceId === rule.dataSourceId &&
        existingRule.active === false
    )
  );
};

export const CloneRulesModal = ({
  allRows,
  ruleCloneSelection,
  setRuleCloneSelection,
  cloneEditRules,
}: {
  allRows: PipelineRule[];
  ruleCloneSelection: PipelineRule[] | [];
  setRuleCloneSelection: React.Dispatch<React.SetStateAction<GridRowSelectionModel>>;
  cloneEditRules: () => void;
}) => {
  const { accessToken } = useAuth();
  const { t } = useMemoTranslation();
  const { filterState, setRefreshData, selectedEntity, selectedPipeline, setSelectedPipeline, selectedStage } =
    usePipelineRulesContext();

  const [loadingState, setLoadingState] = useState<LoadingState>({ status: 'NotStarted' });
  const [open, setOpen] = useState<boolean>(false);
  const [cloneRulePipeline, setCloneRulePipeline] = useState<SuperDuperFiestaPipeline | null>(selectedPipeline);

  const isSamePipeline = useMemo(() => selectedPipeline === cloneRulePipeline, [selectedPipeline, cloneRulePipeline]);

  const canAddToPipeline = useMemo(
    () => (isSamePipeline ? !hasMatchingInactiveRules(ruleCloneSelection, allRows) : true),
    [isSamePipeline, ruleCloneSelection, allRows]
  );

  useEffect(() => {
    if (isSamePipeline && !canAddToPipeline) {
      setLoadingState({
        status: 'Warning',
        warningMessage: 'Cannot add to pipeline: Inactive versions of selected rules already exist in this pipeline',
      });
    } else {
      setLoadingState({ status: 'NotStarted' });
    }
  }, [isSamePipeline, canAddToPipeline]);

  const handleOpen = async () => {
    setOpen(true);
    setCloneRulePipeline(selectedPipeline);
  };

  const handleAddToPipeline = async () => {
    ErrorManagement('Loading', setLoadingState, async () => {
      if (!selectedEntity || !cloneRulePipeline || !selectedStage) {
        return;
      }

      const queryParams: PipelineRulesConfig = {
        pipelineId: cloneRulePipeline.pipelineId,
        stageId: selectedStage.stageId,
        entityId: selectedEntity.entityId,
      };

      // If the pipeline is the same, we need to create new rules with active: false
      if (isSamePipeline && allRows.length > 0) {
        const lastRule = allRows.at(-1);
        const lastExecutionOrder = lastRule?.executionOrder ?? allRows.length;
        let prevStepId = lastRule?.stepId;

        const newCloneRules = ruleCloneSelection.map((rule, index) => {
          const newRule = {
            ...rule,
            stepId: uuid(),
            active: false,
            stepTypeId: findIdFromName(rule.stepTypeName ?? null, 'stepTypeId', filterState.stepTypes),
            issueId: findIdFromName(rule.issueName ?? null, 'issueId', filterState.issues),
            executionOrder: lastExecutionOrder + index + 1,
            previousStepId: prevStepId,
          };

          if (newRule.stepUpdateValues) {
            newRule.stepUpdateValues = newRule.stepUpdateValues.map(value => ({
              ...value,
              stepUpdateValueId: uuid(),
            }));
          }

          prevStepId = newRule.stepId;
          return newRule;
        });
        await CreatePipelineRules(newCloneRules as PipelineRule[], queryParams, accessToken);
        setRefreshData(true);
      } else {
        await ClonePipelineRules(
          ruleCloneSelection.map(r => r.stepId),
          queryParams,
          accessToken
        );
        setSelectedPipeline(cloneRulePipeline);
      }

      setRuleCloneSelection([]);
      handleClose();
    });
  };

  const handleCloneEdit = () => {
    cloneEditRules();
    handleClose();
  };

  const handleClose = () => {
    setOpen(false);
    setCloneRulePipeline(null);
    setLoadingState({ status: 'NotStarted' });
  };

  return (
    <>
      <DialogOpenButton
        title={ruleCloneSelection.length > 0 ? `Clone ${ruleCloneSelection.length} Rule(s)` : 'Clone Rule(s)'}
        onClick={handleOpen}
        disabled={ruleCloneSelection.length === 0}
      >
        <ContentCopyIcon />
      </DialogOpenButton>
      <Dialog open={open} onClose={() => setOpen(false)} fullWidth maxWidth='sm'>
        <DialogTitle>
          <DialogCloseButton onClick={handleClose} />
          Clone Rules
        </DialogTitle>
        <DialogContent>
          <Box component='form' sx={{ flexGrow: 1, mt: 1 }}>
            <AutocompleteWrapper
              fullWidth
              size='small'
              value={cloneRulePipeline}
              options={filterState.pipelines || []}
              getOptionLabel={option => (typeof option === 'string' ? option : option.name)}
              renderInput={params => <TextField {...params} label={t('pipeline')} margin='dense' required />}
              onChange={(_, value) => setCloneRulePipeline(value)}
            />
          </Box>
        </DialogContent>
        <LoadingIndicator loadingState={loadingState} margin={'LR'} />
        <ErrorIndicator loadingState={loadingState} />
        <WarningIndicator loadingState={loadingState} />
        <DialogActions>
          <CancelButton onClick={handleClose} disabled={loadingState.status === 'Loading'}>
            Cancel
          </CancelButton>
          <PrimaryButton onClick={handleCloneEdit} disabled={loadingState.status === 'Loading' || !isSamePipeline}>
            Clone & Edit
          </PrimaryButton>
          <PrimaryButton
            onClick={handleAddToPipeline}
            disabled={loadingState.status === 'Loading' || !canAddToPipeline}
          >
            Add To Pipeline
          </PrimaryButton>
        </DialogActions>
      </Dialog>
    </>
  );
};
