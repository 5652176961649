import { appSettings } from '../AppSettings';
import { BaseGet, BasePostVoid } from './BaseData';

export interface SupplementalFile {
  supplementalFileId: string;
  name?: string;
  location: string;
  supplementalFileTypeId: string;
  description: string;
  createdAt: string;
}

export const DEFAULT_SUPPLEMENTAL_FILE: SupplementalFile = {
  supplementalFileId: '',
  name: '',
  location: '',
  supplementalFileTypeId: '',
  description: '',
  createdAt: '',
};

export const URL_SUPPLEMENTAL_FILE_TYPE_ID = '31e78dc1-fb77-450e-a76a-55bcb29c9937';

export const DEFAULT_URL_SUPPLEMENTAL_FILE: SupplementalFile = {
  supplementalFileId: '',
  name: '',
  location: '',
  supplementalFileTypeId: URL_SUPPLEMENTAL_FILE_TYPE_ID,
  description: '',
  createdAt: '',
};

export async function GetSupplementalFilesForTransitionId(
  transitionId: string,
  accessToken: string
): Promise<ReadonlyArray<SupplementalFile>> {
  return await BaseGet(`${appSettings.api.endpoint}/api/v2/SupplementalFile/Transition/${transitionId}`, accessToken);
}

export async function GetSupplementalFilesForCheckByGroupId(
  checkByGroupId: string,
  accessToken: string
): Promise<ReadonlyArray<SupplementalFile>> {
  return await BaseGet(
    `${appSettings.api.endpoint}/api/v2/SupplementalFile/CheckByGroup/${checkByGroupId}`,
    accessToken
  );
}

export async function CreateSupplementalFilesForTransitionId(
  transitionId: string,
  supplementalFile: SupplementalFile,
  accessToken: string
): Promise<void> {
  await BasePostVoid(
    `${appSettings.api.endpoint}/api/v2/SupplementalFile/Transition/${transitionId}`,
    supplementalFile,
    accessToken
  );
}

export async function CreateSupplementalFilesForCheckByGroupId(
  checkByGroupId: string,
  supplementalFile: SupplementalFile,
  accessToken?: string
): Promise<void> {
  await BasePostVoid(
    `${appSettings.api.endpoint}/api/v2/SupplementalFile/CheckByGroup/${checkByGroupId}`,
    supplementalFile,
    accessToken
  );
}
