import React from 'react';
import { Card } from '@mui/material';
import { FlexTableBox } from './FlexTableBox';

interface Props {
  children: React.ReactNode;
}

export const PageContent = ({ children }: Props) => {
  return (
    <Card sx={{ width: '100%', height: '100%' }}>
      <FlexTableBox padding={3} sx={{ paddingTop: 2 }}>
        {children}
      </FlexTableBox>
    </Card>
  );
};
