import React, { useState } from 'react';
import { Button } from '@mui/material';
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import { DiseaseArea } from 'data/DiseaseAreaData';
import { AuthorizedSection } from '../../auth/AuthorizedSection';
import UpdateDiseaseAreaModal from './UpdateDiseaseAreaModal';
import GridCellWithIconButton from '../../components/grid/cell/GridCellWithIconButton';

export interface Props {
  diseaseArea: DiseaseArea;
  onDiseaseAreaChange: Function;
}

export const DiseaseAreaGridCell = ({ diseaseArea, onDiseaseAreaChange }: Props) => {
  const [open, setOpen] = useState<boolean>(false);

  const handleButtonClick = () => {
    setOpen(true);
  };

  const handleClose = (diseaseAreaChanged: boolean) => {
    setOpen(false);
    if (diseaseAreaChanged) {
      onDiseaseAreaChange();
    }
  };

  return (
    <>
      <GridCellWithIconButton
        icon={<ModeEditOutlineOutlinedIcon color='primary' />}
        buttonPermissions={['hasGreenSideWriteAccess']}
        onButtonClick={handleButtonClick}
      >
        <Button
          disabled={diseaseArea === null}
          onClick={handleButtonClick}
          sx={[
            {
              justifyContent: 'left',
              fontWeight: 'inherit',
              fontSize: 'inherit',
              padding: 0,
              '&:hover': {
                backgroundColor: 'transparent',
              },
            },
          ]}
        >
          {diseaseArea.name}
        </Button>
      </GridCellWithIconButton>
      <AuthorizedSection hasGreenSideWriteAccess>
        <UpdateDiseaseAreaModal open={open} diseaseArea={diseaseArea} onClose={handleClose} />
      </AuthorizedSection>
    </>
  );
};
