import { ResearchProjectOption } from 'researchProjectManagement/ResearchProjectAutocompleteOptions';
import useAuth from '../../auth/UseAuth';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { GetAllResearchProjectsDetails } from 'data/ResearchProjectData';

export const useResearchProjectOptions = (): [ResearchProjectOption[], boolean, Dispatch<SetStateAction<boolean>>] => {
  const { accessToken } = useAuth();
  const [researchProjectOptions, setResearchProjectOptions] = useState<ResearchProjectOption[]>([]);
  const [refresh, refreshOptions] = useState<boolean>(true);

  useEffect(() => {
    async function getData() {
      const researchProjects = await GetAllResearchProjectsDetails(accessToken);
      setResearchProjectOptions(
        researchProjects.map(r => {
          return { label: r.name, researchProject: r };
        })
      );
    }

    getData();
  }, [refresh, accessToken]);

  return [researchProjectOptions, refresh, refreshOptions];
};
