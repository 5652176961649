import { AppBar, Dialog, DialogContent, IconButton, Toolbar, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { AuthorizedSection } from 'auth/AuthorizedSection';
import { PatientCountType } from 'components/grid/GridCountType';
import React, { useState } from 'react';
import { AssignPatientsToResearchProjectModal } from 'patientRecords/AssignPatientsToResearchProjectModal';
import { SampleType } from 'data/SampleTypeData';
import { Organization } from 'data/ReferenceData';
import { ItemOfResearch } from 'data/ResearchProjectData';
import { PatientRecordExportButton } from './PatientRecordExportButton';
import { PatientRecordGrid } from './PatientRecordGrid';
import { FlexTableBox } from '../components/FlexTableBox';
import { FlexBox } from '../components/FlexBox';
import { TimeInterval } from 'data/PatientRecordOptions';
import { DecryptForeignHashesSwitch } from '../components/DecryptForeignHashesSwitch';
import { useUserPermissions } from '../auth/UseUserPermissions';

export interface PatientRecordModalProps {
  open: boolean;
  count: number;
  itemOfResearch: ItemOfResearch;
  timeInterval: TimeInterval;
  organization?: Organization;
  sampleType?: SampleType;
  gridCountType: PatientCountType;
  patientRecordCountType: PatientCountType;
  onClose: Function;
}

export const PatientRecordModal = ({
  open,
  count,
  itemOfResearch,
  timeInterval,
  organization,
  sampleType,
  gridCountType,
  patientRecordCountType,
  onClose,
}: PatientRecordModalProps) => {
  const [modalOpen, setModalOpen] = useState<boolean>(open);
  const [useDecryptedHashes, setUseDecryptedHashes] = useState<boolean>(false);
  const { hasGreenSideViewAccess } = useUserPermissions();

  const handleClose = async () => {
    setModalOpen(false);
    onClose();
  };

  function getModalTitle() {
    let title = `${itemOfResearch.name} - `;

    switch (patientRecordCountType) {
      case 'byBiobank':
      case 'byLab':
        return title + organization?.name;
      case 'bySampleType':
        return title + sampleType?.displayName;
      case 'all':
        var countUnit =
          gridCountType === 'byBiobank' ? 'Biobanks' : gridCountType === 'byLab' ? 'Labs' : 'Sample Types';
        return title + `All ${countUnit}`;
    }
  }

  return (
    <Dialog fullScreen={true} open={hasGreenSideViewAccess && modalOpen} onClose={handleClose}>
      <AppBar sx={{ position: 'relative', backgroundColor: 'black' }}>
        <Toolbar>
          <IconButton edge='start' color='inherit' onClick={handleClose} aria-label='close'>
            <CloseIcon />
          </IconButton>
          <Typography sx={{ ml: 2, flex: 1 }} variant='h6' component='div'>
            {getModalTitle()}
          </Typography>
        </Toolbar>
      </AppBar>
      <DialogContent>
        <FlexTableBox>
          <FlexBox flexDirection='row' alignItems='center' mb={2}>
            <DecryptForeignHashesSwitch decryptedHashesProps={[useDecryptedHashes, setUseDecryptedHashes]} />
            <PatientRecordExportButton
              itemOfResearchType={itemOfResearch.type}
              itemOfResearchId={itemOfResearch.id}
              timeInterval={timeInterval}
              biobankId={organization?.id}
              labId={organization?.id}
              sampleTypeId={sampleType?.sampleTypeId}
              numRecords={count}
              decrypted={useDecryptedHashes}
              countType={patientRecordCountType}
              disabled={false}
            />
            <AuthorizedSection hasGreenSideWriteAccess>
              <AssignPatientsToResearchProjectModal
                itemOfResearch={itemOfResearch}
                timeInterval={timeInterval}
                labId={organization?.type === 'Lab' ? organization?.id : undefined}
                biobankId={organization?.type === 'Biobank' ? organization?.id : undefined}
                sampleTypeId={sampleType?.sampleTypeId}
                numRecords={count}
                countType={patientRecordCountType}
                disabled={false}
              />
            </AuthorizedSection>
          </FlexBox>
          <PatientRecordGrid
            itemOfResearch={itemOfResearch}
            timeInterval={timeInterval}
            biobankId={organization?.id}
            labId={organization?.id}
            sampleTypeId={sampleType?.sampleTypeId}
            numRecords={count}
            decrypted={useDecryptedHashes}
            countType={patientRecordCountType}
          />
        </FlexTableBox>
      </DialogContent>
    </Dialog>
  );
};
