export interface RefreshStatus {
  isRefreshing: boolean;
  lastRefreshed?: Date;
}

export function getLastRefreshedDateAsString(refreshStatus?: RefreshStatus) {
  if (!refreshStatus?.lastRefreshed) {
    return '';
  }

  return new Date(refreshStatus.lastRefreshed).toLocaleString('en-US', {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
    hour: 'numeric',
    minute: '2-digit',
  });
}
