import React from 'react';

import { Box, Stack, useTheme } from '@mui/material';

import MeasurementGraph from './measurementgraph/MeasurementGraph';
import Histogram from './histogram/Histogram';
import DateRangeSelector from './timescale/DateRangeSelector';

const GraphGroup: React.FC = () => {
  const theme = useTheme();

  const histogramHeight = theme.spacing(9.5);

  return (
    <Stack direction='column' spacing={1} flex={1}>
      <Box flex={3} position='relative'>
        <Box position='absolute' top={0} left={0} bottom={0} right={0}>
          <MeasurementGraph />
        </Box>
      </Box>

      <Box flex={0} position='relative' minHeight={histogramHeight} maxHeight={histogramHeight}>
        <Box position='absolute' top={0} left={0} bottom={0} right={0}>
          <Histogram />
        </Box>
      </Box>

      <Box flex={0} paddingX={5}>
        <DateRangeSelector />
      </Box>
    </Stack>
  );
};

export default GraphGroup;
