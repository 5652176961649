import { GridRenderCellParams } from '@mui/x-data-grid-pro';
import { CircularProgress } from '@mui/material';

export function renderLoadingGridCell(params: GridRenderCellParams) {
  return <LoadingGridCell value={params.value} />;
}

interface LoadingGridCellProps {
  value?: object;
}

export const LoadingGridCell = ({ value }: LoadingGridCellProps) => {
  return value !== undefined ? <span>{value}</span> : <CircularProgress size={15} />;
};
