import { getGridStringOperators, GridFilterOperator } from '@mui/x-data-grid-pro';
import { CustomGridFilterInputMultipleValue } from 'components/grid/panel/filterPanel/CustomGridFilterInputMultipleValue';

export const getModifiedGridStringOperators = (): GridFilterOperator[] => {
  const gridStringOperators = getGridStringOperators();
  return gridStringOperators.map(operator => {
    if (operator.value === 'isAnyOf') {
      return {
        ...operator,
        InputComponent: CustomGridFilterInputMultipleValue,
      };
    }
    return operator;
  });
};
