import { appSettings } from '../AppSettings';
import { BasePost } from './BaseData';

export const ACCEPTED_REVIEW_STATUS = {
  reviewStatusId: 'e58001db-2791-4556-a36f-c6e4efd9a9e0',
  name: 'accepted',
  displayName: 'Accepted',
} as const;

export const REJECTED_REVIEW_STATUS = {
  reviewStatusId: '785619c6-b664-4457-bc27-a2496826b8e7',
  name: 'rejected',
  displayName: 'Rejected',
} as const;

export const UNDECIDED_REVIEW_STATUS = {
  reviewStatusId: '5b6ee90e-d5c0-4771-9b1c-332ced40d955',
  name: 'undecided',
  displayName: 'Undecided',
} as const;

export const UNREVIEWED_REVIEW_STATUS = {
  reviewStatusId: '9f3a59e8-0b71-4515-bf30-a41abf549c03',
  name: 'unreviewed',
  displayName: 'Unreviewed',
} as const;

export const REVIEW_STATUSES = [
  ACCEPTED_REVIEW_STATUS,
  REJECTED_REVIEW_STATUS,
  UNDECIDED_REVIEW_STATUS,
  UNREVIEWED_REVIEW_STATUS,
] as const;
export type ReviewStatus = (typeof REVIEW_STATUSES)[number];

export const PATIENT_REVIEW_SCORES = [1, 2, 3, 4, 5] as const;
export type PatientReviewScore = (typeof PATIENT_REVIEW_SCORES)[number];

export interface PatientReview {
  patientReviewId: string;
  reviewStatusId: string;
  researchProjectId: string;
  deidentity: string;
  reviewedBy: string;
  reviewedAt: Date;
  notes: string | null;
  score: PatientReviewScore | null;
}

export async function CreatePatientReview(
  patientReview: PatientReview,
  accessToken: string | undefined
): Promise<PatientReview> {
  return await BasePost(`${appSettings.api.endpoint}/api/v1/PatientReview`, patientReview, accessToken);
}
