import React, { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import useAuth from '../auth/UseAuth';
import { DataProduct, GetDataProduct, UpdateDataProduct } from '../data/DataProductData';
import { AuthorizedPage } from '../auth/AuthorizedPage';
import { Page } from '../components/Page';
import { Box, Button, ButtonProps, CircularProgress, Stack, Tab, Tabs, useTheme } from '@mui/material';
import { PageContent } from '../components/PageContent';
import { cloneDeep } from 'lodash';
import { ProductSchemaEditor } from '../dataProducts/productSchema/ProductSchemaEditor';
import { DatasetsEditor } from '../dataProducts/datasets/DatasetsEditor';
import { useUserPermissions } from '../auth/UseUserPermissions';
import { t } from 'i18next';
import { SqlViewer } from '../dataProducts/sql/SqlViewer';
import { LoadingState, LoadState } from '../components/LoadingStateUtil';

const PAGE_TABS = ['schema', 'datasets', 'sql'] as const;
type PageTabs = (typeof PAGE_TABS)[number];

export const DataProductPage = () => {
  const { accessToken } = useAuth();
  const { hasGreenSideWriteAccess } = useUserPermissions();

  const { dataProductId } = useParams() as { dataProductId: string };

  const theme = useTheme();

  const [selectedTab, setSelectedTab] = useState<PageTabs>('schema');

  const toolbarRef = useRef<Element | null>(null);

  const [dataProduct, setDataProduct] = useState<DataProduct | null>(null);
  const [dataProductLoadingState, setDataProductLoadingState] = useState<LoadingState>({ status: 'NotStarted' });
  useEffect(() => {
    if (accessToken === undefined) {
      return;
    }

    return LoadState(setDataProductLoadingState, async () => {
      const res = await GetDataProduct(dataProductId, accessToken);
      setDataProduct(res);
    });
  }, [accessToken, dataProductId]);

  const [workingDataProduct, setWorkingDataProduct] = useState<DataProduct | null>(null);
  useEffect(() => {
    setWorkingDataProduct(cloneDeep(dataProduct));
  }, [dataProduct]);

  const [hasUpdates, setHasUpdates] = useState(false);
  useEffect(() => {
    if (dataProduct == null || workingDataProduct == null) {
      return;
    }

    setHasUpdates(JSON.stringify(dataProduct) !== JSON.stringify(workingDataProduct));
  }, [dataProduct, workingDataProduct]);

  const [saving, setSaving] = useState(false);
  const onSave = async () => {
    if (accessToken === undefined || workingDataProduct == null) {
      return;
    }

    setSaving(true);

    try {
      const updatedDataProduct = await UpdateDataProduct(workingDataProduct, accessToken);
      setDataProduct(updatedDataProduct);
    } finally {
      setSaving(false);
    }
  };

  const onRevert = () => {
    setWorkingDataProduct(cloneDeep(dataProduct));
  };

  return (
    <AuthorizedPage hasGreenSideViewAccess>
      <Page title={t('dataProducts')} subtitle={dataProduct?.name} goBackUrl='/data-products'>
        <PageContent>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
            mb={2}
          >
            <Box>
              <Tabs value={selectedTab} onChange={(_, newTab) => setSelectedTab(newTab)}>
                {PAGE_TABS.map(tab => (
                  <Tab key={tab} value={tab} label={t(tab)} sx={{ color: theme.colors.alpha.black[100] }} />
                ))}
              </Tabs>
            </Box>
            <Box
              ref={toolbarRef}
              sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            ></Box>
          </Box>

          <Stack direction='column' spacing={2} sx={{ height: '100%' }}>
            {selectedTab === 'schema' && (
              <ProductSchemaEditor
                loadingState={dataProductLoadingState}
                dataProduct={workingDataProduct}
                onChange={setWorkingDataProduct}
              />
            )}

            {selectedTab === 'datasets' && (
              <DatasetsEditor
                loadingState={dataProductLoadingState}
                dataProduct={workingDataProduct}
                onChange={setWorkingDataProduct}
                toolbarRef={toolbarRef}
              />
            )}

            {selectedTab === 'sql' && (
              <SqlViewer loadingState={dataProductLoadingState} dataProduct={workingDataProduct} />
            )}

            <Stack flex={0} direction='row' spacing={2} justifyContent='flex-end'>
              <Button variant='outlined' color='error' disabled={!hasUpdates || saving} onClick={() => onRevert()}>
                {t('revert')}
              </Button>
              <LoadingButton
                loading={saving}
                variant='contained'
                color='primary'
                disabled={!hasGreenSideWriteAccess || !hasUpdates || saving}
                onClick={() => onSave()}
              >
                {t('saveEdits')}
              </LoadingButton>
            </Stack>
          </Stack>
        </PageContent>
      </Page>
    </AuthorizedPage>
  );
};

const LoadingButton: React.FC<{ loading: boolean } & ButtonProps> = ({ loading, children, ...props }) => {
  return (
    <Button {...props} sx={{ ...(props.sx ?? {}), position: 'relative' }}>
      <Box sx={{ visibility: 'hidden' }}>{children}</Box>

      <Box
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        {loading ? <CircularProgress size='1rem' /> : children}
      </Box>
    </Button>
  );
};
