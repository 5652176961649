import { AuthorizedPage } from 'auth/AuthorizedPage';
import useAuth from 'auth/UseAuth';
import { Autocomplete, Box, TextField, Typography } from '@mui/material';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Page } from 'components/Page';
import { ResearchProjectDetails } from 'data/ResearchProjectData';
import { GetLlCustomers } from 'data/LlCustomerData';
import { LlCustomerOption } from 'researchProjectManagement/ResearchProjectAutocompleteOptions';
import { CreateResearchProjectModal } from 'researchProjectManagement/CreateResearchProjectModal';
import { UpdateResearchProjectModal } from 'researchProjectManagement/UpdateResearchProjectModal';
import {
  GetSampleTrackingCountsBySampleTypeForResearchProjects,
  SampleTrackingCounts,
} from '../data/SampleTrackingData';
import useMemoTranslation from '../hooks/UseMemoTranslation';
import { FlexTableBox } from '../components/FlexTableBox';
import { PageContent } from '../components/PageContent';
import { Dictionary } from 'lodash';
import { FlexBox } from '../components/FlexBox';
import { ResearchProjectsGrid } from '../researchProjectManagement/projectsGrid/ResearchProjectsGrid';
import { ErrorManagement, LoadingState, LoadState } from '../components/LoadingStateUtil';
import { AuthorizedSection } from '../auth/AuthorizedSection';
import { SampleTrackingAuthSection } from '../auth/SampleTrackingAuthSection';
import { useResearchProjectsDetails } from '../components/hooks/UseResearchProjectsDetails';
import { useDiseaseAreaOptions } from '../components/hooks/UseDiseaseAreaOptions';
import { useSuperDuperFiestaPipelineOptions } from '../components/hooks/UseSuperDuperFiestaPipelineOptions';

const SampleTrackingCountByTypeValues = [
  'countByWorkLeft',
  'countByPassed',
  'countByFailed',
  'countByRemaining',
] as const;
export type SampleTrackingCountByType = (typeof SampleTrackingCountByTypeValues)[number];

export const ResearchProjectManagementPage = () => {
  const { accessToken } = useAuth();
  const { t } = useMemoTranslation();

  const [loadingState, setLoadingState] = useState<LoadingState>({ status: 'Loading' });

  const [researchProjects, refreshResearchProjects] = useResearchProjectsDetails(setLoadingState);
  const [researchProjectCounts, setResearchProjectsCounts] = useState<Dictionary<ReadonlyArray<SampleTrackingCounts>>>(
    {}
  );
  const [customerOptions, setCustomerOptions] = useState<LlCustomerOption[]>([]);
  const [diseaseAreaOptions] = useDiseaseAreaOptions();
  const [pipelineOptionsLoadingState, setPipelineOptionsLoadingState] = useState<LoadingState>({
    status: 'NotStarted',
  });
  const pipelineOptions = useSuperDuperFiestaPipelineOptions(setPipelineOptionsLoadingState);
  const [openResearchProjectUpdateModal, setOpenResearchProjectUpdateModal] = useState<boolean>(false);
  const [researchProjectToUpdate, setResearchProjectToUpdate] = useState<ResearchProjectDetails>();
  const [sampleTrackingCountByType, setSampleTrackingCountByType] =
    useState<SampleTrackingCountByType>('countByWorkLeft');

  const pipelines = useMemo(() => pipelineOptions.map(p => p.pipeline), [pipelineOptions]);

  useEffect(() => {
    return LoadState(setLoadingState, async () => {
      if (!accessToken) {
        return;
      }

      const sampleTrackingCounts = await GetSampleTrackingCountsBySampleTypeForResearchProjects(
        researchProjects.map(rp => rp.researchProjectId),
        accessToken
      );
      const countByResearchProjectId = sampleTrackingCounts.reduce(
        (dict: Record<string, SampleTrackingCounts[]>, count) => {
          dict[count.researchProjectId] || (dict[count.researchProjectId] = []);
          dict[count.researchProjectId].push(count);
          return dict;
        },
        {}
      );

      setResearchProjectsCounts(countByResearchProjectId);

      const customers = await GetLlCustomers(accessToken);

      setCustomerOptions(
        customers.map(c => {
          return { label: c.name, customer: c };
        })
      );
    });
  }, [accessToken, researchProjects]);

  async function handleResearchProjectUpdate() {
    ErrorManagement('Loading', setLoadingState, async () => {
      if (!accessToken) {
        return;
      }

      refreshResearchProjects();
      setLoadingState({ status: 'Complete' });
    });
  }

  async function handleCustomerCreate() {
    ErrorManagement('Loading', setLoadingState, async () => {
      if (!accessToken) {
        return;
      }

      const customers = await GetLlCustomers(accessToken);
      setCustomerOptions(
        customers.map(c => {
          return { label: c.name, customer: c };
        })
      );
      setLoadingState({ status: 'Complete' });
    });
  }

  const handleOnUpdateResearchProjectClick = useCallback((researchProject: ResearchProjectDetails) => {
    setResearchProjectToUpdate(researchProject);
    setOpenResearchProjectUpdateModal(true);
  }, []);

  const handleUpdateResearchProjectModalClose = useCallback(() => {
    setResearchProjectToUpdate(undefined);
    setOpenResearchProjectUpdateModal(false);
  }, []);

  return (
    <AuthorizedPage requireAny hasGreenSideViewAccess hasSampleTrackingViewAccess>
      <Page title='Research Project Management'>
        <PageContent>
          <Box display='flex' flexDirection='row' alignItems='center' justifyContent={'space-between'}>
            <Typography variant='h3'>Research Projects</Typography>
            <FlexBox>
              <AuthorizedSection hasGreenSideWriteAccess>
                <CreateResearchProjectModal
                  customerOptions={customerOptions}
                  diseaseAreaOptions={diseaseAreaOptions}
                  pipelineOptionsLoadingState={pipelineOptionsLoadingState}
                  pipelineOptions={pipelineOptions}
                  onResearchProjectCreate={handleResearchProjectUpdate}
                  onCustomerCreate={handleCustomerCreate}
                  title={t('newResearchProject')}
                />
              </AuthorizedSection>
              <SampleTrackingAuthSection>
                <Autocomplete
                  options={SampleTrackingCountByTypeValues}
                  getOptionLabel={option => t(option)}
                  renderInput={params => <TextField {...params} label={t('countBy')} margin='normal' />}
                  onChange={(event, value) => {
                    setSampleTrackingCountByType((value as SampleTrackingCountByType) ?? 'countByWorkLeft');
                  }}
                  size='small'
                  sx={{ width: '200px' }}
                  value={sampleTrackingCountByType}
                />
              </SampleTrackingAuthSection>
            </FlexBox>
            {researchProjectToUpdate && (
              <AuthorizedSection hasGreenSideWriteAccess>
                <UpdateResearchProjectModal
                  open={openResearchProjectUpdateModal}
                  researchProject={researchProjectToUpdate}
                  customerOptions={customerOptions}
                  diseaseAreaOptions={diseaseAreaOptions}
                  pipelineOptions={pipelineOptions}
                  pipelineOptionsLoadingState={pipelineOptionsLoadingState}
                  onResearchProjectUpdate={handleResearchProjectUpdate}
                  onCustomerCreate={handleCustomerCreate}
                  onClose={handleUpdateResearchProjectModalClose}
                />
              </AuthorizedSection>
            )}
          </Box>
          <FlexTableBox sx={{ marginTop: 2 }}>
            <ResearchProjectsGrid
              researchProjects={researchProjects}
              researchProjectCounts={researchProjectCounts}
              onUpdateResearchProjectClick={handleOnUpdateResearchProjectClick}
              sampleTrackingCountByType={sampleTrackingCountByType}
              pipelines={pipelines}
              pipelinesLoadingState={pipelineOptionsLoadingState}
              loadingProps={[loadingState, setLoadingState]}
            />
          </FlexTableBox>
        </PageContent>
      </Page>
    </AuthorizedPage>
  );
};
