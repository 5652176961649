import { Chart } from 'react-google-charts';
import { DateAggregate, formatHAxisDate, formatTooltipDate, getHAxisDateFormat } from 'historicCounts/DateAggregate';
import { DiseaseArea } from 'data/DiseaseAreaData';
import { HistoricCount } from 'data/HistoricCountData';
import { ReactElement } from 'react';
import { ConvertAllCodesToUserFormat } from 'data/conversions/Icd10CodeConversions';

export interface Props {
  diseaseAreas: DiseaseArea[];
  historicCounts: HistoricCount[];
  dateAggregate: DateAggregate;
  width: string;
  height: string;
}

export const HistoricDiseaseAreaCountBarChart = ({
  diseaseAreas,
  historicCounts,
  dateAggregate,
  width,
  height,
}: Props) => {
  const dataTable = createDataTable(diseaseAreas, historicCounts, dateAggregate);

  return (
    <div>
      {diseaseAreas.length > 0 && historicCounts.length > 0 && (
        <Chart
          width={width}
          height={height}
          chartType='ColumnChart'
          loader={<div>Loading Chart</div>}
          data={dataTable}
          options={{
            chartArea: { left: '5%', top: '2%', height: '80%' },
            hAxis: {
              format: getHAxisDateFormat(dateAggregate),
              gridlines: {
                color: 'transparent',
              },
              isStacked: true,
              slantedText: true,
              slantedTextAngle: 30,
              textStyle: { fontSize: 14 },
            },
            legend: {
              textStyle: { fontSize: 14 },
            },
            tooltip: { isHtml: true, trigger: 'visible' },
          }}
        />
      )}
    </div>
  );
};

function createDataTable(
  diseaseAreas: DiseaseArea[],
  historicCounts: HistoricCount[],
  dateAggregate: DateAggregate
): [(string | Date | number | Object)[]] {
  if (diseaseAreas.length > 0 && historicCounts.length > 0 && dateAggregate) {
    // Set first column header as 'Date'
    let dataTable: [(string | Date | number | Object)[]] = [['Date']];

    // Add disease area to table column headers
    diseaseAreas.forEach(disease => {
      dataTable[0].push(disease.name);

      // Need one tooltip column for each disease area column
      dataTable[0].push({ type: 'string', role: 'tooltip', p: { html: true } });
    });

    // Add rows to table
    historicCounts.forEach(count => {
      let row: (Date | number | string | ReactElement)[] = [
        historicCounts.length > 6 ? count.date : formatHAxisDate(new Date(count.date), dateAggregate),
      ];

      diseaseAreas.forEach(disease => {
        let diseaseAreaCount = count.counts.find(
          d =>
            ConvertAllCodesToUserFormat(d.icd10Codes).toString() ===
            ConvertAllCodesToUserFormat(disease.codes.flatMap(c => c.icd10Codes)).toString()
        );

        let totalPatients = diseaseAreaCount ? diseaseAreaCount.totalPatients : 0;

        row.push(totalPatients);
        row.push(getTooltip(disease, totalPatients, formatTooltipDate(new Date(count.date), dateAggregate)));
      });
      dataTable.push(row);
    });
    return dataTable;
  }
  return [[]];
}

function getTooltip(diseaseArea: DiseaseArea, count: number, date: string) {
  const pStyle = 'style="margin-top: 0em; margin-bottom: 0.5em"';
  const pStyleBold = 'style="margin-top: 0em; margin-bottom: 0.5em; font-weight: bold;"';

  return `
    <div style="width: 140px; margin: 10px">
      <p ${pStyle}>
        <span style="font-weight: bold; font-size: 14px">
          ${diseaseArea.name}
        </span>
        <br />
        <span>
          ${ConvertAllCodesToUserFormat(diseaseArea.codes.flatMap(c => c.icd10Codes)).join(', ')}
        </span>
      </p>
      <p ${pStyle}>${date}</p>
      <p ${pStyleBold}>Total Patients: ${count.toLocaleString()}<br /></p>
    </div>`;
}
