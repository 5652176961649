import { v4 as uuid } from 'uuid';
import { PipelineRule, SdfStepUpdateValue, SuperDuperFiestaDataSource } from '../../../data/SuperDuperFiestaData';
import { IRuleFormInput } from '../../../contexts/PipelineRulesContext';

export function buildRule(
  data: IRuleFormInput,
  selectedRow: PipelineRule | null,
  rows: PipelineRule[],
  firstDataSource: SuperDuperFiestaDataSource | null
): PipelineRule | undefined {
  if (data.entity === null) {
    return undefined;
  }

  const executionOrder = Number(data.executionOrder ? data.executionOrder : rows.length + 1);
  const suvs: SdfStepUpdateValue[] = [];

  data.stepUpdateValues?.forEach(suv => {
    if (suv.field !== '' && suv.value !== '') {
      suvs.push(suv);
    }
  });

  return {
    id: executionOrder - 1,
    stepId: selectedRow?.stepId || uuid(),
    name: data.ruleName,
    description: data.description,
    stepTypeName: data.stepType?.displayName || null,
    stepTypeId: data.stepType?.stepTypeId || null,
    entityName: data.entity.displayName,
    entityId: data.entity.entityId,
    dataClassName: data.dataClass?.name,
    dataClassId: data.dataClass?.dataClassId,
    dataSourceName: firstDataSource?.displayName || null,
    dataSourceId: firstDataSource?.dataSourceId || null,
    issueName: data.issue?.name,
    issueId: data.issue?.issueId,
    needsReview: data.needsReview,
    queryCondition: data.queryCondition,
    queryJoin: data.queryJoin,
    queryGroupBy: data.queryGroupBy,
    stepUpdateValues: suvs,
    active: data.active,
    stageId: data.stage?.stageId,
    pipelineId: data.pipeline?.pipelineId,
    executionOrderDisplay: executionOrder,
    executionOrder: executionOrder,
  };
}

export function updateRows(
  newRows: PipelineRule[],
  currentIndex: number | null = null,
  existingRows: PipelineRule[] | null = null
): PipelineRule[] {
  let newOrderedRows = existingRows ? existingRows : [];

  newRows.forEach(r => {
    const tempRows = [...newOrderedRows];

    if (currentIndex != null) {
      // remove row from existing index
      tempRows.splice(currentIndex, 1);
    }
    r.previousStepId = tempRows[Number(r.id) - 1]?.stepId;
    // add row at index
    newOrderedRows = [...tempRows.slice(0, r.executionOrder - 1), r, ...tempRows.slice(r.executionOrder - 1)];
  });

  // update all row id's and order #'s
  newOrderedRows.forEach((row, index) => {
    row.id = index;
    row.executionOrder = index + 1;
    row.executionOrderDisplay = index + 1;
  });

  return newOrderedRows;
}

export function removeFromList(stepId: string, rowList: PipelineRule[]): PipelineRule[] | undefined {
  const rowIndex = rowList.findIndex(r => r.stepId === stepId);
  if (rowIndex === -1) {
    return undefined;
  }

  let tempRows = [...rowList];
  // remove row from existing index
  tempRows.splice(rowIndex, 1);
  // update list
  tempRows.forEach((row, index) => {
    row.id = index;
    row.executionOrder = index + 1;
    row.executionOrderDisplay = index + 1;
  });
  return tempRows;
}
