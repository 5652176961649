import {
  Box,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  LinearProgress,
  TextField,
} from '@mui/material';
import { DialogCloseButton } from '../../components/DialogCloseButton';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { CancelButton } from '../../components/CancelButton';
import { PrimaryButton } from '../../components/PrimaryButton';
import React, { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { DiseaseArea } from '../../data/DiseaseAreaData';
import { ConvertAllCodesToUserFormat } from '../../data/conversions/Icd10CodeConversions';
import { LoadingStatus } from '../../components/LoadingStatus';
import { appSettings } from '../../AppSettings';
import useAuth from '../../auth/UseAuth';
import { useDiseaseAreaMeasurementDefinitions } from '../../components/hooks/UseDiseaseAreaMeasurementDefinitions';
import { LoadingState } from '../../components/LoadingStateUtil';
import { LinkedMeasurementDefinition, LinkedMeasurementDefinitions } from '../../data/MeasurementDefinitionData';
import { DeleteButton } from '../../components/DeleteButton';
import DiseaseAreaMeasurementsInput from './DiseaseAreaMeasurementsInput';
import DiseaseAreaCodesInputGroup, { WorkingDiseaseAreaCodes } from './DiseaseAreaCodesInputGroup';
import DiseaseAreaMedicationsInputGroup from './DiseaseAreaMedicationsInputGroup';
import CollapsibleSection from './CollapsibleSection';
import { t } from 'i18next';

interface UpdateDiseaseAreaModalProps {
  open: boolean;
  diseaseArea: DiseaseArea;
  onClose: (diseaseAreaChanged: boolean) => void;
}

export const UpdateDiseaseAreaModal = ({ open, diseaseArea, onClose }: UpdateDiseaseAreaModalProps) => {
  const { accessToken } = useAuth();

  const [linkedMeasurementsLoadingState, setLinkedMeasurementsLoadingState] = useState<LoadingState>({
    status: 'NotStarted',
  });
  const [linkedMeasurementDefinitions] = useDiseaseAreaMeasurementDefinitions(
    diseaseArea.diseaseAreaId,
    setLinkedMeasurementsLoadingState
  );

  const [savingStatus, setSavingStatus] = useState<LoadingStatus>('NotStarted');
  const [errorMessage, setErrorMessage] = useState<string>();
  const [selectedName, setSelectedName] = useState<string>(diseaseArea.name);
  const [selectedCodes, setSelectedCodes] = useState<WorkingDiseaseAreaCodes[]>(
    diseaseArea.codes.map(c => ({
      diagnosis: c.diagnosis,
      icd10Codes: ConvertAllCodesToUserFormat(c.icd10Codes).join(', '),
    }))
  );
  const [selectedMedications, setSelectedMedications] = useState<string[]>(
    diseaseArea.medications.map(m => m.medicationName)
  );
  const [selectedAvailableOnDashboard, setSelectedAvailableOnDashboard] = useState<boolean>(
    diseaseArea.availableToShowOnDashboard
  );
  const [selectedDefaultOnDashboard, setSelectedDefaultOnDashboard] = useState<boolean>(
    diseaseArea.showOnDashboardByDefault
  );

  const [selectedMeasurementDefinitions, setSelectedMeasurementDefinitions] = useState<LinkedMeasurementDefinition[]>(
    []
  );
  useEffect(() => {
    setSelectedMeasurementDefinitions([...linkedMeasurementDefinitions]);
  }, [linkedMeasurementDefinitions]);

  const handleSave = async () => {
    setSavingStatus('Loading');

    try {
      if (accessToken) {
        let updatedDiseaseArea: DiseaseArea = {
          diseaseAreaId: diseaseArea.diseaseAreaId ?? '',
          name: selectedName,
          codes: selectedCodes.map(c => ({
            diseaseAreaId: diseaseArea.diseaseAreaId ?? '',
            diagnosis: c.diagnosis,
            icd10Codes: c.icd10Codes.split(','),
          })),
          medications: selectedMedications.map(m => ({
            diseaseAreaId: diseaseArea.diseaseAreaId ?? '',
            medicationName: m,
          })),
          availableToShowOnDashboard: selectedAvailableOnDashboard,
          showOnDashboardByDefault: selectedDefaultOnDashboard,
        };

        const saveDiseaseAreaResponse = await fetch(`${appSettings.api.endpoint}/api/v2/DiseaseAreas`, {
          method: 'PUT',
          headers: {
            Authorization: `Bearer ${accessToken}`,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(updatedDiseaseArea),
        });

        if (!saveDiseaseAreaResponse.ok) {
          let message = await saveDiseaseAreaResponse.text();
          setSavingStatus('Error');

          if (message.length > 0) {
            setErrorMessage(message);
          } else if (saveDiseaseAreaResponse.status === 401) {
            setErrorMessage('Error: unauthorized user.');
          } else {
            setErrorMessage('Unknown error.');
          }
          return;
        }

        const updatedMeasurementDefinitions: LinkedMeasurementDefinitions = {
          targetStatisticId:
            selectedMeasurementDefinitions.length === 0
              ? undefined
              : selectedMeasurementDefinitions.find(m => m.isTargetStatistic)?.measurementDefinition
                  .measurementDefinitionId,
          measurementDefinitionIds: selectedMeasurementDefinitions.map(
            m => m.measurementDefinition.measurementDefinitionId
          ),
        };

        const saveMeasurementDefinitionsResponse = await fetch(
          `${appSettings.api.endpoint}/api/v2/MeasurementDefinitions/DiseaseAreas/${diseaseArea.diseaseAreaId}`,
          {
            method: 'POST',
            headers: {
              Authorization: `Bearer ${accessToken}`,
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(updatedMeasurementDefinitions),
          }
        );

        if (!saveMeasurementDefinitionsResponse.ok) {
          let message = await saveMeasurementDefinitionsResponse.text();
          setSavingStatus('Error');

          if (message.length > 0) {
            setErrorMessage(message);
          } else if (saveMeasurementDefinitionsResponse.status === 401) {
            setErrorMessage('Error: unauthorized user.');
          } else {
            setErrorMessage('Unknown error.');
          }
          return;
        }

        setSavingStatus('Complete');
        handleClose(true);
      }
    } catch (err) {
      setSavingStatus('Error');
      setErrorMessage('Unknown error.');
    }
  };

  const handleDelete = async () => {
    try {
      if (accessToken) {
        const response = await fetch(`${appSettings.api.endpoint}/api/v2/DiseaseAreas/${diseaseArea.diseaseAreaId}`, {
          method: 'DELETE',
          headers: {
            Authorization: `Bearer ${accessToken}`,
            'Content-Type': 'application/json',
          },
        });
        if (response.ok) {
          setSavingStatus('Complete');
          handleClose(true);
        } else {
          let message = await response.text();
          setSavingStatus('Error');
          setErrorMessage(message.length > 0 ? message : 'Unknown error.');
        }
      }
    } catch (err) {
      setSavingStatus('Error');
      setErrorMessage('Unknown error.');
    }

    clearSelectedState();
  };

  const handleClose = (diseaseAreaChanged: boolean) => {
    clearSelectedState();
    setErrorMessage(undefined);
    onClose(diseaseAreaChanged);
  };

  const handleNameChange = (ev: ChangeEvent<HTMLInputElement>) => {
    setSelectedName(ev.target.value);
  };

  const clearSelectedState = useCallback(() => {
    setSelectedName(diseaseArea.name);
    setSelectedCodes(
      diseaseArea.codes.map(c => ({
        diagnosis: c.diagnosis,
        icd10Codes: ConvertAllCodesToUserFormat(c.icd10Codes).join(', '),
      }))
    );
    setSelectedMedications(diseaseArea.medications.map(m => m.medicationName));
    setSelectedAvailableOnDashboard(diseaseArea.availableToShowOnDashboard);
    setSelectedDefaultOnDashboard(diseaseArea.showOnDashboardByDefault);
    setSavingStatus('NotStarted');
  }, [diseaseArea]);

  useEffect(() => {
    clearSelectedState();
  }, [clearSelectedState, diseaseArea]);

  return (
    <Dialog open={open} onClose={() => handleClose(false)}>
      <DialogTitle>
        <DialogCloseButton onClick={() => handleClose(false)} />
        {`Edit Disease Area: ${diseaseArea.name}`}
      </DialogTitle>
      <DialogContent>
        <Box component='form'>
          <TextField
            id='disease-area-name-input'
            fullWidth
            label='Name'
            margin='normal'
            onChange={handleNameChange}
            type='text'
            value={selectedName}
            variant='outlined'
          />

          <CollapsibleSection title={`${t('codes')} (${selectedCodes.flatMap(c => c.icd10Codes.split(',')).length})`}>
            <DiseaseAreaCodesInputGroup diseaseAreaCodes={selectedCodes} onDiseaseAreaCodesChange={setSelectedCodes} />
          </CollapsibleSection>

          <CollapsibleSection title={`${t('medications')} (${selectedMedications.length})`}>
            <DiseaseAreaMedicationsInputGroup
              diseaseAreaMedications={selectedMedications}
              onDiseaseAreaMedicationsChange={setSelectedMedications}
            />
          </CollapsibleSection>

          <DiseaseAreaMeasurementsInput
            loading={linkedMeasurementsLoadingState.status === 'Loading'}
            selectedMeasurementDefinitions={selectedMeasurementDefinitions}
            onMeasurementsChange={setSelectedMeasurementDefinitions}
          />

          <FormControlLabel
            label='Available to show on dashboard'
            control={
              <Checkbox
                checked={selectedAvailableOnDashboard}
                onChange={() => {
                  setSelectedAvailableOnDashboard(!selectedAvailableOnDashboard);
                }}
              />
            }
          />
          <FormControlLabel
            label='Default on dashboard'
            control={
              <Checkbox
                checked={selectedAvailableOnDashboard && selectedDefaultOnDashboard}
                onChange={() => {
                  setSelectedDefaultOnDashboard(!selectedDefaultOnDashboard);
                }}
                disabled={!selectedAvailableOnDashboard}
              />
            }
          />
        </Box>
        {savingStatus === 'Error' && (
          <DialogContentText display='flex' alignItems='center' mt={1} color='error'>
            <ErrorOutlineIcon sx={{ mr: 1 }} />
            {errorMessage}
          </DialogContentText>
        )}
      </DialogContent>
      {savingStatus === 'Loading' && <LinearProgress />}
      <DialogActions>
        <CancelButton onClick={() => handleClose(false)} disabled={savingStatus === 'Loading'} />
        <DeleteButton onClick={handleDelete} disabled={savingStatus === 'Loading'} />
        <PrimaryButton onClick={handleSave} disabled={savingStatus === 'Loading'}>
          Save
        </PrimaryButton>
      </DialogActions>
    </Dialog>
  );
};

export default UpdateDiseaseAreaModal;
