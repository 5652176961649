import React, { useEffect, useRef, useState } from 'react';
import { AuthorizedPage } from 'auth/AuthorizedPage';
import { CreateDiseaseAreaModal } from 'diseaseAreas/diseaseAreasGrid/CreateDiseaseAreaModal';
import { DiseaseAreaCountGrid } from 'diseaseAreas/diseaseAreasGrid/DiseaseAreaCountGrid';
import { PatientCountType } from 'components/grid/GridCountType';
import { Page } from 'components/Page';
import 'diseaseAreas/DiseaseAreas.css';
import { DiseaseArea, GetDiseaseAreas } from 'data/DiseaseAreaData';
import useAuth from 'auth/UseAuth';
import {
  DiseaseAreaCount,
  GetDiseaseAreaPatientCounts,
  GetDiseaseAreaPatientCountsByBiobank,
  GetDiseaseAreaPatientCountsBySampleType,
  GetDiseaseAreaRefreshStatus,
  RefreshDiseaseAreaMetrics,
} from 'data/DiseaseAreaMetricsData';
import { Box, Tab, Tabs, Typography, useTheme } from '@mui/material';
import { GetBiobanks } from 'data/BiobankData';
import { SampleType } from 'data/SampleTypeData';
import { PageContent } from '../components/PageContent';
import { FlexTableBox } from '../components/FlexTableBox';
import { PatientRecordModal } from 'patientRecords/PatientRecordModal';
import { GetSampleTypes, Organization } from 'data/ReferenceData';
import { ErrorManagement, LoadingState } from 'components/LoadingStateUtil';
import { ErrorIndicator } from 'components/ErrorIndicator';
import { RefreshStatus } from '../data/RefreshStatusData';
import { AuthorizedSection } from '../auth/AuthorizedSection';
import { DiseaseAreaMetrics } from 'data/DiseaseAreaMetricsData';
import { ERROR_COUNT } from 'diseaseAreas/diseaseAreasGrid/PatientCountGridCell';
import { getLastRefreshedDateAsString } from 'data/RefreshStatusData';
import useMemoTranslation from 'hooks/UseMemoTranslation';
import { GridExportButton } from 'components/GridExportButton';
import { useGridApiRef } from '@mui/x-data-grid-pro';
import { diseaseAreaCountsFileName } from 'util/Constants';
import { GridRefreshButton } from 'components/GridRefreshButton';

export const DiseaseAreasPage = () => {
  const { accessToken } = useAuth();
  const theme = useTheme();
  const { t } = useMemoTranslation();
  const apiRef = useGridApiRef();

  const refreshStatusIntervalId = useRef<NodeJS.Timer>();
  const [loadingState, setLoadingState] = useState<LoadingState>({ status: 'NotStarted' });
  const [gridCountType, setGridCountType] = useState<PatientCountType>('byBiobank');
  const [diseaseAreaMetrics, setDiseaseAreaMetrics] = useState<DiseaseAreaMetrics[]>([]);
  const [biobanks, setBiobanks] = useState<Organization[]>([]);
  const [sampleTypes, setSampleTypes] = useState<ReadonlyArray<SampleType>>([]);
  const [refreshStatus, setRefreshStatus] = useState<RefreshStatus>();

  const [selectedDiseaseArea, setSelectedDiseaseArea] = useState<DiseaseArea>();
  const [selectedBiobankId, setSelectedBiobankId] = useState<string>();
  const [selectedSampleTypeId, setSelectedSampleTypeId] = useState<string>();
  const [selectedCount, setSelectedCount] = useState<number>();

  const [diseaseAreaPatientRecordModalOpen, setDiseaseAreaPatientRecordModalOpen] = useState<boolean>(false);
  const [patientRecordCountType, setPatientRecordCountType] = useState<PatientCountType>();

  const [refresh, refreshPage] = useState<boolean>(true);

  useEffect(() => {
    async function getState() {
      ErrorManagement('Loading', setLoadingState, async () => {
        const [diseaseAreas, counts, countsByBiobank, countsBySampleType, biobanksFetched, sampleTypesFetched, status] =
          await Promise.all([
            GetDiseaseAreas(accessToken),
            GetDiseaseAreaPatientCounts(accessToken),
            GetDiseaseAreaPatientCountsByBiobank(accessToken),
            GetDiseaseAreaPatientCountsBySampleType(accessToken),
            GetBiobanks(accessToken),
            GetSampleTypes(accessToken),
            GetDiseaseAreaRefreshStatus(accessToken),
          ]);

        let biobanksAsOrganization: Organization[] = biobanksFetched.map(b => ({
          id: b.biobankId,
          name: b.name,
          type: 'Biobank',
        }));

        let metrics: DiseaseAreaMetrics[] = diseaseAreas.map(da => {
          let patientCount: DiseaseAreaCount = counts.find(c => c.diseaseAreaId === da.diseaseAreaId) ?? {
            diseaseAreaId: da.diseaseAreaId,
            patientCount: ERROR_COUNT,
            pendingRefresh: false,
          };

          return {
            diseaseArea: da,
            patientCount: patientCount,
            patientCountsByBiobank: countsByBiobank.filter(c => c.diseaseAreaId === da.diseaseAreaId),
            patientCountsBySampleType: countsBySampleType.filter(c => c.diseaseAreaId === da.diseaseAreaId),
            refreshedAt: status?.lastRefreshed,
          };
        });

        setBiobanks(biobanksAsOrganization);
        setSampleTypes(sampleTypesFetched);
        setRefreshStatus(status);
        setDiseaseAreaMetrics([...metrics]);
        setLoadingState({ status: 'Complete' });
      });
    }
    getState();
  }, [accessToken, refresh]);

  useEffect(() => {
    async function getState() {
      if (!refreshStatusIntervalId.current && refreshStatus?.isRefreshing && accessToken) {
        const refreshInternal = 30 * 1000; // 30s
        refreshStatusIntervalId.current = setInterval(async () => {
          let status = await GetDiseaseAreaRefreshStatus(accessToken);
          if (!status || status.isRefreshing) {
            return;
          }

          refreshPage(!refresh);

          if (refreshStatusIntervalId.current) {
            clearInterval(refreshStatusIntervalId.current);
            refreshStatusIntervalId.current = undefined;
          }
        }, refreshInternal);
      }
    }

    getState();

    return () => {
      // Clear the timer when we navigate to a different page
      if (refreshStatusIntervalId.current) {
        clearInterval(refreshStatusIntervalId.current);
        refreshStatusIntervalId.current = undefined;
      }
    };
  }, [accessToken, refresh, refreshStatus]);

  const handleCountTypeTabChange = async (event: React.SyntheticEvent, newValue: PatientCountType) => {
    setGridCountType(newValue);
  };

  const handleRefreshButtonClick = async () => {
    if (accessToken) {
      let status = await GetDiseaseAreaRefreshStatus(accessToken);

      // If a refresh is already running, no need to schedule another
      if (!status?.isRefreshing) {
        status = {
          isRefreshing: true,
          lastRefreshed: status?.lastRefreshed,
        };
        setRefreshStatus(status);
        await RefreshDiseaseAreaMetrics(accessToken);
      }
    }
  };

  const handleDiseaseAreaPatientRecordModalClose = async () => {
    setSelectedDiseaseArea(undefined);
    setSelectedBiobankId(undefined);
    setSelectedSampleTypeId(undefined);
    setPatientRecordCountType(undefined);
    setDiseaseAreaPatientRecordModalOpen(false);
  };

  function handleGridCountClick(
    count: number,
    diseaseArea: DiseaseArea,
    patientRecordCountType: PatientCountType,
    biobankId?: string,
    sampleTypeId?: string
  ) {
    setSelectedCount(count);
    setSelectedDiseaseArea(diseaseArea);
    setSelectedBiobankId(biobankId);
    setSelectedSampleTypeId(sampleTypeId);
    setPatientRecordCountType(patientRecordCountType);
    setDiseaseAreaPatientRecordModalOpen(true);
  }

  async function handleDiseaseAreaChange() {
    refreshPage(!refresh);
  }

  return (
    <AuthorizedPage hasGreenSideViewAccess>
      <Page title='Disease Areas'>
        <PageContent>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
            mb={2}
          >
            <Box>
              <Tabs value={gridCountType} onChange={handleCountTypeTabChange}>
                <Tab value='byBiobank' label='By Biobank' sx={{ color: theme.colors.alpha.black[100] }} />
                <Tab value='bySampleType' label='By Sample Type' sx={{ color: theme.colors.alpha.black[100] }} />
              </Tabs>
            </Box>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
              <AuthorizedSection hasGreenSideWriteAccess>
                <GridRefreshButton refreshStatus={refreshStatus} onClick={handleRefreshButtonClick} />
                <GridExportButton apiRef={apiRef} fileName={t(diseaseAreaCountsFileName)} />
                <CreateDiseaseAreaModal onDiseaseAreaCreate={handleDiseaseAreaChange} />
              </AuthorizedSection>
            </Box>
          </Box>
          <FlexTableBox>
            <DiseaseAreaCountGrid
              loadingState={loadingState}
              diseaseAreaMetrics={diseaseAreaMetrics}
              countType={gridCountType}
              onCountClick={handleGridCountClick}
              refreshStatus={refreshStatus}
              onDiseaseAreaChange={handleDiseaseAreaChange}
              apiRef={apiRef}
            />
            <ErrorIndicator loadingState={loadingState} />
          </FlexTableBox>
          <Typography mt={2}>{`Last updated: ${getLastRefreshedDateAsString(refreshStatus)}`}</Typography>
        </PageContent>
        {selectedDiseaseArea && patientRecordCountType && (
          <PatientRecordModal
            open={diseaseAreaPatientRecordModalOpen}
            count={selectedCount ?? 0}
            itemOfResearch={{
              id: selectedDiseaseArea.diseaseAreaId,
              name: selectedDiseaseArea.name,
              type: 'DiseaseArea',
            }}
            timeInterval={'AllTime'}
            organization={biobanks?.find(b => b.id === selectedBiobankId)}
            sampleType={sampleTypes?.find(s => s.sampleTypeId === selectedSampleTypeId)}
            gridCountType={gridCountType}
            patientRecordCountType={patientRecordCountType}
            onClose={handleDiseaseAreaPatientRecordModalClose}
          />
        )}
      </Page>
    </AuthorizedPage>
  );
};
