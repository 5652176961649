import React from 'react';
import { DataProductField, DataProductTable } from '../../data/DataProductData';
import { CompactGridWrapper } from '../../components/grid/CompactGridWrapper';
import { GridColDef } from '@mui/x-data-grid-pro';
import { GridRenderCellParams } from '@mui/x-data-grid/models/params/gridCellParams';
import { Checkbox } from '@mui/material';
import { t } from 'i18next';
import { LoadingState } from '../../components/LoadingStateUtil';

interface FieldsEditorTableProps {
  loadingState: LoadingState;
  disabled?: boolean;
  fields: DataProductField[];
  onChange: (newFields: DataProductField[]) => unknown;
}

export const FieldsEditorTable: React.FC<FieldsEditorTableProps> = ({ loadingState, disabled, fields, onChange }) => {
  const getRowId = (row: DataProductField) => row.dataSchemaFieldId;

  const handleRedactedChange = (rowId: string, redacted: boolean) => {
    const newFields = fields.map(t => (getRowId(t) === rowId ? { ...t, redacted } : t));
    onChange(newFields);
  };

  const RedactedCheckbox: React.FC<GridRenderCellParams<any, boolean>> = params => {
    return (
      <Checkbox
        checked={params.value}
        disabled={disabled ?? false}
        onClick={e => e.stopPropagation()}
        onChange={e => handleRedactedChange(params.id as string, !params.value)}
      />
    );
  };

  const columns: GridColDef<DataProductTable>[] = [
    {
      field: 'name',
      headerName: t('name'),
      type: 'string',
      flex: 1,
    },
    {
      field: 'redacted',
      headerName: t('redacted'),
      type: 'boolean',
      flex: 1,
      renderCell: RedactedCheckbox,
    },
  ];

  return (
    <CompactGridWrapper
      loadingState={loadingState}
      columns={columns}
      rows={fields}
      getRowId={getRowId}
      rowSelection={false}
    />
  );
};
