import { Stack, ToggleButton, ToggleButtonGroup } from '@mui/material';

import React from 'react';
import { PATIENT_REVIEW_SCORES, PatientReviewScore } from '../../../../../data/PatientReviewData';

const ScorePicker: React.FC<{
  score: PatientReviewScore | null;
  onScoreChange: (score: PatientReviewScore) => void;
}> = ({ score, onScoreChange }) => {
  return (
    <Stack direction='row' spacing={2} flex={1}>
      <ToggleButtonGroup
        value={score}
        onChange={(_event, value: PatientReviewScore) => onScoreChange(value)}
        exclusive={true}
        size='small'
        color='primary'
      >
        {PATIENT_REVIEW_SCORES.map(score => (
          <ToggleButton
            key={score}
            value={score}
            sx={{
              paddingLeft: '0.75em',
              paddingRight: '0.75em',
            }}
          >
            {score}
          </ToggleButton>
        ))}
      </ToggleButtonGroup>
    </Stack>
  );
};

export default ScorePicker;
