import { Box, IconButton, Typography, useTheme } from '@mui/material';
import React, { PropsWithChildren } from 'react';
import { GoBack } from './GoBack';
import { FlexBox } from './FlexBox';
import { usePageHeaderContext } from '../contexts/PageHeaderContext';
import { ChevronLeftTwoTone } from '@mui/icons-material';
import { Theme } from '@mui/material/styles';

export interface PageHeaderProps {
  title: string;
  subtitle?: string;
  goBackUrl?: string;
}

export const PageHeader = ({ title, subtitle, goBackUrl }: PageHeaderProps) => {
  return (
    <PageTitleWrapper hasSubtitle={subtitle !== undefined}>
      <FlexBox alignItems='center'>
        <Box whiteSpace='pre-wrap'>
          <FlexBox flexDirection={'row'} alignItems={'center'}>
            {goBackUrl && <GoBack url={goBackUrl} />}
            <PageTitle>{title}</PageTitle>
          </FlexBox>
          {subtitle && <Typography variant='subtitle2'>{subtitle}</Typography>}
        </Box>
      </FlexBox>
    </PageTitleWrapper>
  );
};

const PageTitleWrapper: React.FC<PropsWithChildren<{ hasSubtitle: boolean }>> = ({ hasSubtitle, children }) => {
  const theme = useTheme();
  const { expanded } = usePageHeaderContext();

  return (
    <Box
      className='MuiPageTitle-wrapper'
      position='relative'
      paddingX={theme.spacing(3)}
      paddingTop={theme.spacing(expanded ? 3 : hasSubtitle ? 0.25 : 1)}
      paddingBottom={theme.spacing(expanded ? 3 : hasSubtitle ? 0.5 : 1)}
      sx={{ transition: makeTransition(theme, 'padding', expanded) }}
    >
      {children}

      <HeaderToggleButton />
    </Box>
  );
};

const PageTitle: React.FC<PropsWithChildren<{}>> = ({ children }) => {
  const theme = useTheme();
  const { expanded } = usePageHeaderContext();

  return (
    <Typography
      variant='h3'
      component='h3'
      gutterBottom={expanded}
      sx={{ transition: makeTransition(theme, 'margin', expanded) }}
    >
      {children}
    </Typography>
  );
};

const HeaderToggleButton: React.FC = () => {
  const theme = useTheme();
  const { expanded, setExpanded } = usePageHeaderContext();

  return (
    <Box position='absolute' top={0} right={0} bottom={0}>
      <IconButton
        sx={{
          margin: theme.spacing(1),
        }}
        onClick={() => setExpanded(!expanded)}
      >
        <ChevronLeftTwoTone
          sx={{
            transform: expanded ? 'rotate(90deg)' : 'rotate(-90deg)',
            transition: makeTransition(theme, 'transform', expanded),
          }}
        />
      </IconButton>
    </Box>
  );
};

const makeTransition = (theme: Theme, props: string | string[], expanded: boolean) =>
  theme.transitions.create(props, {
    easing: theme.transitions.easing.sharp,
    // Use the enteringScreen duration when expanded and the leavingScreen duration when collapsed because the
    // transitions are applied as soon as the expanded state changes.
    duration: expanded ? theme.transitions.duration.enteringScreen : theme.transitions.duration.leavingScreen,
  });
