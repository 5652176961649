import React from 'react';
import { DataProductDataset } from '../../data/DataProductData';
import { CompactGridWrapper } from '../../components/grid/CompactGridWrapper';
import { GridColDef, GridValueGetterParams } from '@mui/x-data-grid-pro';
import { Link } from 'react-router-dom';
import { GridRenderCellParams } from '@mui/x-data-grid/models/params/gridCellParams';
import { Box, IconButton } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { t } from 'i18next';
import { LoadingState } from '../../components/LoadingStateUtil';

interface DatasetsTableProps {
  loadingState: LoadingState;
  datasets: DataProductDataset[];
  onDeleteDataset: (researchProjectId: string) => unknown;
}

export const DatasetsTable: React.FC<DatasetsTableProps> = ({ loadingState, datasets, onDeleteDataset }) => {
  const getRowId = (row: DataProductDataset) => row.researchProjectId;

  const DeleteCell: React.FC<GridRenderCellParams<DataProductDataset>> = params => {
    return (
      <IconButton size='small' onClick={() => onDeleteDataset(params.row.researchProjectId)}>
        <DeleteIcon fontSize='small' />
      </IconButton>
    );
  };

  const columns: GridColDef<DataProductDataset>[] = [
    {
      field: 'name',
      headerName: t('name'),
      type: 'string',
      flex: 1,
      valueGetter: (params: GridValueGetterParams<DataProductDataset>) =>
        params.value ?? params.row.researchProjectName,
    },
    {
      field: 'researchProjectName',
      headerName: t('researchProject'),
      type: 'string',
      flex: 1,
      renderCell: ResearchProjectName,
    },
    {
      field: 'delete',
      headerName: t('delete'),
      valueGetter: () => false,
      type: 'boolean',
      flex: 0,
      renderCell: DeleteCell,
    },
  ];

  return (
    <Box flex={1} sx={{ position: 'relative' }}>
      <CompactGridWrapper loadingState={loadingState} columns={columns} rows={datasets} getRowId={getRowId} />
    </Box>
  );
};

const ResearchProjectName: React.FC<GridRenderCellParams<DataProductDataset>> = params => {
  return <Link to={`/research-project/${params.row.researchProjectId}`}>{params.value}</Link>;
};
