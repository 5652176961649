import Highcharts from 'highcharts';
import { Theme } from '@mui/material/styles';

export const computeNewTickInterval = (chart: Highcharts.Chart, labelWidthPx: number): number | null => {
  const xAxis = chart.xAxis[0];
  const xAxisWidthPx = (xAxis as any).len as number;
  const xAxisSize = (xAxis.max ?? 0) - (xAxis.min ?? 0);

  const singleValueWidthPx = xAxisWidthPx / xAxisSize;

  const numValuesInLabelWidth = Math.ceil(labelWidthPx / singleValueWidthPx);

  return numValuesInLabelWidth !== xAxis.options.tickInterval ? numValuesInLabelWidth : null;
};

export const makeFontStyle = (fontSize: number, theme: Theme): any => ({
  color: theme.palette.text.primary,
  // Note: this text stroke only works on HTML labels and not SVG
  webkitTextStroke: '2px white',
  paintOrder: 'stroke fill',
  whiteSpace: 'nowrap',
  textOverflow: 'none',
  fontFamily: theme.typography.fontFamily,
  fontSize: `${fontSize}px`,
});

const labelOffset = 4;

export const makeXPlotLineLabel = (label: string, fontSize: number, theme: Theme) => ({
  x: labelOffset,
  y: 0,
  align: 'left',
  text: label,
  style: {
    ...makeFontStyle(fontSize, theme),
  },
  useHTML: true,
});

export const makeYPlotLineLabel = (label: string, fontSize: number, theme: Theme) => ({
  x: labelOffset,
  y: -labelOffset,
  align: 'left',
  text: label,
  style: {
    ...makeFontStyle(fontSize, theme),
  },
  useHTML: true,
});
