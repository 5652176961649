import { GridColDef } from '@mui/x-data-grid-pro';
import useAuth from 'auth/UseAuth';
import React, { useEffect, useMemo, useState } from 'react';
import { FlexTableBox } from '../../../components/FlexTableBox';
import {
  GetSupplementalFilesForCheckByGroupId,
  GetSupplementalFilesForTransitionId,
  SupplementalFile,
} from '../../../data/SupplementalFileData';
import { copyBtn, createdAt, description, location, nameField, supplementalFileType } from '../../../util/Constants';
import { GetSupplementalFileTypes, SupplementalFileType } from '../../../data/SupplementalFileTypeData';
import { keyBy } from 'lodash';
import { LoadingState, LoadState } from '../../../components/LoadingStateUtil';
import useMemoTranslation from '../../../hooks/UseMemoTranslation';
import { renderCopyTextGridCell } from '../../../components/grid/cell/CopyTextGridCell';
import { CompactGridWrapper } from '../../../components/grid/CompactGridWrapper';

interface BaseGridProps {
  id: string;
  refreshTrigger: boolean;
}

interface GridProps {
  getData: (accessToken: string) => Promise<ReadonlyArray<SupplementalFile>>;
  refreshTrigger?: boolean;
}

export const SupplementalFilesTransitionScopedGrid = ({ id, refreshTrigger }: BaseGridProps) => {
  return (
    <>
      <Grid
        refreshTrigger={refreshTrigger}
        getData={async (accessToken: string) => await GetSupplementalFilesForTransitionId(id, accessToken)}
      />
    </>
  );
};

export const SupplementalFilesCheckByGroupScopedGrid = ({ id, refreshTrigger }: BaseGridProps) => {
  return (
    <>
      <Grid
        refreshTrigger={refreshTrigger}
        getData={async (accessToken: string) => await GetSupplementalFilesForCheckByGroupId(id, accessToken)}
      />
    </>
  );
};

type Row = SupplementalFile & {
  id: number;
  supplementalFileType: string;
  copyBtn: string;
};

export const Grid = ({ getData, refreshTrigger }: GridProps) => {
  const { accessToken } = useAuth();

  const [loadingState, setLoadingState] = useState<LoadingState>({ status: 'NotStarted' });

  const [data, setData] = useState<ReadonlyArray<SupplementalFile>>([]);
  const [fileTypes, setFileTypes] = useState<ReadonlyArray<SupplementalFileType>>([]);

  const columns = useColumns();
  const rows = useRows(data, fileTypes);

  useEffect(() => {
    return LoadState(setLoadingState, async () => {
      if (!accessToken) {
        return;
      }
      const [data, fileTypes] = await Promise.all([
        await getData(accessToken),
        await GetSupplementalFileTypes(accessToken),
      ]);

      setData(data);
      setFileTypes(fileTypes);
    });
  }, [accessToken, refreshTrigger, getData]);

  return (
    <FlexTableBox>
      <CompactGridWrapper
        loadingState={loadingState}
        rows={rows}
        columns={columns}
        initialState={{
          sorting: {
            sortModel: [{ field: createdAt, sort: 'desc' }],
          },
        }}
        disableRowSelectionOnClick
      />
    </FlexTableBox>
  );
};

const useRows = (data: ReadonlyArray<SupplementalFile>, fileTypes: ReadonlyArray<SupplementalFileType>) => {
  return useMemo(() => {
    const idToType = keyBy(fileTypes, 'supplementalFileTypeId');

    const rows: Row[] = data.map((r, index) => {
      return {
        id: index++,
        ...r,
        supplementalFileType: idToType[r.supplementalFileTypeId]?.name ?? '',
        copyBtn: r.location,
      };
    });

    return rows;
  }, [data, fileTypes]);
};

const useColumns = (): GridColDef[] => {
  const { t } = useMemoTranslation();

  return useMemo(
    () => [
      {
        field: supplementalFileType,
        headerName: t(supplementalFileType),
        headerAlign: 'left',
        align: 'left',
        width: 100,
      },
      {
        field: location,
        headerName: t(location),
        headerAlign: 'left',
        align: 'left',
        minWidth: 150,
        flex: 1,
      },
      {
        field: nameField,
        headerName: t(nameField),
        headerAlign: 'left',
        align: 'left',
        minWidth: 100,
        flex: 1,
      },
      {
        field: description,
        headerName: t(description),
        headerAlign: 'left',
        align: 'left',
        minWidth: 150,
        flex: 1,
      },
      {
        field: createdAt,
        headerName: t(createdAt),
        headerAlign: 'center',
        align: 'center',
        width: 150,
        valueFormatter: ({ value }) => value && new Date(value).toLocaleDateString(),
      },
      {
        field: copyBtn,
        headerName: t('copyLocation'),
        headerAlign: 'center',
        align: 'center',
        width: 100,
        renderCell: renderCopyTextGridCell,
      },
    ],
    [t]
  );
};
