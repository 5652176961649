import React, { useCallback, useEffect, useState } from 'react';
import { DataProductTable } from '../../data/DataProductData';
import { CompactGridWrapper } from '../../components/grid/CompactGridWrapper';
import { GridColDef, GridValueGetterParams } from '@mui/x-data-grid-pro';
import { GridRenderCellParams } from '@mui/x-data-grid/models/params/gridCellParams';
import { Checkbox } from '@mui/material';
import { t } from 'i18next';
import { LoadingState } from '../../components/LoadingStateUtil';

interface TablesEditorTableProps {
  loadingState: LoadingState;
  disabled?: boolean;
  tables: DataProductTable[];
  onTableSelected: (tableId: string) => unknown;
  onChange: (newTables: DataProductTable[]) => unknown;
}

export const TablesEditorTable: React.FC<TablesEditorTableProps> = ({
  loadingState,
  disabled,
  tables,
  onTableSelected,
  onChange,
}) => {
  const getRowId = (row: DataProductTable) => row.dataSchemaTableId;

  const [selectedRowId, setSelectedRowId] = useState<string | null>(null);

  const handleSelectionChange = useCallback(
    (selectedRowIds: string[]) => {
      if (selectedRowIds.length !== 1) {
        return;
      }

      setSelectedRowId(selectedRowIds[0]);
      onTableSelected(selectedRowIds[0]);
    },
    [onTableSelected]
  );

  useEffect(() => {
    if (tables.length === 0) {
      setSelectedRowId(null);
      return;
    }

    if (selectedRowId !== null && tables.map(getRowId).includes(selectedRowId)) {
      return;
    }

    setSelectedRowId(getRowId(tables[0]));
    handleSelectionChange([getRowId(tables[0])]);
  }, [selectedRowId, tables, handleSelectionChange]);

  const handleRedactedChange = (rowId: string, redacted: boolean) => {
    const newTables = tables.map(t => (getRowId(t) === rowId ? { ...t, redacted } : t));
    onChange(newTables);
  };

  const RedactedCheckbox: React.FC<GridRenderCellParams<DataProductTable, boolean>> = params => {
    return (
      <Checkbox
        checked={params.value}
        disabled={disabled ?? false}
        onClick={e => e.stopPropagation()}
        onChange={e => handleRedactedChange(params.id as string, !params.value)}
      />
    );
  };

  const columns: GridColDef<DataProductTable>[] = [
    {
      field: 'name',
      headerName: t('name'),
      type: 'string',
      flex: 1,
    },
    {
      field: 'redacted',
      headerName: t('redacted'),
      type: 'boolean',
      flex: 1,
      renderCell: RedactedCheckbox,
    },
    {
      field: 'redactedFields',
      headerName: t('redactedFields'),
      type: 'number',
      flex: 1,
      valueGetter: (params: GridValueGetterParams<DataProductTable>) =>
        params.row.fields.filter(f => f.redacted).length,
      renderCell: (params: GridRenderCellParams<DataProductTable>) => `${params.value}/${params.row.fields.length}`,
    },
  ];

  return (
    <CompactGridWrapper
      loadingState={loadingState}
      columns={columns}
      rows={tables}
      getRowId={getRowId}
      rowSelection={true}
      rowSelectionModel={selectedRowId !== null ? [selectedRowId] : []}
      onRowSelectionModelChange={rowIds => handleSelectionChange(rowIds as string[])}
      sx={{
        '.MuiDataGrid-cell:focus': {
          outline: 'none',
        },
        '& .MuiDataGrid-row:hover': {
          cursor: 'pointer',
        },
      }}
    />
  );
};
