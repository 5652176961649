import React, { useEffect } from 'react';
import { TextField } from '@mui/material';
import { Controller } from 'react-hook-form';
import { FormInputProps } from './FormInputProps';
import { AutocompleteWrapper } from '../AutocompleteWrapper';

export const FormSelectField: React.FC<FormInputProps> = ({
  name,
  control,
  label,
  trigger,
  options,
  required = false,
  disabled = false,
  optionLabel = 'name',
  sx = [],
  inputSx = [],
  additionalProps,
}) => {
  // triggers rules on render
  useEffect(() => {
    trigger(name);
  }, [trigger, name, required]);

  return (
    <Controller
      name={name}
      control={control}
      rules={{
        required: required,
      }}
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <AutocompleteWrapper
          fullWidth
          sx={{ ...sx }}
          size='small'
          disabled={disabled}
          value={value || null}
          options={options}
          getOptionLabel={(option: any) => (typeof option === 'string' ? option : option[optionLabel])}
          isOptionEqualToValue={(option: any, value: any) =>
            typeof option === 'string' ? option === value : option[optionLabel] === value[optionLabel]
          }
          renderInput={params => (
            <TextField
              {...params}
              label={label}
              margin='dense'
              required={required}
              error={!!error}
              sx={{ ...inputSx }}
            />
          )}
          onChange={(_, value) => onChange(value)}
          {...additionalProps}
        />
      )}
    />
  );
};
