import { DataGridPro, GridColDef, GridRowsProp } from '@mui/x-data-grid-pro';
import useAuth from 'auth/UseAuth';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  createdAt,
  failedCount,
  passedCount,
  sampleTrackingCheckByUndoDialog,
  sampleTrackingTransitionFileDialog,
  transition,
  transitionId,
  transitionCheckByGroupId,
} from '../../util/Constants';
import {
  GetAllTransitionWithCheckByGroupsForResearchProject,
  GetCheckByGroupCounts,
  GetUndoableCheckByGroupIds,
  CheckByGroupAndTransition,
  CheckByGroupCounts,
  TransitionSampleCheckByGroup,
} from '../../data/SampleTrackingCheckByGroupData';
import { renderSampleTrackingTransitionSampleCheckByGroupScopedModalCell } from '../../components/grid/cell/SampleTrackingCheckByGroupSampleGridCell';
import { renderSampleTrackingCheckByGroupUndoGridCell } from '../../components/grid/cell/SampleTrackingCheckByGroupUndoGridCell';
import { renderSampleTrackingSupplementalFilesGridCell } from '../../components/grid/cell/SampleTrackingSupplementalFilesGridCell';
import { compact, keyBy, uniq } from 'lodash';
import { CheckByStatus } from '../../data/SampleTrackingData';
import { dateGetter, ExtractDisplayValueFromPossibleArray } from '../../util/grid/TableUtils';
import { LoadingState, LoadState } from '../../components/LoadingStateUtil';
import { LoadingIndicator } from '../../components/LoadingIndicator';
import { ErrorIndicator } from '../../components/ErrorIndicator';
import useMemoTranslation from '../../hooks/UseMemoTranslation';
import { UseStateSetter } from '../../util/TypeUtil';
import { truncateGuid } from '../../data/SampleData';
import { useUserPermissions } from '../../auth/UseUserPermissions';
import { useConfiguredTransitions } from '../../components/hooks/UseConfiguredTransitions';
import { ConfiguredTransition } from '../../data/ConfiguredTransitionData';

export interface SampleTrackingCheckByGridProps {
  researchProjectId: string;
}

type Row = TransitionSampleCheckByGroup & {
  id: number;
  researchProjectId: string;
  transitionId: ReadonlyArray<string>;
  transitionEnumId: ReadonlyArray<string>;
  passedCount?: number;
  failedCount?: number;
  supplementalFileCounts?: number;
};

export const SampleTrackingCheckByGrid = ({ researchProjectId }: SampleTrackingCheckByGridProps) => {
  const { accessToken } = useAuth();

  const [loadingState, setLoadingState] = useState<LoadingState>({ status: 'NotStarted' });
  const [refreshTrigger, setRefreshTrigger] = useState<boolean>(true);

  const [configuredTransitions] = useConfiguredTransitions(researchProjectId, setLoadingState);

  const columns = useColumns(setRefreshTrigger);
  const [rows, setRows] = useState<GridRowsProp<Row>>([]);

  const getRows = useCallback(
    (
      data: ReadonlyArray<CheckByGroupAndTransition>,
      undoableIds: ReadonlyArray<string>,
      counts: ReadonlyArray<CheckByGroupCounts>,
      configuredTransitions: ReadonlyArray<ConfiguredTransition>
    ) => {
      let index = 0;

      const checkByStatusCounts = counts.flatMap(i => i.checkByStatusCounts);
      const passedCounts = keyBy(
        checkByStatusCounts.filter(c => c.checkByStatus === ('Passed' as CheckByStatus)),
        c => c.checkByGroupId
      );
      const failedCounts = keyBy(
        checkByStatusCounts.filter(c => c.checkByStatus === ('Failed' as CheckByStatus)),
        c => c.checkByGroupId
      );
      const countsById = keyBy(counts, i => i.checkByGroupId);
      const transitionById = keyBy(configuredTransitions, i => i.configuredTransitionId);

      const rows: Row[] = data.map(r => {
        const transitionId: string[] = compact(uniq(r.transitionDtos.map(s => s.transitionId)));
        const configuredTransitionIds: string[] = compact(uniq(r.transitionDtos.map(s => s.configuredTransitionId)));
        const transitionType = compact(uniq(r.transitionDtos.map(s => s.transition)));

        const checkByGroupId = r.transitionSampleCheckByGroup.transitionCheckByGroupId;

        return {
          id: index++,
          transitionId: transitionId,
          transitionEnumId: transitionType,
          transition: configuredTransitionIds.map(id => transitionById[id ?? '']?.displayName),
          ...r.transitionSampleCheckByGroup,
          researchProjectId: researchProjectId,
          sampleTrackingTransitionSampleDialog: checkByGroupId,
          sampleTrackingCheckByUndoDialog: undoableIds.includes(checkByGroupId ?? '') ? checkByGroupId : null,
          sampleTrackingTransitionFileDialog: checkByGroupId,
          checkByGroupId: checkByGroupId,
          passedCount: passedCounts[checkByGroupId]?.transitionSampleCounts,
          failedCount: failedCounts[checkByGroupId]?.transitionSampleCounts,
          supplementalFileCounts: countsById[checkByGroupId]?.supplementalFileCounts,
        };
      });

      return rows;
    },
    [researchProjectId]
  );

  useEffect(() => {
    return LoadState(setLoadingState, async () => {
      if (!(accessToken && researchProjectId !== undefined)) {
        return;
      }

      if (configuredTransitions.length === 0) {
        return;
      }

      const [data, undoableIds, counts] = [
        await GetAllTransitionWithCheckByGroupsForResearchProject(researchProjectId, accessToken),
        await GetUndoableCheckByGroupIds(researchProjectId, accessToken),
        await GetCheckByGroupCounts(researchProjectId, accessToken),
      ];

      setRows(getRows(data, undoableIds, counts, configuredTransitions));
    });
  }, [accessToken, researchProjectId, refreshTrigger, getRows, configuredTransitions]);

  return (
    <>
      {loadingState.status === 'Complete' && (
        <DataGridPro
          rows={rows}
          columns={columns}
          density='compact'
          initialState={{
            sorting: {
              sortModel: [{ field: createdAt, sort: 'desc' }],
            },
          }}
          disableRowSelectionOnClick
        />
      )}
      <LoadingIndicator type={'Circular'} loadingState={loadingState} margin={'LR'} />
      <ErrorIndicator loadingState={loadingState} />
    </>
  );
};

const useColumns = (setRefreshTrigger: UseStateSetter<boolean>): GridColDef[] => {
  const { t } = useMemoTranslation();
  const { hasSampleTrackingWriteAccess } = useUserPermissions();

  return useMemo(
    () => [
      {
        field: transitionCheckByGroupId,
        headerName: t(transitionCheckByGroupId),
        headerAlign: 'center',
        align: 'center',
        width: 200,
        renderCell: params =>
          renderSampleTrackingTransitionSampleCheckByGroupScopedModalCell({
            ...params,
          }),
      },
      {
        field: createdAt,
        headerName: t(createdAt),
        headerAlign: 'left',
        align: 'left',
        width: 150,
        type: 'date',
        valueGetter: dateGetter,
      },
      {
        field: transition,
        headerName: t('checkByType'),
        headerAlign: 'left',
        align: 'left',
        minWidth: 150,
        maxWidth: 400,
        flex: 1,
        valueFormatter: ({ value }) => ExtractDisplayValueFromPossibleArray(value, t),
      },
      {
        field: passedCount,
        headerName: t(passedCount),
        headerAlign: 'center',
        align: 'center',
        width: 150,
      },
      {
        field: failedCount,
        headerName: t(failedCount),
        headerAlign: 'center',
        align: 'center',
        width: 150,
      },
      {
        field: transitionId,
        headerName: t(transitionId),
        headerAlign: 'center',
        align: 'center',
        width: 150,
        valueFormatter: ({ value }) => value && truncateGuid(ExtractDisplayValueFromPossibleArray(value, t)),
      },
      ...((hasSampleTrackingWriteAccess
        ? [
            {
              field: sampleTrackingCheckByUndoDialog,
              align: 'center',
              headerAlign: 'center',
              headerName: t('undo'),
              width: 75,
              renderCell: params =>
                renderSampleTrackingCheckByGroupUndoGridCell({
                  ...params,
                  onClose: () => setRefreshTrigger(refreshTrigger => !refreshTrigger),
                }),
            },
          ]
        : []) as GridColDef[]),
      {
        field: sampleTrackingTransitionFileDialog,
        align: 'center',
        headerAlign: 'center',
        headerName: t('numOfFiles'),
        width: 75,
        renderCell: params =>
          renderSampleTrackingSupplementalFilesGridCell({
            ...params,
            scope: 'CheckByGroup',
            numberOfFiles: params.row.supplementalFileCounts,
            transitionType: params.row.transition,
          }),
      },
    ],
    [t, setRefreshTrigger, hasSampleTrackingWriteAccess]
  ) as GridColDef[];
};
