import { GridRenderCellParams } from '@mui/x-data-grid-pro';
import useMemoTranslation from '../../hooks/UseMemoTranslation';
import React, { useEffect, useState } from 'react';
import { DialogOpenButton } from '../DialogOpenButton';
import FullscreenOutlinedIcon from '@mui/icons-material/FullscreenOutlined';
import { Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material';
import { DialogCloseButton } from '../DialogCloseButton';
import { CancelButton } from '../CancelButton';
import { Dictionary, keys } from 'lodash';
import { FlexBox } from '../FlexBox';
import { KeyValuePairTable } from '../KeyValuePairTable';

export function renderGridCellInformaticsComputedValues(params: GridRenderCellParams) {
  return <GridGridCellInformaticsComputedValues {...params} />;
}

const GridGridCellInformaticsComputedValues = ({ row }: GridRenderCellParams) => {
  const { t } = useMemoTranslation();

  const [open, setOpen] = useState<boolean>(false);
  const [computedValues, setComputedValues] = useState<Dictionary<object>>({});

  function handleClose() {
    setOpen(false);
  }

  useEffect(() => {
    if (!row.computedValues) {
      return;
    }

    setComputedValues(row.computedValues);
  }, [row]);

  return (
    <>
      {row.computedValues && (
        <FlexBox sx={{ flexDirection: 'row', justifyContent: 'space-around', width: '100%' }}>
          <DialogOpenButton
            onClick={() => setOpen(true)}
            sx={{ margin: 0, padding: 0, minWidth: 0, width: 0, marginRight: 0 }}
          >
            <FullscreenOutlinedIcon />
          </DialogOpenButton>
          <Dialog open={open} onClose={handleClose} maxWidth={'lg'}>
            <DialogTitle>
              <DialogCloseButton onClick={() => handleClose()} />
              {`${row.sampleIdentifier} - ${row.reference?.name}`}
              <Typography sx={{ whiteSpace: 'pre-line', wordWrap: 'wrap' }}>{t('computedValuesInfo')}</Typography>
            </DialogTitle>
            <DialogContent sx={{ pb: 0, mb: 0 }}>
              <KeyValuePairTable
                data={keys(computedValues).map(key => {
                  return { key: t(key as any), value: computedValues[key]?.toString() ?? '' };
                })}
              />
            </DialogContent>
            <DialogActions>
              <CancelButton onClick={handleClose} />
            </DialogActions>
          </Dialog>
        </FlexBox>
      )}
    </>
  );
};
