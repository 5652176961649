import { GridColDef, useGridApiRef } from '@mui/x-data-grid-pro';
import React, { useMemo } from 'react';
import {
  compactGridHeaderClassName,
  libraryKitName,
  r1FastQLocation,
  r2FastQLocation,
  sampleIdentifier,
  selectionStatus,
  sequenceType,
  sequencingLabName,
  sequencingPlatformName,
} from '../../util/Constants';
import { SampleSequencingJourneyDetails, SequencingFastQ } from 'data/SampleJourneyData';
import { CompactGridWrapper } from '../../components/grid/CompactGridWrapper';
import useMemoTranslation from 'hooks/UseMemoTranslation';
import { SelectedSampleInfo } from './CreateSampleTrackingTransitionModal';
import { find } from 'lodash';
import { renderSampleHierarchyGridCell } from '../../components/grid/cell/SampleHierarchyGridCell';

interface CreateInformaticsTransitionGridProps {
  selectedSamples: ReadonlyArray<SelectedSampleInfo>;
  data: ReadonlyArray<SampleSequencingJourneyDetails>;
}

type Row = SampleSequencingJourneyDetails &
  SelectedSampleInfo & {
    id: number;
    updated: boolean;
  };

export const CreateInformaticsTransitionGrid = ({ selectedSamples, data }: CreateInformaticsTransitionGridProps) => {
  const apiRef = useGridApiRef();

  const columns = useColumns();
  const rows = useRows(selectedSamples, data);

  return (
    <CompactGridWrapper apiRef={apiRef ?? {}} rows={rows} columns={columns} flexGrow={1} disableRowSelectionOnClick />
  );
};

const useColumns = (): GridColDef[] => {
  const { t } = useMemoTranslation();

  return useMemo(
    () => [
      {
        field: sampleIdentifier,
        headerName: t(sampleIdentifier),
        headerClassName: compactGridHeaderClassName,
        headerAlign: 'left',
        align: 'left',
        width: 250,
        renderCell: params =>
          renderSampleHierarchyGridCell({
            ...params,
            sampleId: params.row.sampleId,
            displayValue: params.value,
          }),
      },
      {
        field: sequenceType,
        headerName: t(sequenceType),
        headerClassName: compactGridHeaderClassName,
        headerAlign: 'left',
        align: 'left',
        width: 150,
        valueFormatter: ({ value }) => (value ? value.toUpperCase() : ''),
      },
      {
        field: libraryKitName,
        headerName: t(libraryKitName),
        headerClassName: compactGridHeaderClassName,
        headerAlign: 'left',
        align: 'left',
        width: 250,
      },
      {
        field: sequencingLabName,
        headerName: t(sequencingLabName),
        headerClassName: compactGridHeaderClassName,
        headerAlign: 'left',
        align: 'left',
        width: 150,
      },
      {
        field: sequencingPlatformName,
        headerName: t(sequencingPlatformName),
        headerClassName: compactGridHeaderClassName,
        headerAlign: 'left',
        align: 'left',
        width: 180,
      },
      {
        field: r1FastQLocation,
        headerName: t(r1FastQLocation),
        headerAlign: 'left',
        align: 'left',
        minWidth: 200,
        flex: 2,
        valueGetter: params => find(params.row.fastQs, i => (i as SequencingFastQ).type.name === 'r1')?.file.location,
      },
      {
        field: r2FastQLocation,
        headerName: t(r2FastQLocation),
        headerAlign: 'left',
        align: 'left',
        minWidth: 200,
        flex: 2,
        valueGetter: params => find(params.row.fastQs, i => (i as SequencingFastQ).type.name === 'r2')?.file.location,
      },
      {
        field: selectionStatus,
        headerName: t(selectionStatus),
        headerClassName: compactGridHeaderClassName,
        headerAlign: 'left',
        align: 'left',
        width: 150,
        valueFormatter: ({ value }) => value && t(value),
      },
    ],
    [t]
  );
};

function useRows(
  data: ReadonlyArray<SelectedSampleInfo>,
  sampleSequencingJourneyDetails: ReadonlyArray<SampleSequencingJourneyDetails>
) {
  return useMemo(() => {
    const rows: Row[] = data.map((r, index) => {
      const details = sampleSequencingJourneyDetails.find(i => i.sampleId === r.sampleId);

      return {
        id: index,
        updated: false,
        ...r,
        ...(details as SampleSequencingJourneyDetails),
      };
    });

    return rows;
  }, [data, sampleSequencingJourneyDetails]);
}
