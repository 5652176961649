import { StateCreator } from 'zustand';
import { PatientApprovalPatient } from '../../../../data/PatientApprovalData';

export interface PatientSlice {
  patient: PatientApprovalPatient | undefined;
  setPatient: (patient: PatientApprovalPatient | undefined) => void;
}

export const createPatientSlice: StateCreator<PatientSlice> = set => ({
  patient: undefined,
  setPatient: patient => set({ patient }),
});
