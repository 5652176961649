import React, { useCallback, useEffect, useState } from 'react';
import { CompactGridWrapper } from '../../components/grid/CompactGridWrapper';
import { GridColDef } from '@mui/x-data-grid-pro';
import { t } from 'i18next';
import { LoadingState } from '../../components/LoadingStateUtil';

interface ITable {
  dataSchemaTableId: string;
  name: string;
}

interface TablePickerTableProps {
  loadingState: LoadingState;
  tables: ITable[];
  onTableSelected: (tableId: string) => unknown;
}

export const TablePickerTable: React.FC<TablePickerTableProps> = ({ loadingState, tables, onTableSelected }) => {
  const getRowId = (row: ITable) => row.dataSchemaTableId;

  const [selectedRowId, setSelectedRowId] = useState<string | null>(null);

  const handleSelectionChange = useCallback(
    (selectedRowIds: string[]) => {
      if (selectedRowIds.length !== 1) {
        return;
      }

      setSelectedRowId(selectedRowIds[0]);
      onTableSelected(selectedRowIds[0]);
    },
    [onTableSelected]
  );

  useEffect(() => {
    if (tables.length === 0) {
      setSelectedRowId(null);
      return;
    }

    if (selectedRowId !== null && tables.map(getRowId).includes(selectedRowId)) {
      return;
    }

    setSelectedRowId(getRowId(tables[0]));
    handleSelectionChange([getRowId(tables[0])]);
  }, [selectedRowId, tables, handleSelectionChange]);

  const columns: GridColDef<ITable>[] = [
    {
      field: 'name',
      headerName: t('name'),
      type: 'string',
      flex: 1,
    },
  ];

  return (
    <CompactGridWrapper
      loadingState={loadingState}
      columns={columns}
      rows={tables}
      getRowId={getRowId}
      rowSelection={true}
      rowSelectionModel={selectedRowId !== null ? [selectedRowId] : []}
      onRowSelectionModelChange={selectedRowIds => handleSelectionChange(selectedRowIds as string[])}
      sx={{
        '.MuiDataGrid-cell:focus': {
          outline: 'none',
        },
        '& .MuiDataGrid-row:hover': {
          cursor: 'pointer',
        },
      }}
    />
  );
};
