import * as React from 'react';
import { useCallback, useEffect, useState } from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material';
import { LoadingIndicator } from './LoadingIndicator';
import { LoadingState, LoadState } from './LoadingStateUtil';
import { ErrorIndicator } from './ErrorIndicator';
import { CancelButton } from './CancelButton';
import { PrimaryButton } from './PrimaryButton';
import { DialogOpenButton } from './DialogOpenButton';
import { useTranslation } from 'react-i18next';
import { GetAllSelectableFieldsForResearchProject, SelectableEntity } from '../data/SampleTrackingFieldSelectionData';
import { EntityFieldSelector } from './EntityFieldSelector';
import useLocalStorage from '../hooks/UseLocalStorage';
import ViewWeekOutlinedIcon from '@mui/icons-material/ViewWeekOutlined';
import useAuth from '../auth/UseAuth';
import { useParams } from 'react-router-dom';
import { filter, map } from 'lodash';
import { UseStateSetter } from '../util/TypeUtil';

export const TableLineageColumnSelectorDialog = ({
  onChange,
  onPopulate,
  defaultColumns,
  additionalEntities,
  cacheKey,
}: {
  onChange: (entities: ReadonlyArray<SelectableEntity>) => void;
  onPopulate: UseStateSetter<ReadonlyArray<SelectableEntity>>;
  defaultColumns: ReadonlyArray<string>;
  additionalEntities: SelectableEntity[];
  cacheKey: string;
}) => {
  const { t } = useTranslation();
  const { accessToken } = useAuth();
  let { researchProjectId } = useParams() as { researchProjectId: string };

  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [loadingState, setLoadingState] = useState<LoadingState>({ status: 'Complete' });

  const [entities, setEntities] = useState<ReadonlyArray<SelectableEntity>>([]);
  const [defaultEntities, setDefaultEntities] = useState<SelectableEntity[]>([]);
  const [localStorageEntities, setLocalStorageEntities, localStorageEntitiesKeyPresent] = useLocalStorage<
    ReadonlyArray<SelectableEntity>
  >(`${cacheKey}-column-selection`, []);
  const [initialized, setInitialized] = useState<boolean>(false);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const memoizedOnChange = useCallback(onChange, []);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const memorizedOnPopulate = useCallback(onPopulate, []);

  useEffect(() => {
    if (initialized || localStorageEntities.length === 0) {
      return;
    }

    memoizedOnChange(localStorageEntities);
    setInitialized(true);
  }, [memoizedOnChange, localStorageEntities, initialized]);

  useEffect(() => {
    return LoadState(setLoadingState, async () => {
      if (accessToken === undefined || defaultEntities.length > 0) {
        return;
      }

      const entitiesData = [
        ...additionalEntities,
        ...(await GetAllSelectableFieldsForResearchProject(researchProjectId, accessToken)),
      ];

      const newDefaults = filter(
        map(entitiesData, entity => {
          return {
            ...entity,
            fields: filter(entity.fields, field => {
              return defaultColumns.includes(field.key);
            }),
          };
        }),
        entity => entity.fields.length > 0
      );

      memorizedOnPopulate(prevState => {
        // Data won't change, avoid re-renders by returning current state
        if (prevState.length > 0) {
          return prevState;
        }

        return entitiesData;
      });
      setDefaultEntities(newDefaults);

      if (localStorageEntitiesKeyPresent) {
        return;
      }

      setLocalStorageEntities(newDefaults);
    });
  }, [
    accessToken,
    additionalEntities,
    defaultColumns,
    defaultEntities,
    localStorageEntities,
    localStorageEntitiesKeyPresent,
    memorizedOnPopulate,
    researchProjectId,
    setLocalStorageEntities,
  ]);

  function handleClose() {
    setModalOpen(false);
    setLoadingState({ status: 'Complete' });
  }

  function handleConfirm() {
    setLocalStorageEntities(entities);
    onChange(entities);
    handleClose();
  }

  return (
    <>
      <DialogOpenButton title={t('columns')} onClick={() => setModalOpen(true)}>
        <ViewWeekOutlinedIcon />
      </DialogOpenButton>
      <Dialog open={modalOpen} onClose={handleClose} maxWidth={'xs'} fullWidth>
        <DialogTitle>
          {t('columns')}
          <Typography variant='subtitle1'>{t('columnSelectorLineageInfo')}</Typography>
        </DialogTitle>
        <DialogContent sx={{ paddingBottom: 0 }}>
          <Typography sx={{ whiteSpace: 'pre-line' }}></Typography>
          <EntityFieldSelector
            cacheKey={`${cacheKey}-column-selection`}
            onChange={entries => {
              setEntities(entries);
            }}
            defaultEntities={defaultEntities}
            additionalEntities={additionalEntities}
          />
        </DialogContent>
        <LoadingIndicator loadingState={loadingState} margin={'LR'} />
        <ErrorIndicator loadingState={loadingState} />
        <DialogActions>
          <CancelButton onClick={handleClose} />
          <PrimaryButton disabled={loadingState.status === 'Loading'} onClick={handleConfirm}>
            {t('confirm')}
          </PrimaryButton>
        </DialogActions>
      </Dialog>
    </>
  );
};
