import { GenerateFileDownload } from '../helpers/FileProcessing';

export async function BaseGet(url: string, accessToken?: string) {
  if (!accessToken) {
    return;
  }

  const response = await fetch(url, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });

  if (response.status === 204) {
    return;
  }

  if (response.ok) {
    return await response.json();
  }

  throw new Error(await response.text());
}

export async function BaseGetWithParams(url: string, params: URLSearchParams | string, accessToken?: string) {
  if (!accessToken) {
    return;
  }

  const response = await fetch((url += '?' + params), {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });

  if (response.status === 204) {
    return;
  }

  if (response.ok) {
    return await response.json();
  }

  throw new Error(await response.text());
}

export async function BasePost(url: string, data: object, accessToken?: string) {
  if (!accessToken) {
    return;
  }

  const response = await fetch(url, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${accessToken}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  });

  if (response.ok) {
    return await response.json();
  }

  throw new Error(await response.text());
}

export async function BasePostVoid(url: string, data?: object, accessToken?: string) {
  if (!accessToken) {
    return;
  }

  const response = await fetch(url, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${accessToken}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  });

  if (response.ok) {
    return;
  }

  throw new Error(await response.text());
}

export async function BasePut(url: string, data: object, accessToken?: string) {
  if (!accessToken) {
    return;
  }

  const response = await fetch(url, {
    method: 'PUT',
    headers: {
      Authorization: `Bearer ${accessToken}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  });

  if (response.ok) {
    return await response.json();
  }

  throw new Error(await response.text());
}

export async function BasePutVoid(url: string, formData: FormData, accessToken?: string) {
  if (!accessToken) {
    return;
  }

  const response = await fetch(url, {
    method: 'PUT',
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
    body: formData,
  });

  if (response.ok) {
    return;
  }

  throw new Error(await GetErrorMessage(response));
}

export async function BaseDeleteVoid(url: string, accessToken?: string) {
  if (!accessToken) {
    return;
  }

  const response = await fetch(url, {
    method: 'DELETE',
    headers: {
      Authorization: `Bearer ${accessToken}`,
      'Content-Type': 'application/json',
    },
  });

  if (response.ok) {
    return;
  }

  throw new Error(await response.text());
}

export function BuildArrayQueryParams(values: ReadonlyArray<string>, queryParamName: string): string {
  let queryParams: string = '';

  values.forEach((value: string) => {
    queryParams += `${queryParamName}=${value}&`;
  });

  // Remove trailing '&'
  queryParams = queryParams.slice(0, -1);

  return queryParams;
}

export function BuildArrayQueryParam(value: string, queryParamName: string): string {
  let queryParams: string = '';

  queryParams += `${queryParamName}=${value}`;

  return queryParams;
}

export async function BaseDownloadFile(url: string, accessToken?: string) {
  if (!accessToken) {
    return;
  }

  const response = await fetch(url, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });

  if (response.status === 204) {
    return;
  }

  if (response.ok) {
    await GenerateFileDownload(response);
    return;
  }

  throw new Error(await response.text());
}
export async function BaseDownloadFileUsingPost(url: string, data: any, accessToken?: string) {
  if (!accessToken) {
    return;
  }

  const response = await fetch(url, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${accessToken}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  });

  if (response.status === 204) {
    return;
  }

  if (response.ok) {
    await GenerateFileDownload(response);
    return;
  }

  throw new Error(await response.text());
}

async function GetErrorMessage(response: Response) {
  let responseBody: { message: string } = await response.json();

  if (responseBody.message) {
    return responseBody.message;
  } else if (response.status === 401) {
    return 'Error: unauthorized user.';
  } else if (responseBody) {
  } else {
    return 'Unknown error.';
  }
}
