import React, { useEffect, useState } from 'react';
import { LinkedMeasurementDefinition, MeasurementDefinition } from '../../data/MeasurementDefinitionData';
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Rating,
  Stack,
  Typography,
} from '@mui/material';
import { CancelButton } from '../../components/CancelButton';
import { PrimaryButton } from '../../components/PrimaryButton';
import { makeStyles } from '@mui/styles';
import { useAllMeasurementDefinitions } from '../../components/hooks/UseAllMeasurementDefinitions';
import AddCircleRoundedIcon from '@mui/icons-material/AddCircleRounded';
import RemoveCircleRoundedIcon from '@mui/icons-material/RemoveCircleRounded';
import { DragDropContext, Draggable, Droppable, DropResult } from 'react-beautiful-dnd';
import { LoadingState } from '../../components/LoadingStateUtil';
import { LoadingIndicator } from '../../components/LoadingIndicator';

const useStyles = makeStyles({
  draggingListItem: {
    background: 'rgb(235, 235, 235)',
  },
});

interface AvailableMeasurementDefinition {
  selected: boolean;
  measurementDefinition: MeasurementDefinition;
}

interface SelectedMeasurementDefinition {
  index: number;
  isTargetStatistic: boolean;
  measurementDefinition: MeasurementDefinition;
}

interface SelectMeasurementsModalProps {
  open: boolean;
  onClose: (selectedMeasurementDefinitions: LinkedMeasurementDefinition[] | null) => void;
  initialLinkedMeasurementDefinitions: LinkedMeasurementDefinition[];
}

export const SelectMeasurementsModal = ({
  open,
  onClose,
  initialLinkedMeasurementDefinitions,
}: SelectMeasurementsModalProps) => {
  const classes = useStyles();

  const [measurementDefinitionsLoadingState, setMeasurementDefinitionsLoadingState] = useState<LoadingState>({
    status: 'NotStarted',
  });
  const [allMeasurementDefinitions] = useAllMeasurementDefinitions(setMeasurementDefinitionsLoadingState);

  const [availableMeasurementDefinitions, setAvailableMeasurementDefinitions] = useState<
    ReadonlyArray<AvailableMeasurementDefinition>
  >([]);
  useEffect(() => {
    const selectedIds = new Set(
      initialLinkedMeasurementDefinitions.map(i => i.measurementDefinition.measurementDefinitionId)
    );
    const available = allMeasurementDefinitions.map(md => ({
      selected: selectedIds.has(md.measurementDefinitionId),
      measurementDefinition: md,
    }));
    available.sort((a, b) => a.measurementDefinition.shortName.localeCompare(b.measurementDefinition.shortName));
    setAvailableMeasurementDefinitions(available);
  }, [allMeasurementDefinitions, initialLinkedMeasurementDefinitions]);

  const [selectedMeasurementDefinitions, setSelectedMeasurementDefinitions] = useState<
    ReadonlyArray<SelectedMeasurementDefinition>
  >([]);
  useEffect(() => {
    const selected = initialLinkedMeasurementDefinitions.map((i, index) => ({
      index,
      isTargetStatistic: i.isTargetStatistic,
      measurementDefinition: i.measurementDefinition,
    }));
    setSelectedMeasurementDefinitions(selected);
  }, [initialLinkedMeasurementDefinitions]);

  const handleSelectMeasurement = async (toSelect: AvailableMeasurementDefinition) => {
    const newAvailable = availableMeasurementDefinitions.map(i => {
      if (i.measurementDefinition.measurementDefinitionId === toSelect.measurementDefinition.measurementDefinitionId) {
        return {
          ...i,
          selected: true,
        };
      } else {
        return i;
      }
    });

    setAvailableMeasurementDefinitions(newAvailable);

    const newIndex = selectedMeasurementDefinitions.length;
    const newSelectedItem: SelectedMeasurementDefinition = {
      index: newIndex,
      isTargetStatistic: selectedMeasurementDefinitions.length === 0,
      measurementDefinition: toSelect.measurementDefinition,
    };
    setSelectedMeasurementDefinitions([...selectedMeasurementDefinitions, newSelectedItem]);
  };

  const handleUnselectMeasurement = async (toUnselect: SelectedMeasurementDefinition) => {
    const newAvailable = availableMeasurementDefinitions.map(i => {
      if (
        i.measurementDefinition.measurementDefinitionId === toUnselect.measurementDefinition.measurementDefinitionId
      ) {
        return {
          ...i,
          selected: false,
        };
      } else {
        return i;
      }
    });
    setAvailableMeasurementDefinitions(newAvailable);

    const newSelected = selectedMeasurementDefinitions.filter(
      i => i.measurementDefinition.measurementDefinitionId !== toUnselect.measurementDefinition.measurementDefinitionId
    );
    if (toUnselect.isTargetStatistic && newSelected.length > 0) {
      newSelected[0].isTargetStatistic = true;
    }

    setSelectedMeasurementDefinitions(newSelected);
  };

  const handleTargetStatisticChange = (newTargetStatistic: SelectedMeasurementDefinition) => {
    const newSelected = selectedMeasurementDefinitions.map(i => ({
      ...i,
      isTargetStatistic:
        i.measurementDefinition.measurementDefinitionId ===
        newTargetStatistic.measurementDefinition.measurementDefinitionId,
    }));

    setSelectedMeasurementDefinitions(newSelected);
  };

  const handleReorderMeasurement = ({ destination, source }: DropResult) => {
    if (!destination) {
      return;
    }

    const result = Array.from(selectedMeasurementDefinitions);
    const [removed] = result.splice(source.index, 1);
    result.splice(destination.index, 0, removed);
    for (let i = 0; i < result.length; i++) {
      result[i].index = i;
    }

    setSelectedMeasurementDefinitions(result);
  };

  const buildLinkedMeasurementDefinitions = (
    selectedMeasurementDefinitions: ReadonlyArray<SelectedMeasurementDefinition>
  ): LinkedMeasurementDefinition[] => {
    return selectedMeasurementDefinitions.map((i, index) => ({
      measurementDefinition: i.measurementDefinition,
      isTargetStatistic: i.isTargetStatistic,
      sortOrder: index,
    }));
  };

  return (
    <Dialog
      open={open}
      onClose={() => onClose(null)}
      fullWidth
      maxWidth='md'
      PaperProps={{ sx: { overflowY: 'initial' } }}
    >
      <DialogTitle>Select Measurements for Disease Area</DialogTitle>
      <DialogContent sx={{ height: '70vh', display: 'flex', flexDirection: 'column', overflowY: 'initial' }}>
        <Stack flex={1} direction='row' spacing={2} alignItems='stretch'>
          <Stack flex={1} direction='column' spacing={2} sx={{ maxHeight: '100%' }}>
            <Typography flex={0} variant='h5'>
              Available Measurements
            </Typography>
            <Box flex={1} sx={{ position: 'relative' }}>
              <List sx={{ position: 'absolute', top: 0, left: 0, bottom: 0, right: 0, overflowY: 'scroll' }}>
                {availableMeasurementDefinitions
                  .filter(i => !i.selected)
                  .map(i => (
                    <ListItem
                      key={i.measurementDefinition.measurementDefinitionId}
                      sx={{
                        userSelect: 'none',
                        '&:hover': {
                          backgroundColor: 'rgba(0, 0, 0, 0.05)',
                        },
                      }}
                    >
                      <ListItemText
                        primary={i.measurementDefinition.shortName}
                        secondary={i.measurementDefinition.longName}
                      />
                      <IconButton color='primary' onClick={() => handleSelectMeasurement(i)}>
                        <AddCircleRoundedIcon />
                      </IconButton>
                    </ListItem>
                  ))}
              </List>
            </Box>
          </Stack>

          <Stack flex={1} direction='column' spacing={2} sx={{ maxHeight: '100%' }}>
            <Typography flex={0} variant='h5'>
              Selected Measurements
            </Typography>
            <Box flex={1} sx={{ position: 'relative' }}>
              <DragDropContext onDragEnd={handleReorderMeasurement}>
                <Droppable droppableId='select-measurements-droppable'>
                  {provided => (
                    <List
                      ref={provided.innerRef}
                      {...provided.droppableProps}
                      sx={{ position: 'absolute', top: 0, left: 0, bottom: 0, right: 0, overflowY: 'scroll' }}
                    >
                      {selectedMeasurementDefinitions.map(i => (
                        <Draggable
                          key={i.measurementDefinition.measurementDefinitionId}
                          draggableId={`select-measurements-item-${i.measurementDefinition.measurementDefinitionId}`}
                          index={i.index}
                        >
                          {(provided, snapshot) => (
                            <ListItem
                              ref={provided.innerRef}
                              key={i.measurementDefinition.measurementDefinitionId}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              className={snapshot.isDragging ? classes.draggingListItem : ''}
                              sx={{
                                userSelect: 'none',
                                '&:hover': {
                                  backgroundColor: 'rgba(0, 0, 0, 0.05)',
                                },
                              }}
                            >
                              <Rating
                                value={i.isTargetStatistic ? 1 : 0}
                                max={1}
                                onChange={() => handleTargetStatisticChange(i)}
                              />
                              <ListItemText
                                primary={i.measurementDefinition.shortName}
                                secondary={i.measurementDefinition.longName}
                              />
                              <IconButton color='primary' onClick={() => handleUnselectMeasurement(i)}>
                                <RemoveCircleRoundedIcon />
                              </IconButton>
                            </ListItem>
                          )}
                        </Draggable>
                      ))}
                      {provided.placeholder}
                    </List>
                  )}
                </Droppable>
              </DragDropContext>
            </Box>
          </Stack>
        </Stack>
        <LoadingIndicator loadingState={measurementDefinitionsLoadingState} />
      </DialogContent>
      <DialogActions>
        <CancelButton onClick={() => onClose(null)} />
        <PrimaryButton onClick={() => onClose(buildLinkedMeasurementDefinitions(selectedMeasurementDefinitions))}>
          Confirm
        </PrimaryButton>
      </DialogActions>
    </Dialog>
  );
};
