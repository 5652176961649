import { StateCreator } from 'zustand';

export type ShowDataWithIssues = 'all' | 'noIssues' | 'onlyIssues';

export interface ShowDataWithIssuesSlice {
  showDataWithIssues: ShowDataWithIssues;
  setShowDataWithIssues: (showDataWithIssues: ShowDataWithIssues) => void;
}

export const createShowDataWithIssuesSlice: StateCreator<ShowDataWithIssuesSlice> = set => ({
  showDataWithIssues: 'all',
  setShowDataWithIssues: showDataWithIssues => set({ showDataWithIssues: showDataWithIssues }),
});
