import { appSettings } from '../AppSettings';
import { Sample } from './SampleData';
import { BaseGet, BasePost, BuildArrayQueryParam, BuildArrayQueryParams } from './BaseData';
import { uniq } from 'lodash';
import { Transition, TransitionSampleDetailsDto } from './SampleTrackingTransitionData';
import { LabAssignedSampleLabel, TransitionSample } from './SampleTrackingData';
import { SupplementalFile } from './SupplementalFileData';
import { InformaticsPipelineResult } from './InformaticsCheckByData';
import { InformaticsComputedValues } from './InformaticsData';

export interface SampleJourneyHierarchy {
  root: Sample;
  hierarchy: ReadonlyArray<SampleTransformationDescendant>;
}

export interface SampleTransformationDescendant {
  ancestor: Sample;
  descendant: Sample;
  depth: number;
}

export interface SampleJourneyTree {
  ancestor: Sample;
  descendant: ReadonlyArray<SampleJourneyTree>;
  depth: number;
  path: string[];
  isLeaf: boolean;
}

export interface SampleJourneyTransitionInformation {
  transitionCreatedIn?: Transition;
  transitionSampleCreatedIn?: TransitionSample;
  labAssignedSampleLabelCreatedIn?: LabAssignedSampleLabel;
  currentTransition?: Transition;
  currentTransitionSample?: TransitionSample;
  currentLabAssignedSampleLabel?: LabAssignedSampleLabel;
  sampleId: string;
  sample: Sample;
  sampleJourneyId: string;
}

export interface SampleSequencingJourneyDetails {
  fastQs?: SequencingFastQ[];
  fastqLocation?: string;
  informaticsInstrumentId?: string;
  informaticsLibraryTypeId?: string;
  libraryKitId?: string;
  libraryKitName?: string;
  sampleId: string;
  sequenceRunId?: string;
  informaticsPipelineRunId?: string;
  sequenceType?: string;
  sequencingLabId?: string;
  sequencingLabName?: string;
  sequencingPlatformId?: string;
  sequencingPlatformName?: string;
  sampleJourneyId: string;
}

export interface SampleJourneyInformaticsResultDetails {
  sampleId: string;
  details: ReadonlyArray<SampleJourneyInformaticsResultDetailsEntry>;
}

export interface InformaticsPipelineRun {
  informaticsPipelineRunId: string;
  callbackId: string;
  sampleId: string;
  informaticsOrderId: string;
  sequenceId?: string;
  sequenceRunId?: string;
  tissueTypeId: string;
  sequencingLabId: string;
  sequencingPlatformId: string;
  completedAt?: Date;
  errorMessage?: string;
  createdAt: Date;
  updatedAt: Date;
  pipelineId: string;
}

export interface SampleJourneyInformaticsResultDetailsEntry {
  informaticsPipelineResult: InformaticsPipelineResult;
  computedValues: InformaticsComputedValues;
  informaticsPipelineRun: InformaticsPipelineRun;
}

export interface SequencingFastQ {
  file: SupplementalFile;
  type: { name: 'r1' | 'r2' };
}

interface SampleJourneyIdDto {
  sampleJourneyId: string;
  sampleId: string;
}

export async function GetHierarchyForSampleId(
  sampleId: string,
  accessToken: string,
  decrypt: boolean = false
): Promise<ReadonlyArray<SampleJourneyHierarchy>> {
  return await BaseGet(
    `${appSettings.api.endpoint}/api/v2/SampleJourney/Hierarchy/${sampleId}/?decrypt=${decrypt}`,
    accessToken
  );
}

export async function GetSampleJourneyTransitionInformation(
  sampleIds: ReadonlyArray<string>,
  decrypt: boolean = false,
  accessToken?: string
): Promise<ReadonlyArray<SampleJourneyTransitionInformation>> {
  return await BasePost(
    `${appSettings.api.endpoint}/api/v2/SampleJourney/SampleTransformation/SampleTransitionInformation?decryptForeignHashes=${decrypt}`,
    sampleIds,
    accessToken
  );
}
export async function GetAllTransitionAndTransitionSamples(
  sampleIds: ReadonlyArray<string>,
  accessToken?: string
): Promise<ReadonlyArray<TransitionSampleDetailsDto>> {
  return await BaseGet(
    `${appSettings.api.endpoint}/api/v2/SampleJourney/TransitionSamples?${BuildArrayQueryParams(
      sampleIds,
      'sampleIds'
    )}`,
    accessToken
  );
}

export async function GetSampleSequencingJourneyDetails(
  sampleIds: ReadonlyArray<SampleJourneyIdDto>,
  accessToken?: string
): Promise<ReadonlyArray<SampleSequencingJourneyDetails>> {
  return await BasePost(
    `${appSettings.api.endpoint}/api/v2/SampleJourney/SequencingJourneyDetails`,
    sampleIds,
    accessToken
  );
}

export async function GetInformaticsResultDetails(
  sampleId: string,
  accessToken?: string
): Promise<SampleJourneyInformaticsResultDetails> {
  return await BaseGet(
    `${appSettings.api.endpoint}/api/v2/SampleJourney/InformaticsResultDetails?${BuildArrayQueryParam(
      sampleId,
      'sampleId'
    )}`,
    accessToken
  );
}

export async function GetInformaticsResultDetailsResearchProjectId(
  researchProjectId: string,
  accessToken?: string
): Promise<ReadonlyArray<SampleJourneyInformaticsResultDetails>> {
  return await BaseGet(
    `${appSettings.api.endpoint}/api/v2/SampleJourney/InformaticsResultDetails/ResearchProjectId/${researchProjectId}`,
    accessToken
  );
}

export function ConvertToSampleJourneyTree(hierarchy: SampleJourneyHierarchy): SampleJourneyTree {
  const { root, hierarchy: transformationDescendants } = hierarchy;

  // Create a map of each sample to its descendants and depth
  const descendantsMap = new Map<string, Array<{ descendant: Sample; depth: number }>>();
  for (const { ancestor, descendant, depth } of transformationDescendants) {
    const ancestorId = ancestor.sampleId;
    if (!descendantsMap.has(ancestorId)) {
      descendantsMap.set(ancestorId, []);
    }
    descendantsMap.get(ancestorId)?.push({ descendant, depth });
  }

  // Recursively build the SampleJourneyTree
  function buildTree(sample: Sample, path: string[], depth: number): SampleJourneyTree {
    const sampleId = sample.sampleId;
    const descendants = descendantsMap.get(sampleId) ?? [];
    return {
      ancestor: sample,
      descendant: descendants.map(({ descendant }) => buildTree(descendant, [...path, sample.sampleId], depth + 1)),
      depth,
      path: [...path, sample.sampleId],
      isLeaf: descendants.length === 0,
    };
  }

  return buildTree(root, [], 0);
}

export function GetSampleSpecificBranch(
  tree: SampleJourneyTree,
  sampleIds: ReadonlyArray<string>
): SampleJourneyTree | undefined {
  if (sampleIds.includes(tree.ancestor.sampleId)) {
    return tree;
  }

  for (const subTree of tree.descendant) {
    const node = GetSampleSpecificBranch(subTree, sampleIds);
    if (node) {
      return {
        ancestor: tree.ancestor,
        descendant: [node],
        depth: tree.depth,
        path: tree.path,
        isLeaf: tree.isLeaf,
      };
    }
  }

  return undefined;
}

export function GetAllSampleIds(trees: SampleJourneyTree[]): ReadonlyArray<string> {
  function getIds(tree: SampleJourneyTree) {
    const nodes = [tree.ancestor.sampleId];

    for (const child of tree.descendant) {
      nodes.push(...getIds(child));
    }

    return nodes;
  }

  return uniq(trees.flatMap(tree => getIds(tree)));
}

export function GetAllLeafSampleIds(trees: SampleJourneyTree[]): ReadonlyArray<string> {
  function getIds(tree: SampleJourneyTree) {
    const nodes = tree?.isLeaf ? [tree.ancestor.sampleId] : [];

    for (const child of tree.descendant) {
      nodes.push(...getIds(child));
    }

    return nodes;
  }

  return uniq(trees.flatMap(tree => getIds(tree)));
}
