import { appSettings } from '../AppSettings';
import { BaseGet } from './BaseData';
import { Transition } from './SampleTrackingTransitionData';

export interface TransitionSampleCheckByGroup {
  transitionCheckByGroupId: string;
  createdAt: Date;
}

export interface CheckByGroupAndTransition {
  transitionSampleCheckByGroup: TransitionSampleCheckByGroup;
  transitionDtos: ReadonlyArray<Transition>;
}

export interface CheckByGroupCounts {
  checkByGroupId: string;
  checkByStatusCounts: ReadonlyArray<CheckByGroupStatusCounts>;
  supplementalFileCounts: number;
}

export interface CheckByGroupStatusCounts {
  checkByGroupId: string;
  checkByStatusEnumId: string;
  checkByStatus: string;
  transitionSampleCounts: number;
}

export interface Message {
  message: string;
}

export interface CheckByGroupDetailsDto {
  transitionCheckByGroupId: string;
  sampleIds: string[];
  transitionIds: string[];
}

export async function GetCheckByGroupDetails(
  checkByGroupId: string,
  accessToken?: string
): Promise<CheckByGroupDetailsDto> {
  return await BaseGet(
    `${appSettings.api.endpoint}/api/v2/SampleTrackingCheckByGroup/Details/${checkByGroupId}`,
    accessToken
  );
}

export async function GetAllTransitionWithCheckByGroupsForResearchProject(
  researchProjectId: string,
  accessToken?: string
): Promise<ReadonlyArray<CheckByGroupAndTransition>> {
  return await BaseGet(
    `${appSettings.api.endpoint}/api/v2/SampleTrackingCheckByGroup/Transition/${researchProjectId}`,
    accessToken
  );
}

export async function GetCheckByGroupCounts(
  researchProjectId: string,
  accessToken?: string
): Promise<ReadonlyArray<CheckByGroupCounts>> {
  return await BaseGet(
    `${appSettings.api.endpoint}/api/v2/SampleTrackingCheckByGroup/Counts/${researchProjectId}`,
    accessToken
  );
}

export async function GetUndoableCheckByGroupIds(
  researchProjectId: string,
  accessToken?: string
): Promise<ReadonlyArray<string>> {
  return await BaseGet(
    `${appSettings.api.endpoint}/api/v2/SampleTrackingUndoOperation/Undoable/CheckByGroup/${researchProjectId}`,
    accessToken
  );
}

export async function GetUndoableCheckByGroupReason(
  researchProjectId: string,
  checkByGroupId: string,
  accessToken?: string
): Promise<Message> {
  return await BaseGet(
    `${appSettings.api.endpoint}/api/v2/SampleTrackingUndoOperation/UndoableReason/CheckByGroup/${researchProjectId}/${checkByGroupId}`,
    accessToken
  );
}

export async function GetUndoableTransitionReason(
  researchProjectId: string,
  transitionId: string,
  accessToken?: string
): Promise<Message> {
  return await BaseGet(
    `${appSettings.api.endpoint}/api/v2/SampleTrackingUndoOperation/UndoableReason/Transition/${researchProjectId}/${transitionId}`,
    accessToken
  );
}
