import { Typography, useTheme } from '@mui/material';

export const ZeroCountGridCell = () => {
  const theme = useTheme();

  return (
    <Typography color={`${theme.colors.primary.dark}`} fontSize='inherit'>
      0
    </Typography>
  );
};
