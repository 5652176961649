import { useCallback, useMemo, useReducer } from 'react';
import useAuth from 'auth/UseAuth';
import {
  GetPipelineFilters,
  PipelineFilters,
  SdfIssueType,
  SuperDuperFiestaDataClass,
  SuperDuperFiestaDataSource,
  SuperDuperFiestaEntity,
  SuperDuperFiestaStage,
  SdfIssue,
  SdfStepType,
  SuperDuperFiestaPipeline,
} from '../../data/SuperDuperFiestaData';

export interface BaseFilterState {
  dataClasses: SuperDuperFiestaDataClass[];
  dataSources: SuperDuperFiestaDataSource[];
  entities: SuperDuperFiestaEntity[];
  stages: SuperDuperFiestaStage[];
  issueTypes: SdfIssueType[];
}

export interface ExtendedFilterState extends BaseFilterState {
  issues: SdfIssue[];
  pipelines: SuperDuperFiestaPipeline[];
  stepTypes: SdfStepType[];
}

export type FilterAction<T extends BaseFilterState> = {
  type:
    | 'update_stages'
    | 'update_entities'
    | 'update_dataClasses'
    | 'update_dataSources'
    | 'update_issueTypes'
    | 'update_pipelines'
    | 'update_stepTypes'
    | 'update_issues'
    | 'update_all'
    | 'reset';
} & (T extends ExtendedFilterState ? Partial<ExtendedFilterState> : Partial<BaseFilterState>);

export const initialFilterState: BaseFilterState = {
  stages: [],
  entities: [],
  dataClasses: [],
  dataSources: [],
  issueTypes: [],
};

export const extendedInitialFilterState: ExtendedFilterState = {
  ...initialFilterState,
  issues: [],
  pipelines: [],
  stepTypes: [],
};

function createFilterReducer<T extends BaseFilterState>(initialState: T) {
  return (state: T, action: FilterAction<T>): T => {
    const { type } = action;

    switch (type) {
      case 'update_stages':
        return { ...state, stages: action.stages ?? [] };
      case 'update_entities':
        return { ...state, entities: action.entities ?? [] };
      case 'update_dataClasses':
        return { ...state, dataClasses: action.dataClasses ?? [] };
      case 'update_dataSources':
        return { ...state, dataSources: action.dataSources ?? [] };
      case 'update_issueTypes':
        return { ...state, issueTypes: action.issueTypes ?? [] };
      case 'update_pipelines':
        return { ...state, pipelines: ('pipelines' in action ? action.pipelines : []) ?? [] } as T;
      case 'update_stepTypes':
        return { ...state, stepTypes: ('stepTypes' in action ? action.stepTypes : []) ?? [] } as T;
      case 'update_issues':
        return { ...state, issues: ('issues' in action ? action.issues : []) ?? [] } as T;
      case 'update_all':
        return { ...state, ...action };
      case 'reset':
        return { ...initialState };
      default:
        return state;
    }
  };
}

export function useFilterState<T extends BaseFilterState>(
  initialState: T,
  options: { includePipelines?: boolean; includeStepTypes?: boolean; includeIssues?: boolean } = {}
) {
  const { accessToken } = useAuth();
  const [filterState, dispatch] = useReducer(createFilterReducer(initialState), initialState);

  const queryParams = useMemo(() => {
    const shouldInclude = (key: string, option?: boolean) =>
      Boolean(
        option &&
          key in filterState &&
          Array.isArray((filterState as any)[key]) &&
          (filterState as any)[key].length === 0
      );

    return {
      includeEntities: filterState.entities.length === 0,
      includeStages: filterState.stages.length === 0,
      includeDataClasses: filterState.dataClasses.length === 0,
      includeDataSources: filterState.dataSources.length === 0,
      includeIssueTypes: filterState.issueTypes.length === 0,
      includePipelines: shouldInclude('pipelines', options.includePipelines),
      includeStepTypes: shouldInclude('stepTypes', options.includeStepTypes),
      includeIssues: shouldInclude('issues', options.includeIssues),
    };
  }, [filterState, options]);

  const initialStateKeys = useMemo(() => Object.keys(initialState), [initialState]);

  const getFilterData = useCallback(async () => {
    if (!accessToken) return;

    try {
      if (Object.values(queryParams).every(value => !value)) {
        return;
      }

      const data: PipelineFilters = await GetPipelineFilters(queryParams, accessToken);

      if (data) {
        const filteredData = Object.fromEntries(Object.entries(data).filter(([key]) => initialStateKeys.includes(key)));
        dispatch({ type: 'update_all', ...(filteredData as Partial<T>) });
      }
    } catch (error) {
      console.error('Error fetching filter data:', error);
    }
  }, [accessToken, queryParams, initialStateKeys]);

  return { filterState, dispatch, getFilterData };
}
