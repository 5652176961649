import { BaseGet } from './BaseData';
import { appSettings } from '../AppSettings';

export interface MeasurementDefinition {
  measurementDefinitionId: string;
  shortName: string;
  longName: string;
  description?: string;
  units?: string;
  isTargetStatistic: boolean;
  queryCteAlias?: string;
  queryCte?: string;
  type: MeasurementDefinitionType;
  codes: string[];
  ranges: MeasurementDefinitionRange[];
}

export interface MeasurementDefinitionType {
  measurementDefinitionTypeId: string;
  name: string;
  displayName: string;
  sourceTable: string;
}

export type MeasurementDefinitionRangeType = 'withinIsNormal' | 'aboveIsAbnormal';

export interface MeasurementDefinitionRange {
  measurementDefinitionId: string;
  rangeTypeId: string;
  rangeType: MeasurementDefinitionRangeType;
  name?: string;
  minValue: number;
  maxValue?: number;
  queryCondition?: string;
  executionOrder: number;
}

export type MeasurementNormality = 'undetermined' | 'normal' | 'abnormal';

export interface LinkedMeasurementDefinition {
  measurementDefinition: MeasurementDefinition;
  isTargetStatistic: boolean;
  sortOrder: number;
}

export interface LinkedMeasurementDefinitions {
  targetStatisticId?: string;
  measurementDefinitionIds: string[];
}

export async function GetMeasurementDefinitionTypes(
  accessToken: string | undefined
): Promise<ReadonlyArray<MeasurementDefinitionType>> {
  return await BaseGet(`${appSettings.api.endpoint}/api/v2/MeasurementDefinitions/Types`, accessToken);
}

export async function GetAllMeasurementDefinitions(
  accessToken: string | undefined
): Promise<ReadonlyArray<MeasurementDefinition>> {
  return await BaseGet(`${appSettings.api.endpoint}/api/v2/MeasurementDefinitions`, accessToken);
}

export async function GetResearchProjectMeasurementDefinitions(
  researchProjectId: string,
  accessToken: string | undefined
): Promise<ReadonlyArray<LinkedMeasurementDefinition>> {
  return await BaseGet(
    `${appSettings.api.endpoint}/api/v2/MeasurementDefinitions/ResearchProjects/${researchProjectId}`,
    accessToken
  );
}

export async function GetDiseaseAreaMeasurementDefinitions(
  diseaseAreaId: string,
  accessToken: string | undefined
): Promise<ReadonlyArray<LinkedMeasurementDefinition>> {
  return await BaseGet(
    `${appSettings.api.endpoint}/api/v2/MeasurementDefinitions/DiseaseAreas/${diseaseAreaId}`,
    accessToken
  );
}
