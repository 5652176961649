import React from 'react';
import TextField from '@mui/material/TextField';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { DialogOpenButton } from './DialogOpenButton';
import { PressableIconWrapper } from './PressableIconWrapper';
import { TextFieldProps } from '@mui/material/TextField/TextField';

const CopyTextField = (props: TextFieldProps) => {
  return (
    <>
      <TextField {...props} />
      <DialogOpenButton
        onClick={async () => {
          await navigator.clipboard.writeText(props.value as string);
        }}
        sx={{ minWidth: 0, marginRight: 2, marginLeft: 1, marginTop: 0.5, padding: 'inherit' }}
      >
        <PressableIconWrapper>
          <ContentCopyIcon />
        </PressableIconWrapper>
      </DialogOpenButton>
    </>
  );
};

export default CopyTextField;
