import useAuth from '../../auth/UseAuth';
import { useEffect, useState } from 'react';
import { orderBy } from 'lodash';
import { GetReferences, InformaticsReference } from '../../data/InformaticsData';

const useInformaticsReferences = (): ReadonlyArray<InformaticsReference> => {
  const { accessToken } = useAuth();
  const [informaticsReferences, setInformaticsReferences] = useState<ReadonlyArray<InformaticsReference>>([]);

  useEffect(() => {
    let isCancelled = false;

    GetReferences(accessToken).then(data => {
      if (isCancelled) {
        return;
      }

      setInformaticsReferences(orderBy(data, i => i.name));
    });

    return () => {
      isCancelled = true;
    };
  }, [accessToken]);

  return informaticsReferences;
};

export default useInformaticsReferences;
