import React, { cloneElement, useEffect } from 'react';
import { ButtonProps, Grow, Paper, Popper, useTheme } from '@mui/material';

interface WithPopperProps {
  text: string;
  timeoutMs?: number;
  element: React.ReactElement<{ onClick: ButtonProps['onClick'] }> | null;
}

export const WithPopper: React.FC<WithPopperProps> = ({ text, timeoutMs, element }) => {
  const theme = useTheme();

  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
  const [popperOpen, setPopperOpen] = React.useState<boolean>(false);
  useEffect(() => {
    if (!popperOpen) {
      return;
    }

    const timeout = setTimeout(() => {
      setPopperOpen(false);
    }, timeoutMs ?? 2000);

    return () => clearTimeout(timeout);
  }, [popperOpen, timeoutMs]);

  const onClickHandler: ButtonProps['onClick'] = e => {
    setAnchorEl(e.currentTarget);
    setPopperOpen(true);

    if (element?.props.onClick) {
      element.props.onClick(e);
    }
  };

  return (
    <>
      {element != null &&
        cloneElement(element, {
          onClick: onClickHandler,
        })}

      <Popper open={popperOpen} anchorEl={anchorEl} placement='top' transition>
        {({ TransitionProps }) => (
          <Grow
            {...TransitionProps}
            timeout={popperOpen ? theme.transitions.duration.enteringScreen : theme.transitions.duration.leavingScreen}
          >
            <Paper
              sx={{
                backgroundColor: theme.palette.success.main,
                color: theme.palette.success.contrastText,
                paddingX: theme.spacing(2),
                paddingY: theme.spacing(1),
                marginBottom: theme.spacing(1),
              }}
            >
              {text}
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
};
