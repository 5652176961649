import { appSettings } from 'AppSettings';
import { orderBy } from 'lodash';
import { RefreshStatus } from './RefreshStatusData';
import { BaseGet, BasePostVoid } from './BaseData';
import { DiseaseArea } from './DiseaseAreaData';

export interface DiseaseAreaMetrics {
  diseaseArea: DiseaseArea;
  patientCount: DiseaseAreaCount;
  patientCountsByBiobank: DiseaseAreaCountByBiobank[];
  patientCountsBySampleType: DiseaseAreaCountBySampleType[];
  refreshedAt?: Date;
}

export interface DiseaseAreaCount {
  diseaseAreaId: string;
  patientCount: number;
  pendingRefresh: boolean;
}

export interface DiseaseAreaCountByBiobank extends DiseaseAreaCount {
  biobankId: string;
  biobank: string;
}

export interface DiseaseAreaCountBySampleType extends DiseaseAreaCount {
  sampleTypeId: string;
  sampleType: string;
}

export async function GetDiseaseAreaPatientCounts(accessToken?: string): Promise<DiseaseAreaCount[]> {
  const counts = await BaseGet(`${appSettings.api.endpoint}/api/v2/DiseaseAreaMetrics/PatientCounts`, accessToken);
  return [...orderBy(counts, ['diseaseAreaId'], ['asc'])];
}

export async function GetDiseaseAreaPatientCountsByBiobank(accessToken?: string): Promise<DiseaseAreaCountByBiobank[]> {
  const counts = await BaseGet(
    `${appSettings.api.endpoint}/api/v2/DiseaseAreaMetrics/PatientCounts/ByBiobank`,
    accessToken
  );
  return [...orderBy(counts, ['diseaseAreaId', 'biobankId'], ['asc', 'asc'])];
}

export async function GetDiseaseAreaPatientCountsBySampleType(
  accessToken?: string
): Promise<DiseaseAreaCountBySampleType[]> {
  const counts = await BaseGet(
    `${appSettings.api.endpoint}/api/v2/DiseaseAreaMetrics/PatientCounts/BySampleType`,
    accessToken
  );
  return [...orderBy(counts, ['diseaseAreaId', 'sampleTypeId'], ['asc', 'asc'])];
}

export async function RefreshDiseaseAreaMetrics(accessToken?: string): Promise<void> {
  await BasePostVoid(`${appSettings.api.endpoint}/api/v2/DiseaseAreaMetrics/Refresh`, undefined, accessToken);
}

export async function GetDiseaseAreaRefreshStatus(accessToken?: string): Promise<RefreshStatus | undefined> {
  return await BaseGet(`${appSettings.api.endpoint}/api/v2/DiseaseAreaMetrics/Refresh/Status`, accessToken);
}
