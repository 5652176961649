import { GridRenderCellParams } from '@mui/x-data-grid-pro';
import DoneIcon from '@mui/icons-material/Done';
import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty';

export function renderGridCellInformaticsRunningIndicator(params: GridRenderCellParams) {
  if (params.row.sampleId === undefined) {
    return <></>;
  }

  return <GridGridCellInformaticsRunningIndicator sequenceRunId={params.value} />;
}

interface GridGridCellInformaticsRunningIndicatorProps {
  sequenceRunId?: string;
}

const GridGridCellInformaticsRunningIndicator = ({ sequenceRunId }: GridGridCellInformaticsRunningIndicatorProps) => {
  return (
    <>
      {!!sequenceRunId && <DoneIcon />}
      {!sequenceRunId && <HourglassEmptyIcon />}
    </>
  );
};
