import React, { useEffect, useState } from 'react';
import { Box, IconButton } from '@mui/material';
import { AuthorizedSection, AuthorizedSectionProps } from '../../../auth/AuthorizedSection';
import { useUserPermissions } from '../../../auth/UseUserPermissions';

export interface GridCellWithIconButtonProps {
  icon: React.ReactNode;
  buttonPermissions?: (keyof ReturnType<typeof useUserPermissions>)[];
  onButtonClick: () => unknown;
}

const GridCellWithIconButton: React.FC<GridCellWithIconButtonProps> = ({
  icon,
  buttonPermissions,
  onButtonClick,
  children,
}) => {
  const [authorizedSectionProps, setAuthorizedSectionProps] = useState<AuthorizedSectionProps>();
  useEffect(() => {
    const newAuthorizedSectionProps: AuthorizedSectionProps = {};
    buttonPermissions?.forEach(permission => {
      newAuthorizedSectionProps[permission] = true;
    });
    setAuthorizedSectionProps(newAuthorizedSectionProps);
  }, [buttonPermissions]);

  return (
    <Box display='flex' justifyContent='space-between' width='100%'>
      <Box
        sx={{
          flex: 1,
        }}
      >
        {children}
      </Box>
      <AuthorizedSection {...authorizedSectionProps}>
        <IconButton
          onClick={onButtonClick}
          sx={[
            {
              flex: 0,
              fontWeight: 'inherit',
              paddingTop: 0,
              paddingBottom: 0,
              '&:hover': {
                backgroundColor: 'transparent',
              },
            },
          ]}
        >
          {icon}
        </IconButton>
      </AuthorizedSection>
    </Box>
  );
};

export default GridCellWithIconButton;
