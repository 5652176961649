import BackupOutlinedIcon from '@mui/icons-material/BackupOutlined';
import CandlestickChartOutlinedIcon from '@mui/icons-material/CandlestickChartOutlined';
import CarpenterOutlinedIcon from '@mui/icons-material/CarpenterOutlined';
import ColorizeOutlinedIcon from '@mui/icons-material/ColorizeOutlined';
import FolderOutlinedIcon from '@mui/icons-material/FolderOutlined';
import Inventory2OutlinedIcon from '@mui/icons-material/Inventory2Outlined';
import InventoryOutlinedIcon from '@mui/icons-material/InventoryOutlined';
import LocalShippingOutlinedIcon from '@mui/icons-material/LocalShippingOutlined';
import ReceiptLongOutlinedIcon from '@mui/icons-material/ReceiptLongOutlined';
import SavingsOutlinedIcon from '@mui/icons-material/SavingsOutlined';
import BiotechOutlinedIcon from '@mui/icons-material/BiotechOutlined';
import ChangeCircleOutlinedIcon from '@mui/icons-material/ChangeCircleOutlined';
import EarbudsOutlinedIcon from '@mui/icons-material/EarbudsOutlined';
import { SxProps } from '@mui/system';
import { Theme } from '@mui/material/styles';
import { TransitionTypeExtended } from '../researchProjectManagement/sampleTracking/SampleTrackingGrid';
import { Typography } from '@mui/material';
import { TypographyProps } from '@mui/material/Typography/Typography';
import { LightTooltip } from '../components/grid/cell/GridCellTooltip';
import DraftsOutlinedIcon from '@mui/icons-material/DraftsOutlined';
import FunctionsOutlinedIcon from '@mui/icons-material/FunctionsOutlined';
import React from 'react';

export type TransitionIconProps = {
  transitionType: TransitionTypeExtended;
  text?: string;
  color?: 'primary' | undefined;
} & TypographyProps;

export interface TransitionTableHeaderIconProps {
  transitionType: TransitionTypeExtended;
  header: string;
}
export interface TransitionIconWithTooltipProps {
  transitionType: TransitionTypeExtended;
  children: React.ReactNode;
  tooltip: string;
}

export interface TransitionToolBarProps {
  transitionType: TransitionTypeExtended;
}

export const TransitionIcon = (props: TransitionIconProps) => {
  // Removes unwanted properties from type preventing them from showing up in the DOM causing errors
  const { transitionType, text, color, ...typographyProps } = props;

  return (
    <>
      {getIcon({ transitionType: props.transitionType, color: props.color })}
      {props.text && (
        <Typography sx={{ ...{ marginLeft: 0 }, ...props.sx }} {...typographyProps}>
          {props.text}
        </Typography>
      )}
    </>
  );
};
export const TransitionToolBar = (props: TransitionToolBarProps) => {
  return (
    <>
      {getIcon({
        transitionType: props.transitionType,
        color: 'primary',
        sx: { fontSize: 40, marginRight: 2 },
      })}
    </>
  );
};

export const TransitionTableHeaderIcon = ({ transitionType, header }: TransitionTableHeaderIconProps) => {
  return (
    <>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        {getIcon({ transitionType: transitionType, sx: { fontSize: '18px' } })}
        <div className={'MuiDataGrid-columnHeaderTitle'} style={{ fontWeight: 500, marginLeft: '5px' }}>
          {header}
        </div>
      </div>
    </>
  );
};

export const TransitionIconWithTooltip = ({ transitionType, children, tooltip }: TransitionIconWithTooltipProps) => {
  return (
    <>
      <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
        <LightTooltip title={tooltip}>
          {getIcon({ transitionType: transitionType, sx: { fontSize: '18px' } })}
        </LightTooltip>
        <div style={{ fontWeight: 500, marginLeft: '5px', width: '100%' }}>{children}</div>
      </div>
    </>
  );
};

interface Props {
  transitionType: TransitionTypeExtended;
  color?: 'primary' | undefined;
  sx?: SxProps<Theme>;
}

function getIcon({ transitionType, color, sx }: Props) {
  switch (transitionType) {
    case 'Selected':
      return <InventoryOutlinedIcon sx={sx} color={color} />;

    case 'Shipping':
      return <LocalShippingOutlinedIcon sx={sx} color={color} />;

    case 'Receiving':
      return <ReceiptLongOutlinedIcon sx={sx} color={color} />;

    case 'Subsetting':
      return <CarpenterOutlinedIcon sx={sx} color={color} />;

    case 'Extraction':
      return <ColorizeOutlinedIcon sx={sx} color={color} />;

    case 'LibraryPrep':
      return <CandlestickChartOutlinedIcon sx={sx} color={color} />;

    case 'Sequencing':
      return <BiotechOutlinedIcon sx={sx} color={color} />;

    case 'Informatics':
      return <BackupOutlinedIcon sx={sx} color={color} />;

    case 'ReBiobanking':
      return <FolderOutlinedIcon sx={sx} color={color} />;

    case 'ReadyForDelivery':
      return <Inventory2OutlinedIcon sx={sx} color={color} />;

    case 'Delivered':
      return <SavingsOutlinedIcon sx={sx} color={color} />;

    case 'Generic':
      return <EarbudsOutlinedIcon sx={sx} color={color} />;

    case 'GenericWithSampleOutput':
      return <ChangeCircleOutlinedIcon sx={sx} color={color} />;

    case 'Quantification':
      return <FunctionsOutlinedIcon sx={sx} color={color} />;

    default:
      return <DraftsOutlinedIcon sx={sx} color={color} />;
  }
}
