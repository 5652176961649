import useAuth from '../../auth/UseAuth';
import { useEffect, useState } from 'react';
import { orderBy } from 'lodash';
import { GetPipelines, InformaticsPipeline } from '../../data/InformaticsData';

const useInformaticsPipelines = (): ReadonlyArray<InformaticsPipeline> => {
  const { accessToken } = useAuth();
  const [informaticsPipelines, setInformaticsPipelines] = useState<ReadonlyArray<InformaticsPipeline>>([]);

  useEffect(() => {
    let isCancelled = false;

    GetPipelines(accessToken).then(data => {
      if (isCancelled) {
        return;
      }

      setInformaticsPipelines(orderBy(data, i => i.name));
    });

    return () => {
      isCancelled = true;
    };
  }, [accessToken]);

  return informaticsPipelines;
};

export default useInformaticsPipelines;
