import React from 'react';
import { Box, IconButton, styled, TextField, useTheme } from '@mui/material';
import { ContentCopyOutlined } from '@mui/icons-material';
import { t } from 'i18next';
import { WithPopper } from '../common/WithPopper';
import { LoadingState } from '../../components/LoadingStateUtil';
import { LoadingIndicator } from '../../components/LoadingIndicator';
import { ErrorIndicator } from '../../components/ErrorIndicator';

interface SqlTextFieldProps {
  loadingState: LoadingState;
  sql: string | null;
}

const FullHeightTextField = styled(TextField)(({ theme }) => ({
  '& .MuiInputBase-root': {
    height: '100% !important',
  },

  '& .MuiInputBase-input': {
    height: '100% !important',
    overflowY: 'auto !important',
    fontFamily: 'monospace',
    tabSize: 4,
  },
}));

export const SqlTextField: React.FC<SqlTextFieldProps> = ({ loadingState, sql }) => {
  const theme = useTheme();

  return (
    <Box sx={{ flex: 1, position: 'relative' }}>
      <FullHeightTextField
        multiline
        fullWidth
        value={sql ?? ''}
        placeholder={loadingState.status === 'Loading' ? t('loading...') : t('naNoDatasetsLoaded')}
        disabled
        sx={{ height: '100%' }}
      />

      <WithPopper
        text={t('copied')}
        element={
          <IconButton
            size='small'
            disabled={loadingState.status !== 'Complete' || sql === null}
            onClick={() => {
              navigator.clipboard.writeText(sql || '').catch(e => console.error(e));
            }}
            sx={{ position: 'absolute', top: theme.spacing(0.5), right: theme.spacing(4) }}
          >
            <ContentCopyOutlined />
          </IconButton>
        }
      />

      <LoadingIndicator loadingState={loadingState} />
      <ErrorIndicator loadingState={loadingState} />
    </Box>
  );
};
