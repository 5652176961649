import React from 'react';
import { GridColDef } from '@mui/x-data-grid-pro';
import { Link } from 'react-router-dom';
import { GridRenderCellParams } from '@mui/x-data-grid/models/params/gridCellParams';
import { CompactGridWrapper } from '../../components/grid/CompactGridWrapper';
import { DataSchema } from '../../data/DataSchemaData';
import { t } from 'i18next';
import { LoadingState } from '../../components/LoadingStateUtil';

interface DataSchemasTableProps {
  loadingState: LoadingState;
  dataSchemas: DataSchema[];
}

export const DataSchemasTable: React.FC<DataSchemasTableProps> = ({ loadingState, dataSchemas }) => {
  const getRowId = (row: DataSchema) => row.dataSchemaId;

  const columns: GridColDef<DataSchema>[] = [
    {
      field: 'name',
      headerName: t('name'),
      type: 'string',
      flex: 1,
      renderCell: DataSchemaName,
    },
  ];

  return <CompactGridWrapper loadingState={loadingState} columns={columns} rows={dataSchemas} getRowId={getRowId} />;
};

const DataSchemaName: React.FC<GridRenderCellParams<DataSchema>> = params => {
  return <Link to={`/data-products/schemas/${params.row.dataSchemaId}`}>{params.value}</Link>;
};
