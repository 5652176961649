import { RefObject, useEffect, useRef } from 'react';

export type SelectionDirection = 'left' | 'right';

export interface Selection {
  startX: number;
  direction: SelectionDirection;
}

// Returns the state of the current horizontal selection anywhere on the page, if there is one. This returns a ref
// rather than the selection itself because internally it needs to use a ref to be anywhere close to performant.
// Returning that ref itself to the caller instead of the current value will let the caller know that the selection
// should not be used to control rendering directly, which is disallowed for refs.
const useHorizontalSelectionRef = (): RefObject<Selection | null> => {
  const mouseIsClickedRef = useRef(false);
  const mouseClickedXRef = useRef(0);

  const selectionRef = useRef<Selection | null>(null);

  useEffect(() => {
    const leftClick = 0;

    const onMouseDown = (e: MouseEvent) => {
      if (e.button === leftClick) {
        mouseIsClickedRef.current = true;
        mouseClickedXRef.current = e.clientX;
      }
    };

    const onMouseUp = (e: MouseEvent) => {
      if (e.button === leftClick) {
        mouseIsClickedRef.current = false;
        selectionRef.current = null;
      }
    };

    const onMouseMove = (e: MouseEvent) => {
      if (mouseIsClickedRef.current) {
        const curMouseX = e.clientX;
        if (curMouseX !== mouseClickedXRef.current) {
          selectionRef.current = {
            startX: mouseClickedXRef.current,
            direction: curMouseX > mouseClickedXRef.current ? 'right' : 'left',
          };
        }
      }
    };

    document.addEventListener('mousedown', onMouseDown);
    document.addEventListener('mouseup', onMouseUp);
    document.addEventListener('mousemove', onMouseMove);

    return () => {
      document.removeEventListener('mousedown', onMouseDown);
      document.removeEventListener('mouseup', onMouseUp);
      document.removeEventListener('mousemove', onMouseMove);
    };
  }, [mouseIsClickedRef, mouseClickedXRef, selectionRef]);

  return selectionRef;
};

export default useHorizontalSelectionRef;
