import {
  Autocomplete,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TableHead,
  TextField,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { CancelButton } from '../../components/CancelButton';
import { PrimaryButton } from '../../components/PrimaryButton';
import { DialogOpenButton } from '../../components/DialogOpenButton';
import useMemoTranslation from '../../hooks/UseMemoTranslation';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import { SampleTrackingCheckByFastqValidationResultPayload, SequenceRun } from '../../data/SampleTrackingData';
import { FlexBox } from '../../components/FlexBox';
import { GroupHeader } from '../../components/GroupHeader';
import { GroupItems } from '../../components/GroupItems';
import { get, orderBy, set } from 'lodash';
import { FastQUpdateFunction } from './SampleTrackingIdMappingConfirmationGrid';
import { UseState } from '../../util/TypeUtil';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import { DialogCloseButton } from '../../components/DialogCloseButton';
import { FastQValidationState } from '../../components/grid/cell/SampleTrackingFastQResolutionGridCell';
import { LightTooltip } from '../../components/grid/cell/GridCellTooltip';
import {
  instrumentName,
  opaqueIdentifier,
  r1FastQLocation,
  r2FastQLocation,
  sequencingLabName,
  tissueTypeName,
} from '../../util/Constants';
import FullscreenOutlinedIcon from '@mui/icons-material/FullscreenOutlined';

type Row = {
  id: string;
  outputId: string;
  r1FastQLocation: string;
  r2FastQLocation: string;
  r1FastQLocationRecommendation: string;
  r2FastQLocationRecommendation: string;
  existingSequenceRun: SequenceRun;
};

type State = { r1FastQLocation: string; r2FastQLocation: string };

export interface SampleTrackingFastQResolutionModalProps {
  isValid: boolean;
  isR1Valid: boolean;
  isR2Valid: boolean;
  row: Row;
  fastQValidationData: SampleTrackingCheckByFastqValidationResultPayload;
  onFastQUpdate: FastQUpdateFunction;
  fileValidationStates: [FastQValidationState, FastQValidationState];
  value: string;
}

export const SampleTrackingFastQResolutionModal = ({
  isValid,
  row,
  fastQValidationData,
  isR1Valid,
  isR2Valid,
  onFastQUpdate,
  fileValidationStates,
  value,
}: SampleTrackingFastQResolutionModalProps) => {
  return (
    <>
      {row.existingSequenceRun === undefined ? (
        <FastQResolver
          {...{
            isValid,
            row,
            fastQValidationData,
            isR1Valid,
            isR2Valid,
            onFastQUpdate,
            fileValidationStates,
            value,
          }}
        />
      ) : (
        <ExistingSequenceRunInfo {...{ row }} />
      )}
    </>
  );
};

const ExistingSequenceRunInfo = React.memo(
  ({ row }: { row: Row }) => {
    const { t } = useMemoTranslation();

    const [open, setOpen] = useState<boolean>(false);
    const [existingSequence, setExistingSequence] = useState<SequenceRun>();

    function handleClose() {
      setOpen(false);
    }

    useEffect(() => {
      if (!row.existingSequenceRun) {
        return;
      }

      setExistingSequence(row.existingSequenceRun);
    }, [row]);

    return (
      <>
        {row.existingSequenceRun && (
          <>
            <DialogOpenButton
              onClick={() => setOpen(true)}
              sx={{ margin: 0, padding: 0, minWidth: 0, width: 0, marginRight: 2, marginLeft: 2 }}
            >
              <FullscreenOutlinedIcon />
            </DialogOpenButton>
            {t('existingSequenceRunFound')}
            <Dialog open={open} onClose={handleClose} maxWidth={'lg'}>
              <DialogTitle>
                <DialogCloseButton onClick={() => handleClose()} />
                {row.outputId}
                <Typography sx={{ whiteSpace: 'pre-line', wordWrap: 'wrap' }}>
                  {t('existingSequenceRunInfo')}
                </Typography>
              </DialogTitle>
              <DialogContent sx={{ pb: 0, mb: 0 }}>
                <Table sx={{ minWidth: 650 }} aria-label='simple table'>
                  <TableHead>
                    <TableRow>
                      <TableCell>Field</TableCell>
                      <TableCell>Value</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {[
                      [t(opaqueIdentifier), existingSequence?.opaqueIdentifier],
                      [t(r1FastQLocation), existingSequence?.fastqR1Path],
                      [t(r2FastQLocation), existingSequence?.fastqR2Path],
                      [t(sequencingLabName), existingSequence?.sequencingLabName],
                      [t(instrumentName), existingSequence?.instrumentName],
                      [t(tissueTypeName), existingSequence?.tissueTypeName],
                    ].map(i => {
                      return (
                        <TableRow>
                          <TableCell sx={{ fontWeight: '500' }}>{i[0]}</TableCell>
                          <TableCell>{i[1]}</TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </DialogContent>
              <DialogActions>
                <CancelButton onClick={handleClose} />
              </DialogActions>
            </Dialog>
          </>
        )}
      </>
    );
  },
  (prevProps, nextProps) => {
    return prevProps.row.existingSequenceRun === nextProps.row.existingSequenceRun;
  }
);

const FastQResolver = ({
  isValid,
  row,
  fastQValidationData,
  isR1Valid,
  isR2Valid,
  onFastQUpdate,
  fileValidationStates,
  value,
}: {
  isValid: boolean;
  isR1Valid: boolean;
  isR2Valid: boolean;
  row: Row;
  fastQValidationData: SampleTrackingCheckByFastqValidationResultPayload;
  onFastQUpdate: FastQUpdateFunction;
  fileValidationStates: [FastQValidationState, FastQValidationState];
  value: string;
}) => {
  const [open, setOpen] = useState<boolean>(false);
  const [r1ValidationState, r2ValidationState] = fileValidationStates;

  const { t } = useMemoTranslation();

  const [updatedData, setUpdatedData] = useState<State>({
    r1FastQLocation: '',
    r2FastQLocation: '',
  });

  useEffect(() => {
    if (!open) {
      return;
    }

    setUpdatedData({
      r1FastQLocation: row.r1FastQLocation,
      r2FastQLocation: row.r2FastQLocation,
    });
  }, [row, open]);

  function handleClose() {
    setOpen(false);
  }
  function handleSubmit() {
    onFastQUpdate(row.outputId, updatedData.r1FastQLocation, updatedData.r2FastQLocation);
    setOpen(false);
  }

  return (
    <>
      <DialogOpenButton
        onClick={() => setOpen(true)}
        sx={{ margin: 0, padding: 0, minWidth: 0, width: 0, marginRight: 1 }}
      >
        <LightTooltip title={`R1: ${t(r1ValidationState)}\nR2: ${t(r2ValidationState)}`}>
          {isValid ? (
            <CheckCircleOutlineOutlinedIcon color={'success'} />
          ) : (
            <ErrorOutlineOutlinedIcon color={'error'} />
          )}
        </LightTooltip>
      </DialogOpenButton>
      {value}
      <Dialog open={open} onClose={handleClose} maxWidth={'lg'}>
        <DialogTitle>
          <DialogCloseButton onClick={() => handleClose()} />
          {t('resolveFastQs')}
          <Typography sx={{ whiteSpace: 'pre-line' }}>{row.outputId}</Typography>
        </DialogTitle>
        <DialogContent sx={{ pb: 0, mb: 0 }}>
          <FlexBox flexDirection={'column'}>
            <Controls
              {...{ row, fastQValidationData }}
              fastQLocationKey={'r1FastQLocation'}
              fastqLocationRecommendationKey={'r1FastQLocationRecommendation'}
              updateDataProps={[updatedData, setUpdatedData]}
              isValid={isR1Valid}
              fileValidationState={r1ValidationState}
              title={t('r1FastQLocation')}
            />
            <Controls
              {...{ row, fastQValidationData }}
              fastQLocationKey={'r2FastQLocation'}
              fastqLocationRecommendationKey={'r2FastQLocationRecommendation'}
              updateDataProps={[updatedData, setUpdatedData]}
              isValid={isR2Valid}
              fileValidationState={r2ValidationState}
              title={t('r2FastQLocation')}
            />
          </FlexBox>
        </DialogContent>
        <DialogActions>
          <CancelButton onClick={handleClose} />
          <PrimaryButton onClick={handleSubmit}>{t('update')}</PrimaryButton>
        </DialogActions>
      </Dialog>
    </>
  );
};

const Controls = ({
  fastQLocationKey,
  fastqLocationRecommendationKey,
  isValid,
  row,
  fastQValidationData,
  updateDataProps,
  fileValidationState,
  title,
}: {
  fastQLocationKey: string;
  fastqLocationRecommendationKey: string;
  isValid: boolean;
  row: Row;
  fastQValidationData: SampleTrackingCheckByFastqValidationResultPayload;
  updateDataProps: UseState<State>;
  fileValidationState: FastQValidationState;
  title: string;
}) => {
  type Option = {
    name: string;
    category: string;
    sortOrder: number;
  };

  const { t } = useMemoTranslation();
  const [options, setOptions] = useState<Option[]>([]);
  const [defaultOption, setDefaultOption] = useState<Option | undefined>();
  const [ready, setReady] = useState<boolean>(false);
  const [inputValue, setInputValue] = useState<string>('');

  const [updatedData, setUpdatedData] = updateDataProps;

  useEffect(() => {
    if (!fastQValidationData) {
      return;
    }

    const tmpOptions = orderBy(
      fastQValidationData.possibleFiles.map(i => {
        let category = 'All';
        let sortOrder = 0;

        if (i.toLowerCase() === get(row, fastQLocationKey)?.toLowerCase()) {
          category = 'Current';
          sortOrder = 2;
        } else if (i.toLowerCase() === get(row, fastqLocationRecommendationKey)?.toLowerCase()) {
          category = 'Recommended';
          sortOrder = 1;
        }

        return {
          name: i,
          category: category,
          sortOrder: sortOrder,
        };
      }),
      i => i.sortOrder,
      'desc'
    );

    setDefaultOption(tmpOptions.find(i => i.category === 'Current'));
    setOptions(tmpOptions);
    setInputValue(row.outputId);
    setReady(true);
  }, [fastQValidationData, row, fastQLocationKey, fastqLocationRecommendationKey]);

  return (
    <FlexBox flexDirection={'column'}>
      <Typography variant={'h5'} sx={{ mb: 1 }}>
        {title}
      </Typography>
      <TableContainer>
        <Table sx={{ minWidth: 650 }} aria-label='simple table'>
          <TableHead>
            <TableRow>
              <TableCell colSpan={2}>
                <Typography color={isValid ? 'success.main' : 'error'}>{t(fileValidationState)}</Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell sx={{ fontWeight: '500' }}>{t('current')}</TableCell>
              <TableCell align='right'>{get(updatedData, fastQLocationKey)}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell sx={{ fontWeight: '500' }}>{t('recommended')}</TableCell>
              <TableCell align='right'>{get(row, fastqLocationRecommendationKey)}</TableCell>
            </TableRow>
            <TableRow sx={{ mb: 0 }}>
              <TableCell colSpan={2} align='right' sx={{ borderBottom: 'none', pr: 0, pl: 0, pb: 0 }}>
                {ready && (
                  <Autocomplete
                    id='grouped-demo'
                    options={options}
                    defaultValue={defaultOption}
                    inputValue={inputValue}
                    onInputChange={(event, newInputValue) => {
                      if (event) {
                        setInputValue(newInputValue);
                      }
                    }}
                    defaultChecked={true}
                    groupBy={option => option.category}
                    getOptionLabel={option => option.name}
                    sx={{ minWidth: '1000px', width: '100%' }}
                    renderInput={params => <TextField {...params} label={t('fastQFiles')} />}
                    renderGroup={params => (
                      <li key={params.key}>
                        <GroupHeader>{params.group}</GroupHeader>
                        <GroupItems>{params.children}</GroupItems>
                      </li>
                    )}
                    onChange={(event, value) => {
                      set(updatedData, fastQLocationKey, value?.name ?? '');
                      setUpdatedData({ ...updatedData });
                    }}
                  />
                )}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell colSpan={2} align='right' sx={{ borderBottom: 'none', pr: 0 }}>
                <Button
                  color={'primary'}
                  size={'small'}
                  sx={{}}
                  disabled={get(row, fastqLocationRecommendationKey) === undefined}
                  onClick={() => {
                    set(updatedData, fastQLocationKey, get(row, fastqLocationRecommendationKey));
                    setUpdatedData({ ...updatedData });
                  }}
                >
                  {t('setToRecommended')}
                </Button>
                <Button
                  color={'primary'}
                  size={'small'}
                  sx={{}}
                  onClick={() => {
                    set(updatedData, fastQLocationKey, '');
                    setUpdatedData({ ...updatedData });
                  }}
                >
                  {t('clearCurrent')}
                </Button>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      <FlexBox flexDirection={'column'} gap={1}>
        <FlexBox flexDirection={'row-reverse'}></FlexBox>
      </FlexBox>
    </FlexBox>
  );
};
