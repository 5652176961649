import React from 'react';
import { Button, ButtonProps } from '@mui/material';

export const DeleteButton = (props: ButtonProps) => {
  return (
    <Button color={'error'} {...props}>
      Delete
    </Button>
  );
};
