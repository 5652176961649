import React, { useEffect } from 'react';
import {
  gridExpandedSortedRowEntriesSelector,
  gridPageSelector,
  gridPageSizeSelector,
  GridRowId,
  selectedGridRowsSelector,
  useGridApiRef,
} from '@mui/x-data-grid-pro';
import { CompactGridWrapper, CompactGridWrapperProps } from '../../../../components/grid/CompactGridWrapper';

export type NavigationTriggerRef = React.MutableRefObject<() => void>;

// Data grid that supports populating the given refs with functions that will navigate the grid forward or backward by
// one row.
const NavigatableDataGrid: React.FC<
  {
    selectNextRowRef?: NavigationTriggerRef;
    selectPrevRowRef?: NavigationTriggerRef;
  } & Omit<CompactGridWrapperProps, 'apiRef' | 'disableMultipleRowSelection'> &
    React.RefAttributes<HTMLDivElement>
> = ({ selectNextRowRef, selectPrevRowRef, ...dataGridProps }) => {
  const gridApiRef = useGridApiRef();

  useEffect(() => {
    if (selectNextRowRef) {
      selectNextRowRef.current = makeNavigationFunction(gridApiRef, 1);
    }
  }, [selectNextRowRef, gridApiRef]);

  useEffect(() => {
    if (selectPrevRowRef) {
      selectPrevRowRef.current = makeNavigationFunction(gridApiRef, -1);
    }
  }, [selectPrevRowRef, gridApiRef]);

  return <CompactGridWrapper {...dataGridProps} apiRef={gridApiRef} disableMultipleRowSelection={true} />;
};

// Builds a function that will navigate the grid controlled by the given gridApiRef forward or backward by idxChange
// rows, with wrapping at the grid boundaries.
const makeNavigationFunction = (gridApiRef: ReturnType<typeof useGridApiRef>, idxChange: number) => () => {
  // This function seems to not be called when there aren't any rows selected so we can safely assume that there is
  // always a selected row.
  const selectedRowId = selectedGridRowsSelector(gridApiRef).keys().next().value as GridRowId;

  // Find where the selected row is in the table based on the given sort/filter/etc.
  const rowsInDisplayOrder = gridExpandedSortedRowEntriesSelector(gridApiRef);
  const selectedIdx = rowsInDisplayOrder.findIndex(row => row.id === selectedRowId);

  // Compute the index of the next row to select, with wrapping if necessary.
  let nextIdx = selectedIdx + idxChange;
  if (nextIdx < 0) {
    nextIdx = rowsInDisplayOrder.length + nextIdx;
  } else if (nextIdx >= rowsInDisplayOrder.length) {
    nextIdx = nextIdx - rowsInDisplayOrder.length;
  }

  // Select the next row which will trigger the `onRowSelectionModelChange` handler if it exists.
  const nextRowId = rowsInDisplayOrder.at(nextIdx)?.id;
  if (nextRowId === undefined) {
    return;
  }

  gridApiRef.current.selectRow(nextRowId, true, true);

  // If the newly-selected row is not on the current page then navigate to the page that contains it.
  const currentPage = gridPageSelector(gridApiRef);
  const pageSize = gridPageSizeSelector(gridApiRef);
  const expectedPage = Math.floor(nextIdx / pageSize);
  if (currentPage !== expectedPage) {
    gridApiRef.current.setPage(expectedPage);
  }
};

export default NavigatableDataGrid;
