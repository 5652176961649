import React, { useEffect, useState } from 'react';
import { GetSampleTrackingCountsBySampleType, SampleTrackingCountRow } from '../data/SampleTrackingData';
import { LoadingState, LoadState } from './LoadingStateUtil';
import useAuth from '../auth/UseAuth';
import useMemoTranslation from '../hooks/UseMemoTranslation';
import { Card, CardContent, Typography } from '@mui/material';
import { FlexBox } from './FlexBox';
import { createCaseInsensitiveMap } from '../util/TypeUtil';
import { keyBy } from 'lodash';
import { dashDash } from '../util/Constants';
import { LoadingIndicator } from './LoadingIndicator';
import { ErrorIndicator } from './ErrorIndicator';
import { useConfiguredTransition } from './hooks/UseConfiguredTransitions';

export const SampleTrackingCountsKpi = ({
  researchProjectId,
  configuredTransitionId,
  sampleGroup,
}: {
  researchProjectId: string;
  configuredTransitionId?: string;
  sampleGroup: string;
}) => {
  const { accessToken } = useAuth();
  const { t } = useMemoTranslation();

  const [loadingState, setLoadingState] = useState<LoadingState>({ status: 'NotStarted' });
  const [patientCounts, setPatientCounts] = useState<SampleTrackingCountRow>();
  const [sampleCounts, setSampleCounts] = useState<SampleTrackingCountRow>();

  const configuredTransition = useConfiguredTransition(researchProjectId, configuredTransitionId, setLoadingState);

  useEffect(() => {
    return LoadState(setLoadingState, async () => {
      if (!(accessToken && researchProjectId !== undefined)) {
        return;
      }

      const countsTmp = await GetSampleTrackingCountsBySampleType(researchProjectId, accessToken);

      const sampleGroupCountsTmp = createCaseInsensitiveMap(keyBy(countsTmp, i => i.sampleGroup)).get(sampleGroup);
      const sampleCounts = createCaseInsensitiveMap(sampleGroupCountsTmp?.sampleCounts).get(
        configuredTransition?.displayName ?? ''
      );
      const patientCounts = createCaseInsensitiveMap(sampleGroupCountsTmp?.patientCounts).get(
        configuredTransition?.displayName ?? ''
      );

      setPatientCounts(patientCounts);
      setSampleCounts(sampleCounts);
    });
  }, [accessToken, researchProjectId, sampleGroup, configuredTransition]);

  return (
    <>
      <FlexBox data-testid='sample-tracking-kpi-count'>
        <Card
          sx={{
            marginLeft: 1,
            marginBottom: 1,
            width: 'auto',
            paddingBottom: 1,
            paddingTop: 1,
            boxShadow: '0px 2px 4px rgba(159, 162, 191, .18), 0px 0.5px 0.5px rgba(159, 162, 191, 0.32)',
          }}
          variant='outlined'
        >
          <CardContent sx={{ paddingBottom: '0 !important', paddingTop: '0 !important' }}>
            <FlexBox sx={{ flexDirection: 'row', gap: 1 }}>
              {[
                {
                  label: t('needsCheckBy'),
                  sampleCount: sampleCounts?.workLeftCount,
                  patientCount: patientCounts?.workLeftCount,
                },
                {
                  label: t('passed'),
                  sampleCount: sampleCounts?.passedCount,
                  patientCount: patientCounts?.passedCount,
                },
                {
                  label: t('failed'),
                  sampleCount: sampleCounts?.failedCount,
                  patientCount: patientCounts?.failedCount,
                },
                {
                  label: t('remaining'),
                  sampleCount: sampleCounts?.remainingCount,
                  patientCount: patientCounts?.remainingCount,
                  lastItem: true,
                },
              ].map((item, i) => {
                let countLabel = `${item.sampleCount?.toLocaleString()} (${item.patientCount?.toLocaleString()}p)`;

                if (item.sampleCount === undefined || item.sampleCount === 0) {
                  countLabel = dashDash;
                }

                return (
                  <FlexBox sx={{ gap: 1, alignItems: 'center' }} key={i}>
                    <Typography fontSize={'14px'} variant='button'>
                      {item.label}:
                    </Typography>
                    <Typography fontSize={'12px'} variant='button' fontWeight={'0'}>
                      {countLabel}
                    </Typography>
                    {!item.lastItem && (
                      <Typography fontSize={'12px'} variant='button'>
                        |
                      </Typography>
                    )}
                  </FlexBox>
                );
              })}
            </FlexBox>
            <LoadingIndicator loadingState={loadingState} margin={'None'} />
            <ErrorIndicator loadingState={loadingState} />
          </CardContent>
        </Card>
      </FlexBox>
    </>
  );
};
