import useAuth from '../../auth/UseAuth';
import { useEffect, useState } from 'react';
import { orderBy } from 'lodash';
import { GetPipelines, SuperDuperFiestaPipeline } from '../../data/SuperDuperFiestaData';

const useSuperDuperFiestaPipelines = (): ReadonlyArray<SuperDuperFiestaPipeline> => {
  const { accessToken } = useAuth();
  const [SuperDuperFiestaPipelines, setSuperDuperFiestaPipelines] = useState<ReadonlyArray<SuperDuperFiestaPipeline>>(
    []
  );

  useEffect(() => {
    let isCancelled = false;

    GetPipelines(accessToken).then(data => {
      if (isCancelled) {
        return;
      }

      setSuperDuperFiestaPipelines(orderBy(data, i => i.name));
    });

    return () => {
      isCancelled = true;
    };
  }, [accessToken]);

  return SuperDuperFiestaPipelines;
};

export default useSuperDuperFiestaPipelines;
