import useAuth from '../../auth/UseAuth';
import { useEffect, useState } from 'react';
import { LoadingState, LoadState } from '../LoadingStateUtil';
import { UseStateSetter } from '../../util/TypeUtil';
import { GetAllResearchProjectsDetails, ResearchProjectDetails } from '../../data/ResearchProjectData';

export const useResearchProjectsDetails = (
  setLoadingState?: UseStateSetter<LoadingState>
): [ReadonlyArray<ResearchProjectDetails>, () => void] => {
  const { accessToken } = useAuth();
  const [researchProjectDetails, setResearchProjectDetails] = useState<ReadonlyArray<ResearchProjectDetails>>([]);
  const [refresh, refreshTrigger] = useState<boolean>(true);

  useEffect(() => {
    return LoadState(setLoadingState, async () => {
      if (!accessToken) {
        return;
      }

      const data = await GetAllResearchProjectsDetails(accessToken);
      setResearchProjectDetails(data);
    });
  }, [refresh, accessToken, setLoadingState]);

  function refreshCallBack() {
    refreshTrigger(!refresh);
  }

  return [researchProjectDetails, refreshCallBack];
};
