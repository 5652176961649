export const appSettings = {
  auth: {
    domain: process.env.REACT_APP_AUTH0_DOMAIN || 'livinglab-development.us.auth0.com',
    client_id: process.env.REACT_APP_AUTH0_CLIENT_ID || '4GCIJ5CqacRCS5KiUTF3R0beS7mMkCxq',
    redirect_uri: window.location.origin,
    scope: 'openid profile email user_metadata LivingLab',
    audience: process.env.REACT_APP_AUDIENCE || 'https://dataservices',
    claims_namespace: process.env.REACT_APP_CLAIM_NAMESPACE || 'https://development.ovation.io',
  },
  api: {
    endpoint: process.env.REACT_APP_API_URL,
    prince_metrics_dns: process.env.REACT_APP_PRINCE_METRICS_DNS,
  },
  license: {
    muiX: '0bb1f9e69ae1731d6c11d218557fcadeTz04NzAyMixFPTE3NDI3NTI4NjYwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI=',
  },
  depthStore: {
    endpoint: process.env.REACT_APP_DEPTHSTORE_API_URL,
  },
};
