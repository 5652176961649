import * as React from 'react';
import { useCallback, useEffect, useState } from 'react';
import { Button, Checkbox, Collapse, FormControlLabel } from '@mui/material';
import { LoadingState, LoadState } from './LoadingStateUtil';
import {
  GetAllSelectableFieldsForResearchProject,
  SelectableEntity,
  SelectableField,
} from '../data/SampleTrackingFieldSelectionData';
import useAuth from '../auth/UseAuth';
import { FlexBox } from './FlexBox';
import { every, filter, find, map, orderBy, remove, some } from 'lodash';
import { UseState } from '../util/TypeUtil';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import { ErrorIndicator } from './ErrorIndicator';
import { LoadingIndicator } from './LoadingIndicator';
import { useParams } from 'react-router-dom';
import useLocalStorage from '../hooks/UseLocalStorage';
import { ActionButton } from './DialogOpenButton';
import useMemoTranslation from '../hooks/UseMemoTranslation';

type Entity = Omit<SelectableEntity, 'fields'> & {
  checked: boolean;
  fields: Field[];
};

type Field = SelectableField & {
  checked: boolean;
};

export const EntityFieldSelector = ({
  cacheKey,
  onChange,
  defaultEntities,
  additionalEntities,
  hideSelectDefault,
  customActions,
  disabled,
  disableCaching,
}: {
  cacheKey?: string;
  onChange: (entities: ReadonlyArray<SelectableEntity>) => void;
  defaultEntities?: SelectableEntity[];
  additionalEntities?: SelectableEntity[];
  hideSelectDefault?: boolean;
  customActions?: React.ReactNode;
  disabled?: boolean;
  disableCaching?: boolean;
}) => {
  const { t } = useMemoTranslation();
  const { accessToken } = useAuth();
  const [loadingState, setLoadingState] = useState<LoadingState>({ status: 'NotStarted' });
  let { researchProjectId } = useParams() as { researchProjectId: string };

  const [localStorageEntities] = useLocalStorage<ReadonlyArray<SelectableEntity>>(cacheKey, defaultEntities ?? []);
  const [entities, setEntities] = useState<ReadonlyArray<Entity>>([]);
  const [initialized, setInitialized] = useState<boolean>(false);

  useEffect(() => {
    return LoadState(setLoadingState, async () => {
      if (accessToken === undefined) {
        return;
      }

      if (initialized) {
        return;
      }

      const cacheKeyPresent = cacheKey !== undefined;
      const entitiesData = [
        ...(additionalEntities ?? []),
        ...(await GetAllSelectableFieldsForResearchProject(researchProjectId, accessToken)),
      ];
      setEntities(
        entitiesData.map(entity => {
          const localStorageEntity = find(localStorageEntities, i => i.name === entity.name);

          return {
            ...entity,
            checked: !!localStorageEntity || !cacheKeyPresent,
            fields: entity.fields.map(field => ({
              ...field,
              checked: !!find(localStorageEntity?.fields, i => i.columnName === field.columnName) || !cacheKeyPresent,
            })),
          };
        })
      );

      setInitialized(true);
    });
  }, [initialized, localStorageEntities, cacheKey, researchProjectId, accessToken, additionalEntities]);

  const memoizedOnChange = useCallback(
    (entities: ReadonlyArray<SelectableEntity>) => {
      if (!initialized) {
        return;
      }

      onChange(entities);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [initialized, cacheKey]
  );

  useEffect(() => {
    memoizedOnChange(
      map(
        filter(entities, i => i.checked || some(i.fields, j => j.checked)),
        i => ({ ...i, fields: filter(i.fields, j => j.checked) })
      )
    );
  }, [entities, memoizedOnChange]);

  function setAllToValue(checked: boolean) {
    entities.forEach(entity => {
      entity.checked = checked;
      entity.fields.forEach(field => (field.checked = checked));
    });
    setEntities([...entities]);
  }

  return (
    <>
      <FlexBox
        sx={{
          flexDirection: 'column',
          gap: 1,
        }}
      >
        <FlexBox sx={{ gap: 2 }}>
          {customActions}
          <ActionButton
            sx={{ p: 0 }}
            title={t('selectAll')}
            onClick={() => {
              setAllToValue(true);
            }}
            disableMarginLeft={true}
          >
            <></>
          </ActionButton>
          <ActionButton
            sx={{ p: 0 }}
            title={t('selectNone')}
            onClick={() => {
              setAllToValue(false);
            }}
            disableMarginLeft={true}
          >
            <></>
          </ActionButton>
          {!hideSelectDefault && (
            <ActionButton
              sx={{ p: 0 }}
              title={t('selectDefault')}
              onClick={() => {
                if (!disableCaching) {
                  entities.forEach(entity => {
                    const defaultEntity = find(defaultEntities, i => i.name === entity.name);

                    entity.checked = !!defaultEntity;
                    entity.fields.forEach(
                      field =>
                        (field.checked = defaultEntity?.fields?.some(i => i.columnName === field.columnName) ?? false)
                    );
                  });

                  setEntities([...entities]);
                }
              }}
              disableMarginLeft={true}
            >
              <></>
            </ActionButton>
          )}
        </FlexBox>
        <FlexBox
          sx={{
            maxHeight: '60vh',
            padding: 0,
            flexDirection: 'column',
            margin: 0,
            gap: 0,
          }}
        >
          {orderBy(entities, i => i.displayName).map(entity => (
            <EntityOptions
              key={entity.name}
              entity={entity}
              entityProps={[entities, setEntities]}
              disabled={disabled ?? false}
            />
          ))}
        </FlexBox>
      </FlexBox>
      <LoadingIndicator loadingState={loadingState} margin={'T'} />
      <ErrorIndicator loadingState={loadingState} />
    </>
  );
};

const EntityOptions = ({
  entity,
  entityProps,
  disabled,
}: {
  entity: Entity;
  entityProps: UseState<ReadonlyArray<Entity>>;
  disabled: boolean;
}) => {
  const [entities, setEntities] = entityProps;
  const [expanded, setExpanded] = useState<boolean>(false);

  return (
    <FlexBox sx={{ flexDirection: 'column', width: '100%`' }}>
      <FlexBox sx={{ alignItems: 'center', width: '100%', justifyContent: 'space-between' }}>
        <FlexBox sx={{ alignItems: 'center' }}>
          <FormControlLabel
            value={`entity-field-selector-${entity.name}-checkbox`}
            key={`entity-field-selector-${entity.name}-checkbox`}
            label={entity.displayName}
            control={
              <Checkbox
                size='medium'
                disabled={disabled}
                checked={entity.checked}
                indeterminate={!every(entity.fields, i => i.checked) && some(entity.fields, i => i.checked)}
                onChange={() => {
                  entity.checked = !entity.checked;
                  entity.fields.forEach(field => (field.checked = entity.checked));
                  setEntities([...remove(entities, i => i.name !== entity.name), entity]);
                }}
                sx={{ padding: '2px', transform: 'scale(.75)', fontSize: '12px' }}
              />
            }
            sx={{ padding: '2px', fontSize: '12px', ml: 1 }}
          />
        </FlexBox>
        <Button
          sx={{
            '&:hover': {
              backgroundColor: 'transparent',
            },
            justifyContent: 'right',
          }}
          size={'small'}
          onClick={() => setExpanded(!expanded)}
        >
          <>{expanded ? <ArrowDropDownIcon fontSize={'small'} /> : <ArrowRightIcon fontSize={'small'} />}</>
        </Button>
      </FlexBox>

      <Collapse in={expanded}>
        <FlexBox sx={{ flexDirection: 'column' }}>
          {orderBy(entity.fields, i => i.columnDisplayName).map(field => (
            <FlexBox key={field.columnName} sx={{ alignItems: 'center' }}>
              <FormControlLabel
                value={`entity-field-selector-${field.columnDisplayName}-field-checkbox`}
                key={`entity-field-selector-${field.columnDisplayName}-field-checkbox`}
                label={field.columnDisplayName}
                control={
                  <Checkbox
                    size='medium'
                    disabled={disabled}
                    checked={field.checked}
                    onChange={() => {
                      field.checked = !field.checked;
                      entity.checked = every(entity.fields, i => i.checked);
                      setEntities([...remove(entities, i => i.name !== entity.name), entity]);
                    }}
                    sx={{ padding: '0px', transform: 'scale(.75)', fontSize: '12px' }}
                  />
                }
                sx={{ padding: '0px', fontSize: '12px', ml: 4 }}
              />
            </FlexBox>
          ))}
        </FlexBox>
      </Collapse>
    </FlexBox>
  );
};
