import React, { createContext, useEffect, useReducer, useState } from 'react';
import {
  PipelineReviewConfig,
  SdfIssueType,
  SuperDuperFiestaDataClass,
  SuperDuperFiestaDataSource,
  SuperDuperFiestaEntity,
  SuperDuperFiestaStage,
} from '../data/SuperDuperFiestaData';
import { useFilterState, BaseFilterState, FilterAction, initialFilterState } from 'components/hooks/UseFilterState';

interface IPipelineReviewState {
  entity: SuperDuperFiestaEntity | null;
  stage: SuperDuperFiestaStage | null;
  dataSource: SuperDuperFiestaDataSource | null;
  dataClass: SuperDuperFiestaDataClass | null;
  issueId: number | null;
  issueTypes: SdfIssueType[] | null;
  pageNumber: string | null;
  pageSize: string | null;
}

interface IPipelineReviewAction {
  type:
    | 'update_entity'
    | 'update_stage'
    | 'update_dataSource'
    | 'update_dataClass'
    | 'update_selectedIssue'
    | 'update_issueTypes'
    | 'reset';
  entity?: SuperDuperFiestaEntity | null;
  stage?: SuperDuperFiestaStage | null;
  dataSource?: SuperDuperFiestaDataSource | null;
  dataClass?: SuperDuperFiestaDataClass | null;
  issueId?: number | null;
  issueTypes?: SdfIssueType[] | null;
  pageNumber?: string | null;
  pageSize?: string | null;
}

const initialState = {
  entity: null,
  stage: null,
  dataSource: null,
  dataClass: null,
  issueId: null,
  issueTypes: null,
  pageNumber: null,
  pageSize: null,
};

function pipelineReviewReducer(state: IPipelineReviewState, action: IPipelineReviewAction): IPipelineReviewState {
  const { type } = action;

  switch (type) {
    case 'update_entity': {
      return { ...state, entity: action.entity ?? null };
    }
    case 'update_stage': {
      return { ...state, stage: action.stage ?? null };
    }
    case 'update_dataSource': {
      return { ...state, dataSource: action.dataSource ?? null };
    }
    case 'update_dataClass': {
      return { ...state, dataClass: action.dataClass ?? null };
    }
    case 'update_selectedIssue': {
      return { ...state, issueId: action.issueId ?? null };
    }
    case 'update_issueTypes': {
      return { ...state, issueTypes: action.issueTypes ?? [] };
    }
    case 'reset': {
      return { ...initialState };
    }
    default:
      return state;
  }
}

export interface IPipelineReviewContextData {
  pipelineReviewState: IPipelineReviewState;
  pipelineReviewParams: PipelineReviewConfig | null;
  resetPaginationModel: boolean;
  filterState: BaseFilterState;
  getFilterData: () => void;
}

export interface IPipelineReviewContext extends IPipelineReviewContextData {
  pipelineReviewDispatch: React.Dispatch<IPipelineReviewAction>;
  clearContextSelection: () => void;
  setResetPaginationModel: React.Dispatch<React.SetStateAction<boolean>>;
  filterState: BaseFilterState;
  filtersDispatch: React.Dispatch<FilterAction<BaseFilterState>>;
  getFilterData: () => void;
}

export type PipelineReviewActionType = IPipelineReviewAction['type'];

const PipelineReviewContext = createContext<IPipelineReviewContext>({
  pipelineReviewParams: null,
  pipelineReviewState: initialState,
  pipelineReviewDispatch: () => {
    /* empty */
  },
  clearContextSelection: () => {
    /* empty */
  },
  resetPaginationModel: false,
  setResetPaginationModel: () => {
    /* empty */
  },
  filterState: initialFilterState,
  filtersDispatch: () => {
    /* empty */
  },
  getFilterData: () => {
    /* empty */
  },
});

export const PipelineReviewContextProvider: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const [pipelineReviewParams, setPipelineReviewParams] = useState<PipelineReviewConfig | null>(null);
  const [pipelineReviewState, pipelineReviewDispatch] = useReducer(pipelineReviewReducer, initialState);
  const [resetPaginationModel, setResetPaginationModel] = useState<boolean>(false);

  const { filterState, dispatch: filtersDispatch, getFilterData } = useFilterState(initialFilterState);

  const clearContextSelection = () => {
    pipelineReviewDispatch({ type: 'reset' });
  };

  useEffect(() => {
    if (pipelineReviewState.entity === null) {
      setPipelineReviewParams(null);
    } else {
      const queryParams: PipelineReviewConfig = {
        entity: pipelineReviewState.entity.name,
        stage: pipelineReviewState?.stage?.name || 'selection',
        issueTypeIds: pipelineReviewState.issueTypes?.map(t => t.issueTypeId) ?? [1],
      };

      if (pipelineReviewState.dataSource !== null) {
        queryParams['dataSourceId'] = pipelineReviewState.dataSource.dataSourceId;
      }
      if (pipelineReviewState.dataClass !== null) {
        queryParams['dataClassId'] = pipelineReviewState.dataClass.dataClassId;
      }
      if (pipelineReviewState.issueId !== null) {
        queryParams['selectedIssueId'] = pipelineReviewState.issueId.toString();
      }
      setPipelineReviewParams(queryParams);
    }
  }, [pipelineReviewState]);

  return (
    <PipelineReviewContext.Provider
      value={{
        pipelineReviewState,
        pipelineReviewDispatch,
        pipelineReviewParams,
        clearContextSelection,
        resetPaginationModel,
        setResetPaginationModel,
        filterState,
        filtersDispatch,
        getFilterData,
      }}
    >
      {children}
    </PipelineReviewContext.Provider>
  );
};

export default PipelineReviewContext;
