import React from 'react';
import { DataProductField, DataProductTable } from '../../data/DataProductData';
import { CompactGridWrapper } from '../../components/grid/CompactGridWrapper';
import { GridColDef } from '@mui/x-data-grid-pro';
import { DataSchemaField } from '../../data/DataSchemaData';
import { t } from 'i18next';
import { LoadingState } from '../../components/LoadingStateUtil';

interface FieldsViewerTableProps {
  loadingState: LoadingState;
  fields: DataSchemaField[];
}

export const FieldsViewerTable: React.FC<FieldsViewerTableProps> = ({ loadingState, fields }) => {
  const getRowId = (row: DataProductField) => row.dataSchemaFieldId;

  const columns: GridColDef<DataProductTable>[] = [
    {
      field: 'name',
      headerName: t('name'),
      type: 'string',
      flex: 1,
    },
  ];

  return (
    <CompactGridWrapper
      loadingState={loadingState}
      columns={columns}
      rows={fields}
      getRowId={getRowId}
      rowSelection={false}
    />
  );
};
