import { Box, IconButton, Stack, useTheme } from '@mui/material';

import React from 'react';
import FilterGroup from './filterGroup/FilterGroup';
import PatientList from './patientList/PatientList';
import { NavigationTriggerRef } from '../grids/NavigatableDataGrid';
import {
  KeyboardArrowLeftTwoTone,
  KeyboardArrowRightTwoTone,
  KeyboardDoubleArrowLeftTwoTone,
  KeyboardDoubleArrowRightTwoTone,
} from '@mui/icons-material';
import { ExpansionState } from '../../pages/PatientApprover';

const LeftNavigation: React.FC<{
  selectNextPatientRef: NavigationTriggerRef;
  selectPrevPatientRef: NavigationTriggerRef;
  expansionState: ExpansionState;
  setExpansionState: (expansionState: ExpansionState) => void;
}> = ({ expansionState, setExpansionState, selectNextPatientRef, selectPrevPatientRef }) => {
  return (
    <Stack direction='column' spacing={2} sx={{ flex: 1 }} maxWidth={'100%'}>
      <Stack
        direction={expansionState === ExpansionState.OnlyPatientFrame ? 'column-reverse' : 'row'}
        spacing={expansionState === ExpansionState.OnlyPatientFrame ? 1 : 2}
        alignItems={expansionState === ExpansionState.OnlyPatientFrame ? 'center' : 'end'}
        sx={{ flex: 0 }}
      >
        <Box sx={{ flex: 1 }}>
          <FilterGroup expanded={expansionState !== ExpansionState.OnlyPatientFrame} />
        </Box>

        <ExpansionButtons expansionState={expansionState} setExpansionState={setExpansionState} />
      </Stack>
      <Box
        sx={{
          flex: 1,
          width: '100%',
          position: 'relative',
          visibility: expansionState === ExpansionState.OnlyPatientFrame ? 'hidden' : undefined,
          opacity: expansionState === ExpansionState.OnlyPatientFrame ? 0 : undefined,
        }}
      >
        <Box sx={{ position: 'absolute', top: 0, left: 0, right: 0, bottom: 0 }}>
          <PatientList selectNextPatientRef={selectNextPatientRef} selectPrevPatientRef={selectPrevPatientRef} />
        </Box>
      </Box>
    </Stack>
  );
};

const ExpansionButtons: React.FC<{
  expansionState: ExpansionState;
  setExpansionState: (expansionState: ExpansionState) => void;
}> = ({ expansionState, setExpansionState }) => {
  const theme = useTheme();

  return (
    <Stack
      direction={expansionState === ExpansionState.OnlyPatientFrame ? 'column' : 'row'}
      spacing={1}
      alignItems='center'
      justifyContent='center'
      sx={{ flex: 0, paddingTop: expansionState !== ExpansionState.OnlyPatientFrame ? undefined : theme.spacing(1) }}
    >
      {/* Full collapse button, only available when fully expanded */}
      {expansionState === ExpansionState.OnlyPatientList && (
        <IconButton onClick={() => setExpansionState(ExpansionState.OnlyPatientFrame)}>
          <KeyboardDoubleArrowLeftTwoTone />
        </IconButton>
      )}

      {/* Collapse button, available unless fully collapsed */}
      {(expansionState === ExpansionState.Both || expansionState === ExpansionState.OnlyPatientList) && (
        <IconButton
          onClick={() => {
            switch (expansionState) {
              case ExpansionState.OnlyPatientList:
                setExpansionState(ExpansionState.Both);
                break;
              case ExpansionState.Both:
                setExpansionState(ExpansionState.OnlyPatientFrame);
                break;
            }
          }}
        >
          <KeyboardArrowLeftTwoTone />
        </IconButton>
      )}

      {/* Expand button, available unless fully expanded */}
      {(expansionState === ExpansionState.Both || expansionState === ExpansionState.OnlyPatientFrame) && (
        <IconButton
          onClick={() => {
            switch (expansionState) {
              case ExpansionState.OnlyPatientFrame:
                setExpansionState(ExpansionState.Both);
                break;
              case ExpansionState.Both:
                setExpansionState(ExpansionState.OnlyPatientList);
                break;
            }
          }}
        >
          <KeyboardArrowRightTwoTone />
        </IconButton>
      )}

      {/* Full expand button, only available when fully collapsed */}
      {expansionState === ExpansionState.OnlyPatientFrame && (
        <IconButton onClick={() => setExpansionState(ExpansionState.OnlyPatientList)}>
          <KeyboardDoubleArrowRightTwoTone />
        </IconButton>
      )}
    </Stack>
  );
};

export default LeftNavigation;
