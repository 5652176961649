import React from 'react';
import { Box, Button, IconButton, Stack, TextField, Typography } from '@mui/material';
import { DeleteTwoTone } from '@mui/icons-material';
import AddCircleRoundedIcon from '@mui/icons-material/AddCircleRounded';
import { t } from 'i18next';

export interface WorkingDiseaseAreaCodes {
  diagnosis?: string;
  icd10Codes: string;
}

interface DiseaseAreaCodesInputGroupProps {
  diseaseAreaCodes: WorkingDiseaseAreaCodes[];
  onDiseaseAreaCodesChange: (diseaseAreaCodes: WorkingDiseaseAreaCodes[]) => unknown;
}

export const DiseaseAreaCodesInputGroup: React.FC<DiseaseAreaCodesInputGroupProps> = ({
  diseaseAreaCodes,
  onDiseaseAreaCodesChange,
}) => {
  const handleDiagnosisChange = (diagnosis: string, index: number) => {
    const newCodes = [...diseaseAreaCodes];
    newCodes[index].diagnosis = diagnosis;
    onDiseaseAreaCodesChange(newCodes);
  };

  const handleIcd10CodesChange = (icd10Codes: string, index: number) => {
    const newCodes = [...diseaseAreaCodes];
    newCodes[index].icd10Codes = icd10Codes;
    onDiseaseAreaCodesChange(newCodes);
  };

  const handleAddCode = () => {
    const newCodes = [...diseaseAreaCodes];
    newCodes.push({
      diagnosis: undefined,
      icd10Codes: '',
    });
    onDiseaseAreaCodesChange(newCodes);
  };

  const handleDeleteCode = (index: number) => {
    const newCodes = [...diseaseAreaCodes];
    newCodes.splice(index, 1);
    onDiseaseAreaCodesChange(newCodes);
  };

  return (
    <Box>
      {diseaseAreaCodes.map((c, i) => {
        const sameDiagnosisCount = diseaseAreaCodes.map(c => c.diagnosis).filter(d => d === c.diagnosis).length;
        const [diagnosisHasError, diagnosisErrorMessage] =
          c.diagnosis !== undefined && sameDiagnosisCount > 1 ? [true, t('diagnosisMustBeUnique')] : [false, undefined];

        const codesHasError = c.icd10Codes.trim().length === 0;

        return (
          <Stack key={i} direction='row' spacing={1} sx={{ marginTop: 1.5 }}>
            <TextField
              fullWidth
              label={t('diagnosis')}
              onChange={e => handleDiagnosisChange(e.target.value, i)}
              type='text'
              value={c.diagnosis ?? ''}
              error={diagnosisHasError}
              helperText={diagnosisErrorMessage}
              variant='outlined'
              size='small'
            />
            <TextField
              fullWidth
              label={`${t('icd10Codes')}*`}
              onChange={e => handleIcd10CodesChange(e.target.value, i)}
              type='text'
              value={c.icd10Codes}
              error={codesHasError}
              variant='outlined'
              size='small'
            />
            <IconButton disabled={diseaseAreaCodes.length === 1} onClick={() => handleDeleteCode(i)}>
              <DeleteTwoTone />
            </IconButton>
          </Stack>
        );
      })}

      <Button fullWidth onClick={handleAddCode} variant='contained' sx={{ marginTop: 0.75 }}>
        <Stack direction='row' alignItems='center' spacing={0.5}>
          <Typography>{t('addCodes')}</Typography>
          <AddCircleRoundedIcon />
        </Stack>
      </Button>
    </Box>
  );
};

export default DiseaseAreaCodesInputGroup;
