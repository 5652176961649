import { Stack } from '@mui/material';
import React from 'react';

import StartYearSelector from './startYearSelector/StartYearSelector';

const FilterGroup: React.FC<{ expanded: boolean }> = ({ expanded }) => {
  return (
    <Stack direction='column' spacing={2}>
      <StartYearSelector expanded={expanded} />
    </Stack>
  );
};

export default FilterGroup;
