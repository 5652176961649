import { StateCreator } from 'zustand';

export interface ResearchProjectSlice {
  researchProjectId: string | undefined;
  setResearchProjectId: (researchProjectId: string | undefined) => void;
}

export const createResearchProjectSlice: StateCreator<ResearchProjectSlice> = set => ({
  researchProjectId: undefined,
  setResearchProjectId: researchProjectId => set({ researchProjectId: researchProjectId }),
});
