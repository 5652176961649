import React, { useEffect, useMemo, useState } from 'react';
import EditIcon from '@mui/icons-material/Edit';
import { IconButton } from '@mui/material';
import { GridColDef, useGridApiRef } from '@mui/x-data-grid-pro';
import { FlexBox } from 'components/FlexBox';
import { FlexTableBox } from 'components/FlexTableBox';
import { CompactGridWrapper } from 'components/grid/CompactGridWrapper';
import { SdfIssue, SdfIssueType } from 'data/SuperDuperFiestaData';
import useMemoTranslation from '../../hooks/UseMemoTranslation';
import { CreateIssueModal } from './CreateIssueModal';
import useSdfIssues from 'components/hooks/UseSdfIssues';
import useSdfIssueTypes from 'components/hooks/UseSdfIssueTypes';
export const Issues = () => {
  const apiRef = useGridApiRef();
  const { issues: issuesList, refresh } = useSdfIssues();
  const issueTypes = useSdfIssueTypes();

  const [rows, setRows] = useState<SdfIssue[]>([]);
  const [selectedIssue, setSelectedIssue] = useState<SdfIssue | null>(null);
  const [open, setOpen] = useState<boolean>(false);

  useEffect(() => {
    if (!issuesList) return;
    setRows(issuesList.map((issue, i) => ({ ...issue, id: i + 1 })));
  }, [issuesList]);

  useEffect(() => {
    if (!selectedIssue) {
      return;
    }
    setOpen(true);
  }, [selectedIssue]);

  const handleClose = () => {
    setSelectedIssue(null);
    setOpen(false);
  };

  const handleSave = (issue: SdfIssue) => {
    refresh();
  };

  const columns = useColumns(setSelectedIssue, [...issueTypes]);

  return (
    <FlexTableBox sx={{ gap: 1, width: '50%' }}>
      <FlexBox flexDirection={'row-reverse'}>
        <CreateIssueModal
          issueTypes={[...issueTypes]}
          issue={selectedIssue}
          isOpen={open}
          onClose={handleClose}
          onSave={handleSave}
        />
      </FlexBox>
      <CompactGridWrapper apiRef={apiRef} columns={columns} rows={rows} rowHeight={35} />
    </FlexTableBox>
  );
};

const useColumns = (
  setSelectedIssue: React.Dispatch<React.SetStateAction<SdfIssue | null>>,
  issueTypes: SdfIssueType[]
): GridColDef[] => {
  const { t } = useMemoTranslation();

  return useMemo(() => {
    return [
      {
        field: 'name',
        headerName: t('name'),
        width: 500,
      },
      {
        field: 'issueTypeId',
        headerName: t('issueType'),
        width: 120,
        valueGetter: params => {
          const type = issueTypes.find(t => t.issueTypeId === params.row.issueTypeId);
          return type?.displayName || '';
        },
      },
      {
        field: 'action',
        width: 50,
        renderCell: (params: any) => {
          return (
            <IconButton onClick={() => setSelectedIssue(params.row)} color='primary' sx={{ pr: 0 }}>
              <EditIcon fontSize='small' />
            </IconButton>
          );
        },
      },
    ];
  }, [t, setSelectedIssue, issueTypes]);
};
