import { useState } from 'react';
import { Scrollbar } from 'components/Scrollbar';
import { Box, alpha, styled, Divider, useTheme, lighten, darken, Drawer, CSSObject, Fade } from '@mui/material';
import { Theme } from '@mui/material/styles';
import { SidebarControls } from './SidebarControls';
import { SidebarTopSection } from './SidebarTopSection';
import { SidebarMenu } from './SidebarMenu';
import useMediaQueryEffect from '../../hooks/UseMediaQueryEffect';

export const Sidebar = () => {
  const theme = useTheme();

  const [open, setOpen] = useState(true);

  // Auto-close the sidebar if the screen is small and re-open it if the screen becomes large again (but only if it was
  // previous auto-closed, otherwise the user might want it closed even when the screen is large).
  const [lastCloseWasAutoClose, setLastCloseWasAutoClose] = useState(false);

  const userSetOpen = (open: boolean) => {
    if (!open) {
      setLastCloseWasAutoClose(false);
    }
    setOpen(open);
  };

  useMediaQueryEffect(
    theme.breakpoints.down('lg'),
    isSmallScreen => {
      if (isSmallScreen && open) {
        setOpen(false);
        setLastCloseWasAutoClose(true);
      } else if (!isSmallScreen && !open && lastCloseWasAutoClose) {
        setOpen(true);
      }
    },
    [open, lastCloseWasAutoClose]
  );

  return (
    <SidebarWrapper variant='permanent' open={open}>
      <SidebarControls
        open={open}
        setOpen={userSetOpen}
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          zIndex: 1,
        }}
      />

      <Fade in={open}>
        <SidebarContent>
          <SidebarTopSection />
          <Divider
            sx={{
              my: theme.spacing(3),
              mx: theme.spacing(2),
              background: theme.colors.alpha.trueWhite[10],
            }}
          />
          <Box sx={{ flex: 1 }}>
            <Scrollbar>
              <SidebarMenu />
            </Scrollbar>
          </Box>
        </SidebarContent>
      </Fade>
    </SidebarWrapper>
  );
};

const openMixin = (theme: Theme): CSSObject => ({
  width: theme.sidebar.width,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    // Use the enteringScreen duration because this transition will be applied as soon as the sidebar starts opening.
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme: Theme): CSSObject => ({
  width: theme.spacing(6),
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    // Use the leavingScreen duration because this transition will be applied as soon as the sidebar starts closing.
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
});

const themingMixin = (theme: Theme): CSSObject => ({
  color: theme.colors.alpha.trueWhite[70],
  background:
    theme.palette.mode === 'dark'
      ? alpha(lighten(theme.header.background ?? '', 0.1), 0.5)
      : darken(theme.colors.alpha.black[100], 0.5),
  boxShadow: theme.palette.mode === 'dark' ? theme.sidebar.boxShadow : 'none',
});

const SidebarWrapper = styled(Drawer, { shouldForwardProp: prop => prop !== 'open' })(({ theme, open }) => ({
  flexShrink: 0,
  whiteSpace: 'nowrap',
  height: '100%',
  paddingBottom: '61px',
  ...themingMixin(theme),

  ...(open && {
    ...openMixin(theme),
    '& .MuiDrawer-paper': {
      ...openMixin(theme),
      ...themingMixin(theme),
    },
  }),

  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': {
      ...closedMixin(theme),
      ...themingMixin(theme),
    },
  }),
}));

const SidebarContent = styled(Box)(({ theme }) => ({
  height: '100%',
  width: theme.sidebar.width,
  display: 'flex',
  flexDirection: 'column',
}));
