import React, { useEffect, useState } from 'react';
import useMemoTranslation from '../hooks/UseMemoTranslation';
import { DialogOpenButton } from './DialogOpenButton';
import FullscreenOutlinedIcon from '@mui/icons-material/FullscreenOutlined';
import { Dialog, DialogContent, DialogTitle, Typography } from '@mui/material';
import { DialogCloseButton } from './DialogCloseButton';
import { KeyValuePairTable } from './KeyValuePairTable';
import { LoadingState, LoadState } from './LoadingStateUtil';
import {
  GetAllSelectableFieldsForResearchProject,
  SelectableCandidate,
  SelectFields,
} from '../data/SampleTrackingFieldSelectionData';
import useAuth from '../auth/UseAuth';
import { flatMap, keys, map } from 'lodash';
import { useParams } from 'react-router-dom';
import { LoadingIndicator } from './LoadingIndicator';
import { ErrorIndicator } from './ErrorIndicator';

export const SampleLineageDataDialog = ({ candidates }: { candidates: ReadonlyArray<SelectableCandidate> }) => {
  const { t } = useMemoTranslation();
  const { accessToken } = useAuth();
  let { researchProjectId } = useParams() as { researchProjectId: string };

  const [loadingState, setLoadingState] = useState<LoadingState>({ status: 'NotStarted' });

  const [open, setOpen] = useState<boolean>(false);
  const [data, setData] = useState<ReadonlyArray<any>>([]);

  useEffect(() => {
    if (!open) {
      return;
    }

    return LoadState(setLoadingState, async () => {
      const entities = await GetAllSelectableFieldsForResearchProject(researchProjectId, accessToken);
      const response = await SelectFields(
        { entities: entities, candidates: candidates, researchProjectId: researchProjectId },
        accessToken
      );
      const items = response.data;

      setData(
        flatMap(keys(items), key => {
          const v = items[key];
          const entries = map(response.columns, k => {
            return { key: k.fullDisplayName, value: v[k.key] };
          });

          return [...entries];
        })
      );
    });
  }, [open, candidates, researchProjectId, accessToken, setLoadingState]);

  function handleClose() {
    setOpen(false);
    setData([]);
  }

  return (
    <>
      <DialogOpenButton title={t('sampleLineageDetails')} onClick={() => setOpen(true)}>
        <FullscreenOutlinedIcon />
      </DialogOpenButton>
      <Dialog open={open} onClose={handleClose} maxWidth={'lg'}>
        <DialogTitle>
          <DialogCloseButton onClick={() => handleClose()} />
          {t('sampleLineageDetails')}
          <Typography sx={{ whiteSpace: 'pre-line', wordWrap: 'wrap' }}>{t('sampleLineageDetailsInfo')}</Typography>
        </DialogTitle>
        <DialogContent>
          {loadingState.status === 'Complete' && <KeyValuePairTable data={data} />}
          <LoadingIndicator loadingState={loadingState} margin={'TB'} />
          <ErrorIndicator loadingState={loadingState} />
        </DialogContent>
      </Dialog>
    </>
  );
};
