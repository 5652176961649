import { appSettings } from 'AppSettings';
import { BaseGet, BasePostVoid } from './BaseData';
import { RefreshStatus } from './RefreshStatusData';

export interface PathogenGroupMetrics {
  pathogenGroupId: string;
  pathogenGroupName: string;
  snomedClinicalTermIds: string[];
  snomedConceptIds: string[];
  patientCounts: PathogenGroupPatientCount;
  patientCountsByLab: PathogenGroupPatientCountByLab[];
  patientCountsBySampleType: PathogenGroupPatientCountBySampleType[];
  refreshedAt?: Date;
}

export interface PathogenGroupPatientCount {
  pathogenGroupId: string;
  pastWeek: number;
  pastMonth: number;
  pastThreeMonths: number;
  pastYear: number;
  allTime: number;
}

export interface PathogenGroupPatientCountByLab extends PathogenGroupPatientCount {
  labId: string;
  lab: string;
}

export interface PathogenGroupPatientCountBySampleType extends PathogenGroupPatientCount {
  sampleTypeId: string;
  sampleType: string;
}

export async function GetPathogenGroupPatientCounts(accessToken?: string): Promise<PathogenGroupPatientCount[]> {
  return await BaseGet(`${appSettings.api.endpoint}/api/v2/PathogenGroupMetrics/PatientCounts`, accessToken);
}

export async function GetPathogenGroupPatientCountsByLab(
  accessToken?: string
): Promise<PathogenGroupPatientCountByLab[]> {
  return await BaseGet(`${appSettings.api.endpoint}/api/v2/PathogenGroupMetrics/PatientCounts/ByLab`, accessToken);
}

export async function GetPathogenGroupPatientCountsBySampleType(
  accessToken?: string
): Promise<PathogenGroupPatientCountBySampleType[]> {
  return await BaseGet(
    `${appSettings.api.endpoint}/api/v2/PathogenGroupMetrics/PatientCounts/BySampleType`,
    accessToken
  );
}

export async function RefreshPathogenGroupMetrics(accessToken?: string) {
  await BasePostVoid(`${appSettings.api.endpoint}/api/v2/PathogenGroupMetrics/Refresh`, undefined, accessToken);
}

export async function GetPathogenGroupMetricsRefreshStatus(accessToken?: string): Promise<RefreshStatus> {
  return await BaseGet(`${appSettings.api.endpoint}/api/v2/PathogenGroupMetrics/RefreshStatus`, accessToken);
}
