import { AuthorizedPage } from 'auth/AuthorizedPage';
import { Page } from 'components/Page';
import useAuth from 'auth/UseAuth';
import React, { useEffect, useState } from 'react';
import { Biobank, GetBiobanks } from 'data/BiobankData';
import {
  GetBiobankPatientTotal,
  GetBiobankSampleTotal,
  GetBiobankHistoricCounts,
  HistoricCount,
} from 'data/HistoricCountData';
import { HistoricCountLineChart } from 'historicCounts/HistoricCountLineChart';
import { CountType } from 'historicCounts/CountTypeSelector';
import { DateAggregate } from 'historicCounts/DateAggregate';
import { HistoricCountFilters } from 'historicCounts/HistoricCountFilters';
import 'historicCounts/HistoricCounts.css';
import { Box, Card } from '@mui/material';
import { LoadingIndicator } from '../components/LoadingIndicator';

type StatusType = 'Loading' | 'Complete' | 'InsufficientData';

export const BiobankMetricsPage = () => {
  const { accessToken } = useAuth();
  const defaultStartDate = new Date();
  defaultStartDate.setMonth(defaultStartDate.getUTCMonth() - 24);

  const [chartDataStatus, setChartDataStatus] = useState<StatusType>('Loading');
  const [startDate, setStartDate] = useState<Date>(defaultStartDate);
  const [endDate, setEndDate] = useState<Date>(new Date());
  const [dateAggregate, setDateAggregate] = useState<DateAggregate>('month');
  const [countType, setCountType] = useState<CountType>('patient');
  const [biobanks, setBiobanks] = useState<Biobank[]>([]);
  const [biobankCounts, setBiobankCounts] = useState<HistoricCount[]>([]);
  const [totalBiobanked, setTotalBiobanked] = useState<number>();

  useEffect(() => {
    async function getState() {
      if (accessToken) {
        setBiobanks(await GetBiobanks(accessToken));
      }
    }
    getState();
  }, [accessToken]);

  useEffect(() => {
    async function getState() {
      if (accessToken && biobanks.length > 0) {
        let promises = [];
        setChartDataStatus('Loading');
        if (countType === 'patient') {
          promises.push(GetBiobankPatientTotal(accessToken).then(setTotalBiobanked));
        }
        if (countType === 'sample') {
          promises.push(GetBiobankSampleTotal(accessToken).then(setTotalBiobanked));
        }
        promises.push(
          GetBiobankHistoricCounts(
            accessToken,
            startDate,
            endDate,
            dateAggregate,
            biobanks.filter(b => b.selected).map(b => b.biobankId),
            countType
          ).then(counts => {
            setBiobankCounts([...counts]);
            setChartDataStatus(counts.length > 0 ? 'Complete' : 'InsufficientData');
          })
        );
        await Promise.all(promises);
      }
    }
    getState();
  }, [accessToken, startDate, endDate, dateAggregate, biobanks, countType]);

  return (
    <AuthorizedPage>
      <Page
        title='Historic Counts by Biobank'
        subtitle={`Total Biobanked: ${totalBiobanked ? totalBiobanked.toLocaleString() : ''}`}
      >
        <Box display='flex' flexDirection='row'>
          <Box width={320} mr={3}>
            <Card>
              <Box p={2}>
                <HistoricCountFilters
                  countType={countType}
                  dateAggregate={dateAggregate}
                  startDate={startDate}
                  endDate={endDate}
                  biobanks={biobanks}
                  onCountTypeSelect={setCountType}
                  onDateAggregateSelect={setDateAggregate}
                  onStartDateSelect={setStartDate}
                  onEndDateSelect={setEndDate}
                  onBiobankSelect={setBiobanks}
                />
              </Box>
            </Card>
          </Box>
          <Box pb={3}>
            <Card sx={{ width: 1075, height: 650 }}>
              <Box p={4} justifyContent='center' alignItems='center'>
                {chartDataStatus === 'Loading' && <LoadingIndicator loadingState={{ status: 'Loading' }} />}
                {chartDataStatus === 'Complete' && (
                  <div>
                    <HistoricCountLineChart
                      entities={biobanks.filter(b => b.selected === true).map(b => b.name)}
                      historicCounts={biobankCounts}
                      dateAggregate={dateAggregate}
                      width='100%'
                      height='600px'
                      chartAreaWidth='75%'
                    />
                  </div>
                )}
                {chartDataStatus === 'InsufficientData' && (
                  <div className='historic-counts-loading'>No counts were found for selected filters.</div>
                )}
              </Box>
            </Card>
          </Box>
        </Box>
      </Page>
    </AuthorizedPage>
  );
};
