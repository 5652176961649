import { GridRenderCellParams } from '@mui/x-data-grid-pro';
import React, { useState } from 'react';
import { DialogOpenButton } from '../../DialogOpenButton';
import { ConfirmationDialog } from '../../ConfirmationDialog';
import useAuth from '../../../auth/UseAuth';
import { truncateGuid } from '../../../data/SampleData';
import { PostUndoCheckByGroup } from '../../../data/SampleTrackingTransitionData';
import useMemoTranslation from '../../../hooks/UseMemoTranslation';
import UndoIcon from '@mui/icons-material/Undo';
import { OnDemandTextDialog } from '../../OnDemandTextDialog';
import { GetUndoableCheckByGroupReason } from '../../../data/SampleTrackingCheckByGroupData';
import { useParams } from 'react-router-dom';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

interface SampleTrackingCheckByGroupUndoGridCellProps extends GridRenderCellParams {
  onClose?: () => void;
}

export function renderSampleTrackingCheckByGroupUndoGridCell(params: SampleTrackingCheckByGroupUndoGridCellProps) {
  return <MemoizedSampleTrackingCheckByGroupUndoGridCell {...params} />;
}

const SampleTrackingCheckByGroupUndoGridCell = ({
  value,
  row,
  onClose = () => {},
}: SampleTrackingCheckByGroupUndoGridCellProps) => {
  const { accessToken } = useAuth();
  const { t } = useMemoTranslation();
  let { researchProjectId } = useParams() as { researchProjectId: string };

  const [modalOpen, setModalOpen] = useState<boolean>(false);

  async function handleConfirm() {
    if (value) {
      await PostUndoCheckByGroup(value, accessToken);
    }

    handleClose();
    onClose();
  }

  function handleClose() {
    setModalOpen(false);
  }

  return (
    <span>
      {value ? (
        <>
          <DialogOpenButton fontWeight={'inherit'} onClick={() => setModalOpen(true)}>
            <UndoIcon />
          </DialogOpenButton>
          <ConfirmationDialog
            open={modalOpen}
            onConfirm={handleConfirm}
            onClose={handleClose}
            title={t('confirmCheckByGroupUndo')}
            subtitle={truncateGuid(row.checkByGroupId)}
            message={t('checkByGroupUndoInfo')}
            confirmBtnText={t('undoCheckByGroup')}
          />
        </>
      ) : (
        <>
          <DialogOpenButton fontWeight={'inherit'} onClick={() => setModalOpen(true)}>
            <InfoOutlinedIcon />
          </DialogOpenButton>
          <OnDemandTextDialog
            open={modalOpen}
            onClose={handleClose}
            title={t('confirmCheckByGroupUndo')}
            subtitle={truncateGuid(row.checkByGroupId)}
            getText={async () =>
              (await GetUndoableCheckByGroupReason(researchProjectId, row.checkByGroupId, accessToken)).message
            }
          />
        </>
      )}
    </span>
  );
};

const MemoizedSampleTrackingCheckByGroupUndoGridCell = React.memo(
  SampleTrackingCheckByGroupUndoGridCell,
  (prevProps, nextProps) => {
    return prevProps.value === nextProps.value;
  }
);
