import React from 'react';
import { AuthorizedPage } from 'auth/AuthorizedPage';
import { Page } from 'components/Page';
import { Issues } from './components/Issues';
import useMemoTranslation from '../hooks/UseMemoTranslation';
import { PageContent } from '../components/PageContent';
import { FlexBox } from '../components/FlexBox';

export const IssuesPage = () => {
  const { t } = useMemoTranslation();

  return (
    <AuthorizedPage hasSuperDuperFiestaAccess>
      <Page title={t('issues')}>
        <PageContent>
          <FlexBox sx={{ flexDirection: 'column', height: '100%' }}>
            <FlexBox sx={{ flexDirection: 'row', gap: 3, flexGrow: 2, height: '100%' }}>
              <Issues />
            </FlexBox>
          </FlexBox>
        </PageContent>
      </Page>
    </AuthorizedPage>
  );
};
