import { PipelineOption } from 'researchProjectManagement/ResearchProjectAutocompleteOptions';
import useAuth from '../../auth/UseAuth';
import { useEffect, useState } from 'react';
import { GetPipelines } from '../../data/SuperDuperFiestaData';
import { UseStateSetter } from '../../util/TypeUtil';
import { LoadingState, LoadState } from '../LoadingStateUtil';

export const useSuperDuperFiestaPipelineOptions = (
  setLoadingState: UseStateSetter<LoadingState>
): ReadonlyArray<PipelineOption> => {
  const { accessToken } = useAuth();
  const [pipelineOptions, setPipelineOptions] = useState<ReadonlyArray<PipelineOption>>([]);

  useEffect(() => {
    return LoadState(setLoadingState, async () => {
      if (!accessToken) {
        return;
      }

      const pipelines = [...((await GetPipelines(accessToken)) ?? [])];
      pipelines.sort((a, b) => a.name.localeCompare(b.name));
      setPipelineOptions(
        pipelines.map(p => {
          return { label: p.name, pipeline: p };
        })
      );
    });
  }, [accessToken, setLoadingState]);

  return pipelineOptions;
};
