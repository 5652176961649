import { GridColDef, GridRowsProp, GridSortModel } from '@mui/x-data-grid-pro';
import { renderCellTooltip } from 'components/grid/cell/GridCellTooltip';
import { renderSampleCommentCellTooltip } from 'components/grid/cell/SampleCommentGridCellTooltip';
import { useEffect, useMemo, useState } from 'react';
import useAuth from 'auth/UseAuth';
import { renderGuidCellTooltip } from 'components/grid/cell/GuidGridCellTooltip';
import { CompactGridWrapper } from 'components/grid/CompactGridWrapper';
import {
  GetDiseaseAreaPatientRecordsByBiobank,
  GetPathogenGroupPatientRecordsByLab,
  GetPatientRecords,
  GetPatientRecordsBySampleType,
  PatientRecord,
  PatientRecordOrderBy,
} from 'data/PatientRecordData';
import { ItemOfResearch } from 'data/ResearchProjectData';
import { PatientCountType } from 'components/grid/GridCountType';
import { FlexTableBox } from '../components/FlexTableBox';
import { ErrorManagement, LoadingState } from '../components/LoadingStateUtil';
import useMemoTranslation from '../hooks/UseMemoTranslation';
import {
  bbid,
  bbids,
  biobank,
  biobanks,
  deidentity,
  ethnicity,
  foreignHash,
  foreignHashes,
  gender,
  icd9AndIcd10Codes,
  lab,
  labs,
  latestSampleCollectionDate,
  patientId,
  race,
  sampleComment,
  sampleComments,
  sampleCount,
  sampleType,
  sampleTypes,
  snomedConceptIdsWithCtValue,
  yearOfBirth,
} from '../util/Constants';
import { upperFirst } from 'lodash';
import { ConvertToSingleValue } from 'data/conversions/StringConversions';
import { PatientRecordOptions, RecordSorting, TimeInterval } from 'data/PatientRecordOptions';

export interface Patient {
  patientId: string;
}

export interface PatientRecordGridProps {
  itemOfResearch: ItemOfResearch;
  biobankId?: string;
  labId?: string;
  sampleTypeId?: string;
  timeInterval: TimeInterval;
  countType: PatientCountType;
  numRecords: number;
  decrypted: boolean;
}

export const PatientRecordGrid = ({
  itemOfResearch,
  biobankId,
  labId,
  sampleTypeId,
  timeInterval,
  countType,
  numRecords,
  decrypted,
}: PatientRecordGridProps) => {
  const { accessToken } = useAuth();

  const [loadingState, setLoadingState] = useState<LoadingState>({ status: 'NotStarted' });
  const [sortModel, setSortModel] = useState<GridSortModel>([{ field: 'Deidentity', sort: 'asc' }]);
  const [pageSize, setPageSize] = useState<number>(100);
  const [pageStart, setPageStart] = useState<number>(0);
  const [patientRecords, setPatientRecords] = useState<PatientRecord[]>();
  const [rows, setRows] = useState<GridRowsProp>([]);

  const columns = useColumns();

  useEffect(() => {
    ErrorManagement('Loading', setLoadingState, async () => {
      if (!accessToken) {
        setLoadingState({ status: 'Error' });
        return;
      }

      let sorting = sortModel.map(s => {
        return { orderBy: upperFirst(s.field), sortOrder: s.sort } as RecordSorting<PatientRecordOrderBy>;
      });

      let patientRecordOptions: PatientRecordOptions<PatientRecordOrderBy> = {
        timeInterval: timeInterval,
        sorting: sorting,
        paging: {
          pageSize: pageSize,
          pageStart: pageStart,
        },
        decrypted: decrypted,
      };

      if (itemOfResearch.type === 'DiseaseArea' && 'byBiobank' && biobankId !== undefined) {
        setPatientRecords(
          await GetDiseaseAreaPatientRecordsByBiobank(itemOfResearch.id, biobankId, patientRecordOptions, accessToken)
        );
      } else if (itemOfResearch.type === 'PathogenGroup' && 'byLab' && labId !== undefined) {
        setPatientRecords(
          await GetPathogenGroupPatientRecordsByLab(itemOfResearch.id, labId, patientRecordOptions, accessToken)
        );
      } else if (countType === 'bySampleType' && sampleTypeId !== undefined) {
        setPatientRecords(
          await GetPatientRecordsBySampleType(
            itemOfResearch.id,
            itemOfResearch.type,
            sampleTypeId,
            patientRecordOptions,
            accessToken
          )
        );
      } else if (countType === 'all') {
        setPatientRecords(
          await GetPatientRecords(itemOfResearch.id, itemOfResearch.type, patientRecordOptions, accessToken)
        );
      }

      setLoadingState({ status: 'Complete' });
    });
  }, [
    numRecords,
    biobankId,
    sampleTypeId,
    sortModel,
    pageSize,
    pageStart,
    decrypted,
    countType,
    accessToken,
    labId,
    itemOfResearch.type,
    itemOfResearch.id,
    timeInterval,
  ]);

  useEffect(() => {
    if (patientRecords) {
      setRows(getRows(patientRecords));
    }
  }, [patientRecords, decrypted]);

  return (
    <FlexTableBox>
      <CompactGridWrapper
        loadingState={loadingState}
        rows={rows}
        columns={columns}
        disableColumnFilter={true}
        pagination={true}
        paginationMode='server'
        onPaginationModelChange={model => {
          setPageStart(model.page);
          setPageSize(model.pageSize);
        }}
        paginationModel={{ pageSize: pageSize, page: pageStart }}
        rowCount={numRecords}
        pageSizeOptions={[100, 250, 500]}
        sortingMode='server'
        sortModel={sortModel}
        onSortModelChange={newSortModel => {
          if (newSortModel?.length > 0) {
            setSortModel(newSortModel);
          }
        }}
        hideFooterSelectedRowCount={true}
      />
    </FlexTableBox>
  );
};

function getRows(patientRecords: PatientRecord[]) {
  const rows: any[] = [];

  patientRecords.forEach((patient, index) => {
    rows.push({
      id: index++,
      patientId: patient.patientId,
      deidentity: patient.deidentity,
      sampleCount: patient.sampleCount,
      labs: ConvertToSingleValue(patient.sampleCount, patient.labs),
      biobanks: ConvertToSingleValue(patient.sampleCount, patient.biobanks),
      bbids: ConvertToSingleValue(patient.sampleCount, patient.bbids),
      foreignHashes: ConvertToSingleValue(patient.sampleCount, patient.foreignHashes),
      sampleTypes: ConvertToSingleValue(patient.sampleCount, patient.sampleTypes),
      sampleComments: ConvertToSingleValue(patient.sampleCount, patient.sampleComments),
      latestSampleCollectionDate: dateToString(new Date(patient.latestSampleCollectionDate)),
      gender: patient.gender,
      yearOfBirth: patient.yearOfBirth,
      race: patient.race,
      ethnicity: patient.ethnicity,
      icd9AndIcd10Codes: patient.icd9AndIcd10Codes.sort().join(', '),
      snomedConceptIdsWithCtValue: patient.snomedConceptIds.sort().join(', '),
    });
  });
  return rows;
}

function dateToString(date: Date) {
  return date.toLocaleString('en-US', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
  });
}

const useColumns = (): GridColDef[] => {
  const { t } = useMemoTranslation();

  return useMemo(
    () => [
      {
        field: patientId,
        headerName: t(patientId),
        width: 130,
        align: 'left',
        renderCell: renderGuidCellTooltip,
      },
      {
        field: deidentity,
        headerName: t(deidentity),
        width: 130,
        align: 'left',
        renderCell: renderGuidCellTooltip,
      },
      {
        field: sampleCount,
        headerName: t(sampleCount),
        headerAlign: 'center',
        align: 'center',
        width: 150,
      },
      {
        field: biobanks,
        headerName: t(biobank),
        width: 150,
        renderCell: renderCellTooltip,
      },
      {
        field: labs,
        headerName: t(lab),
        width: 150,
        renderCell: renderCellTooltip,
      },
      {
        field: bbids,
        headerName: t(bbid),
        width: 150,
        renderCell: renderCellTooltip,
      },
      {
        field: foreignHashes,
        headerName: t(foreignHash),
        width: 180,
        type: 'string',
        renderCell: renderCellTooltip,
      },
      {
        field: sampleTypes,
        headerName: t(sampleType),
        width: 200,
        renderCell: renderCellTooltip,
      },
      {
        field: sampleComments,
        headerName: t(sampleComment),
        width: 230,
        renderCell: renderSampleCommentCellTooltip,
      },
      {
        field: latestSampleCollectionDate,
        headerName: t(latestSampleCollectionDate),
        headerAlign: 'center',
        align: 'center',
        width: 230,
      },
      { field: gender, headerName: t(gender), width: 100 },
      { field: yearOfBirth, headerName: t(yearOfBirth), headerAlign: 'center', align: 'center', width: 120 },
      { field: race, headerName: t(race), width: 170 },
      { field: ethnicity, headerName: t(ethnicity), width: 170 },
      {
        field: icd9AndIcd10Codes,
        headerName: t(icd9AndIcd10Codes),
        width: 230,
        renderCell: renderCellTooltip,
      },
      {
        field: snomedConceptIdsWithCtValue,
        headerName: t(snomedConceptIdsWithCtValue),
        width: 230,
        renderCell: renderCellTooltip,
      },
    ],
    [t]
  );
};
