import * as React from 'react';
import { useState } from 'react';
import { Button, Collapse, Typography } from '@mui/material';
import { SelectableEntity } from '../data/SampleTrackingFieldSelectionData';
import { FlexBox } from './FlexBox';
import { orderBy } from 'lodash';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';

export const EntityFieldDisplay = ({ entities }: { entities: ReadonlyArray<SelectableEntity> }) => {
  return (
    <FlexBox
      sx={{
        maxHeight: '60vh',
        padding: 0,
        flexDirection: 'column',
        margin: 0,
        gap: 0,
      }}
    >
      {orderBy(entities, i => i.displayName).map(entity => (
        <EntityOptions key={entity.name} entity={entity} />
      ))}
    </FlexBox>
  );
};

const EntityOptions = ({ entity }: { entity: SelectableEntity }) => {
  const [expanded, setExpanded] = useState<boolean>(false);

  return (
    <FlexBox sx={{ flexDirection: 'column', marginRight: 2, width: '100%`', marginLeft: 1 }}>
      <FlexBox sx={{ alignItems: 'center', width: '100%', justifyContent: 'space-between' }}>
        <FlexBox sx={{ alignItems: 'center' }}>
          <Typography sx={{ whiteSpace: 'pre-line' }}>{entity.displayName}</Typography>
        </FlexBox>
        <Button
          sx={{
            '&:hover': {
              backgroundColor: 'transparent',
            },
            justifyContent: 'right',
          }}
          size={'small'}
          onClick={() => setExpanded(!expanded)}
        >
          <>{expanded ? <ArrowDropDownIcon fontSize={'small'} /> : <ArrowRightIcon fontSize={'small'} />}</>
        </Button>
      </FlexBox>

      <Collapse in={expanded}>
        <FlexBox sx={{ flexDirection: 'column', ml: 3 }}>
          {orderBy(entity.fields, i => i.columnDisplayName).map(field => (
            <FlexBox key={field.columnName} sx={{ alignItems: 'center' }}>
              <Typography sx={{ whiteSpace: 'pre-line' }}>{field.columnDisplayName}</Typography>
            </FlexBox>
          ))}
        </FlexBox>
      </Collapse>
    </FlexBox>
  );
};
