import React, { PropsWithChildren, ReactNode, useEffect, useState } from 'react';
import { useUserPermissions } from './UseUserPermissions';
import { keys } from 'lodash';

type PermissionKeys = keyof ReturnType<typeof useUserPermissions>;
export type AuthorizedSectionProps = {
  requireAny?: boolean;
  unauthorizedComponent?: ReactNode;
} & Partial<ReturnType<typeof useUserPermissions>>;

export const AuthorizedSection: React.FC<PropsWithChildren<AuthorizedSectionProps>> = ({
  requireAny,
  unauthorizedComponent,
  children,
  ...permissionsProps
}) => {
  const permissions = useUserPermissions();
  const [authorized, setAuthorized] = useState<boolean>(false);

  useEffect(() => {
    if (!permissions.isAuthenticated) {
      setAuthorized(false);
      return;
    }

    const allKeys: ReadonlyArray<PermissionKeys> = keys(permissions) as ReadonlyArray<PermissionKeys>;
    const requireOneOf = requireAny ?? false;

    const expectedPermissions: boolean[] = [];
    allKeys.forEach((permission: PermissionKeys) => {
      const propsPermission = permissionsProps[permission];
      const userPermission = permissions[permission];

      if (propsPermission !== undefined) {
        expectedPermissions.push(userPermission);
      }
    });

    // If we're just checking for authentication, then we don't need to check for any permissions
    if (expectedPermissions.length === 0) {
      setAuthorized(true);
      return;
    }

    const authorized = requireOneOf
      ? expectedPermissions.some(value => value)
      : expectedPermissions.every(value => value);

    setAuthorized(authorized);
    return;
  }, [permissions, requireAny, permissionsProps]);

  return <>{authorized ? children : unauthorizedComponent}</>;
};
