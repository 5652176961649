import { appSettings } from '../AppSettings';
import { BasePost } from './BaseData';

export type DeliveryCandidateType = 'yes' | 'no' | 'notYetDecided';

export interface InformaticsPipelineResult {
  informaticsPipelineResultId: string;
  informaticsPipelineRunId: string;
  referenceTypeId: number;
  isDeliveryCandidate: DeliveryCandidateType;
  createdAt: Date;
  updatedAt: Date;
}

export async function GetInformaticsPipelineRunResults(
  transitionSampleIds: ReadonlyArray<string>,
  accessToken?: string
): Promise<ReadonlyArray<InformaticsPipelineResult>> {
  return await BasePost(
    `${appSettings.api.endpoint}/api/v2/InformaticsCheckBy/Results`,
    transitionSampleIds,
    accessToken
  );
}
