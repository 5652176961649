import React from 'react';
import { CustomizableSwitch } from './CustomizableSwitch';
import { useTranslation } from 'react-i18next';
import { UseState } from '../util/TypeUtil';
import { AuthorizedSection } from '../auth/AuthorizedSection';

export const DecryptForeignHashesSwitch = ({ decryptedHashesProps }: { decryptedHashesProps: UseState<boolean> }) => {
  const { t } = useTranslation();
  const [useDecryptedHashes, setUseDecryptedHashes] = decryptedHashesProps;

  return (
    <AuthorizedSection hasDecryptForeignHashesAccess>
      <CustomizableSwitch
        title={t('decryptedForeignHashes')}
        checked={useDecryptedHashes}
        onChange={() => setUseDecryptedHashes(!useDecryptedHashes)}
      />
    </AuthorizedSection>
  );
};
