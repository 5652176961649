import React, { useEffect } from 'react';
import { Controller } from 'react-hook-form';
import TextField from '@mui/material/TextField';
import { FormInputProps } from './FormInputProps';

export const FormTextField = ({
  name,
  control,
  label,
  trigger,
  required = false,
  disabled = false,
  sx = [],
  additionalProps,
}: FormInputProps) => {
  const minLength = required ? 1 : 0;

  // triggers rules on render
  useEffect(() => {
    trigger(name);
  }, [trigger, name, required]);

  return (
    <Controller
      name={name}
      control={control}
      rules={{
        required: required,
        minLength: minLength,
      }}
      render={({ field: { onBlur, onChange, value, ref }, fieldState: { error }, formState }) => (
        <TextField
          sx={{ ...sx }}
          helperText={error ? error.message : null}
          size='small'
          error={!!error}
          onBlur={onBlur}
          onChange={onChange}
          ref={ref}
          value={value ?? ''}
          fullWidth
          label={label}
          required={required}
          disabled={disabled}
          variant='outlined'
          {...additionalProps}
        />
      )}
    />
  );
};
