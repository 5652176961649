import React from 'react';
import { Page } from '../components/Page';
import { Stack, Typography, useTheme } from '@mui/material';
import { Link } from 'react-router-dom';
import { t } from 'i18next';

export const UnauthorizedPage = () => {
  const theme = useTheme();

  return (
    <Page title={t('unauthorized')}>
      <Stack direction='column' spacing={1} alignItems='center' sx={{ marginTop: theme.spacing(4) }}>
        <Typography variant='h3'>{t('noAccessToPage')}</Typography>
        <Link to='/' style={{ color: theme.palette.primary.main }}>
          {t('returnToHomePage')}
        </Link>
      </Stack>
    </Page>
  );
};
