import React, { useEffect, useState } from 'react';
import { AuthorizedPage } from 'auth/AuthorizedPage';
import { Page } from 'components/Page';
import 'diseaseAreas/DiseaseAreas.css';
import useAuth from 'auth/UseAuth';
import { Box, FormControl, InputLabel, MenuItem, Select, Tab, Tabs, Typography, useTheme } from '@mui/material';
import {
  GetPathogenGroupMetricsRefreshStatus,
  GetPathogenGroupPatientCounts,
  GetPathogenGroupPatientCountsByLab,
  GetPathogenGroupPatientCountsBySampleType,
  PathogenGroupMetrics,
  PathogenGroupPatientCount,
  RefreshPathogenGroupMetrics,
} from 'data/PathogenGroupMetricsData';
import { GetPathogenGroups, PathogenGroup } from 'data/PathogenGroupData';
import { PatientCountType } from 'components/grid/GridCountType';
import { PathogenGroupCountGrid } from 'pathogenGroups/PathogenGroupCountGrid';
import { CreatePathogenGroupModal } from 'pathogenGroups/CreatePathogenGroupModal';
import { GetPathogenGroupOptions, PathogenGroupOption } from 'pathogenGroups/PathogenGroupAutoCompleteOptions';
import { ErrorManagement, LoadingState } from 'components/LoadingStateUtil';
import { SampleType } from 'data/SampleTypeData';
import { GetSampleTypes, Organization } from 'data/ReferenceData';
import { PatientRecordModal } from 'patientRecords/PatientRecordModal';
import { FlexTableBox } from '../components/FlexTableBox';
import { PageContent } from '../components/PageContent';
import { GridExportButton } from '../components/GridExportButton';
import useMemoTranslation from '../hooks/UseMemoTranslation';
import { useGridApiRef } from '@mui/x-data-grid-pro';
import { FlexBox } from '../components/FlexBox';
import { deletePathogenGroup, pathogenCountsFileName } from '../util/Constants';
import { TimeIntervalOptions } from 'pathogenGroups/TimeIntervalOptions';
import { GetLabs } from 'data/LabData';
import { ErrorIndicator } from 'components/ErrorIndicator';
import { ERROR_COUNT } from 'pathogenGroups/PatientCountGridCell';
import { TimeInterval } from 'data/PatientRecordOptions';
import { AuthorizedSection } from '../auth/AuthorizedSection';
import { RefreshStatus, getLastRefreshedDateAsString } from 'data/RefreshStatusData';
import { GridRefreshButton } from 'components/GridRefreshButton';

export const PathogenGroupPage = () => {
  const { accessToken } = useAuth();
  const theme = useTheme();
  const { t } = useMemoTranslation();
  const apiRef = useGridApiRef();

  const [loadingState, setLoadingState] = useState<LoadingState>({ status: 'NotStarted' });
  const [gridCountType, setGridCountType] = useState<PatientCountType>('byLab');
  const [timeInterval, setTimeInterval] = useState<TimeInterval>('Month');
  const [pathogenGroupMetrics, setPathogenGroupMetrics] = useState<PathogenGroupMetrics[]>([]);
  const [pathogenGroupOptions, setPathogenGroupOptions] = useState<PathogenGroupOption[]>([]);
  const [labs, setLabs] = useState<Organization[]>();
  const [sampleTypes, setSampleTypes] = useState<ReadonlyArray<SampleType>>();
  const [refreshStatus, setRefreshStatus] = useState<RefreshStatus>();

  const [selectedPathogenGroup, setSelectedPathogenGroup] = useState<PathogenGroup>();
  const [selectedLabId, setSelectedLabId] = useState<string>();
  const [selectedSampleTypeId, setSelectedSampleTypeId] = useState<string>();
  const [selectedCount, setSelectedCount] = useState<number>();

  const [pathogenGroupPatientRecordModalOpen, setPathogenGroupPatientRecordModalOpen] = useState<boolean>(false);
  const [patientRecordCountType, setPatientRecordCountType] = useState<PatientCountType>();

  const [refresh, refreshPage] = useState<boolean>(true);

  useEffect(() => {
    async function getState() {
      ErrorManagement('Loading', setLoadingState, async () => {
        const [pathogenGroups, counts, countsByLab, countsBySampleType, labsFetched, sampleTypesFetched, status] =
          await Promise.all([
            GetPathogenGroups(accessToken),
            GetPathogenGroupPatientCounts(accessToken),
            GetPathogenGroupPatientCountsByLab(accessToken),
            GetPathogenGroupPatientCountsBySampleType(accessToken),
            GetLabs(accessToken),
            GetSampleTypes(accessToken),
            GetPathogenGroupMetricsRefreshStatus(accessToken),
          ]);

        let labsAsOrganization: Organization[] = labsFetched.map(l => ({
          id: l.labId,
          name: l.name,
          type: 'Lab',
        }));

        let metrics: PathogenGroupMetrics[] = pathogenGroups.map(pg => {
          let patientCounts: PathogenGroupPatientCount = counts.find(c => c.pathogenGroupId === pg.pathogenGroupId) ?? {
            pathogenGroupId: pg.pathogenGroupId,
            pastWeek: ERROR_COUNT,
            pastMonth: ERROR_COUNT,
            pastThreeMonths: ERROR_COUNT,
            pastYear: ERROR_COUNT,
            allTime: ERROR_COUNT,
          };

          return {
            pathogenGroupId: pg.pathogenGroupId,
            pathogenGroupName: pg.name,
            snomedClinicalTermIds: pg.snomedClinicalTermIds,
            snomedConceptIds: pg.snomedConceptIds,
            patientCounts: patientCounts,
            patientCountsByLab: countsByLab.filter(c => c.pathogenGroupId === pg.pathogenGroupId),
            patientCountsBySampleType: countsBySampleType.filter(c => c.pathogenGroupId === pg.pathogenGroupId),
            refreshedAt: status?.lastRefreshed,
          };
        });
        setRefreshStatus(status);
        setPathogenGroupMetrics(metrics);
        setLabs(labsAsOrganization);
        setSampleTypes(sampleTypesFetched);
        setLoadingState({ status: 'Complete' });
      });
    }
    getState();
  }, [accessToken, refresh]);

  useEffect(() => {
    async function getState(accessToken?: string) {
      if (accessToken) {
        setPathogenGroupOptions(await GetPathogenGroupOptions(accessToken));
      }
    }
    getState(accessToken);
  }, [accessToken, pathogenGroupMetrics]);

  const handleCountTypeTabChange = async (event: React.SyntheticEvent, newValue: PatientCountType) => {
    setGridCountType(newValue);
  };

  const handleRefreshButtonClick = async () => {
    if (refreshStatus && !refreshStatus.isRefreshing) {
      setRefreshStatus({ isRefreshing: true, lastRefreshed: refreshStatus.lastRefreshed });
      await RefreshPathogenGroupMetrics(accessToken);
      setRefreshStatus(await GetPathogenGroupMetricsRefreshStatus(accessToken));
      refreshPage(!refresh);
    }
  };

  const handlePatientRecordModalClose = async () => {
    setSelectedPathogenGroup(undefined);
    setSelectedLabId(undefined);
    setSelectedSampleTypeId(undefined);
    setPatientRecordCountType(undefined);
    setPathogenGroupPatientRecordModalOpen(false);
  };

  function handleGridCountClick(
    count: number,
    pathogenGroup: PathogenGroup,
    countType: PatientCountType,
    labId?: string,
    sampleTypeId?: string
  ) {
    setSelectedCount(count);
    setSelectedPathogenGroup(pathogenGroup);
    setSelectedLabId(labId);
    setSelectedSampleTypeId(sampleTypeId);
    setPatientRecordCountType(countType);
    setPathogenGroupPatientRecordModalOpen(true);
  }

  async function handlePathogenGroupChange() {
    ErrorManagement('Loading', setLoadingState, async () => {
      setLoadingState({ status: 'Complete' });
      refreshPage(!refresh);
    });
  }

  return (
    <AuthorizedPage>
      <Page title='Pathogens'>
        <PageContent>
          <FlexBox
            sx={{
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
            mb={2}
          >
            <Box>
              <Tabs value={gridCountType} onChange={handleCountTypeTabChange}>
                <Tab value='byLab' label='By Lab' sx={{ color: theme.colors.alpha.black[100] }} />
                <Tab value='bySampleType' label='By Sample Type' sx={{ color: theme.colors.alpha.black[100] }} />
              </Tabs>
            </Box>
            <FlexBox
              sx={{
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
              <AuthorizedSection hasGreenSideWriteAccess>
                <GridRefreshButton refreshStatus={refreshStatus} onClick={handleRefreshButtonClick} />
                <GridExportButton
                  apiRef={apiRef}
                  fileName={t(pathogenCountsFileName)}
                  fieldsToNotExport={[deletePathogenGroup]}
                />
                <CreatePathogenGroupModal
                  pathogenGroupOptions={pathogenGroupOptions}
                  onPathogenGroupCreate={handlePathogenGroupChange}
                />
              </AuthorizedSection>
            </FlexBox>
          </FlexBox>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'end',
              justifyContent: 'space-between',
            }}
          >
            <FormControl sx={{ mt: 1, mb: 1, width: '200px' }}>
              <InputLabel id='date-aggregate-for-pathogen-grid-counts-label'>{t('timeRange')}</InputLabel>
              <Select
                id='date-aggregate-for-pathogen-grid-counts'
                labelId='date-aggregate-for-pathogen-grid-counts-label'
                label={t('timeRange')}
                value={TimeIntervalOptions.find(o => o.timeInterval === timeInterval)?.timeInterval}
                onChange={ev => {
                  setTimeInterval((ev.target.value ?? 'Month') as TimeInterval);
                }}
                size={'small'}
              >
                {TimeIntervalOptions.map(o => {
                  return (
                    <MenuItem key={`pathogen-group-page-time-interval-${o.timeInterval}`} value={o.timeInterval}>
                      {o.label}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Box>
          <FlexTableBox>
            <PathogenGroupCountGrid
              countType={gridCountType}
              timeInterval={timeInterval}
              pathogenGroupMetrics={pathogenGroupMetrics}
              onPatientCountClick={handleGridCountClick}
              onPathogenGroupDelete={handlePathogenGroupChange}
              apiRef={apiRef}
              loadingState={loadingState}
            />
            <ErrorIndicator loadingState={loadingState} />
          </FlexTableBox>
          <Typography mt={2}>{`Last updated: ${getLastRefreshedDateAsString(refreshStatus)}`}</Typography>
          {selectedPathogenGroup && patientRecordCountType && (
            <PatientRecordModal
              open={pathogenGroupPatientRecordModalOpen}
              count={selectedCount ?? 0}
              itemOfResearch={{
                id: selectedPathogenGroup.pathogenGroupId,
                name: selectedPathogenGroup.name,
                type: 'PathogenGroup',
              }}
              timeInterval={timeInterval}
              organization={labs?.find(l => l.id === selectedLabId)}
              sampleType={sampleTypes?.find(s => s.sampleTypeId === selectedSampleTypeId)}
              gridCountType={gridCountType}
              patientRecordCountType={patientRecordCountType}
              onClose={handlePatientRecordModalClose}
            />
          )}
        </PageContent>
      </Page>
    </AuthorizedPage>
  );
};
