import React, { useState } from 'react';
import useMemoTranslation from '../../hooks/UseMemoTranslation';
import useSuperDuperFiestaPipelines from '../../components/hooks/UseSuperDuperFiestaPipelines';
import { Checkbox, FormControlLabel, TextField } from '@mui/material';
import { AutocompleteWrapper } from '../../components/AutocompleteWrapper';
import useSuperDuperFiestaEntities from '../../components/hooks/UseSuperDuperFiestaEntities';
import { map } from 'lodash';
import {
  ExecutePipeline,
  SuperDuperFiestaEntity,
  SuperDuperFiestaPipeline,
  SuperDuperFiestaPipelineConfig,
  SuperDuperFiestaStage,
} from '../../data/SuperDuperFiestaData';
import { FlexBox } from '../../components/FlexBox';
import { PrimaryButton } from '../../components/PrimaryButton';
import useSuperDuperFiestaStages from '../../components/hooks/UseSuperDuperFiestaStages';
import { CancelButton } from '../../components/CancelButton';
import { ErrorManagement, LoadingProps } from '../../components/LoadingStateUtil';
import useAuth from '../../auth/UseAuth';
import { ActionButton } from '../../components/DialogOpenButton';

export const PipelineExecution = ({ loadingProps }: { loadingProps: LoadingProps }) => {
  const { t } = useMemoTranslation();
  const { accessToken } = useAuth();
  const pipelines = useSuperDuperFiestaPipelines();
  const entities = useSuperDuperFiestaEntities();
  const stages = useSuperDuperFiestaStages();

  const [selectedPipeline, setSelectedPipeline] = useState<SuperDuperFiestaPipeline | null>();
  const [selectedStage, setSelectedStage] = useState<SuperDuperFiestaStage | null>();
  const [selectedEntities, setSelectedEntities] = useState<SuperDuperFiestaEntity[]>([]);

  const [, setLoadingState] = loadingProps;

  function executePipeline() {
    ErrorManagement('Loading', setLoadingState, async () => {
      if (!selectedPipeline) {
        return;
      }

      const config: SuperDuperFiestaPipelineConfig = {
        entities: map(selectedEntities, i => i.name),
        runSubsequentStages: true,
        startAtStage: selectedStage?.name ?? undefined,
      };

      await ExecutePipeline(selectedPipeline!.pipelineId, config, accessToken);

      handleClear();
    });
  }

  function handleClear() {
    setSelectedPipeline(null);
    setSelectedStage(null);
    setSelectedEntities([]);
    setLoadingState({ status: 'NotStarted' });
  }

  return (
    <FlexBox sx={{ flexDirection: 'column', gap: 1, width: '100%' }}>
      <AutocompleteWrapper
        fullWidth
        options={pipelines}
        getOptionLabel={pipeline => pipeline.name}
        renderInput={params => <TextField {...params} label={t('pipeline')} margin='normal' />}
        onChange={(event, value) => setSelectedPipeline(value)}
      />
      <AutocompleteWrapper
        fullWidth
        options={stages}
        getOptionLabel={stage => stage.name}
        renderInput={params => <TextField {...params} label={t('stage')} margin='normal' />}
        onChange={(event, value) => setSelectedStage(value)}
      />
      <FlexBox sx={{ flexDirection: 'column', gap: 0 }}>
        {entities.map(entity => (
          <FormControlLabel
            value={`pipeline-execution-${entity.name}-checkbox`}
            key={`pipeline-execution-${entity.name}-checkbox`}
            label={entity.displayName}
            control={
              <Checkbox
                size='small'
                checked={map(selectedEntities, i => i.entityId).includes(entity.entityId)}
                value={entity.entityId}
                onClick={() => {
                  const has = map(selectedEntities, i => i.entityId).includes(entity.entityId);

                  if (has) {
                    setSelectedEntities(selectedEntities.filter(i => i.entityId !== entity.entityId));
                  } else {
                    setSelectedEntities([...selectedEntities, entity]);
                  }
                }}
              />
            }
            sx={{ padding: 0 }}
          />
        ))}
        <FlexBox sx={{ gap: 1 }}>
          <ActionButton
            sx={{ p: 0 }}
            title={t('selectAll')}
            onClick={() => {
              setSelectedEntities([...entities]);
            }}
            disableMarginLeft={true}
          >
            <></>
          </ActionButton>
          <ActionButton
            sx={{ p: 0 }}
            title={t('selectNone')}
            onClick={() => {
              setSelectedEntities([]);
            }}
            disableMarginLeft={true}
          >
            <></>
          </ActionButton>
        </FlexBox>
      </FlexBox>
      <FlexBox sx={{ flexDirection: 'row-reverse' }}>
        <PrimaryButton
          disabled={selectedPipeline === undefined || selectedEntities.length === 0}
          onClick={executePipeline}
        >
          {t('executePipeline')}
        </PrimaryButton>
        <CancelButton onClick={handleClear} />
      </FlexBox>
    </FlexBox>
  );
};
