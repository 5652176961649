import { DialogOpenButton } from './DialogOpenButton';
import { MutableRefObject } from 'react';
import useMemoTranslation from '../hooks/UseMemoTranslation';
import { GridApiPro } from '@mui/x-data-grid-pro/models/gridApiPro';
import FileDownloadIcon from '@mui/icons-material/FileDownload';

interface Props {
  apiRef: MutableRefObject<GridApiPro>;
  fileName: string;
  fieldsToNotExport?: string[];
  label?: string;
}

export const GridExportButton = ({ apiRef, fileName, fieldsToNotExport, label }: Props) => {
  const { t } = useMemoTranslation();

  function download() {
    apiRef.current.exportDataAsCsv({
      fields: apiRef.current
        .getAllColumns()
        .filter(o => !o.disableExport)
        .map(o => o.field)
        .filter(o => !(fieldsToNotExport ?? []).includes(o)),
      fileName: fileName,
    });
  }

  return (
    <DialogOpenButton title={label ?? t('export')} onClick={() => download()}>
      <FileDownloadIcon />
    </DialogOpenButton>
  );
};
