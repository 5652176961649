import React, { useEffect, useState } from 'react';
import {
  Autocomplete,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  TextField,
  useTheme,
} from '@mui/material';
import useAuth from 'auth/UseAuth';
import { GetAllResearchProjects, ResearchProject } from '../../data/ResearchProjectData';
import { t } from 'i18next';
import { LoadingState, LoadState } from '../../components/LoadingStateUtil';
import { LoadingIndicator } from '../../components/LoadingIndicator';
import { ErrorIndicator } from '../../components/ErrorIndicator';

interface DatasetCreationDialogProps {
  open: boolean;
  disallowedResearchProjectIds: string[];
  onAdd: (name: string | null, researchProject: ResearchProject) => unknown;
  onCancel: () => unknown;
}

export const DatasetCreationDialog: React.FC<DatasetCreationDialogProps> = ({
  open,
  disallowedResearchProjectIds,
  onAdd,
  onCancel,
}) => {
  const { accessToken } = useAuth();

  const theme = useTheme();

  const [researchProjects, setResearchProjects] = useState<ResearchProject[]>([]);
  const [loadingState, setLoadingState] = useState<LoadingState>({ status: 'NotStarted' });
  useEffect(() => {
    if (accessToken === undefined) {
      return;
    }

    return LoadState(setLoadingState, async () => {
      const res = await GetAllResearchProjects(accessToken);
      setResearchProjects([...res]);
    });
  }, [accessToken]);

  const [name, setName] = useState<string | null>(null);
  const [selectedResearchProject, setSelectedResearchProject] = useState<ResearchProject | null>(null);

  return (
    <Dialog open={open} onClose={onCancel} fullWidth maxWidth='xs' scroll='paper' sx={{ height: '100%' }}>
      <DialogTitle>{t('chooseResearchProject')}</DialogTitle>
      <DialogContent sx={{ paddingTop: `${theme.spacing(1)} !important` }}>
        <Stack direction='column' spacing={2}>
          <TextField
            label={t('nameOptional')}
            value={name ?? ''}
            onChange={e => {
              const newName = e.target.value.trim();
              if (newName.length > 0) {
                setName(e.target.value);
              } else {
                setName(null);
              }
            }}
          />

          <Autocomplete
            disabled={loadingState.status !== 'Complete'}
            options={researchProjects.filter(rp => !disallowedResearchProjectIds.includes(rp.researchProjectId))}
            getOptionLabel={option => option.name}
            renderInput={params => <TextField {...params} label={t('researchProject')} />}
            value={selectedResearchProject}
            onChange={(_, value) => setSelectedResearchProject(value)}
          />
          <LoadingIndicator loadingState={loadingState} />
          <ErrorIndicator loadingState={loadingState} />
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button variant='outlined' color='error' onClick={onCancel}>
          {t('cancel')}
        </Button>
        <Button
          variant='contained'
          color='primary'
          disabled={selectedResearchProject === null}
          onClick={() => {
            if (selectedResearchProject !== null) {
              onAdd(name, selectedResearchProject);
              setName(null);
              setSelectedResearchProject(null);
            }
          }}
        >
          {t('add')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
