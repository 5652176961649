import useAuth from '../../auth/UseAuth';
import { useEffect, useState } from 'react';
import { orderBy } from 'lodash';
import { GetIssues, SdfIssue } from '../../data/SuperDuperFiestaData';

const useSdfIssues = () => {
  const { accessToken } = useAuth();
  const [sdfIssues, setSdfIssues] = useState<ReadonlyArray<SdfIssue>>([]);

  const fetchIssues = async () => {
    const data = await GetIssues(accessToken);
    setSdfIssues(orderBy(data, i => i.name));
  };

  useEffect(() => {
    let isCancelled = false;

    GetIssues(accessToken).then(data => {
      if (isCancelled) {
        return;
      }
      setSdfIssues(orderBy(data, i => i.name));
    });

    return () => {
      isCancelled = true;
    };
  }, [accessToken]);

  return { issues: sdfIssues, refresh: fetchIssues };
};

export default useSdfIssues;
