import { FlexBox } from './FlexBox';
import { DialogTitle } from '@mui/material';
import { DialogTitleProps } from '@mui/material/DialogTitle/DialogTitle';
import { DialogCloseButton } from './DialogCloseButton';
import * as React from 'react';

export type DialogHeaderProps = { handleClose: () => void } & DialogTitleProps;
export function DialogHeader(props: DialogHeaderProps) {
  return (
    <FlexBox
      sx={{ gap: 0, flexDirection: 'column', padding: '24px', paddingTop: '16px', paddingBottom: 0, ...props.sx }}
    >
      <DialogCloseButton
        onClick={() => {
          props.handleClose();
        }}
      />
      <DialogTitle sx={{ padding: 0 }}>{props.title}</DialogTitle>
      {props.children}
    </FlexBox>
  );
}
