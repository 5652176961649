import React, { useState } from 'react';
import { DataProduct, DataProductDataset } from '../../data/DataProductData';
import { DatasetsTable } from './DatasetsTable';
import { LoadingState } from '../../components/LoadingStateUtil';
import { ResearchProject } from '../../data/ResearchProjectData';
import { DatasetCreationDialog } from './DatasetCreationDialog';
import { createPortal } from 'react-dom';
import { AuthorizedSection } from '../../auth/AuthorizedSection';
import { DialogOpenButton } from '../../components/DialogOpenButton';

interface DatasetsEditorProps {
  loadingState: LoadingState;
  dataProduct: DataProduct | null;
  onChange: (newDataProduct: DataProduct) => unknown;
  toolbarRef: React.RefObject<Element | null>;
}

export const DatasetsEditor: React.FC<DatasetsEditorProps> = ({ loadingState, dataProduct, onChange, toolbarRef }) => {
  const [creationDialogOpen, setCreationDialogOpen] = useState(false);

  const onAddDataset = (name: string | null, researchProject: ResearchProject) => {
    if (dataProduct === null) {
      return;
    }

    const newDataset: DataProductDataset = {
      dataProductId: dataProduct.dataProductId,
      researchProjectId: researchProject.researchProjectId,
      name,
      researchProjectName: researchProject.name,
    };
    const newDatasets = [...dataProduct.datasets, newDataset];
    newDatasets.sort((a, b) => (a.name ?? a.researchProjectName).localeCompare(b.name ?? b.researchProjectName));

    const newDataProduct = { ...dataProduct, datasets: newDatasets };
    onChange(newDataProduct);
  };

  const onDeleteDataset = (researchProjectId: string) => {
    if (dataProduct === null) {
      return;
    }

    const newDatasets = dataProduct.datasets.filter(ds => ds.researchProjectId !== researchProjectId);
    const newDataProduct = { ...dataProduct, datasets: newDatasets };
    onChange(newDataProduct);
  };

  const CustomToolbar: React.FC = () => {
    return (
      <AuthorizedSection hasGreenSideWriteAccess>
        <DialogOpenButton title='Add Dataset' onClick={() => setCreationDialogOpen(true)} />
      </AuthorizedSection>
    );
  };

  return (
    <>
      <DatasetsTable
        loadingState={loadingState}
        datasets={dataProduct?.datasets ?? []}
        onDeleteDataset={onDeleteDataset}
      />
      <DatasetCreationDialog
        open={creationDialogOpen}
        disallowedResearchProjectIds={dataProduct?.datasets.map(ds => ds.researchProjectId) ?? []}
        onAdd={(name, researchProject) => {
          onAddDataset(name, researchProject);
          setCreationDialogOpen(false);
        }}
        onCancel={() => setCreationDialogOpen(false)}
      />
      {toolbarRef.current && createPortal(<CustomToolbar />, toolbarRef.current as Element)}
    </>
  );
};
