import { StateCreator } from 'zustand';
import { AccessTokenSlice } from './accessTokenSlice';
import {
  GetPatientEvents,
  GetPatientJourney,
  GetPatientLatestReview,
  GetPatientMeasurements,
  GetPatientTests,
  GetPatientWeeklyCounts,
  GetSampleCollectionDates,
  PatientApprovalPatientData,
} from '../../../../data/PatientApprovalData';
import { initialLoadableData, LoadableData, loadedData, trackLoading } from '../../utils/loadingUtils';

export interface PatientDataSlice {
  patientData: LoadableData<PatientApprovalPatientData>;
  setPatientData: (patientData: PatientApprovalPatientData | undefined) => void;
  loadPatientDataFromServer: (researchProjectId: string, deidentity: string) => void;
}

export const createPatientDataSlice: StateCreator<AccessTokenSlice & PatientDataSlice, [], [], PatientDataSlice> = (
  set,
  get
) => ({
  patientData: initialLoadableData(),
  setPatientData: patientData => set({ patientData: loadedData(patientData) }),
  loadPatientDataFromServer: (researchProjectId: string, deidentity: string) => {
    const accessToken = get().accessToken;

    if (accessToken === undefined) {
      return;
    }

    trackLoading(
      () => GetPatientJourney(researchProjectId, deidentity, accessToken),
      state =>
        set({
          patientData: {
            ...get().patientData,
            data: {
              ...(get().patientData.data as PatientApprovalPatientData),
              patientJourney: state.data ?? [],
            },
          },
        })
    );
    trackLoading(
      () => GetPatientTests(researchProjectId, deidentity, accessToken),
      state =>
        set({
          patientData: {
            ...get().patientData,
            data: {
              ...(get().patientData.data as PatientApprovalPatientData),
              tests: state.data ?? [],
            },
          },
        })
    );
    trackLoading(
      () => GetPatientWeeklyCounts(researchProjectId, deidentity, accessToken),
      state =>
        set({
          patientData: {
            ...get().patientData,
            data: {
              ...(get().patientData.data as PatientApprovalPatientData),
              weeklyCounts: state.data ?? [],
            },
          },
        })
    );
    trackLoading(
      () => GetPatientMeasurements(researchProjectId, deidentity, accessToken),
      state =>
        set({
          patientData: {
            ...get().patientData,
            data: {
              ...(get().patientData.data as PatientApprovalPatientData),
              measurements: state.data ?? [],
            },
          },
        })
    );
    trackLoading(
      () => GetPatientEvents(researchProjectId, deidentity, accessToken),
      state =>
        set({
          patientData: {
            ...get().patientData,
            data: {
              ...(get().patientData.data as PatientApprovalPatientData),
              events: state.data ?? [],
            },
          },
        })
    );
    trackLoading(
      () => GetSampleCollectionDates(deidentity, accessToken),
      state =>
        set({
          patientData: {
            ...get().patientData,
            data: {
              ...(get().patientData.data as PatientApprovalPatientData),
              sampleCollectionDates: state.data ?? [],
            },
          },
        })
    );
    trackLoading(
      () => GetPatientLatestReview(researchProjectId, deidentity, accessToken),
      state =>
        set({
          patientData: {
            ...get().patientData,
            data: {
              ...(get().patientData.data as PatientApprovalPatientData),
              review: state.data ?? null,
            },
          },
        })
    );
  },
});
