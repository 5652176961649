import { appSettings } from '../AppSettings';
import { BaseGet } from './BaseData';

export interface DataSchema {
  dataSchemaId: string;
  name: string;
  tables: DataSchemaTable[];
}

export interface DataSchemaTable {
  dataSchemaTableId: string;
  dataSchemaId: string;
  leapfrogModelId: string;
  name: string;
  fields: DataSchemaField[];
}

export interface DataSchemaField {
  dataSchemaFieldId: string;
  dataSchemaTableId: string;
  name: string;
  fieldOrder: number;
}

export async function GetDataSchemas(includeLinkedEntities: boolean, accessToken: string): Promise<DataSchema[]> {
  return await BaseGet(
    `${appSettings.api.endpoint}/api/v2/DataSchema?includeLinkedEntities=${includeLinkedEntities}`,
    accessToken
  );
}

export async function GetDataSchema(dataSchemaId: string, accessToken: string): Promise<DataSchema | null> {
  return await BaseGet(`${appSettings.api.endpoint}/api/v2/DataSchema/${dataSchemaId}`, accessToken);
}
