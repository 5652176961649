export function formatDateTime(dateVal?: Date): string {
  const date = dateVal ?? new Date();
  const year = date.getUTCFullYear();
  const month = padZero(date.getUTCMonth() + 1); // Month is zero-based
  const day = padZero(date.getUTCDate());
  const hours = padZero(date.getUTCHours());
  const minutes = padZero(date.getUTCMinutes());

  return `${year}-${month}-${day}-${hours}-${minutes}`;
}

function padZero(num: number): string {
  return num < 10 ? `0${num}` : `${num}`;
}
