import { DataProduct } from '../data/DataProductData';
import React from 'react';
import { GridColDef } from '@mui/x-data-grid-pro';
import { Link } from 'react-router-dom';
import { GridRenderCellParams } from '@mui/x-data-grid/models/params/gridCellParams';
import { CompactGridWrapper } from '../components/grid/CompactGridWrapper';
import { Box, IconButton } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { t } from 'i18next';
import { LoadingState } from '../components/LoadingStateUtil';

interface DataProductsTableProps {
  loadingState: LoadingState;
  dataProducts: DataProduct[];
  onDeleteDataProduct: (dataProductId: string) => unknown;
}

export const DataProductsTable: React.FC<DataProductsTableProps> = ({
  loadingState,
  dataProducts,
  onDeleteDataProduct,
}) => {
  const getRowId = (row: DataProduct) => row.dataProductId;

  const DeleteCell: React.FC<GridRenderCellParams<DataProduct>> = params => {
    return (
      <IconButton size='small' onClick={() => onDeleteDataProduct(getRowId(params.row))}>
        <DeleteIcon fontSize='small' />
      </IconButton>
    );
  };

  const columns: GridColDef<DataProduct>[] = [
    {
      field: 'name',
      headerName: t('name'),
      type: 'string',
      flex: 1,
      renderCell: DataProductName,
    },
    {
      field: 'dataSchemaName',
      headerName: t('schema'),
      type: 'string',
      flex: 1,
      renderCell: DataSchemaName,
    },
    {
      field: 'delete',
      headerName: t('delete'),
      type: 'string',
      flex: 0,
      valueGetter: () => true,
      renderCell: DeleteCell,
    },
  ];

  return (
    <Box flex={1} sx={{ position: 'relative' }}>
      <CompactGridWrapper loadingState={loadingState} columns={columns} rows={dataProducts} getRowId={getRowId} />
    </Box>
  );
};

const DataProductName: React.FC<GridRenderCellParams<DataProduct>> = params => {
  return <Link to={`/data-products/${params.row.dataProductId}`}>{params.value}</Link>;
};

const DataSchemaName: React.FC<GridRenderCellParams<DataProduct>> = params => {
  return <Link to={`/data-products/schemas/${params.row.dataSchemaId}`}>{params.value}</Link>;
};
