import { Box, Tab, Tabs, Typography, useTheme } from '@mui/material';
import { AuthorizedPage } from 'auth/AuthorizedPage';
import { AuthorizedSection } from 'auth/AuthorizedSection';
import useAuth from 'auth/UseAuth';
import { LoadingStatus } from 'components/LoadingStatus';
import { Page } from 'components/Page';
import { Biobank, GetBiobanks } from 'data/BiobankData';
import { GetInventoryCheckRequests, InventoryCheckRequest } from 'data/InventoryCheckRequest';
import { GetResearchProjectDetails, ResearchProjectDetails } from 'data/ResearchProjectData';
import { orderBy } from 'lodash';
import React, { useEffect, useState } from 'react';
import { Spinner } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { ResearchProjectCohortGrid } from 'researchProjectManagement/cohortGrid/ResearchProjectCohortGrid';
import { CreateInventoryCheckRequestModal } from 'researchProjectManagement/inventoryCheckRequests/CreateInventoryCheckRequestModal';
import { InventoryCheckRequestGrid } from 'researchProjectManagement/inventoryCheckRequests/InventoryCheckRequestGrid';
import { UpdateInventoryCheckRequestModal } from 'researchProjectManagement/inventoryCheckRequests/UpdateInventoryCheckRequestModal';
import { ResearchProjectCohortExportButton } from 'researchProjectManagement/projectsGrid/ResearchProjectCohortExportButton';
import { UpdateResearchProjectCohortModal } from 'researchProjectManagement/UpdateResearchProjectCohortModal';
import { SampleTrackingGrid } from '../researchProjectManagement/sampleTracking/SampleTrackingGrid';
import { PageContent } from '../components/PageContent';
import { FlexTableBox } from '../components/FlexTableBox';
import { SampleTrackingOrderGrid } from '../researchProjectManagement/sampleTracking/orders/SampleTrackingOrderGrid';
import { SampleTrackingCheckByGrid } from '../researchProjectManagement/sampleTracking/SampleTrackingCheckByGrid';
import useMemoTranslation from '../hooks/UseMemoTranslation';
import { GetResearchProjectEnrichmentUpdatedAtAsync } from '../data/EnrichmentsData';
import { RefreshClinicalDataButton } from '../researchProjectManagement/projectsGrid/RefreshClinicalDataButton';
import { SampleTrackingAuthSection } from '../auth/SampleTrackingAuthSection';
import { GreenAuthorizedSection } from '../auth/GreenAuthorizedSection';
import { useUserPermissions } from '../auth/UseUserPermissions';
import { SampleJourneyInformaticsGrid } from '../researchProjectManagement/sampleJourney/SampleJourneyInformaticsResultGrid';
import PatientApprover from '../researchProjectManagement/patientApproval/pages/PatientApprover';

type PageTabs =
  | 'patientApproval'
  | 'patientCohort'
  | 'inventoryChecks'
  | 'sampleTracking'
  | 'orders'
  | 'checkBys'
  | 'informatics';

export const ResearchProjectCohortPage = () => {
  const { accessToken } = useAuth();
  const { hasSampleTrackingViewAccess, hasGreenSideViewAccess } = useUserPermissions();

  let { researchProjectId } = useParams() as { researchProjectId: string };
  const theme = useTheme();
  const { t } = useMemoTranslation();

  const [status, setStatus] = useState<LoadingStatus>('NotStarted');
  const [selectedTab, setSelectedTab] = useState<PageTabs>();
  const [researchProject, setResearchProject] = useState<ResearchProjectDetails>();
  const [inventoryCheckRequests, setInventoryCheckRequests] = useState<InventoryCheckRequest[]>([]);
  const [biobankFacilites, setBiobankFacilites] = useState<Biobank[]>([]);
  const [enrichmentUpdatedAt, setEnrichmentUpdatedAt] = useState<Date>();

  useEffect(() => {
    if (hasSampleTrackingViewAccess) {
      setSelectedTab('sampleTracking');
    } else if (hasGreenSideViewAccess) {
      setSelectedTab('patientCohort');
    }
  }, [hasGreenSideViewAccess, hasSampleTrackingViewAccess]);

  useEffect(() => {
    async function getState() {
      if (!accessToken || !hasGreenSideViewAccess) {
        return;
      }

      setStatus('Loading');
      setResearchProject(await GetResearchProjectDetails(researchProjectId, accessToken));

      const values = await GetInventoryCheckRequests(researchProjectId, accessToken);
      setInventoryCheckRequests([...orderBy(values, [(irc: InventoryCheckRequest) => irc.createdAt], ['desc'])]);
      setBiobankFacilites(await GetBiobanks(accessToken));

      const updatedAt = await GetResearchProjectEnrichmentUpdatedAtAsync(researchProjectId, accessToken);
      setEnrichmentUpdatedAt(updatedAt);
      setStatus('Complete');
    }

    getState();
  }, [researchProjectId, accessToken, hasGreenSideViewAccess]);

  const handleTabChange = async (event: React.SyntheticEvent, newValue: PageTabs) => {
    setSelectedTab(newValue);
  };

  async function handleResearchProjectCohortUpdate() {
    if (accessToken) {
      setStatus('Loading');
      setResearchProject(await GetResearchProjectDetails(researchProjectId, accessToken));
      setStatus('Complete');
    }
  }

  async function onInventoryCheckRequestUpdate() {
    if (accessToken) {
      setStatus('Loading');
      const values = await GetInventoryCheckRequests(researchProjectId, accessToken);
      setInventoryCheckRequests([...orderBy(values, [(irc: InventoryCheckRequest) => irc.createdAt], ['desc'])]);
      setStatus('Complete');
    }
  }

  function GetEnrichmentUpdatedAt() {
    if (!enrichmentUpdatedAt) {
      return;
    }
    return new Date(enrichmentUpdatedAt).toLocaleString('en-US', {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      hour: 'numeric',
      minute: '2-digit',
    });
  }

  return (
    <AuthorizedPage requireAny hasSampleTrackingViewAccess hasGreenSideViewAccess>
      <Page
        title='Research Project Management'
        subtitle={`${researchProject?.customer.name ?? ''} - ${researchProject?.name ?? ''}`}
        goBackUrl={'/research-project'}
      >
        <PageContent>
          <Box mb={1}>
            <Tabs value={selectedTab} onChange={handleTabChange}>
              {hasSampleTrackingViewAccess && (
                <Tab value='sampleTracking' label='Sample Tracking' sx={{ color: theme.colors.alpha.black[100] }} />
              )}

              {hasGreenSideViewAccess && (
                <Tab value='patientApproval' label='Approval' sx={{ color: theme.colors.alpha.black[100] }} />
              )}

              {hasGreenSideViewAccess && (
                <Tab value='patientCohort' label='Cohort' sx={{ color: theme.colors.alpha.black[100] }} />
              )}
              {hasGreenSideViewAccess && (
                <Tab value='inventoryChecks' label='Inventory Checks' sx={{ color: theme.colors.alpha.black[100] }} />
              )}

              {hasSampleTrackingViewAccess && (
                <Tab value='orders' label='Orders' sx={{ color: theme.colors.alpha.black[100] }} />
              )}
              {hasSampleTrackingViewAccess && (
                <Tab value='checkBys' label={t('checkBys')} sx={{ color: theme.colors.alpha.black[100] }} />
              )}
              {hasSampleTrackingViewAccess && (
                <Tab value='informatics' label={t('informatics')} sx={{ color: theme.colors.alpha.black[100] }} />
              )}
            </Tabs>
          </Box>

          {selectedTab === 'patientApproval' && (
            <GreenAuthorizedSection>
              <Box sx={{ width: '100%', height: '100%', paddingTop: theme.spacing(1) }}>
                <PatientApprover researchProjectId={researchProjectId} />
              </Box>
            </GreenAuthorizedSection>
          )}

          {selectedTab === 'patientCohort' && (
            <GreenAuthorizedSection>
              <FlexTableBox>
                <Box mb={1} display='flex' justifyContent='right'>
                  <ResearchProjectCohortExportButton
                    researchProjectId={researchProjectId}
                    cohortType='patient'
                    useDecryptedHashes={true}
                  />
                  <ResearchProjectCohortExportButton
                    researchProjectId={researchProjectId}
                    cohortType='sample'
                    useDecryptedHashes={true}
                  />
                  <AuthorizedSection hasGreenSideWriteAccess>
                    <RefreshClinicalDataButton researchProjectId={researchProjectId} />

                    <UpdateResearchProjectCohortModal onExcelUpload={handleResearchProjectCohortUpdate} />
                  </AuthorizedSection>
                </Box>
                {status === 'Complete' && (
                  <ResearchProjectCohortGrid researchProjectId={researchProjectId} autoHeight={false} />
                )}
              </FlexTableBox>
            </GreenAuthorizedSection>
          )}

          {selectedTab === 'inventoryChecks' && (
            <GreenAuthorizedSection>
              <FlexTableBox>
                <AuthorizedSection hasGreenSideWriteAccess>
                  <Box mb={1} display='flex' justifyContent='right'>
                    <UpdateInventoryCheckRequestModal onUpdateInventoryCheckRequest={onInventoryCheckRequestUpdate} />
                    <CreateInventoryCheckRequestModal
                      researchProjectId={researchProjectId}
                      onCreateInventoryCheckRequest={onInventoryCheckRequestUpdate}
                    />
                  </Box>
                </AuthorizedSection>

                {status === 'Loading' && (
                  <Box display='flex' alignItems='center' justifyContent='center'>
                    <Spinner animation='border' />{' '}
                  </Box>
                )}
                {status === 'Complete' && (
                  <InventoryCheckRequestGrid
                    inventoryCheckRequests={inventoryCheckRequests}
                    biobankFacilites={biobankFacilites}
                    onInventoryCheckRequestUpdate={onInventoryCheckRequestUpdate}
                    autoHeight={false}
                  />
                )}
              </FlexTableBox>
            </GreenAuthorizedSection>
          )}

          {selectedTab === 'sampleTracking' && (
            <SampleTrackingAuthSection>
              <FlexTableBox>
                <SampleTrackingGrid researchProjectId={researchProjectId} />
              </FlexTableBox>
            </SampleTrackingAuthSection>
          )}

          {selectedTab === 'orders' && (
            <SampleTrackingAuthSection>
              <FlexTableBox>
                <SampleTrackingOrderGrid researchProjectId={researchProjectId} />
              </FlexTableBox>
            </SampleTrackingAuthSection>
          )}

          {selectedTab === 'checkBys' && (
            <SampleTrackingAuthSection>
              <FlexTableBox>
                <SampleTrackingCheckByGrid researchProjectId={researchProjectId} />
              </FlexTableBox>
            </SampleTrackingAuthSection>
          )}

          {selectedTab === 'informatics' && (
            <SampleTrackingAuthSection>
              <FlexTableBox>
                <SampleJourneyInformaticsGrid researchProjectId={researchProjectId} />
              </FlexTableBox>
            </SampleTrackingAuthSection>
          )}

          {selectedTab === 'patientCohort' && enrichmentUpdatedAt && (
            <Typography mt={2}>{`Clinical Data Refreshed At: ${GetEnrichmentUpdatedAt()}`}</Typography>
          )}
        </PageContent>
      </Page>
    </AuthorizedPage>
  );
};
