import { AppBar, Dialog, DialogContent, IconButton, Toolbar } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import React, { useState } from 'react';
import { SampleTrackingSelectedSampleGrid } from './SampleTrackingSelectedSampleGrid';
import { TransitionTypeExtended } from './SampleTrackingGrid';
import {
  TransitionSampleGrid,
  TransitionSampleOrderScopedGrid,
  TransitionSampleCheckByGroupScopedGrid,
} from './SampleTrackingTransitionSampleGrid';
import { TransitionType } from '../../data/SampleTrackingData';
import { SampleTrackingOrderRow } from './orders/SampleTrackingOrderGrid';
import { TransitionIcon } from '../../util/TransitionUtil';

export interface BaseModalProps {
  researchProjectId: string;
  title: string;
  onClose: () => void;
}

export interface SampleTrackingTransitionRecordModalProps extends BaseModalProps {
  sampleGroup?: string;
  transitionType?: TransitionTypeExtended;
  configuredTransitionId: string;
}

export interface SampleTrackingSelectedTransitionRecordModalProps extends BaseModalProps {
  sampleGroup?: string;
  transitionType?: TransitionTypeExtended;
}

export interface SampleTrackingTransitionSampleModalProps extends BaseModalProps {
  transitionType: TransitionType;
  transitionIds: ReadonlyArray<string>;
  orderDetails: SampleTrackingOrderRow;
}

export interface SampleTrackingTransitionSampleCheckByGroupScopedModalProps extends BaseModalProps {
  transitionType: TransitionType;
  checkByGroupId: string;
}

export interface ModalProps {
  title: string;
  onClose: () => void;
  children: React.ReactNode;
  transitionType: TransitionTypeExtended;
}

export const SampleTrackingTransitionSampleModal = ({
  researchProjectId,
  sampleGroup,
  transitionType,
  title,
  onClose,
  configuredTransitionId,
}: SampleTrackingTransitionRecordModalProps) => {
  return (
    <Modal onClose={onClose} title={title} transitionType={transitionType as TransitionTypeExtended}>
      <TransitionSampleGrid
        researchProjectId={researchProjectId}
        transitionType={transitionType as TransitionType}
        sampleGroup={sampleGroup}
        configuredTransitionId={configuredTransitionId}
      />
    </Modal>
  );
};

export const SampleTrackingSelectedTransitionSampleModal = ({
  researchProjectId,
  sampleGroup,
  transitionType,
  title,
  onClose,
}: SampleTrackingSelectedTransitionRecordModalProps) => {
  return (
    <Modal onClose={onClose} title={title} transitionType={transitionType as TransitionTypeExtended}>
      <SampleTrackingSelectedSampleGrid researchProjectId={researchProjectId} sampleGroup={sampleGroup} />
    </Modal>
  );
};

export const SampleTrackingTransitionSampleOrderScopedModal = ({
  researchProjectId,
  transitionType,
  transitionIds,
  title,
  onClose,
  orderDetails,
}: SampleTrackingTransitionSampleModalProps) => {
  return (
    <Modal onClose={onClose} title={title} transitionType={transitionType}>
      <TransitionSampleOrderScopedGrid
        researchProjectId={researchProjectId}
        transitionIds={transitionIds}
        transitionType={transitionType}
        orderDetails={orderDetails}
      />
    </Modal>
  );
};

export const SampleTrackingTransitionSampleCheckByGroupScopedModal = ({
  researchProjectId,
  transitionType,
  checkByGroupId,
  title,
  onClose,
}: SampleTrackingTransitionSampleCheckByGroupScopedModalProps) => {
  return (
    <Modal onClose={onClose} title={title} transitionType={transitionType}>
      <TransitionSampleCheckByGroupScopedGrid
        researchProjectId={researchProjectId}
        checkByGroupId={checkByGroupId}
        transitionType={transitionType}
      />
    </Modal>
  );
};

const Modal = ({ title, onClose, children, transitionType }: ModalProps) => {
  const [modalOpen, setModalOpen] = useState<boolean>(true);

  const handleClose = async () => {
    setModalOpen(false);
    onClose();
  };

  return (
    <>
      <Dialog fullScreen={true} onClose={handleClose} open={modalOpen}>
        <AppBar sx={{ position: 'relative', backgroundColor: 'black' }}>
          <Toolbar>
            <IconButton edge='start' color='inherit' onClick={handleClose} aria-label='close'>
              <CloseIcon />
            </IconButton>
            <TransitionIcon transitionType={transitionType} text={title} sx={{ ml: 1, flex: 1 }} variant='h4' />
          </Toolbar>
        </AppBar>
        <DialogContent>{children}</DialogContent>
      </Dialog>
    </>
  );
};
