import React from 'react';
import { LoadingState } from './LoadingStateUtil';
import { Alert } from '@mui/material';

export interface WarningIndicatorProps {
  loadingState: LoadingState;
}

export const WarningIndicator = ({ loadingState }: WarningIndicatorProps) => {
  if (loadingState.status !== 'Warning') {
    return <></>;
  }

  const msg = loadingState.warningMessage?.trim().length === 0 ? 'Unknown Warning' : loadingState.warningMessage;

  return (
    <Alert sx={{ margin: 2 }} severity='warning'>
      {msg}
    </Alert>
  );
};
