import * as React from 'react';
import { useState } from 'react';
import useMemoTranslation from '../../hooks/UseMemoTranslation';
import { DialogOpenButton } from '../../components/DialogOpenButton';
import FullscreenOutlinedIcon from '@mui/icons-material/FullscreenOutlined';
import { Dialog, DialogContent, DialogTitle, Typography } from '@mui/material';
import { DialogCloseButton } from '../../components/DialogCloseButton';
import { PipelineQueryHistoryGrid } from './PipelineQueryHistoryGrid';
import { LoadingIndicator } from '../../components/LoadingIndicator';
import { ErrorIndicator } from '../../components/ErrorIndicator';
import { LoadingState } from '../../components/LoadingStateUtil';
import { FlexTableBox } from '../../components/FlexTableBox';

export const PipelineQueryHistoryDialog = ({ executedPipelineHistoryId }: { executedPipelineHistoryId: string }) => {
  const { t } = useMemoTranslation();

  const [open, setOpen] = useState<boolean>(false);
  const [loadingState, setLoadingState] = useState<LoadingState>({ status: 'NotStarted' });

  function handleClose() {
    setOpen(false);
  }

  return (
    <>
      <DialogOpenButton onClick={() => setOpen(true)}>
        <FullscreenOutlinedIcon />
      </DialogOpenButton>
      <Dialog open={open} onClose={handleClose} maxWidth={'xl'}>
        <DialogTitle>
          <DialogCloseButton onClick={() => handleClose()} />
          {t('pipelineQueryHistory')}
          <Typography sx={{ whiteSpace: 'pre-line', wordWrap: 'wrap' }}>{executedPipelineHistoryId}</Typography>
        </DialogTitle>
        <DialogContent>
          <FlexTableBox sx={{ height: '60vh' }}>
            <PipelineQueryHistoryGrid
              loadingProps={[loadingState, setLoadingState]}
              executedPipelineHistoryId={executedPipelineHistoryId}
            />
          </FlexTableBox>
          <LoadingIndicator loadingState={loadingState} margin={'TB'} />
          <ErrorIndicator loadingState={loadingState} />
        </DialogContent>
      </Dialog>
    </>
  );
};
