import React from 'react';
import { Box, Stack, useTheme } from '@mui/material';
import MeasurementSelector from '../measurementSelector/MeasurementSelector';
import GraphGroup from '../graphGroup/GraphGroup';
import ShowDataWithIssuesButton from '../common/ShowDataWithIssuesButton';

const CuratedDataGroup: React.FC = () => {
  const theme = useTheme();

  return (
    <Stack
      direction='column'
      spacing={1}
      justifyContent='center'
      alignItems='stretch'
      flex={1}
      sx={{ color: theme.palette.text.primary }}
    >
      <Stack direction='row' alignItems='center' spacing={1}>
        <Box flex={1}>
          <MeasurementSelector />
        </Box>

        <ShowDataWithIssuesButton />
      </Stack>
      <GraphGroup />
    </Stack>
  );
};

export default CuratedDataGroup;
