import { BaseGet, BasePost } from './BaseData';
import { appSettings } from '../AppSettings';
import { Dictionary } from 'lodash';

export type InformaticsPipeline = {
  pipelineId: string;
  organismTypeId: number;
  organismTypeName: string;
  libraryTypeId: string;
  libraryTypeName: string;
  name: string;
  isAvailable: boolean;
  versionNumber: string;
  supportedReferences: ReadonlyArray<InformaticsReference>;
};

export type InformaticsReference = {
  referenceTypeId: number;
  name: string;
  baseName: string;
  relativePath: string;
};

export async function GetPipelines(accessToken?: string): Promise<ReadonlyArray<InformaticsPipeline>> {
  return await BaseGet(`${appSettings.api.endpoint}/api/v2/Informatics/Pipelines`, accessToken);
}

export async function GetReferences(accessToken?: string): Promise<ReadonlyArray<InformaticsReference>> {
  return await BaseGet(`${appSettings.api.endpoint}/api/v2/Informatics/References`, accessToken);
}

export interface InformaticsComputedValues {
  sequenceRunId: string;
  globalValues: Dictionary<any>;
  perReferenceValues: Dictionary<Dictionary<any>>;
}

export async function GetInformaticsComputedValues(
  sequenceRunIds: ReadonlyArray<string>,
  accessToken?: string
): Promise<ReadonlyArray<InformaticsComputedValues>> {
  return await BasePost(`${appSettings.api.endpoint}/api/v2/Informatics/ComputedValues`, sequenceRunIds, accessToken);
}
