import React, { useState } from 'react';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import {
  Control,
  FieldArray,
  UseFieldArrayAppend,
  UseFieldArrayUpdate,
  UseFieldArrayRemove,
  UseFormTrigger,
} from 'react-hook-form';
import { DialogCloseButton } from '../../../components/DialogCloseButton';
import { DialogOpenButton } from '../../../components/DialogOpenButton';
import { IRuleFormInput } from '../../../contexts/PipelineRulesContext';
import { StepUpdateValueList } from './StepUpdateValueList';

export const StepUpdateValueModal = ({
  control,
  trigger,
  fields,
  append,
  remove,
  update,
  disabled,
}: {
  control: Control<IRuleFormInput, any>;
  trigger: UseFormTrigger<IRuleFormInput>;
  fields: FieldArray<IRuleFormInput, 'stepUpdateValues'>[];
  append: UseFieldArrayAppend<IRuleFormInput, 'stepUpdateValues'>;
  remove: UseFieldArrayRemove;
  update: UseFieldArrayUpdate<IRuleFormInput, 'stepUpdateValues'>;
  disabled: boolean;
}) => {
  const [open, setOpen] = useState<boolean>(false);

  const handleOpen = async () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <DialogOpenButton
        title={`See all (${fields.length})`}
        onClick={handleOpen}
        disableMarginLeft={true}
        disabled={disabled}
      >
        {''}
      </DialogOpenButton>
      <Dialog open={open} onClose={() => setOpen(false)} fullWidth maxWidth='sm'>
        <DialogTitle>
          <DialogCloseButton onClick={handleClose} />
          Update Values
        </DialogTitle>
        <DialogContent>
          {open && (
            <Box component='form' sx={{ flexGrow: 1, mt: 1 }}>
              <StepUpdateValueList
                control={control}
                trigger={trigger}
                fields={fields}
                append={append}
                remove={remove}
                update={update}
                disabled={disabled}
              />
            </Box>
          )}
        </DialogContent>
        <DialogActions>
          <Button color={'secondary'} onClick={handleClose}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
