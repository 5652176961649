import { LoadingState, LoadState } from '../LoadingStateUtil';
import { Dictionary, UseStateSetter } from '../../util/TypeUtil';
import {
  ConfiguredTransition,
  GetConfiguredTransitionsForResearchProjects,
  GetResearchProjectConfiguredTransitions,
} from '../../data/ConfiguredTransitionData';
import useAuth from '../../auth/UseAuth';
import { useEffect, useState } from 'react';

export const useConfiguredTransitions = (
  researchProjectId: string,
  setLoadingState: UseStateSetter<LoadingState>
): [ReadonlyArray<ConfiguredTransition>, () => void] => {
  const { accessToken } = useAuth();
  const [researchProjectTransitions, setResearchProjectTransitions] = useState<ReadonlyArray<ConfiguredTransition>>([]);
  const [refresh, refreshTrigger] = useState<boolean>(true);

  useEffect(() => {
    return LoadState(setLoadingState, async () => {
      const data = await GetResearchProjectConfiguredTransitions(researchProjectId, accessToken);
      setResearchProjectTransitions(data);
    });
  }, [accessToken, researchProjectId, setLoadingState]);

  function refreshCallBack() {
    refreshTrigger(!refresh);
  }

  return [researchProjectTransitions, refreshCallBack];
};

export const useConfiguredTransition = (
  researchProjectId: string,
  configuredTransitionId: string | undefined,
  setLoadingState: UseStateSetter<LoadingState>
): ConfiguredTransition | undefined => {
  const { accessToken } = useAuth();
  const [configuredTransition, setConfiguredTransition] = useState<ConfiguredTransition | undefined>();

  useEffect(() => {
    return LoadState(setLoadingState, async () => {
      if (configuredTransitionId === undefined) {
        return;
      }

      const data = await GetResearchProjectConfiguredTransitions(researchProjectId, accessToken);
      setConfiguredTransition(data.find(i => i.configuredTransitionId === configuredTransitionId));
    });
  }, [accessToken, researchProjectId, configuredTransitionId, setLoadingState]);

  return configuredTransition;
};

export const useConfiguredTransitionsForResearchProjects = (
  researchProjectIds: ReadonlyArray<string>,
  setLoadingState: UseStateSetter<LoadingState>
): [Dictionary<ReadonlyArray<ConfiguredTransition>>, () => void] => {
  const { accessToken } = useAuth();
  const [configuredTransitions, setConfiguredTransitions] = useState<Dictionary<ReadonlyArray<ConfiguredTransition>>>(
    {}
  );
  const [refresh, refreshTrigger] = useState<boolean>(true);

  useEffect(() => {
    return LoadState(setLoadingState, async () => {
      setLoadingState({ status: 'Loading' });

      const configuredTransitions = await GetConfiguredTransitionsForResearchProjects(researchProjectIds, accessToken);
      const transitionByResearchProjectId = configuredTransitions.reduce(
        (dict: Record<string, ConfiguredTransition[]>, transition) => {
          dict[transition.researchProjectId] || (dict[transition.researchProjectId] = []);
          dict[transition.researchProjectId].push(transition);
          return dict;
        },
        {}
      );

      setConfiguredTransitions(transitionByResearchProjectId);
    });
  }, [refresh, accessToken, researchProjectIds, setLoadingState]);

  function refreshCallBack() {
    refreshTrigger(!refresh);
  }

  return [configuredTransitions, refreshCallBack];
};
