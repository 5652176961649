import React, { useEffect, useState } from 'react';
import { Box, Stack, Typography } from '@mui/material';
import { TablesEditorTable } from './TablesEditorTable';
import { DataProduct, DataProductField, DataProductTable } from '../../data/DataProductData';
import { FieldsEditorTable } from './FieldsEditorTable';
import { useUserPermissions } from '../../auth/UseUserPermissions';
import { t } from 'i18next';
import { LoadingState } from '../../components/LoadingStateUtil';

interface ProductSchemaEditorProps {
  loadingState: LoadingState;
  dataProduct: DataProduct | null;
  onChange: (newDataProduct: DataProduct) => unknown;
}

export const ProductSchemaEditor: React.FC<ProductSchemaEditorProps> = ({ loadingState, dataProduct, onChange }) => {
  const { hasGreenSideWriteAccess } = useUserPermissions();

  const [selectedTableId, setSelectedTableId] = useState<string | null>(null);

  const [selectedTable, setSelectedTable] = useState<DataProductTable | null>(null);
  useEffect(() => {
    if (dataProduct === null) {
      setSelectedTable(null);
      return;
    }

    if (selectedTableId === null) {
      setSelectedTable(null);
      return;
    }

    const table = dataProduct.tables.find(t => t.dataSchemaTableId === selectedTableId);
    if (table === undefined) {
      setSelectedTable(null);
      return;
    }

    setSelectedTable(table);
  }, [dataProduct, selectedTableId]);

  const onTablesChange = (newTables: DataProductTable[]) => {
    if (dataProduct === null) {
      return;
    }

    const newDataProduct = { ...dataProduct, tables: newTables };
    onChange(newDataProduct);
  };

  const onFieldsChange = (newFields: DataProductField[]) => {
    if (dataProduct === null || selectedTable === null) {
      return;
    }

    const newTable = { ...selectedTable, fields: newFields };
    const newDataProduct = {
      ...dataProduct,
      tables: dataProduct.tables.map(t => (t.dataSchemaTableId === selectedTableId ? newTable : t)),
    };
    onChange(newDataProduct);
  };

  return (
    <Stack flex={1} direction='row' spacing={2}>
      <Stack flex={1} direction='column' spacing={2}>
        <Typography flex={0} variant='h4'>
          {t('tables')}
        </Typography>
        <Box flex={1} sx={{ position: 'relative' }}>
          <Box sx={{ position: 'absolute', top: 0, left: 0, bottom: 0, right: 0 }}>
            <TablesEditorTable
              loadingState={loadingState}
              disabled={!hasGreenSideWriteAccess}
              tables={dataProduct?.tables ?? []}
              onTableSelected={setSelectedTableId}
              onChange={onTablesChange}
            />
          </Box>
        </Box>
      </Stack>
      <Stack flex={1} direction='column' spacing={2}>
        <Typography flex={0} variant='h4'>
          {t('fields')}
        </Typography>
        <Box flex={1} sx={{ position: 'relative' }}>
          <Box sx={{ position: 'absolute', top: 0, left: 0, bottom: 0, right: 0 }}>
            <FieldsEditorTable
              loadingState={loadingState}
              disabled={!hasGreenSideWriteAccess || selectedTable?.redacted}
              fields={
                selectedTable?.fields.filter(f => {
                  if (f.pointsToTableId === null) {
                    return true;
                  }

                  return !(dataProduct?.tables ?? [])
                    .filter(t => t.redacted)
                    .map(t => t.dataSchemaTableId)
                    .includes(f.pointsToTableId);
                }) ?? []
              }
              onChange={onFieldsChange}
            />
          </Box>
        </Box>
      </Stack>
    </Stack>
  );
};
