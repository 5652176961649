import { Box, IconButton, useTheme } from '@mui/material';
import { ChevronLeft } from '@mui/icons-material';
import MenuIcon from '@mui/icons-material/Menu';
import { BoxProps } from '@mui/material/Box/Box';

export interface SidebarControlsProps {
  open: boolean;
  setOpen: (value: boolean) => void;
  sx?: BoxProps['sx'];
}

export const SidebarControls = ({ open, setOpen, sx }: SidebarControlsProps) => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        ...sx,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: open ? 'flex-end' : 'center',
        padding: theme.spacing(1),
        pointerEvents: 'none',
      }}
    >
      <IconButton
        onClick={() => setOpen(!open)}
        sx={{
          pointerEvents: 'auto',
          color: theme.colors.alpha.trueWhite[70],
          '&:hover': {
            background: theme.colors.alpha.trueWhite[10],
          },
        }}
      >
        {open ? <ChevronLeft /> : <MenuIcon />}
      </IconButton>
    </Box>
  );
};
