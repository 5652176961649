import React, { useEffect, useState } from 'react';
import useAuth from 'auth/UseAuth';
import CloseIcon from '@mui/icons-material/Close';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Tab,
  Tabs,
  Typography,
  useTheme,
} from '@mui/material';
import { InformaticsCheckByRow, InformaticsQcMetricsGrid } from './InformaticsQcMetricsGrid';
import {
  CreateInformaticsCheckBy,
  SampleTrackingInformaticsCheckByDto,
  SampleTrackingInformaticsReferenceCheckByDto,
} from 'data/SampleTrackingData';
import { ErrorManagement, LoadingState, LoadState } from 'components/LoadingStateUtil';
import { cloneDeep, filter, find, groupBy, map, merge, uniqBy } from 'lodash';
import { DialogOpenButton } from 'components/DialogOpenButton';
import { recordCheckBy } from 'util/Constants';
import { ErrorIndicator } from 'components/ErrorIndicator';
import { LoadingIndicator } from 'components/LoadingIndicator';
import UseMemoTranslation from '../../hooks/UseMemoTranslation';
import { FlexTableBox } from '../../components/FlexTableBox';
import { CancelButton } from '../../components/CancelButton';
import { PrimaryButton } from '../../components/PrimaryButton';
import { SequenceType } from '../../data/InformaticsQcData';
import {
  DeliveryCandidateType,
  GetInformaticsPipelineRunResults,
  InformaticsPipelineResult,
} from '../../data/InformaticsCheckByData';
import { GridRowSelectionModel } from '@mui/x-data-grid-pro';
import { GetInformaticsComputedValues, InformaticsComputedValues } from '../../data/InformaticsData';

export type InformaticsCheckByData = {
  sampleId: string;
  transitionSampleId: string;
  sampleIdentifier: string;
  selectionStatus: string;
  checkByStatus: string;
  sequenceType: string;
  sequenceRunId: string;
  informaticsPipelineRunId: string;
};

export type InformaticsCheckByTabs = SequenceType | 'Other';

export const InformaticsCheckByModal = ({
  researchProjectId,
  data,
  onClose,
  configuredTransitionId,
}: {
  researchProjectId: string;
  data: ReadonlyArray<InformaticsCheckByData>;
  onClose: (hasChanges: boolean) => void;
  configuredTransitionId: string;
}) => {
  const { accessToken } = useAuth();

  const { t } = UseMemoTranslation();
  const theme = useTheme();
  const INITIAL_LOADING_STATE: LoadingState = { status: 'NotStarted' };
  const INITIAL_READY_TO_SUBMIT = false;
  const INITIAL_SEQ_TYPE = 'Other';

  const [open, setOpen] = useState<boolean>(false);
  const [DNACount, setDNACount] = useState<number>(0);
  const [RNACount, setRNACount] = useState<number>(0);
  const [TNACount, setTNACount] = useState<number>(0);
  const [otherCount, setOtherCount] = useState<number>(0);
  const [loadingState, setLoadingState] = useState<LoadingState>(INITIAL_LOADING_STATE);
  const [readyToSubmit, setReadyToSubmit] = useState<boolean>(INITIAL_READY_TO_SUBMIT);
  const [informaticsCheckByCache, setInformaticsCheckByCache] = useState<ReadonlyArray<InformaticsCheckByRow>>([]);
  const [gridSeqType, setGridSeqType] = useState<InformaticsCheckByTabs>(INITIAL_SEQ_TYPE);

  useEffect(() => {
    return LoadState(setLoadingState, async () => {
      if (!open) {
        return;
      }

      const sampleIds = map(data, i => i.sampleId);
      const transitionSampleIds = map(data, i => i.transitionSampleId);
      const sequenceRunIds = filter(
        map(data, i => i.sequenceRunId),
        i => !!i
      );

      if (sampleIds.length === 0 || !open) {
        return;
      }

      const [informaticsPipelineResults, informaticsComputedValues] = await Promise.all([
        await GetInformaticsPipelineRunResults(transitionSampleIds, accessToken),
        await GetInformaticsComputedValues(sequenceRunIds, accessToken),
      ]);

      setInformaticsCheckByCache(getRowData(data, informaticsPipelineResults, informaticsComputedValues));
    });
  }, [data, open, accessToken]);

  useEffect(() => {
    const data = uniqBy(informaticsCheckByCache, i => i.sampleId);

    setDNACount(data.filter(qc => qc.sequenceType === 'DNA').length);
    setRNACount(data.filter(qc => qc.sequenceType === 'RNA').length);
    setTNACount(data.filter(qc => qc.sequenceType === 'TNA').length);
    setOtherCount(data.filter(qc => !['DNA', 'RNA', 'TNA'].includes(qc.sequenceType)).length);
  }, [informaticsCheckByCache]);

  useEffect(() => {
    if (DNACount !== 0) {
      setGridSeqType('DNA');
    } else if (RNACount !== 0) {
      setGridSeqType('RNA');
    } else if (TNACount !== 0) {
      setGridSeqType('TNA');
    } else {
      setGridSeqType('Other');
    }
  }, [DNACount, RNACount, TNACount]);

  const handleSeqTypeTabChange = async (event: React.SyntheticEvent, newValue: SequenceType) => {
    setGridSeqType(newValue);
  };

  const handleButtonClick = async () => {
    setOpen(true);
  };

  const handleDeliveryCandidateMarking = (selectionModel: GridRowSelectionModel, selection: DeliveryCandidateType) => {
    let tempCache = [...informaticsCheckByCache];
    setReadyToSubmit(true);

    selectionModel.forEach(rowId => {
      const updated = find(tempCache, i => i.id === rowId);

      if (updated === undefined) {
        return;
      }

      updated.isDeliveryCandidate = selection;
    });

    setInformaticsCheckByCache([...cloneDeep(tempCache)]);
  };

  const handleSubmit = async () => {
    ErrorManagement('Loading', setLoadingState, async () => {
      if (!accessToken) {
        return;
      }

      const processedRows = informaticsCheckByCache.filter(i => i.isDeliveryCandidate !== 'notYetDecided');

      // each row is per reference but we only need on id mapping for the sample
      const idMappings = uniqBy(processedRows, i => i.sampleId).map(i => {
        return {
          originalSampleId: i.sampleId,
          sampleIdentifier: i.sampleIdentifier,
          checkByStatus: 'Passed',
        };
      });

      const referenceMappings: SampleTrackingInformaticsReferenceCheckByDto[] = processedRows.map(i => {
        return {
          sampleId: i.sampleId,
          informaticsPipelineResultId: i.informaticsPipelineResultId,
          deliveryCandidate: i.isDeliveryCandidate,
        };
      });

      const payload: SampleTrackingInformaticsCheckByDto = {
        SampleTrackingCheckByDtos: idMappings,
        SampleTrackingInformaticsReferenceCheckByDtos: referenceMappings,
      };

      await CreateInformaticsCheckBy(researchProjectId, configuredTransitionId, payload, accessToken);

      await handleClose(true);
    });
  };

  function handleClose(hasChanges: boolean) {
    onClose(hasChanges);
    setOpen(false);
    setLoadingState(INITIAL_LOADING_STATE);
    setReadyToSubmit(INITIAL_READY_TO_SUBMIT);
    setInformaticsCheckByCache([]);
    setGridSeqType(INITIAL_SEQ_TYPE);
  }

  function getFilteredGridData() {
    return informaticsCheckByCache.filter(qc => {
      if (gridSeqType === 'Other') {
        return !['DNA', 'RNA', 'TNA'].includes(qc.sequenceType);
      }

      return qc.sequenceType === gridSeqType;
    });
  }

  function getTabDisplayValue(tab: InformaticsCheckByTabs) {
    switch (tab) {
      case 'DNA':
        return `${t('dna')} (${DNACount})`;
      case 'RNA':
        return `${t('rna')} (${RNACount})`;
      case 'TNA':
        return `${t('tna')} (${TNACount})`;
      case 'Other':
        return `${t('other')} (${otherCount})`;
    }
  }

  return (
    <>
      <DialogOpenButton title={t(recordCheckBy)} onClick={handleButtonClick} />
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        fullWidth
        maxWidth='xl'
        PaperProps={{ sx: { width: '90%', height: '90%' } }}
      >
        <DialogTitle>
          <IconButton
            aria-label='close'
            onClick={() => handleClose(false)}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: theme => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
          <Typography variant='h4'>Informatics Metrics</Typography>
        </DialogTitle>
        <DialogContent>
          <FlexTableBox>
            <Tabs value={gridSeqType} onChange={handleSeqTypeTabChange} sx={{ mb: 1 }}>
              <Tab value='DNA' label={getTabDisplayValue('DNA')} sx={{ color: theme.colors.alpha.black[100] }} />
              <Tab value='RNA' label={getTabDisplayValue('RNA')} sx={{ color: theme.colors.alpha.black[100] }} />
              <Tab value='TNA' label={getTabDisplayValue('TNA')} sx={{ color: theme.colors.alpha.black[100] }} />
              <Tab value='Other' label={getTabDisplayValue('Other')} sx={{ color: theme.colors.alpha.black[100] }} />
            </Tabs>
            <InformaticsQcMetricsGrid
              seqType={gridSeqType}
              data={getFilteredGridData()}
              onDeliveryCandidateMarking={handleDeliveryCandidateMarking}
            />
          </FlexTableBox>
        </DialogContent>
        <ErrorIndicator loadingState={loadingState} />
        <LoadingIndicator loadingState={loadingState} margin={'LR'} />
        <DialogActions>
          <CancelButton onClick={() => handleClose(false)} />
          <PrimaryButton onClick={handleSubmit} disabled={loadingState.status === 'Loading' || !readyToSubmit}>
            {t('submit')}
          </PrimaryButton>
        </DialogActions>
      </Dialog>
    </>
  );
};

function getRowData(
  data: ReadonlyArray<InformaticsCheckByData>,
  informaticsPipelineResults: ReadonlyArray<InformaticsPipelineResult>,
  informaticsComputedValues: ReadonlyArray<InformaticsComputedValues>
): ReadonlyArray<InformaticsCheckByRow> {
  const counts = groupBy(informaticsPipelineResults, i => i.informaticsPipelineRunId);
  let id = 0;

  const items = (
    map(informaticsPipelineResults, result => {
      const dataEntry = find(data, i => i.informaticsPipelineRunId === result.informaticsPipelineRunId);

      if (dataEntry === undefined) {
        return undefined;
      }

      const computedValues = find(informaticsComputedValues, i => i.sequenceRunId === dataEntry.sequenceRunId);
      const numberOfReferences = counts[result.informaticsPipelineRunId]?.length ?? 0;

      return {
        id: id++,
        ...result,
        ...dataEntry,
        sequenceType: (dataEntry.sequenceType ? dataEntry.sequenceType.toUpperCase() : '') as SequenceType,
        groupBy:
          numberOfReferences === 1
            ? [dataEntry.sampleIdentifier]
            : [dataEntry.sampleIdentifier, result.referenceTypeId],
        computedValues:
          merge(computedValues?.perReferenceValues[result.referenceTypeId] ?? {}, computedValues?.globalValues ?? {}) ??
          {},
        numberOfReferences: numberOfReferences,
      };
    }).filter(i => i !== undefined) as InformaticsCheckByRow[]
  ).filter(qc => qc.isDeliveryCandidate === 'notYetDecided');

  return items;
}
