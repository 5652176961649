import { GridColDef, GridComparatorFn, GridRenderCellParams, useGridApiRef } from '@mui/x-data-grid-pro';
import { renderCellLinkWithLaunchIcon } from 'components/grid/GridCellLink';
import { GetResearchProjectActualCohortCounts, ResearchProjectDetails } from 'data/ResearchProjectData';
import { CamelCaseToLowerCaseWithSpace } from 'data/ResearchProjectStatusData';
import React, { useEffect, useMemo, useState } from 'react';
import { find, keys, orderBy } from 'lodash';
import {
  actualCohortSize,
  customer,
  dashDash,
  diseaseArea,
  expectedCohortSize,
  mondayLink,
  nameField,
  pipeline,
  project,
  salesforceLink,
  selectedSampleCount,
  statusField,
} from '../../util/Constants';
import {
  formatPatientSampleCountAsFlex,
  formatPatientSampleCountWithDisplayName,
  formatSampleCountWithDisplayName,
} from '../../util/grid/TableUtils';
import { SampleTrackingCounts } from '../../data/SampleTrackingData';
import useMemoTranslation from '../../hooks/UseMemoTranslation';
import { CompactGridWrapper } from '../../components/grid/CompactGridWrapper';
import { ResearchProjectGridCell, ResearchProjectGridCellProps } from './ResearchProjectGridCell';
import { LoadingProps, LoadingState, LoadState } from '../../components/LoadingStateUtil';
import { Dictionary } from '../../util/TypeUtil';
import { TransitionIconWithTooltip } from '../../util/TransitionUtil';
import { LinkButton } from '../../components/LinkButton';
import { TransitionTypeExtended } from '../sampleTracking/SampleTrackingGrid';
import { SampleTrackingCountByType } from '../../pages/ResearchProjectManagementPage';
import useAuth from '../../auth/UseAuth';
import { renderLoadingGridCell } from '../../components/grid/cell/LoadingGridCell';
import { useUserPermissions } from '../../auth/UseUserPermissions';
import { useConfiguredTransitionsForResearchProjects } from '../../components/hooks/UseConfiguredTransitions';
import { ConfiguredTransition } from '../../data/ConfiguredTransitionData';
import { SuperDuperFiestaPipeline } from '../../data/SuperDuperFiestaData';
import { ErrorTwoTone } from '@mui/icons-material';
import { CircularProgress, Tooltip } from '@mui/material';

export interface ResearchProjectsGridProps {
  researchProjects: ReadonlyArray<ResearchProjectDetails>;
  researchProjectCounts: Dictionary<ReadonlyArray<SampleTrackingCounts>>;
  onUpdateResearchProjectClick(researchProject: ResearchProjectDetails, open: boolean): void;
  baseUrl?: string;
  sampleTrackingCountByType: SampleTrackingCountByType;
  pipelines: ReadonlyArray<SuperDuperFiestaPipeline>;
  loadingProps: LoadingProps;
  pipelinesLoadingState: LoadingState;
}

export const ResearchProjectsGrid = ({
  researchProjects,
  researchProjectCounts,
  onUpdateResearchProjectClick,
  baseUrl = '/research-project',
  sampleTrackingCountByType,
  pipelines,
  pipelinesLoadingState,
  loadingProps,
}: ResearchProjectsGridProps) => {
  const { accessToken } = useAuth();
  const apiRef = useGridApiRef();
  const [loadingState, setLoadingState] = loadingProps;

  const [configuredTransitionsByResearchProject] = useConfiguredTransitionsForResearchProjects(
    useMemo(() => researchProjects.map(i => i.researchProjectId), [researchProjects]),
    setLoadingState
  );

  const [cohortCounts, setCohortCounts] = useState<Dictionary<number>>({});

  const columns = useColumns(
    onUpdateResearchProjectClick,
    configuredTransitionsByResearchProject,
    baseUrl,
    sampleTrackingCountByType,
    pipelines,
    pipelinesLoadingState
  );
  const rows = useRows(researchProjects, researchProjectCounts, onUpdateResearchProjectClick, cohortCounts);

  useEffect(() => {
    return LoadState(undefined, async () => {
      const cohortCounts = await GetResearchProjectActualCohortCounts(accessToken);
      setCohortCounts(cohortCounts);
    });
  }, [accessToken]);

  return (
    <>
      <CompactGridWrapper
        apiRef={apiRef}
        rows={rows}
        columns={columns}
        hideFooterSelectedRowCount={true}
        columnsToAutoSize={columns.map(c => c.field)}
        initialState={{
          columns: {
            columnVisibilityModel: {
              expectedCohortSize: false,
              salesforceLink: false,
              mondayLink: false,
              name: false,
            },
          },
          pinnedColumns: { left: [customer, project] },
          sorting: {
            sortModel: [{ field: 'name', sort: 'asc' }],
          },
          filter: {
            filterModel: {
              items: [{ field: statusField, operator: 'equals', value: 'active' }],
            },
          },
        }}
        loadingState={loadingState}
      />
    </>
  );
};

const useColumns = (
  onUpdateResearchProjectClick: (researchProject: ResearchProjectDetails, open: boolean) => void,
  configuredTransitionsByResearchProject: Dictionary<ReadonlyArray<ConfiguredTransition>>,
  baseUrl: string | undefined,
  sampleTrackingCountByType: SampleTrackingCountByType,
  pipelines: ReadonlyArray<SuperDuperFiestaPipeline>,
  pipelinesLoadingState: LoadingState
): GridColDef[] => {
  const { t } = useMemoTranslation();
  const { hasSampleTrackingViewAccess } = useUserPermissions();

  const generatedColumns: GridColDef[] = useMemo(() => {
    if (!hasSampleTrackingViewAccess) {
      return [];
    }

    function getTransitionIconWithTooltip(
      transitionType: TransitionTypeExtended,
      displayName: string,
      includePatientCount: boolean,
      params: GridRenderCellParams
    ) {
      return (
        <TransitionIconWithTooltip
          transitionType={transitionType}
          children={
            <LinkButton
              url={`${baseUrl}/${params?.row.project.researchProject.researchProjectId}`}
              sx={{
                paddingLeft: 0,
                paddingRight: 0,
                textWrap: 'wrap',
                lineHeight: 'normal',
                width: '100%',
                minWidth: '0',
              }}
              fontWeight='bold'
            >
              {formatPatientSampleCountAsFlex(
                params?.row,
                displayName,
                sampleTrackingCountByType,
                includePatientCount,
                dashDash
              )}
            </LinkButton>
          }
          tooltip={displayName}
        />
      );
    }

    let maxNumOfColumns = 1;
    Object.keys(configuredTransitionsByResearchProject).forEach(
      k => (maxNumOfColumns = Math.max(maxNumOfColumns, configuredTransitionsByResearchProject[k].length))
    );

    let columns: GridColDef[] = [
      {
        field: selectedSampleCount,
        headerName: t(selectedSampleCount),
        headerAlign: 'center',
        align: 'left',
        valueGetter: params =>
          formatPatientSampleCountWithDisplayName(params?.row, 'Selected', sampleTrackingCountByType, dashDash),
        renderCell: params => getTransitionIconWithTooltip('Selected', t('Selected'), true, params),
      },
    ];

    const colName = 'pos';
    for (let i = 0; i < maxNumOfColumns; i++) {
      columns.push({
        field: `${colName}_${i.toString()}`,
        headerName: '',
        headerAlign: 'center',
        align: 'left',
        valueGetter: params => {
          const pathData = orderBy(
            configuredTransitionsByResearchProject[params?.row.researchProjectId] ?? [],
            i => i.transitionOrder
          );

          return formatSampleCountWithDisplayName(
            params?.row,
            pathData[i]?.displayName,
            sampleTrackingCountByType,
            dashDash
          );
        },
        renderCell: params => {
          const pathData = orderBy(
            configuredTransitionsByResearchProject[params?.row.researchProjectId] ?? [],
            i => i.transitionOrder
          );
          const data = pathData[i];

          if (!data) {
            return <></>;
          }

          return getTransitionIconWithTooltip(data?.transitionEnum.name, data?.displayName, false, params);
        },
      });
    }

    return columns;
  }, [t, configuredTransitionsByResearchProject, baseUrl, sampleTrackingCountByType, hasSampleTrackingViewAccess]);

  return useMemo(
    () => [
      {
        field: customer,
        headerName: t(customer),
        valueGetter: params => params?.row.customer?.name,
      },
      {
        field: project,
        headerName: t(project),
        renderCell: (params: GridRenderCellParams<ResearchProjectGridCellProps>) => {
          if (params.value) {
            return (
              <ResearchProjectGridCell
                researchProject={params.value.researchProject}
                url={`${baseUrl}/${params.value.researchProject.researchProjectId}`}
                onEditButtonClick={() => onUpdateResearchProjectClick(params.value.researchProject, true)}
              />
            );
          }
        },
        sortComparator: byProjectComparator,
      },
      {
        field: expectedCohortSize,
        headerName: t(expectedCohortSize),
        headerAlign: 'center',
        align: 'center',
        type: 'number',
      },
      {
        field: diseaseArea,
        headerName: t(diseaseArea),
        valueGetter: params => params.row.diseaseAreas[0]?.name,
      },
      {
        field: pipeline,
        headerName: t(pipeline),
        align:
          pipelinesLoadingState.status === 'Loading' || pipelinesLoadingState.status === 'Error' ? 'center' : 'left',
        valueGetter: params => {
          if (params.row.pipelineId === undefined) {
            return undefined;
          }

          const pipeline = pipelines.find(p => p.pipelineId === params.row.pipelineId);
          return pipeline?.name;
        },
        renderCell: (params: GridRenderCellParams) => {
          if (params.value === undefined) {
            return undefined;
          }

          if (pipelinesLoadingState.status === 'Loading') {
            return <CircularProgress size={15} />;
          } else if (pipelinesLoadingState.status === 'Error') {
            return (
              <Tooltip title={pipelinesLoadingState.errorMessage} placement='top'>
                <ErrorTwoTone color='error' sx={{ cursor: 'pointer', pointerEvents: 'auto' }} />
              </Tooltip>
            );
          }

          return params.value;
        },
      },
      {
        field: actualCohortSize,
        headerName: t(actualCohortSize),
        headerAlign: 'center',
        align: 'center',
        type: 'number',
        renderCell: renderLoadingGridCell,
      },
      {
        field: statusField,
        headerName: t(statusField),
        headerAlign: 'center',
        align: 'center',
        minWidth: 120,
      },
      {
        field: nameField,
        headerName: t(nameField),
        headerAlign: 'center',
        align: 'center',
      },
      {
        field: salesforceLink,
        headerName: t(salesforceLink),
        headerAlign: 'center',
        align: 'center',
        sortable: false,
        renderCell: renderCellLinkWithLaunchIcon,
      },
      {
        field: mondayLink,
        headerName: t(mondayLink),
        headerAlign: 'center',
        align: 'center',
        sortable: false,
        renderCell: renderCellLinkWithLaunchIcon,
      },
      ...generatedColumns,
    ],
    [
      t,
      onUpdateResearchProjectClick,
      baseUrl,
      generatedColumns,
      pipelines,
      pipelinesLoadingState.status,
      pipelinesLoadingState.errorMessage,
    ]
  ) as GridColDef[];
};

const useRows = (
  researchProjects: ReadonlyArray<ResearchProjectDetails>,
  researchProjectCounts: Dictionary<ReadonlyArray<SampleTrackingCounts>>,
  onUpdateResearchProjectClick: (researchProject: ResearchProjectDetails, open: boolean) => void,
  cohortCounts: Dictionary<number>
) => {
  return useMemo(() => {
    const rows: any[] = [];
    let id = 1;

    researchProjects.forEach(r => {
      let project = {
        researchProject: r,
        onUpdateResearchProjectClick: onUpdateResearchProjectClick,
      };

      const cohortLoaded = keys(cohortCounts ?? {}).length;

      rows.push({
        id: id++,
        ...r,
        project: project,
        status: CamelCaseToLowerCaseWithSpace(r.status),
        patientCounts: find(researchProjectCounts[r.researchProjectId], i => i.sampleGroup.toLowerCase() === 'all')
          ?.patientCounts,
        sampleCounts: find(researchProjectCounts[r.researchProjectId], i => i.sampleGroup.toLowerCase() === 'all')
          ?.sampleCounts,
        actualCohortSize: cohortLoaded ? cohortCounts[r.researchProjectId] ?? '' : undefined,
      });
    });

    return rows;
  }, [researchProjects, researchProjectCounts, onUpdateResearchProjectClick, cohortCounts]);
};

const byProjectComparator: GridComparatorFn = (v1, v2) => {
  if ((v1 as ResearchProjectDetails).name < (v2 as ResearchProjectDetails).name) {
    return -1;
  } else if ((v1 as ResearchProjectDetails).name > (v2 as ResearchProjectDetails).name) {
    return 1;
  } else {
    return 0;
  }
};
