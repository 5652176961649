import React, { ReactNode } from 'react';
import { AuthorizedSection } from './AuthorizedSection';

interface Props {
  children?: ReactNode;
}

export const SampleTrackingAuthSection = ({ children }: Props) => {
  return <AuthorizedSection hasSampleTrackingViewAccess>{children}</AuthorizedSection>;
};
