import { Box } from '@mui/material';

interface TextOverflowBoxProps {
  value?: string;
}

export const TextOverflowBox = ({ value }: TextOverflowBoxProps) => {
  return (
    <Box
      sx={{
        display: 'inline-block',
        width: 'inherit',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
      }}
    >
      {value}
    </Box>
  );
};
