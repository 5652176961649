import { GridRenderCellParams } from '@mui/x-data-grid-pro';
import React, { useState } from 'react';
import { SampleHierarchyDialog } from '../../../researchProjectManagement/sampleJourney/SampleHierarchyDialog';
import { DialogOpenButton } from '../../DialogOpenButton';
import { TextOverflowBox } from 'components/TextOverflowBox';

interface SampleHierarchyGridCellProps extends GridRenderCellParams {
  sampleId?: string;
  displayValue: string;
}

export function renderSampleHierarchyGridCell(params: SampleHierarchyGridCellProps) {
  return <MemoizedSampleHierarchyGridCell {...params} />;
}

export const SampleHierarchyGridCell = ({ sampleId, displayValue }: SampleHierarchyGridCellProps) => {
  const [modalOpen, setModalOpen] = useState<boolean>(false);

  return (
    <>
      {sampleId && (
        <>
          <DialogOpenButton
            sx={{ paddingLeft: 0, minWidth: 0, fontFamily: 'monospace', width: 'inherit' }}
            fontWeight={'inherit'}
            onClick={() => setModalOpen(true)}
          >
            <TextOverflowBox value={displayValue} />
          </DialogOpenButton>
          {modalOpen && (
            <SampleHierarchyDialog sampleIds={[sampleId]} open={modalOpen} onClose={() => setModalOpen(false)} />
          )}
        </>
      )}
    </>
  );
};

const MemoizedSampleHierarchyGridCell = React.memo(SampleHierarchyGridCell, (prevProps, nextProps) => {
  return prevProps.value === nextProps.value;
});
