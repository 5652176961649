import React, { useEffect, useRef, useState } from 'react';

import { Box, darken, Typography, useTheme } from '@mui/material';
import Highcharts from 'highcharts';
import HighchartsReact, { HighchartsReactRefObject } from 'highcharts-react-official';
import { SHOW_MEAN_ON_MEASUREMENT_GRAPH, SHOW_MEDIAN_ON_MEASUREMENT_GRAPH } from '../../../rules';
import { formatAsUtcDate, formatAsUtcMonth } from '../../../utils/dateUtils';
import { intersection, uniq } from 'lodash';
import { useMeasurement, usePatientData, useShowDataWithIssues } from '../../../stores/dataStore';
import { getMeasurementsInRange } from '../../../helpers/dataTransforms';
import {
  computeNewTickInterval,
  makeFontStyle,
  makeXPlotLineLabel,
  makeYPlotLineLabel,
} from '../../../utils/highchartsUtils';
import { isBefore } from 'date-fns';
import { useActiveDateRange } from '../../../stores/dateRangeStore';
import assertExhaustive from '../../../utils/assertExhaustive';
import { MeasurementNormality } from '../../../../../data/MeasurementDefinitionData';

const MeasurementGraph: React.FC = () => {
  const theme = useTheme();
  const chartComponentRef = useRef<HighchartsReactRefObject>(null);

  const { loading, data: patientData } = usePatientData();
  const activeDateRange = useActiveDateRange();
  const measurement = useMeasurement();

  const [chartData, setChartData] = useState<Partial<Highcharts.Point>[]>([]);
  const [minY, setMinY] = useState<number | undefined>();
  const [maxY, setMaxY] = useState<number | undefined>();
  const [mean, setMean] = useState<number | undefined>();
  const [median, setMedian] = useState<number | undefined>();

  const showIssuesInTooltipRef = useRef<boolean>(false);
  const currentIssuesRef = useRef<string[]>([]);
  useEffect(() => {
    showIssuesInTooltipRef.current = false;
  }, [loading, patientData]);

  const showDataWithIssues = useShowDataWithIssues();
  useEffect(() => {
    if (loading || !patientData || !measurement) {
      setChartData([]);
      currentIssuesRef.current = [];
      return;
    }

    const measurementsInRange = getMeasurementsInRange(patientData.measurements, activeDateRange);
    const measurementData = measurementsInRange
      .filter(pm => pm.type === measurement.type.name)
      // Never show near-zero values that are flagged with issues because it is very unlikely we would ever use them,
      // and they can cause the graph to be scaled much larger than it needs to be.
      .filter(pm => !(pm.issues.length > 0 && pm.average < 0.00000000001))
      .filter(pm => {
        switch (showDataWithIssues) {
          case 'noIssues':
            return pm.issues.length === 0;
          case 'onlyIssues':
            return pm.issues.length > 0;
          case 'all':
            return true;
          default:
            assertExhaustive(showDataWithIssues);
            throw new Error('Unreachable');
        }
      })
      .filter(pm => intersection(measurement.codes, pm.codes).length > 0);

    const chartData = measurementData.map(pm => ({
      x: new Date(pm.performedWeek).getTime(),
      y: pm.average ?? 0,
      color:
        pm.issues.length > 0
          ? theme.palette.grey[500]
          : pm.normality === 'normal'
          ? theme.palette.success.main
          : pm.normality === 'abnormal'
          ? theme.palette.error.main
          : undefined,
      events: {
        click: function () {
          const self = this as unknown as Highcharts.Point;
          showIssuesInTooltipRef.current = !showIssuesInTooltipRef.current;
          self.series.chart.redraw();
        },
        mouseOver: function () {
          currentIssuesRef.current = pm.issues;
        },
      },
    }));
    setChartData(chartData);

    let min = measurement.ranges.map(r => r.minValue).reduce((acc, val) => Math.min(acc, val), Number.MAX_VALUE);
    let max = measurement.ranges.map(r => r.maxValue ?? r.minValue).reduce((acc, val) => Math.max(acc, val), 0);
    measurementData.forEach(pm => {
      if (pm.average) {
        if (pm.average <= min) {
          min = pm.average;
        }

        if (pm.average >= max) {
          max = pm.average;
        }
      }
    });

    const range = max - min;
    const margin = range * 0.1;
    setMinY(Math.max(min - margin, 0));
    setMaxY(max + margin);

    // if these aren't set then don't incur the costs to compute
    if (SHOW_MEAN_ON_MEASUREMENT_GRAPH || SHOW_MEDIAN_ON_MEASUREMENT_GRAPH) {
      // we're going to need this anyway, so pull out the data and sort it
      const values = measurementData
        .map(d => d.average)
        .filter(v => v)
        .sort((a, b) => a - b);

      if (SHOW_MEAN_ON_MEASUREMENT_GRAPH) {
        // compute the mean
        let s = 0;
        let c = 0;
        values.forEach(v => {
          s += v;
          c++;
        });
        setMean(c !== 0 ? s / c : undefined);
      }

      if (SHOW_MEDIAN_ON_MEASUREMENT_GRAPH) {
        // compute the median
        let m: number | undefined = undefined;
        if (values.length % 2 === 0) {
          // even, grab the middle two and average
          m = (values[Math.floor(values.length / 2) - 1] + values[Math.floor(values.length / 2)]) / 2.0;
        } else {
          // grab the center
          m = values[Math.floor(values.length / 2)];
        }
        setMedian(m);
      }
    }
  }, [loading, patientData, activeDateRange, measurement, showDataWithIssues, currentIssuesRef, theme]);

  const labelWidthPx = 55;
  const [tickInterval, setTickInterval] = useState<number>(Number.MAX_SAFE_INTEGER);

  if (chartData?.length === 0) {
    return (
      <Box
        sx={{
          height: '100%',
          border: '1px solid white',
          paddingBottom: 0,
        }}
        justifyContent='center'
        alignItems='center'
        display='flex'
      >
        <Typography variant='h5'>No available data</Typography>
      </Box>
    );
  }

  const xAxisPlotLines = [];

  let eventsBeforeStart = 0;

  if (patientData?.events) {
    for (const event of patientData.events) {
      if (event.issues.length > 0 && showDataWithIssues === 'noIssues') {
        continue;
      }

      if (event.issues.length === 0 && showDataWithIssues === 'onlyIssues') {
        continue;
      }

      const date = new Date(event.eventDate);
      const isBeforeStart = isBefore(date, activeDateRange.earliest);
      if (isBeforeStart) {
        eventsBeforeStart++;
        continue;
      }

      xAxisPlotLines.push({
        color: event.issues.length === 0 ? theme.palette.warning.main : theme.palette.error.main,
        width: 1,
        value: isBeforeStart ? activeDateRange.earliest.getTime() : date.getTime(),
        label: makeXPlotLineLabel(isBeforeStart ? '...' : event.name, 10, theme),
      });
    }

    if (eventsBeforeStart > 0) {
      xAxisPlotLines.push({
        color: theme.palette.warning.main,
        width: 1,
        value: activeDateRange.earliest.getTime(),
        label: makeXPlotLineLabel(`(${eventsBeforeStart} ${eventsBeforeStart === 1 ? 'event' : 'events'})`, 10, theme),
      });
    }
  }

  if (patientData?.sampleCollectionDates) {
    for (const sampleCollectionDate of patientData.sampleCollectionDates) {
      const date = new Date(sampleCollectionDate.collectionDate);

      xAxisPlotLines.push({
        color: theme.palette.info.main,
        width: 1,
        value: date.getTime(),
        label: makeXPlotLineLabel(
          sampleCollectionDate.sampleCount === 1
            ? `${sampleCollectionDate.sampleCount} sample`
            : `${sampleCollectionDate.sampleCount} samples`,
          10,
          theme
        ),
      });
    }
  }

  const yAxisPlotLines = [];

  const getCommonPrefix = (a: string, b: string) => {
    const minLength = Math.min(a.length, b.length);
    for (let i = 0; i < minLength; i++) {
      if (a[i] !== b[i]) {
        return a.substring(0, i);
      }
    }
    return a.substring(0, minLength);
  };

  const minNames =
    measurement?.ranges.reduce((acc, r) => {
      const curName = r.name ?? 'normal';
      const newName = r.minValue in acc ? getCommonPrefix(acc[r.minValue], curName) : curName;
      return { ...acc, [r.minValue]: newName };
    }, {} as Record<number, string>) ?? {};

  const maxNames =
    measurement?.ranges
      .filter(r => r.maxValue !== undefined)
      .reduce((acc, r) => {
        const curName = r.name ?? 'normal';
        const newName = r.maxValue! in acc ? getCommonPrefix(acc[r.maxValue!], curName) : curName;
        return { ...acc, [r.maxValue!]: newName };
      }, {} as Record<number, string>) ?? {};

  for (const [minValue, minName] of Object.entries(minNames)) {
    const name = minName.length > 0 ? minName : 'normal';
    yAxisPlotLines.push({
      label: makeYPlotLineLabel(`${name} min ${minValue}`, 9, theme),
      value: minValue,
    });
  }

  for (const [maxValue, maxName] of Object.entries(maxNames)) {
    const name = maxName.length > 0 ? maxName : 'normal';
    yAxisPlotLines.push({
      label: makeYPlotLineLabel(`${name} max ${maxValue}`, 9, theme),
      value: maxValue,
    });
  }

  if (SHOW_MEAN_ON_MEASUREMENT_GRAPH && mean !== undefined) {
    const label = makeYPlotLineLabel(`mean ${mean.toFixed(2)}`, 9, theme);
    label.align = 'right';
    label.x = 0;
    yAxisPlotLines.push({
      label,
      value: mean,
      dashStyle: 'ShortDash',
    });
  }

  if (SHOW_MEDIAN_ON_MEASUREMENT_GRAPH && measurement !== undefined && median !== undefined) {
    const smallestMin = measurement.ranges
      .map(r => r.minValue)
      .reduce((acc, val) => Math.min(acc, val), Number.MAX_VALUE);
    const largestMin = measurement.ranges.map(r => r.minValue).reduce((acc, val) => Math.max(acc, val), 0);
    const smallestMax = measurement.ranges
      .map(r => r.maxValue ?? r.minValue)
      .reduce((acc, val) => Math.min(acc, val), Number.MAX_VALUE);
    const largestMax = measurement.ranges
      .map(r => r.maxValue ?? r.minValue)
      .reduce((acc, val) => Math.max(acc, val), 0);

    let valueNormality: MeasurementNormality = 'undetermined';
    let allRangeTypes = uniq(measurement.ranges.map(r => r.rangeType));
    if (allRangeTypes.length === 1) {
      const rangeType = allRangeTypes[0];
      switch (rangeType) {
        case 'withinIsNormal':
          if (median >= largestMin && median <= smallestMax) {
            valueNormality = 'normal';
          } else if (median < smallestMin || median > largestMax) {
            valueNormality = 'abnormal';
          } else {
            valueNormality = 'undetermined';
          }
          break;
        case 'aboveIsAbnormal':
          if (median > largestMax) {
            valueNormality = 'abnormal';
          } else if (median < smallestMin) {
            valueNormality = 'normal';
          } else {
            valueNormality = 'undetermined';
          }
          break;
        default:
          assertExhaustive(rangeType);
      }
    }

    let [textColor, lineColor] =
      valueNormality === 'normal'
        ? [darken(theme.palette.success.main, 0.1), theme.palette.success.main]
        : valueNormality === 'abnormal'
        ? [theme.palette.error.main, theme.palette.error.main]
        : valueNormality === 'undetermined'
        ? [darken('rgb(44, 175, 254)', 0.1), 'rgb(44, 175, 254)'] // Highcharts default series color
        : assertExhaustive(valueNormality);

    const label = makeYPlotLineLabel(`median ${median.toFixed(2)}`, 10, theme);
    label.align = 'right';
    label.x = 0;
    label.style = { ...label.style, textColor };

    yAxisPlotLines.push({
      label,
      value: median,
      dashStyle: 'Dash',
      color: lineColor,
      width: 2,
    });
  }

  return (
    <Box
      sx={{
        position: 'relative',
        height: '100%',
        width: '100%',
        border: '1px solid lightgray',
        borderRadius: theme.spacing(1),
      }}
    >
      <HighchartsReact
        containerProps={{
          style: {
            position: 'absolute',
            top: 0,
            left: theme.spacing(1),
            bottom: 0,
            right: theme.spacing(1),
          },
        }}
        highcharts={Highcharts}
        options={{
          chart: {
            type: 'scatter',
            animation: false,
            marginTop: Number(theme.spacing(1).slice(0, -2)),
            marginBottom: Number(theme.spacing(3).slice(0, -2)),
            marginLeft: Number(theme.spacing(5).slice(0, -2)),
            marginRight: Number(theme.spacing(4).slice(0, -2)),
            events: {
              render: function () {
                const chart = this as unknown as Highcharts.Chart;
                const newTickInterval = computeNewTickInterval(chart, labelWidthPx);
                if (newTickInterval !== null) {
                  setTickInterval(newTickInterval);
                }
              },
            },
          },
          boost: {
            enabled: false,
          },
          title: undefined,
          legend: {
            enabled: false,
          },
          xAxis: {
            title: undefined,
            min: activeDateRange.earliest.getTime(),
            max: activeDateRange.latest.getTime(),
            lineColor: theme.palette.text.primary,
            labels: {
              overflow: 'allow',
              autoRotation: false,
              style: {
                ...makeFontStyle(11, theme),
              },
              distance: Math.floor(Number(theme.spacing(0.75).slice(0, -2))),
              padding: 0,
              formatter: function (): string {
                const self = this as any;
                const date = new Date(self.value);
                return formatAsUtcMonth(date);
              },
            },
            tickLength: 5,
            tickWidth: 1,
            tickInterval: tickInterval,
            plotLines: xAxisPlotLines,
          },
          yAxis: {
            title: undefined,
            min: minY,
            max: maxY,
            endOnTick: false,
            lineWidth: eventsBeforeStart === 0 ? 1 : 0,
            lineColor: theme.palette.text.primary,
            gridLineWidth: 0,
            tickLength: 5,
            tickWidth: 1,
            labels: {
              style: {
                ...makeFontStyle(11, theme),
              },
            },
            plotLines: yAxisPlotLines,
          },
          series: [
            {
              name: measurement?.shortName ?? 'Unknown',
              data: chartData,
              cursor: 'pointer',
              marker: {
                symbol: 'circle',
                fillColor: '#FF000000',
                lineWidth: 2,
                lineColor: null, // inherit from series
              },
            },
          ],
          tooltip: {
            outside: true,
            headerFormat: '',
            pointFormatter: function (): string {
              const self = this as any;

              const date = new Date(self.x);
              const header = `${formatAsUtcDate(date)}: <b>${self.y}</b>`;

              const makeIssueLine = (text: string, color: string) =>
                `<span style="color: ${color}">\u2022 ${text}</span>`;

              const body = showIssuesInTooltipRef.current
                ? currentIssuesRef.current.length > 0
                  ? `<br>${currentIssuesRef.current
                      .map(issue => makeIssueLine(issue, theme.palette.error.main))
                      .join('<br>')}`
                  : `<br>${makeIssueLine('No issues', darken(theme.palette.success.main, 0.1))}`
                : '';

              return header + body;
            },
          },
          accessibility: {
            enabled: false,
          },
          credits: {
            enabled: false,
          },
        }}
        ref={chartComponentRef}
      />
    </Box>
  );
};

export default MeasurementGraph;
