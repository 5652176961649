import { Button, useTheme } from '@mui/material';
import { PatientCountType } from 'components/grid/GridCountType';
import { PathogenGroup } from 'data/PathogenGroupData';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

export interface PatientCountGridCellProps {
  pathogenGroup: PathogenGroup;
  labId?: string;
  sampleTypeId?: string;
  count: number;
  countType: PatientCountType;
  onPatientCountClick(
    count: number,
    pathogenGroup: PathogenGroup,
    pathogenGroupCountType: PatientCountType,
    labId?: string,
    sampleTypeId?: string
  ): void;
}

export const ERROR_COUNT = -1;

export const PatientCountGridCell = ({
  pathogenGroup,
  labId,
  sampleTypeId,
  count,
  countType,
  onPatientCountClick,
}: PatientCountGridCellProps) => {
  const theme = useTheme();

  const handlePatientCountButtonClick = async () => {
    onPatientCountClick(count, pathogenGroup, countType, labId, sampleTypeId);
  };

  return (
    <Button
      disabled={count <= 0}
      onClick={handlePatientCountButtonClick}
      sx={[
        {
          fontWeight: 'inherit',
          fontSize: 'inherit',
          '&:hover': {
            backgroundColor: 'transparent',
          },
          '&:disabled': {
            color: `${theme.colors.primary.dark}`,
          },
        },
      ]}
    >
      {count === ERROR_COUNT && <ErrorOutlineIcon />}
      {count === ERROR_COUNT ? '' : count.toLocaleString() ?? 0}
    </Button>
  );
};
