import { Slider } from '@mui/material';

import React, { useEffect, useState } from 'react';
import { dateToRangeWeekOffset, formatAsUtcDate, rangeWeekOffsetToDate } from '../../../utils/dateUtils';
import { DateRange, dateRangesEqual } from '../../../models/DateRange';
import useDebounce from '../../../hooks/useDebounce';
import { useGlobalDateRange, useActiveDateRange, useSetActiveDateRange } from '../../../stores/dateRangeStore';

const DateRangeSelector: React.FC = () => {
  const globalDateRange = useGlobalDateRange();
  const activeDateRange = useActiveDateRange();
  const setActiveDateRange = useSetActiveDateRange();

  const [selectedDateRange, setSelectedDateRange] = useState<DateRange>(activeDateRange);
  useEffect(() => {
    setSelectedDateRange(cur => (dateRangesEqual(cur, activeDateRange) ? cur : activeDateRange));
  }, [activeDateRange, setSelectedDateRange]);

  // The slider works in values representing the number of weeks since the start of the date range so that we can lock
  // it into skipping by discrete weeks.
  const sliderMin = 0;
  const sliderMax = dateToRangeWeekOffset(globalDateRange.latest, globalDateRange);

  const startWeekValue = dateToRangeWeekOffset(selectedDateRange.earliest, globalDateRange);
  const endWeekValue = dateToRangeWeekOffset(selectedDateRange.latest, globalDateRange);

  const selectedRange = [startWeekValue, endWeekValue];

  const handleChange = (_event: Event, newValue: number | number[]) => {
    if (!Array.isArray(newValue)) {
      return;
    }

    const newStartDay = rangeWeekOffsetToDate(newValue[0], globalDateRange);
    const newEndDay = rangeWeekOffsetToDate(newValue[1], globalDateRange);
    setSelectedDateRange({ earliest: newStartDay, latest: newEndDay });
  };

  // Debounce the date range before firing the changed event because firing this too fast can trigger many re-renders
  // of other components that may be expensive and impact performance.
  const dateRangeDebounceTime = 50;
  const debouncedSelectedDateRange = useDebounce(selectedDateRange, dateRangeDebounceTime);
  useEffect(() => {
    setActiveDateRange(debouncedSelectedDateRange);
  }, [debouncedSelectedDateRange, setActiveDateRange]);

  const formatValue = (value: number) => {
    const date = rangeWeekOffsetToDate(value, globalDateRange);
    return formatAsUtcDate(date);
  };

  return (
    <Slider
      getAriaLabel={() => 'Patient journey date range'}
      size='small'
      min={sliderMin}
      max={sliderMax}
      value={selectedRange}
      valueLabelFormat={formatValue}
      onChange={handleChange}
      valueLabelDisplay='auto'
      getAriaValueText={formatValue}
      disableSwap
    />
  );
};

export default DateRangeSelector;
