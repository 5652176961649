import { GridRenderCellParams } from '@mui/x-data-grid-pro';
import React, { useState } from 'react';
import { DialogOpenButton } from '../../DialogOpenButton';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import {
  SupplementalFilesCheckByGroupScopedModal,
  SupplementalFilesTransitionScopedModal,
} from '../../../researchProjectManagement/sampleTracking/supplementalFiles/SupplementalFilesModal';
import { TransitionType } from '../../../data/SampleTrackingData';
import useMemoTranslation from '../../../hooks/UseMemoTranslation';
import { truncateGuid } from '../../../data/SampleData';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';

export type SupplementalFilesModalScope = 'Transition' | 'CheckByGroup';

interface SampleTrackingSupplementalFilesGridCellProps extends GridRenderCellParams {
  scope: SupplementalFilesModalScope;
  numberOfFiles: number;
  transitionType: TransitionType;
}

export function renderSampleTrackingSupplementalFilesGridCell(params: SampleTrackingSupplementalFilesGridCellProps) {
  return <MemoizedSampleTrackingSupplementalFilesGridCell {...params} />;
}

const SampleTrackingSupplementalFilesGridCell = ({
  value,
  row,
  scope,
  numberOfFiles,
  transitionType,
}: SampleTrackingSupplementalFilesGridCellProps) => {
  const { t } = useMemoTranslation();

  const [modalOpen, setModalOpen] = useState<boolean>(false);

  function handleClose() {
    setModalOpen(false);
  }

  return (
    <span>
      {value && (
        <>
          <DialogOpenButton fontWeight={'bold'} onClick={() => setModalOpen(true)}>
            {numberOfFiles === 0 ? (
              <AddCircleOutlineIcon />
            ) : (
              <>
                <AttachFileIcon />
                {`(${numberOfFiles})`}
              </>
            )}
          </DialogOpenButton>
          {modalOpen &&
            (scope === 'Transition' ? (
              <SupplementalFilesTransitionScopedModal
                onClose={handleClose}
                id={row.sampleTrackingTransitionFileDialog}
                title={`${t(transitionType)} Order Id: ${truncateGuid(row.sampleTrackingTransitionFileDialog)}`}
              />
            ) : (
              <SupplementalFilesCheckByGroupScopedModal
                onClose={handleClose}
                id={row.sampleTrackingTransitionFileDialog}
                title={`${t(transitionType)} ${t('checkBy')}: ${truncateGuid(row.sampleTrackingTransitionFileDialog)}`}
              />
            ))}
        </>
      )}
    </span>
  );
};

const MemoizedSampleTrackingSupplementalFilesGridCell = React.memo(
  SampleTrackingSupplementalFilesGridCell,
  (prevProps, nextProps) => {
    return prevProps.value === nextProps.value;
  }
);
