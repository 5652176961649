import React from 'react';
import { Box, Button, IconButton, Stack, TextField, Typography } from '@mui/material';
import { DeleteTwoTone } from '@mui/icons-material';
import AddCircleRoundedIcon from '@mui/icons-material/AddCircleRounded';
import { t } from 'i18next';

interface DiseaseAreaMedicationsInputGroupProps {
  diseaseAreaMedications: string[];
  onDiseaseAreaMedicationsChange: (diseaseAreaMedications: string[]) => unknown;
}

export const DiseaseAreaMedicationsInputGroup: React.FC<DiseaseAreaMedicationsInputGroupProps> = ({
  diseaseAreaMedications,
  onDiseaseAreaMedicationsChange,
}) => {
  const handleMedicationNameChange = (medication: string, index: number) => {
    const newMedications = [...diseaseAreaMedications];
    newMedications[index] = medication;
    onDiseaseAreaMedicationsChange(newMedications);
  };

  const handleAddMedication = () => {
    const newMedications = [...diseaseAreaMedications];
    newMedications.push('');
    onDiseaseAreaMedicationsChange(newMedications);
  };

  const handleDeleteMedication = (index: number) => {
    const newMedications = [...diseaseAreaMedications];
    newMedications.splice(index, 1);
    onDiseaseAreaMedicationsChange(newMedications);
  };

  return (
    <Box>
      {diseaseAreaMedications.map((m, i) => {
        const sameMedicationCount = diseaseAreaMedications.filter(i => i === m).length;
        const [medicationNameHasError, medicationNameErrorMessage] =
          m.trim().length === 0
            ? [true, t('medicationNameMustBeGiven')]
            : sameMedicationCount > 1
            ? [true, t('medicationNameMustBeUnique')]
            : [false, undefined];

        return (
          <Stack key={i} direction='row' spacing={1} sx={{ marginTop: 1.5 }}>
            <TextField
              fullWidth
              label={`${t('medicationName')}*`}
              onChange={e => handleMedicationNameChange(e.target.value, i)}
              type='text'
              value={m ?? ''}
              error={medicationNameHasError}
              helperText={medicationNameErrorMessage}
              variant='outlined'
              size='small'
            />
            <IconButton disabled={diseaseAreaMedications.length === 1} onClick={() => handleDeleteMedication(i)}>
              <DeleteTwoTone />
            </IconButton>
          </Stack>
        );
      })}

      <Button fullWidth onClick={handleAddMedication} variant='contained' sx={{ marginTop: 0.75 }}>
        <Stack direction='row' alignItems='center' spacing={0.5}>
          <Typography>{t('addMedication')}</Typography>
          <AddCircleRoundedIcon />
        </Stack>
      </Button>
    </Box>
  );
};

export default DiseaseAreaMedicationsInputGroup;
