import React, { useContext, useEffect, useState } from 'react';
import { LoadingProps, LoadState } from '../../components/LoadingStateUtil';
import useAuth from '../../auth/UseAuth';
import { FlexTableBox } from '../../components/FlexTableBox';
import { CompactGridWrapper } from '../../components/grid/CompactGridWrapper';
import { FilterParamsConfig, GetPipelineReview, PipelineReviewConfig } from '../../data/SuperDuperFiestaData';
import {
  GridColDef,
  GridEventListener,
  GridFilterModel,
  GridLogicOperator,
  GridPagination,
  GridPreferencePanelParams,
  GridSortModel,
  GridToolbarColumnsButton,
  GridToolbarExport,
  GridToolbarFilterButton,
} from '@mui/x-data-grid-pro';
import PipelineReviewContext from '../../contexts/PipelineReviewContext';
import { FlexBox } from 'components/FlexBox';
import { renderCellBooleanCheck } from 'components/grid/GridCellBooleanCheck';

export const PipelineReviewGrid = ({ loadingProps }: { loadingProps: LoadingProps }) => {
  const { accessToken } = useAuth();

  const [, setLoadingState] = loadingProps;
  const [rowCount, setRowCount] = useState(0);
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 100,
  });
  const { pipelineReviewParams, resetPaginationModel, setResetPaginationModel } = useContext(PipelineReviewContext);

  const [columns, setColumns] = useState<GridColDef[]>([]);
  const [rows, setRows] = useState<ReadonlyArray<object>>([]);
  const [queryOptions, setQueryOptions] = React.useState<GridFilterModel>({ items: [] });
  const [filterValues, setFilterValues] = React.useState<object[]>([]);
  const [sortValues, setSortValues] = React.useState<string[]>([]);

  useEffect(() => {
    return LoadState(setLoadingState, async () => {
      if (!accessToken) {
        return;
      }

      if (pipelineReviewParams !== null) {
        const queryParams: PipelineReviewConfig = { ...pipelineReviewParams };
        const filterParams: FilterParamsConfig = {};
        queryParams['pageNumber'] = (paginationModel.page + 1).toString();
        queryParams['pageSize'] = paginationModel.pageSize.toString();

        if (filterValues.length > 0) {
          filterValues.forEach(obj => {
            const key = Object.keys(obj)[0] as keyof typeof obj;
            filterParams[key] = obj[key];
          });

          queryParams['filterBy'] = filterParams;
        }
        if (sortValues.length > 0) {
          queryParams['sortBy'] = sortValues;
        }

        const data = await GetPipelineReview(queryParams, accessToken);

        if (data.length === 0) {
          setRowCount(0);
          setRows([]);
        } else {
          const disabledColumns = ['types'];
          const disabledProps = { filterable: false, sortable: false };

          // loop through keys of first record to find and set all headers
          let headers: GridColDef[] = [];
          Object.keys(data[0]).forEach(key => {
            const headerProps: GridColDef = {
              field: key,
              headerName: humanize(key),
              width: 125,
            };

            if (typeof (data[0] as Record<string, unknown>)[key] === 'boolean') {
              headerProps.renderCell = renderCellBooleanCheck;
              headerProps.align = 'center';
            }

            if (disabledColumns.includes(key)) {
              headers.push({ ...headerProps, ...disabledProps });
            } else {
              headers.push(headerProps);
            }
          });
          setColumns(headers);
          setRowCount(data.length);
          setRows(data.map((row, index) => ({ ...row, id: index })));
        }
      } else {
        setRowCount(0);
        setRows([]);
        setColumns([]);
      }
    });
  }, [setLoadingState, accessToken, pipelineReviewParams, paginationModel, filterValues, sortValues]);

  useEffect(() => {
    if (resetPaginationModel === true) {
      setPaginationModel({
        page: 0,
        pageSize: 100,
      });
      setResetPaginationModel(false);
    }
  }, [resetPaginationModel, setResetPaginationModel]);

  function humanize(str: string) {
    return str
      .replace(/^[\s_]+|[\s_]+$/g, '')
      .replace(/[_\s]+/g, ' ')
      .replace(/^[a-z]/, function (m) {
        return m.toUpperCase();
      });
  }

  const handleEvent: GridEventListener<'preferencePanelClose'> = (params: GridPreferencePanelParams) => {
    if (params['openedPanelValue'] === 'filters') {
      let filterObjects: object[] = [];
      queryOptions.items.forEach(item =>
        filterObjects.push({ [`${item.field}_${item.operator}`]: item.value || true })
      );
      setFilterValues(filterObjects);
    }
  };

  const onFilterChange = React.useCallback((filterModel: GridFilterModel) => {
    setQueryOptions({ ...filterModel });
  }, []);

  const handleSortModelChange = React.useCallback((sortModel: GridSortModel) => {
    setSortValues(sortModel.map(item => `${item.field}_${item.sort}`));
  }, []);

  function CustomFooter() {
    return (
      <FlexBox flexDirection={'row-reverse'}>
        <GridPagination />
        <GridToolbarExport printOptions={{ disableToolbarButton: true }} />
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
      </FlexBox>
    );
  }

  return (
    <FlexTableBox sx={{ gap: 1 }}>
      <FlexTableBox>
        <CompactGridWrapper
          columns={columns}
          rows={rows}
          rowHeight={25}
          pagination
          rowCount={rowCount < paginationModel.pageSize ? rowCount : Number.MAX_VALUE}
          paginationModel={paginationModel}
          pageSizeOptions={[100, 250, 500]}
          paginationMode='server'
          onPaginationModelChange={setPaginationModel}
          filterMode='server'
          onFilterModelChange={onFilterChange}
          sortingMode='server'
          onSortModelChange={handleSortModelChange}
          filterDebounceMs={250}
          onPreferencePanelClose={handleEvent}
          slots={{
            footer: CustomFooter,
          }}
          slotProps={{
            filterPanel: {
              // Force usage of "And" operator
              logicOperators: [GridLogicOperator.And],
            },
          }}
        />
      </FlexTableBox>
    </FlexTableBox>
  );
};
