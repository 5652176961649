import { DateRange } from '../models/DateRange';
import { create } from 'zustand';
import { dateUsingUtc, toUtcInterval, yearStartDate } from '../utils/dateUtils';
import { addWeeks, clamp, startOfWeek } from 'date-fns';

const computeGlobalDateRange = (startYear: number, currentDate: Date): DateRange => {
  // The start of the date range is the start of the week containing the first day of the start year.
  const startWeekStartDate = dateUsingUtc([yearStartDate(startYear)], d => startOfWeek(d));

  // The end of the date range is the start of the week after the week containing the current date. The range's end date
  // is exclusive, so we have to go one week extra to ensure that the week containing the current date falls within
  // the range.
  const nextWeekStart = dateUsingUtc([currentDate], d => addWeeks(startOfWeek(d), 1));

  // The dates in used for the bounds must be the starts of UTC weeks for various components to work properly. If not
  // it's possible that there will be bugs at the boundaries (maybe just visual, maybe not).
  return {
    earliest: startWeekStartDate,
    latest: nextWeekStart,
  };
};

interface DateRangeState {
  currentDate: Date;
  earliestStartYear: number;
  currentStartYear: number;
  globalDateRange: DateRange;
  setStartYear: (startYear: number) => void;
  activeDateRange: DateRange;
  setActiveDateRange: (dateRange: DateRange) => void;
}

// Store holding all data about the global and active date range for the application, as well as data needed to power the
// components that modify them.
const useDateRangeStore = create<DateRangeState>()((set, get) => {
  const currentDate = new Date();
  const maxYearsBack = 20;
  const defaultYearsBack = 10;

  const yearsBackToYear = (yearsBack: number): number => currentDate.getUTCFullYear() - yearsBack;
  const initialDateRange = computeGlobalDateRange(yearsBackToYear(defaultYearsBack), currentDate);

  const clampToGlobalDateRange = (dateRange: DateRange): DateRange => {
    const globalInterval = toUtcInterval(get().globalDateRange);
    return {
      earliest: clamp(dateRange.earliest, globalInterval),
      latest: clamp(dateRange.latest, globalInterval),
    };
  };

  return {
    currentDate,
    earliestStartYear: yearsBackToYear(maxYearsBack),
    currentStartYear: yearsBackToYear(defaultYearsBack),
    globalDateRange: initialDateRange,
    setStartYear: (startYear: number) => {
      const newGlobalDateRange = computeGlobalDateRange(startYear, currentDate);
      set({
        currentStartYear: startYear,
        globalDateRange: newGlobalDateRange,
        activeDateRange: clampToGlobalDateRange(get().activeDateRange),
      });
    },
    activeDateRange: initialDateRange,
    setActiveDateRange: (dateRange: DateRange) =>
      set({
        activeDateRange: clampToGlobalDateRange(dateRange),
      }),
  };
});

// Getters
export const useCurrentDate = () => useDateRangeStore(state => state.currentDate);
export const useEarliestStartYear = () => useDateRangeStore(state => state.earliestStartYear);
export const useStartYear = () => useDateRangeStore(state => state.currentStartYear);
export const useGlobalDateRange = () => useDateRangeStore(state => state.globalDateRange);
export const useActiveDateRange = () => useDateRangeStore(state => state.activeDateRange);

// Actions
export const useSetStartYear = () => useDateRangeStore(state => state.setStartYear);
export const useSetActiveDateRange = () => useDateRangeStore(state => state.setActiveDateRange);
