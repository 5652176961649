import React, { useEffect, useState } from 'react';
import { Box, Dialog, DialogContent, DialogTitle, Tab, Tabs, useTheme } from '@mui/material';
import { SampleJourneyHierarchyGrid } from './SampleJourneyHierarchyGrid';
import { DialogCloseButton } from '../../components/DialogCloseButton';
import useMemoTranslation from '../../hooks/UseMemoTranslation';
import { DialogTypography } from '../../components/DialogTypography';
import { SampleJourneyHistoryGrid } from './SampleJourneyHistoryGrid';
import { LoadingState, LoadState } from '../../components/LoadingStateUtil';
import {
  ConvertToSampleJourneyTree,
  GetAllLeafSampleIds,
  GetAllSampleIds,
  GetAllTransitionAndTransitionSamples,
  GetHierarchyForSampleId,
  GetSampleJourneyTransitionInformation,
  GetSampleSpecificBranch,
  SampleJourneyTransitionInformation,
  SampleJourneyTree,
} from '../../data/SampleJourneyData';
import { TransitionSampleDetailsDto } from '../../data/SampleTrackingTransitionData';
import { Dictionary, UseState } from '../../util/TypeUtil';
import { chunk, compact, flatMap, keyBy, map, mapValues, uniqBy } from 'lodash';
import { getSampleIdentifier, Sample } from '../../data/SampleData';
import useAuth from '../../auth/UseAuth';
import { LoadingIndicator } from '../../components/LoadingIndicator';
import { ErrorIndicator } from '../../components/ErrorIndicator';
import { FlexTableBox } from '../../components/FlexTableBox';
import { CustomizableSwitch } from '../../components/CustomizableSwitch';
import { useParams } from 'react-router-dom';
import { SampleJourneyInformaticsResultGrid } from './SampleJourneyInformaticsResultGrid';
import { SampleLineageDataDialog } from '../../components/SampleLineageDataDialog';
import { SelectableCandidate } from '../../data/SampleTrackingFieldSelectionData';
import { FlexBox } from '../../components/FlexBox';
import { DecryptForeignHashesSwitch } from '../../components/DecryptForeignHashesSwitch';
import { useConfiguredTransitions } from '../../components/hooks/UseConfiguredTransitions';

type PageTabs = 'history' | 'hierarchy' | 'informatics';

export interface SampleHierarchyDialogProps {
  sampleIds: ReadonlyArray<string>;
  open: boolean;
  onClose?: () => void;
}

export const SampleHierarchyDialog = ({ sampleIds, open, onClose = () => {} }: SampleHierarchyDialogProps) => {
  const { accessToken } = useAuth();

  let { researchProjectId } = useParams() as { researchProjectId: string };
  const { t } = useMemoTranslation();
  const theme = useTheme();

  const [modalOpen, setModalOpen] = useState<boolean>(open);
  const [selectedTab, setSelectedTab] = useState<PageTabs>('history');

  const [loadingState, setLoadingState] = useState<LoadingState>({ status: 'Loading' });

  const [selectedSampleIds, setSelectedSampleIds] = useState<ReadonlyArray<string>>(sampleIds);
  const [leafSampleIds, setLeafSampleIds] = useState<ReadonlyArray<string>>([]);
  const [baseHierarchyTreeData, setBaseHierarchyTreeData] = useState<ReadonlyArray<SampleJourneyTree>>([]);
  const [transitionInformation, setTransitionInformation] = useState<ReadonlyArray<SampleJourneyTransitionInformation>>(
    []
  );
  const [transitionSample, setTransitionSample] = useState<ReadonlyArray<TransitionSampleDetailsDto>>([]);
  const [samples, setSamples] = useState<ReadonlyArray<Sample>>([]);
  const [identifiersBySampleId, setIdentifiersBySampleId] = useState<Dictionary<string>>({});
  const [identifiersByTransitionSampleId, setIdentifiersByTransitionSampleId] = useState<Dictionary<string>>({});
  const [useDecryptedHashes, setUseDecryptedHashes] = useState<boolean>(false);
  const [filterOutInputOrders, setFilterOutInputOrders] = useState<boolean>(true);
  const [showGlobalHistory, setShowGlobalHistory] = useState<boolean>(false);

  const [selectableCandidates, setSelectableCandidates] = useState<ReadonlyArray<SelectableCandidate>>([]);

  const [branchSpecificHierarchyData, setBranchSpecificHierarchyData] = useState<ReadonlyArray<SampleJourneyTree>>([]);
  const [branchSpecificTransitionSampleData, setBranchSpecificTransitionSampleData] = useState<
    ReadonlyArray<TransitionSampleDetailsDto>
  >([]);

  const [configuredTransitions] = useConfiguredTransitions(researchProjectId, setLoadingState);

  useEffect(() => {
    return LoadState(setLoadingState, async () => {
      if (accessToken && sampleIds && sampleIds.length > 0) {
        const data = flatMap(
          await Promise.all(
            sampleIds.map(async id => await GetHierarchyForSampleId(id, accessToken, useDecryptedHashes))
          )
        );

        if (data === null) {
          return;
        }

        const allSamples = uniqBy(
          data.flatMap(d => [d.root, ...d.hierarchy.map(h => h.ancestor), ...d.hierarchy.map(h => h.descendant)]),
          i => i.sampleId
        );
        const allSampleIds = allSamples.map(i => i.sampleId);

        setLoadingState({ status: 'Loading' });
        const informationData = await GetSampleJourneyTransitionInformation(
          allSampleIds,
          useDecryptedHashes,
          accessToken
        );

        setLoadingState({ status: 'Loading' });
        const transitionSamplesData = compact(
          flatMap(
            await Promise.all(
              chunk(allSampleIds, 10).map(async ids => await GetAllTransitionAndTransitionSamples(ids, accessToken))
            )
          )
        );

        const sampleById = keyBy(allSamples, i => i.sampleId);
        const informationById = keyBy(informationData, i => i.sampleId);

        const identifiersByTransitionSampleId = mapValues(
          keyBy(transitionSamplesData, i => i.transitionSample.transitionSampleId),
          i => {
            const sample = sampleById[i.transitionSample.sampleId];
            return getSampleIdentifier(sample, i.labAssignedSampleLabel);
          }
        );

        const identifiers = mapValues(
          keyBy(allSampleIds, i => i),
          sampleId => {
            const info = informationById[sampleId];
            const sample = sampleById[sampleId];

            return getSampleIdentifier(
              sample,
              info?.currentLabAssignedSampleLabel ?? info?.labAssignedSampleLabelCreatedIn
            );
          }
        );

        setSamples(allSamples);
        setIdentifiersBySampleId(identifiers);
        setIdentifiersByTransitionSampleId(identifiersByTransitionSampleId);
        setTransitionInformation(informationData);
        setTransitionSample(transitionSamplesData);
        setBaseHierarchyTreeData(data.map(tree => ConvertToSampleJourneyTree(tree)));
      }
    });
  }, [accessToken, sampleIds, useDecryptedHashes]);

  useEffect(() => {
    const hierarchyData = compact(baseHierarchyTreeData.map(tree => GetSampleSpecificBranch(tree, selectedSampleIds)));
    setBranchSpecificHierarchyData(hierarchyData);

    const allSampleIds = GetAllSampleIds(hierarchyData);
    const allLeafSampleIds = GetAllLeafSampleIds(hierarchyData);

    setLeafSampleIds(allLeafSampleIds);
    setBranchSpecificTransitionSampleData(
      transitionSample.filter(i => allSampleIds.includes(i.transitionSample.sampleId))
    );
  }, [baseHierarchyTreeData, selectedSampleIds, transitionSample]);

  useEffect(() => {
    const informationById = keyBy(transitionInformation, i => i.sampleId);
    const candidates = map(selectedSampleIds, sampleId => {
      const info = informationById[sampleId];
      return { sampleId: info?.sampleId ?? '', sampleJourneyId: info?.sampleJourneyId ?? '' };
    }).filter(i => i.sampleId);

    setSelectableCandidates(candidates);
  }, [selectedSampleIds, transitionInformation]);

  function handleClose() {
    onClose();
    setModalOpen(false);
  }

  return (
    <Dialog maxWidth={'xl'} fullWidth={true} open={modalOpen}>
      <DialogTitle sx={{ minWidth: '300px', paddingBottom: 0 }}>
        <DialogCloseButton onClick={() => handleClose()} />
        {t('sampleHierarchy')}
        <DialogTypography variant='subtitle1' sx={{ maxWidth: '75%' }}>
          {t('sampleHierarchyInfo')}
        </DialogTypography>
      </DialogTitle>
      <DialogContent>
        <Box mb={1}>
          <Tabs
            value={selectedTab}
            onChange={(event: React.SyntheticEvent, newValue: PageTabs) => {
              setSelectedTab(newValue);
            }}
            sx={{ borderBottom: 1, borderColor: 'divider', marginBottom: 0 }}
          >
            <Tab value='history' label={t('history')} sx={{ color: theme.colors.alpha.black[100] }} />
            <Tab value='hierarchy' label={t('hierarchy')} sx={{ color: theme.colors.alpha.black[100] }} />
            <Tab value='informatics' label={t('informatics')} sx={{ color: theme.colors.alpha.black[100] }} />
          </Tabs>
        </Box>
        <FlexTableBox sx={{ height: '60vh' }}>
          {selectedTab === 'history' && (
            <SampleJourneyHistoryGrid
              researchProjectId={researchProjectId}
              identifiersByTransitionSampleId={identifiersByTransitionSampleId}
              transitionInformation={transitionInformation}
              transitionSample={branchSpecificTransitionSampleData}
              selectedSampleIds={selectedSampleIds}
              setSelectedSampleIds={setSelectedSampleIds}
              filterOutInputOrders={filterOutInputOrders}
              showGlobalHistory={showGlobalHistory}
              samples={samples}
              configuredTransitions={configuredTransitions}
              loadingProps={[loadingState, setLoadingState]}
              leftActions={
                <LeftActions
                  selectedTab={selectedTab}
                  filterOutInputOrderProps={[filterOutInputOrders, setFilterOutInputOrders]}
                  showGlobalHistoryProps={[showGlobalHistory, setShowGlobalHistory]}
                  decryptedHashesProps={[useDecryptedHashes, setUseDecryptedHashes]}
                />
              }
              rightActions={<RightActions selectableCandidates={selectableCandidates} />}
              leafSampleIds={leafSampleIds}
            />
          )}
          {selectedTab === 'hierarchy' && (
            <SampleJourneyHierarchyGrid
              identifiersBySampleId={identifiersBySampleId}
              transitionInformation={transitionInformation}
              hierarchyTreeData={branchSpecificHierarchyData}
              selectedSampleIds={selectedSampleIds}
              setSelectedSampleIds={setSelectedSampleIds}
              configuredTransitions={configuredTransitions}
              loadingProps={[loadingState, setLoadingState]}
              leftActions={
                <LeftActions
                  selectedTab={selectedTab}
                  filterOutInputOrderProps={[filterOutInputOrders, setFilterOutInputOrders]}
                  showGlobalHistoryProps={[showGlobalHistory, setShowGlobalHistory]}
                  decryptedHashesProps={[useDecryptedHashes, setUseDecryptedHashes]}
                />
              }
              rightActions={<RightActions selectableCandidates={selectableCandidates} />}
            />
          )}
          {selectedTab === 'informatics' && (
            <SampleJourneyInformaticsResultGrid
              identifiersBySampleId={identifiersBySampleId}
              transitionInformation={transitionInformation}
              selectedSampleIds={selectedSampleIds}
              loadingProps={[loadingState, setLoadingState]}
              leftActions={
                <LeftActions
                  selectedTab={selectedTab}
                  filterOutInputOrderProps={[filterOutInputOrders, setFilterOutInputOrders]}
                  showGlobalHistoryProps={[showGlobalHistory, setShowGlobalHistory]}
                  decryptedHashesProps={[useDecryptedHashes, setUseDecryptedHashes]}
                />
              }
              rightActions={<RightActions selectableCandidates={selectableCandidates} />}
            />
          )}
        </FlexTableBox>
      </DialogContent>
      <LoadingIndicator loadingState={loadingState} margin={'LRB'} />
      <ErrorIndicator loadingState={loadingState} />
    </Dialog>
  );
};

const LeftActions = ({
  selectedTab,
  filterOutInputOrderProps,
  showGlobalHistoryProps,
  decryptedHashesProps,
}: {
  selectedTab: PageTabs;
  filterOutInputOrderProps: UseState<boolean>;
  showGlobalHistoryProps: UseState<boolean>;
  decryptedHashesProps: UseState<boolean>;
}) => {
  const { t } = useMemoTranslation();

  const [filterOutInputOrders, setFilterOutInputOrders] = filterOutInputOrderProps;
  const [showGlobalHistory, setShowGlobalHistory] = showGlobalHistoryProps;

  return (
    <FlexBox>
      <DecryptForeignHashesSwitch decryptedHashesProps={decryptedHashesProps} />
      {selectedTab === 'history' && (
        <>
          <CustomizableSwitch
            title={t('filterOutInputOrders')}
            checked={filterOutInputOrders}
            onChange={() => {
              setFilterOutInputOrders(!filterOutInputOrders);
            }}
          />
          <CustomizableSwitch
            title={t('showGlobalHistory')}
            checked={showGlobalHistory}
            onChange={() => {
              setShowGlobalHistory(!showGlobalHistory);
            }}
          />
        </>
      )}
    </FlexBox>
  );
};

const RightActions = ({ selectableCandidates }: { selectableCandidates: ReadonlyArray<SelectableCandidate> }) => {
  return <SampleLineageDataDialog candidates={selectableCandidates} />;
};
