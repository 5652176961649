import React, { MutableRefObject, useMemo } from 'react';
import { GridColDef, GridComparatorFn, GridRenderCellParams } from '@mui/x-data-grid-pro';
import { PatientCountType } from 'components/grid/GridCountType';
import { PathogenGroup } from 'data/PathogenGroupData';
import { PatientCountGridCell, PatientCountGridCellProps } from './PatientCountGridCell';
import useMemoTranslation from 'hooks/UseMemoTranslation';
import { deletePathogenGroup, pathogenGroup, refreshedAt, snomedConceptIds, total } from 'util/Constants';
import { DeletePathogenGroupGridCell, DeletePathogenGroupGridCellProps } from './DeletePathogenGroupGridCell';
import { orderBy } from 'lodash';
import { ZeroCountGridCell } from 'components/grid/cell/ZeroCountGridCell';
import { CompactGridWrapper } from 'components/grid/CompactGridWrapper';
import {
  PathogenGroupMetrics,
  PathogenGroupPatientCountByLab,
  PathogenGroupPatientCountBySampleType,
} from 'data/PathogenGroupMetricsData';
import { GridApiPro } from '@mui/x-data-grid-pro/models/gridApiPro';
import { renderCellTooltip } from 'components/grid/cell/GridCellTooltip';
import { TimeInterval } from 'data/PatientRecordOptions';
import { LoadingState } from '../components/LoadingStateUtil';
import { AuthorizedSection } from '../auth/AuthorizedSection';

export interface PathogenGroupCountGridProps {
  loadingState: LoadingState;
  pathogenGroupMetrics: PathogenGroupMetrics[];
  countType: PatientCountType;
  timeInterval: TimeInterval;
  onPathogenGroupDelete: Function;
  onPatientCountClick(
    count: number,
    pathogenGroup: PathogenGroup,
    countType: PatientCountType,
    labId?: string,
    sampleTypeId?: string
  ): void;
  apiRef?: MutableRefObject<GridApiPro>;
}

export const PathogenGroupCountGrid = ({
  loadingState,
  pathogenGroupMetrics,
  countType,
  timeInterval,
  onPathogenGroupDelete,
  onPatientCountClick,
  apiRef,
}: PathogenGroupCountGridProps) => {
  const columns = useColumns(pathogenGroupMetrics, countType, timeInterval, onPatientCountClick, onPathogenGroupDelete);
  const rows = useRows(pathogenGroupMetrics, countType, timeInterval, onPatientCountClick, onPathogenGroupDelete);

  return (
    <CompactGridWrapper
      loadingState={loadingState}
      apiRef={apiRef}
      rows={rows}
      columns={columns}
      initialState={{
        pinnedColumns: { left: [pathogenGroup, snomedConceptIds, total] },
        sorting: { sortModel: [{ field: pathogenGroup, sort: 'asc' }] },
      }}
      columnsToAutoSize={columns.map(c => c.field).filter(c => c !== snomedConceptIds)}
      hideFooterRowCount
      hideFooterSelectedRowCount
      disableRowSelectionOnClick
    />
  );
};

const useColumns = (
  pathogenGroupMetrics: PathogenGroupMetrics[],
  countType: PatientCountType,
  timeInterval: TimeInterval,
  onPatientCountClick: (
    count: number,
    pathogenGroup: PathogenGroup,
    pathogenGroupCountType: PatientCountType,
    labId?: string,
    sampleTypeId?: string
  ) => void,
  onPathogenGroupDelete: Function
): GridColDef[] => {
  const { t } = useMemoTranslation();

  return useMemo(() => {
    const startColumns: GridColDef[] = [
      {
        field: pathogenGroup,
        headerName: t(pathogenGroup),
        width: 220,
        filterable: false,
        renderCell: renderCellTooltip,
      },
      {
        field: snomedConceptIds,
        headerName: t(snomedConceptIds),
        headerAlign: 'center',
        align: 'right',
        width: 120,
      },
      {
        field: total,
        headerName: t(total),
        type: 'number',
        width: 170,
        headerAlign: 'center',
        align: 'center',
        filterable: false,
        valueFormatter: ({ value }) => value && value.count,
        sortComparator: patientCountComparator,
        renderCell: (params: GridRenderCellParams<PatientCountGridCellProps>) => {
          if (params.value) {
            return (
              <PatientCountGridCell
                pathogenGroup={params.value.pathogenGroup}
                labId={params.value.labId}
                sampleTypeId={params.value.sampleTypeId}
                count={params.value.count}
                countType={params.value.countType}
                onPatientCountClick={onPatientCountClick}
              />
            );
          } else {
            return <ZeroCountGridCell />;
          }
        },
      },
    ];

    const countColumns: GridColDef[] = [];

    const columnFields =
      countType === 'byLab'
        ? GetLabColumnFields(timeInterval, pathogenGroupMetrics)
        : GetSampleTypeColumnFields(timeInterval, pathogenGroupMetrics);

    columnFields.forEach(field => {
      countColumns.push({
        field: field.id,
        headerName: field.name,
        type: 'number',
        width: 170,
        headerAlign: 'center',
        align: 'center',
        filterable: false,
        valueFormatter: ({ value }) => value && value.count,
        sortComparator: patientCountComparator,
        renderCell: (params: GridRenderCellParams<PatientCountGridCellProps>) => {
          if (params.value) {
            return (
              <PatientCountGridCell
                pathogenGroup={params.value.pathogenGroup}
                labId={params.value.labId}
                sampleTypeId={params.value.sampleTypeId}
                count={params.value.count}
                countType={params.value.countType}
                onPatientCountClick={onPatientCountClick}
              />
            );
          } else {
            return <ZeroCountGridCell />;
          }
        },
      });
    });

    const endColumns: GridColDef[] = [
      {
        field: deletePathogenGroup,
        headerName: t(deletePathogenGroup),
        width: 70,
        headerAlign: 'center',
        align: 'center',
        renderCell: (params: GridRenderCellParams<DeletePathogenGroupGridCellProps>) => {
          return (
            <AuthorizedSection hasGreenSideWriteAccess>
              <DeletePathogenGroupGridCell
                pathogenGroup={params.value?.pathogenGroup}
                onDeletePathogenGroupClick={onPathogenGroupDelete}
              />
            </AuthorizedSection>
          );
        },
      },
      {
        field: refreshedAt,
        headerName: t(refreshedAt),
        type: 'date',
        width: 150,
        headerAlign: 'center',
        align: 'center',
        filterable: false,
        renderCell: (params: GridRenderCellParams<Date>) => (
          <span title={params.value ? params.value.toString() : ''}>
            {params.value
              ? params.value.toLocaleString('en-US', { year: 'numeric', month: 'short', day: 'numeric' })
              : ''}
          </span>
        ),
      },
    ];

    return startColumns.concat(countColumns, endColumns);
  }, [t, countType, timeInterval, pathogenGroupMetrics, onPatientCountClick, onPathogenGroupDelete]);
};

const useRows = (
  pathogenGroupMetrics: PathogenGroupMetrics[],
  countType: PatientCountType,
  timeInterval: TimeInterval,
  onPatientCountClick: (
    count: number,
    pathogenGroup: PathogenGroup,
    pathogenGroupCountType: PatientCountType,
    labId?: string,
    sampleTypeId?: string
  ) => void,
  onPathogenGroupDelete: Function
) => {
  return useMemo(() => {
    const rows: any[] = [];

    pathogenGroupMetrics.forEach((p, index) => {
      let row: any = {};
      const pathogenGroup: PathogenGroup = {
        pathogenGroupId: p.pathogenGroupId,
        name: p.pathogenGroupName,
        snomedClinicalTermIds: p.snomedClinicalTermIds,
        snomedConceptIds: p.snomedConceptIds,
      };

      let deletePathogenGroup: DeletePathogenGroupGridCellProps = {
        pathogenGroup: pathogenGroup,
        onDeletePathogenGroupClick: onPathogenGroupDelete,
      };

      let totalCount: PatientCountGridCellProps = {
        pathogenGroup: pathogenGroup,
        countType: 'all',
        count: getTotalPatientCount(timeInterval, p),
        onPatientCountClick: onPatientCountClick,
      };

      row.id = index;
      row.pathogenGroup = p.pathogenGroupName;
      row.snomedConceptIds = p.snomedConceptIds.sort().join(', ');
      row.total = totalCount;
      row.deletePathogenGroup = deletePathogenGroup;
      row.refreshedAt = p.refreshedAt ? new Date(p.refreshedAt) : undefined;

      if (countType === 'byLab') {
        p.patientCountsByLab.forEach(c => {
          let count: PatientCountGridCellProps = {
            pathogenGroup: pathogenGroup,
            countType: countType,
            labId: c.labId,
            count: getPatientCountByLab(timeInterval, c),
            onPatientCountClick: onPatientCountClick,
          };

          Object.defineProperty(row, `${c.labId}`, { value: count, writable: true });
        });
      } else {
        p.patientCountsBySampleType.forEach(c => {
          let count: PatientCountGridCellProps = {
            pathogenGroup: pathogenGroup,
            countType: countType,
            sampleTypeId: c.sampleTypeId,
            count: getPatientCountBySampleType(timeInterval, c),
            onPatientCountClick: onPatientCountClick,
          };

          Object.defineProperty(row, `${c.sampleTypeId}`, { value: count, writable: true });
        });
      }

      rows.push(row);
    });

    return rows;
  }, [countType, timeInterval, onPathogenGroupDelete, onPatientCountClick, pathogenGroupMetrics]);
};

function GetLabColumnFields(timeInterval: TimeInterval, pathogenGroupMetrics: PathogenGroupMetrics[]) {
  let counts = pathogenGroupMetrics.flatMap(m => m.patientCountsByLab);
  let labIdToName = new Map<string, string>();
  let totals = new Map<string, number>();

  counts.forEach(count => {
    let labCount = getPatientCountByLab(timeInterval, count);

    if (totals.has(count.labId)) {
      let labTotal = totals.get(count.labId) ?? 0;
      totals.set(count.labId, labTotal + labCount);
    } else {
      labIdToName.set(count.labId, count.lab);
      totals.set(count.labId, labCount);
    }
  });

  let totalArray = Array.from(totals, ([id, totalPatientCount]) => ({ id, totalPatientCount })).filter(
    c => c.totalPatientCount > 0
  );
  let result = orderBy(totalArray, ['totalPatientCount', 'labId'], ['desc', 'asc']);

  return result.map(c => ({
    id: c.id,
    name: labIdToName.get(c.id) ?? '',
  }));
}

function GetSampleTypeColumnFields(timeInterval: TimeInterval, pathogenGroupMetrics: PathogenGroupMetrics[]) {
  let counts = pathogenGroupMetrics.flatMap(m => m.patientCountsBySampleType);
  let sampleIdToName = new Map<string, string>();
  let totals = new Map<string, number>();

  counts.forEach(count => {
    let sampleTypeCount = getPatientCountBySampleType(timeInterval, count);

    if (totals.has(count.sampleTypeId)) {
      let sampleTypeTotal = totals.get(count.sampleTypeId) ?? 0;
      totals.set(count.sampleTypeId, sampleTypeTotal + sampleTypeCount);
    } else {
      sampleIdToName.set(count.sampleTypeId, count.sampleType);
      totals.set(count.sampleTypeId, sampleTypeCount);
    }
  });

  let totalArray = Array.from(totals, ([id, totalPatientCount]) => ({ id, totalPatientCount })).filter(
    c => c.totalPatientCount > 0
  );
  let result = orderBy(totalArray, ['totalPatientCount', 'sampleTypeId'], ['desc', 'asc']);

  return result.map(c => ({
    id: c.id,
    name: sampleIdToName.get(c.id) ?? '',
  }));
}

const patientCountComparator: GridComparatorFn = (v1, v2) => {
  let val1 = v1 ?? 0;
  let val2 = v2 ?? 0;
  return (val1 as PatientCountGridCellProps).count - (val2 as PatientCountGridCellProps).count;
};

function getTotalPatientCount(timeInterval: TimeInterval, pathogenGroupMetrics: PathogenGroupMetrics) {
  switch (timeInterval) {
    case 'Week':
      return pathogenGroupMetrics.patientCounts.pastWeek;
    case 'Month':
      return pathogenGroupMetrics.patientCounts.pastMonth;
    case 'ThreeMonths':
      return pathogenGroupMetrics.patientCounts.pastThreeMonths;
    case 'Year':
      return pathogenGroupMetrics.patientCounts.pastYear;
    case 'AllTime':
      return pathogenGroupMetrics.patientCounts.allTime;
    default:
      return 0;
  }
}

function getPatientCountByLab(timeInterval: TimeInterval, byLabCount: PathogenGroupPatientCountByLab) {
  switch (timeInterval) {
    case 'Week':
      return byLabCount.pastWeek;
    case 'Month':
      return byLabCount.pastMonth;
    case 'ThreeMonths':
      return byLabCount.pastThreeMonths;
    case 'Year':
      return byLabCount.pastYear;
    case 'AllTime':
      return byLabCount.allTime;
    default:
      return 0;
  }
}

function getPatientCountBySampleType(
  timeInterval: TimeInterval,
  bySampleTypeCount: PathogenGroupPatientCountBySampleType
) {
  switch (timeInterval) {
    case 'Week':
      return bySampleTypeCount.pastWeek;
    case 'Month':
      return bySampleTypeCount.pastMonth;
    case 'ThreeMonths':
      return bySampleTypeCount.pastThreeMonths;
    case 'Year':
      return bySampleTypeCount.pastYear;
    case 'AllTime':
      return bySampleTypeCount.allTime;
    default:
      return 0;
  }
}
