import { StateCreator } from 'zustand';
import { AccessTokenSlice } from './accessTokenSlice';
import { GetResearchProjectApprovalPatients, PatientApprovalPatient } from 'data/PatientApprovalData';
import { initialLoadableData, LoadableData, loadedData, trackLoading } from '../../utils/loadingUtils';

export interface ResearchProjectPatientsSlice {
  researchProjectPatients: LoadableData<PatientApprovalPatient[]>;
  setResearchProjectPatients: (researchProjectPatients: PatientApprovalPatient[]) => void;
  loadResearchProjectPatientsFromServer: (researchProjectId: string) => void;
}

export const createResearchProjectPatientsSlice: StateCreator<
  AccessTokenSlice & ResearchProjectPatientsSlice,
  [],
  [],
  ResearchProjectPatientsSlice
> = (set, get) => ({
  researchProjectPatients: initialLoadableData(),
  setResearchProjectPatients: researchProjectPatients =>
    set({ researchProjectPatients: loadedData(researchProjectPatients) }),
  loadResearchProjectPatientsFromServer: (researchProjectId: string) => {
    const accessToken = get().accessToken;

    if (accessToken === undefined) {
      return;
    }

    trackLoading(
      () => GetResearchProjectApprovalPatients(researchProjectId, accessToken),
      state => set({ researchProjectPatients: state })
    );
  },
});
