import useAuth from '../../auth/UseAuth';
import { useEffect, useState } from 'react';
import { LoadingState, LoadState } from '../LoadingStateUtil';
import { UseStateSetter } from '../../util/TypeUtil';
import {
  GetDiseaseAreaMeasurementDefinitions,
  LinkedMeasurementDefinition,
} from '../../data/MeasurementDefinitionData';

export const useDiseaseAreaMeasurementDefinitions = (
  diseaseAreaId: string,
  setLoadingState?: UseStateSetter<LoadingState>
): [ReadonlyArray<LinkedMeasurementDefinition>, () => void] => {
  const { accessToken } = useAuth();
  const [measurementDefinitions, setMeasurementDefinitions] = useState<ReadonlyArray<LinkedMeasurementDefinition>>([]);
  const [refresh, refreshTrigger] = useState<boolean>(true);

  useEffect(() => {
    return LoadState(setLoadingState, async () => {
      if (!accessToken) {
        return;
      }

      const data = await GetDiseaseAreaMeasurementDefinitions(diseaseAreaId, accessToken);
      setMeasurementDefinitions(data);
    });
  }, [diseaseAreaId, refresh, accessToken, setLoadingState]);

  function refreshCallBack() {
    refreshTrigger(!refresh);
  }

  return [measurementDefinitions, refreshCallBack];
};
