import React, { useState, useEffect } from 'react';
import { Box, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@mui/material';
import useAuth from 'auth/UseAuth';
import { CancelButton } from 'components/CancelButton';
import { PrimaryButton } from 'components/PrimaryButton';
import { DialogCloseButton } from '../../components/DialogCloseButton';
import { DialogOpenButton } from '../../components/DialogOpenButton';
import { ErrorIndicator } from '../../components/ErrorIndicator';
import { LoadingIndicator } from '../../components/LoadingIndicator';
import { ErrorManagement, LoadingState } from '../../components/LoadingStateUtil';
import { CreateIssue, UpdateIssue, SdfIssue, SdfIssueType } from '../../data/SuperDuperFiestaData';
import { AutocompleteWrapper } from 'components/AutocompleteWrapper';

export const CreateIssueModal = ({
  onSave,
  issue,
  issueTypes,
  isOpen,
  onClose,
}: {
  onSave?: (issue: SdfIssue) => void;
  issue?: SdfIssue | null;
  issueTypes: SdfIssueType[];
  isOpen?: boolean;
  onClose?: () => void;
}) => {
  const { accessToken } = useAuth();

  const [loadingState, setLoadingState] = useState<LoadingState>({ status: 'NotStarted' });
  const [open, setOpen] = useState<boolean>(false);
  const [name, setName] = useState<string>(issue?.name ?? '');
  const [selectedIssueType, setSelectedIssueType] = useState<SdfIssueType | null>(
    issue ? issueTypes.find(t => t.issueTypeId === issue.issueTypeId) ?? null : null
  );

  useEffect(() => {
    if (isOpen !== undefined) {
      setOpen(isOpen);

      if (!isOpen) {
        setName('');
        setSelectedIssueType(null);
        setLoadingState({ status: 'NotStarted' });
      } else if (issue) {
        setName(issue.name);
        setSelectedIssueType(issueTypes.find(t => t.issueTypeId === issue.issueTypeId) ?? null);
      }
    }
  }, [isOpen, issue, issueTypes]);

  const handleOpen = async () => {
    setOpen(true);
  };

  const handleSave = async () => {
    ErrorManagement('Loading', setLoadingState, async () => {
      if (!name || !selectedIssueType) {
        return;
      }

      const issueData: SdfIssue = {
        ...(issue ?? {}),
        name: name,
        issueTypeId: selectedIssueType.issueTypeId,
      };

      const response = issue?.issueId
        ? await UpdateIssue(issue.issueId, issueData, accessToken)
        : await CreateIssue(issueData, accessToken);

      if (onSave) {
        onSave(response);
      }

      handleClose();
    });
  };

  const handleClose = () => {
    setOpen(false);
    onClose?.();
    setName('');
    setSelectedIssueType(null);
    setLoadingState({ status: 'NotStarted' });
  };

  return (
    <>
      <DialogOpenButton
        title={isOpen !== undefined ? 'Create Issue' : ''}
        onClick={handleOpen}
        sx={{ pr: 0, pl: 0, minWidth: '10%' }}
      />
      <Dialog open={open} onClose={handleClose} fullWidth maxWidth='sm'>
        <DialogTitle>
          <DialogCloseButton onClick={handleClose} />
          {issue ? 'Edit Issue' : 'Create Issue'}
        </DialogTitle>
        <DialogContent>
          <Box component='form' sx={{ flexGrow: 1, mt: 1 }}>
            <TextField
              fullWidth
              size='small'
              label='Name'
              value={name}
              sx={{ mb: '4px', mt: '8px' }}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setName(event.target.value);
              }}
            />
            <AutocompleteWrapper
              fullWidth
              value={selectedIssueType}
              options={issueTypes}
              getOptionLabel={option => (typeof option === 'string' ? option : option.displayName)}
              isOptionEqualToValue={(option: any, value: any) =>
                typeof option === 'string' ? option === value : option.displayName === value.displayName
              }
              renderInput={params => <TextField {...params} label={'IssueType'} margin='dense' required />}
              onChange={(_, value) => setSelectedIssueType(value)}
            />
          </Box>
        </DialogContent>
        <LoadingIndicator loadingState={loadingState} margin={'LR'} />
        <ErrorIndicator loadingState={loadingState} />
        <DialogActions>
          <CancelButton onClick={handleClose} disabled={loadingState.status === 'Loading'}>
            Cancel
          </CancelButton>
          <PrimaryButton onClick={handleSave} disabled={loadingState.status === 'Loading'}>
            Save
          </PrimaryButton>
        </DialogActions>
      </Dialog>
    </>
  );
};
