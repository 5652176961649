import { addMinutes, addWeeks, differenceInWeeks, Interval, subMilliseconds, subMinutes } from 'date-fns';
import { DateRange } from '../models/DateRange';

// Converts the given date from the local time zone to UTC, which is not really a conversion but instead a shifting of
// the date such that date-fns functions like `startOfWeek` will produce a result as if the date were in UTC time.
export const toUtc = (date: Date): Date => {
  return addMinutes(date, date.getTimezoneOffset());
};

// Converts the given date from UTC to the local time zone, which is the inverse of `toUtc` and should probably only
// ever be used on dates that had `toUtc` called somewhere upstream on them.
export const fromUtc = (date: Date): Date => {
  return subMinutes(date, date.getTimezoneOffset());
};

// Helper type to convert types to tuples so that we can accept functions that expect that same number and type of
// arguments. This allows us to write functions like `usingUtc` in such a way that an example usage like
// `usingUtc([date1, date2], (utcDate1, utcDate2) => {...})` will compile but something like
// `usingUtc([date1, date2], (utcDate1, utcDate2, utcDate3) => {...})` will not because the given function will only
// receive two arguments.
type Tuple<T> = { [P in keyof T]: T[P] };

type NonDate<T> = T extends Date ? never : T extends Date[] ? never : T;

const usingUtc = <T extends Date[], R>(dates: Tuple<T>, fn: (...utcDates: Tuple<T>) => R): R => {
  const utcDates = dates.map(toUtc) as Tuple<T>;
  return fn(...utcDates);
};

// Helper function for performing date-fns operations on dates. date-fns operates on all of its dates in the local time
// zone, so for example if you call `startOfWeek` on a date whose value is on a Saturday in the local time zone but on
// the following Sunday in UTC you will get a date six days before the given date instead of one day later. To get a
// result based on UTC you first need to "convert the date to UTC" (which is really shifting it by the local time zone
// offset to get a different date), perform any date-fns operations on the UTC date, and then convert the result back to
// the local time zone.
//
// We want all the date operations in this application to operate on UTC time so that the results are consistent no
// matter what the local time zone is, so this function exists to make that more convenient. It takes in a tuple of
// dates in the local time zone and converts them to UTC before passing them to the given function. Note that by design
// this function does *not* allow returning date values. This is a safety net to prevent accidentally returning a date
// that is still in UTC without realizing it. There's technically nothing wrong with this if that's really what you
// meant, but we probably don't ever want that so for now it's a compiler error. For functions that produce dates that
// should be converted back to local time automatically use `dateUsingUtc` instead.
export const valueUsingUtc = <T extends Date[], R>(dates: Tuple<T>, fn: (...utcDates: Tuple<T>) => NonDate<R>): R => {
  return usingUtc(dates, fn);
};

// The same as `usingUtc` but for producing a result Date that is in the local time zone.
export const dateUsingUtc = <T extends Date[]>(dates: Tuple<T>, fn: (...utcDates: Tuple<T>) => Date): Date => {
  return fromUtc(usingUtc(dates, fn));
};

export const formatAsUtcDate = (date: Date): string => {
  return valueUsingUtc([date], d => d.toISOString().split('T')[0]);
};
export const formatAsUtcMonth = (date: Date): string => {
  return valueUsingUtc([date], d => d.toISOString().split('T')[0]).slice(0, -3);
};

export const yearStartDate = (year: number): Date => {
  return new Date(year, 0, 1);
};
export const utcYearStartDate = (year: number): Date => {
  return new Date(Date.UTC(year, 0, 1));
};

export const dateToRangeWeekOffset = (date: Date, dateRangeBounds: DateRange): number => {
  return valueUsingUtc([date, dateRangeBounds.earliest], (utcDate, utcEarliest) =>
    differenceInWeeks(utcDate, utcEarliest)
  );
};

export const rangeWeekOffsetToDate = (weekOffset: number, dateRangeBounds: DateRange): Date => {
  return dateUsingUtc([dateRangeBounds.earliest], d => addWeeks(d, weekOffset));
};

export const toExclusiveInterval = (dateRange: DateRange): Interval => {
  return { start: dateRange.earliest, end: subMilliseconds(dateRange.latest, 1) };
};

export const toUtcInterval = (dateRange: DateRange): Interval => {
  return { start: toUtc(dateRange.earliest), end: toUtc(dateRange.latest) };
};
