import React, { useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Stack, TextField, useTheme } from '@mui/material';
import { t } from 'i18next';

interface ConfirmDeleteProductDialogProps {
  open: boolean;
  name: string;
  onConfirm: () => unknown;
  onCancel: () => unknown;
}

export const ConfirmDeleteProductDialog: React.FC<ConfirmDeleteProductDialogProps> = ({
  open,
  name,
  onConfirm,
  onCancel,
}) => {
  const theme = useTheme();

  const [enteredName, setEnteredName] = useState('');

  return (
    <Dialog
      open={open}
      onClose={() => {
        setEnteredName('');
        onCancel();
      }}
      fullWidth
      maxWidth='xs'
    >
      <DialogTitle>
        {t('delete')} {name}?
      </DialogTitle>
      <DialogContent sx={{ paddingTop: `${theme.spacing(1)} !important` }}>
        <Stack direction='column'>
          <TextField
            label={t('productName')}
            placeholder={t('enterProductNameToConfirm')}
            value={enteredName}
            onChange={e => setEnteredName(e.target.value)}
          />
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button
          variant='outlined'
          color='error'
          onClick={() => {
            setEnteredName('');
            onCancel();
          }}
        >
          {t('cancel')}
        </Button>
        <Button
          variant='contained'
          color='primary'
          onClick={() => {
            setEnteredName('');
            onConfirm();
          }}
          disabled={enteredName !== name}
        >
          {t('delete')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
