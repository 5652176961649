import { Container, TextField } from '@mui/material';

import React from 'react';

const ReviewNotes: React.FC<{
  reviewNotes: string | null;
  setReviewNotes: (reviewNotes: string) => void;
}> = ({ reviewNotes, setReviewNotes }) => {
  return (
    <Container maxWidth={false} disableGutters={true} sx={{ margin: '0', marginBottom: '16px' }}>
      <TextField
        label='Review notes'
        multiline={false}
        fullWidth={true}
        value={reviewNotes ? reviewNotes : ''} // Workaround for label not moving on initial value set
        onChange={event => setReviewNotes(event.target.value)}
      />
    </Container>
  );
};

export default ReviewNotes;
