import React from 'react';

import {
  CrisisAlertTwoTone,
  DeviceThermostatTwoTone,
  HealingTwoTone,
  MedicationTwoTone,
  QuestionMarkTwoTone,
  ScienceTwoTone,
} from '@mui/icons-material';

const MeasurementTypeAvatar: React.FC<{ type: string }> = ({ type }) => {
  const style = {
    marginRight: '0.5em',
  };

  const component =
    type === 'lab' ? (
      <ScienceTwoTone style={style} />
    ) : type === 'observation' ? (
      <DeviceThermostatTwoTone style={style} />
    ) : type === 'medication' ? (
      <MedicationTwoTone style={style} />
    ) : type === 'procedure' ? (
      <HealingTwoTone style={style} />
    ) : type === 'problem' ? (
      <CrisisAlertTwoTone style={style} />
    ) : (
      <QuestionMarkTwoTone style={style} />
    );

  return component;
};

export default MeasurementTypeAvatar;
