import React, { useEffect, useState } from 'react';
import { DataSchema, DataSchemaTable } from '../../data/DataSchemaData';
import { Box, Stack, Typography } from '@mui/material';
import { TablePickerTable } from '../common/TablePickerTable';
import { FieldsViewerTable } from './FieldsViewerTable';
import { t } from 'i18next';
import { LoadingState } from '../../components/LoadingStateUtil';

interface SchemaViewerProps {
  loadingState: LoadingState;
  dataSchema: DataSchema | null;
}

export const SchemaViewer: React.FC<SchemaViewerProps> = ({ loadingState, dataSchema }) => {
  const [selectedTableId, setSelectedTableId] = useState<string | null>(null);

  const [selectedTable, setSelectedTable] = useState<DataSchemaTable | null>(null);
  useEffect(() => {
    if (dataSchema === null) {
      setSelectedTable(null);
      return;
    }

    if (selectedTableId === null) {
      setSelectedTable(null);
      return;
    }

    const table = dataSchema.tables.find(t => t.dataSchemaTableId === selectedTableId);
    if (table === undefined) {
      setSelectedTable(null);
      return;
    }

    setSelectedTable(table);
  }, [dataSchema, selectedTableId]);

  return (
    <Stack flex={1} direction='row' spacing={2}>
      <Stack flex={1} direction='column' spacing={2}>
        <Typography flex={0} variant='h4'>
          {t('tables')}
        </Typography>
        <Box flex={1} sx={{ position: 'relative' }}>
          <Box sx={{ position: 'absolute', top: 0, left: 0, bottom: 0, right: 0 }}>
            <TablePickerTable
              loadingState={loadingState}
              tables={dataSchema?.tables ?? []}
              onTableSelected={newSelectedTableId => setSelectedTableId(newSelectedTableId)}
            />
          </Box>
        </Box>
      </Stack>
      <Stack flex={1} direction='column' spacing={2}>
        <Typography flex={0} variant='h4'>
          {t('fields')}
        </Typography>
        <Box flex={1} sx={{ position: 'relative' }}>
          <Box sx={{ position: 'absolute', top: 0, left: 0, bottom: 0, right: 0 }}>
            <FieldsViewerTable loadingState={loadingState} fields={selectedTable?.fields ?? []} />
          </Box>
        </Box>
      </Stack>
    </Stack>
  );
};
